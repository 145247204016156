import { Component, Input } from '@angular/core';
import { FlexiblePushReportings } from '../../../models/flexibles';
import { CommonModule } from '@angular/common';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive"; // Ajout de CommonModule

@Component({
  selector: 'app-flexible-push-reporting',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-push-reporting.component.html',
  styleUrl: './flexible-push-reporting.component.scss'
})
export class FlexiblePushReportingComponent {
  @Input() flexible!: FlexiblePushReportings;
}
