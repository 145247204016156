<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <div class="flexible-push-medias flexible-content">
    @for(media of flexible.medias; track media.id) {
      <div class="flexible-push-media-title" [ngClass]="{'video': media.typeMedia === 'video'}" (click)="openDialogMedia(media)">
        <div class="flexible-push-media-title--img">
        <img src="{{media.visuel.sizes?.['w338h226']}}"
            width="338"
            height="226"
            alt="{{media.visuel.alt}}" />
            </div>
           <div class="flexible-push-media-title--content" [ngClass]="{
    'background-darker-blue': media.cColor === 'bleu-fonce',
    'background-green': media.cColor === 'vert',
    'background-blue': media.cColor === 'bleu'
    }">

          <p [customInnerHTML]="media.title"></p>
        </div>
      </div>
    }
  </div>
</div>
