import { Routes } from '@angular/router';
import { ActualitesComponent } from './pages/actualites/actualites.component';
import { AuQuotidienComponent } from './pages/au-quotidien/au-quotidien.component';
import { BoiteAOutilsComponent } from './pages/boite-a-outils/boite-a-outils.component';
import { CollaborateursComponent } from './pages/collaborateurs/collaborateurs.component';
import { HomeComponent } from './pages/home/home.component';
import { QuelCapComponent } from './pages/quel-cap/quel-cap.component';
import { QuiSommeNousComponent } from './pages/qui-somme-nous/qui-somme-nous.component';
import { MesAppsComponent } from './pages/mes-apps/mes-apps.component';
import { MesServicesComponent } from './pages/mes-services/mes-services.component';
import { ServiceIntermediaireComponent } from './pages/service-intermediaire/service-intermediaire.component';
import { RechercheComponent } from './pages/recherche/recherche.component';
import { WpPageComponent } from './pages/wp-page/wp-page.component';
import { MsalGuard } from "@azure/msal-angular";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {ArticleComponent} from "./pages/article/article.component";

export const routes: Routes = [
    { path: '', component: HomeComponent, title: "Accueil", pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'article/:subpath**', component: ArticleComponent, canActivate: [MsalGuard] },
    { path: 'actualites', component: ActualitesComponent, title: "Actualités", canActivate: [MsalGuard] },
    { path: 'qui-sommes-nous', component: QuiSommeNousComponent, title: "Qui sommes-nous ?", canActivate: [MsalGuard] },
    { path: 'quel-cap', component: QuelCapComponent, title: "Quel cap?", canActivate: [MsalGuard] },
    { path: 'au-quotidien', component: AuQuotidienComponent, title: "Au quotidien", canActivate: [MsalGuard] },
    { path: 'collaborateurs', component: CollaborateursComponent, title: "Collaborateurs", canActivate: [MsalGuard] },
    { path: 'boite-a-outils', component: BoiteAOutilsComponent, title: "Boite à Outils", canActivate: [MsalGuard] },
    { path: 'mes-apps', component: MesAppsComponent, title: "Mes Apps", canActivate: [MsalGuard] },
    { path: 'mes-services', component: MesServicesComponent, title: "Mes Services", canActivate: [MsalGuard] },
    { path: 'service-intermediaire', component: ServiceIntermediaireComponent, title: "Service Intermédiaire", canActivate: [MsalGuard] },
    { path: 'recherche', component: RechercheComponent, title: "Recherche", canActivate: [MsalGuard] },
    { path: 'page-non-trouvee', component: PageNotFoundComponent, title: "Page non trouvée", canActivate: [MsalGuard] },
    { path: '**', component: WpPageComponent, canActivate: [MsalGuard]  } // Route fallback pour les URL non existantes
];
