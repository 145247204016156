<div class="flexible-similar-articles flexible-content">
  <h2 class="flexible-similar-articles__title">Articles similaires</h2>
  <div class="flexible-similar-articles__news">
    <swiper-container #swiperContainer1 class="swiper-container" init="false">
      @for (article of flexible.articles; track article.id) {
      <swiper-slide>
        <article class="news-secondary">
          <div class="flexible-similar-articles-img-wrapper">
            <picture>
              <source srcset="{{article.visuel.sizes?.['w360h190']}}" width="360" height="190" media="(min-width: 391px)" />
              <source srcset="{{article.visuel.sizes?.['w266h176']}}" width="266" height="176" media="(max-width: 390px)" />
              <img src="{{article.visuel.sizes?.['w360h190'] || article.visuel.url}}" width="360" height="190" alt="{{article.visuel.alt}}" />
            </picture>
            <div class="news-secondary-tags-container">
              <span *ngIf='article.theme' class="news-secondary-thematic">{{article.theme}}</span>
              <span *ngIf='article.theme' class="news-secondary-tag">{{article.perimetre}}</span>
            </div>
          </div>
          <div class="news-secondary-date">
            <time datetime="2024-04-08">
              <span class="news-secondary-day">08</span>
              <span class="news-secondary-month">AVR</span>
              <span class="news-secondary-year">2024</span>
            </time>
          </div>
          <div class="news-secondary-content">
            <h2 class="news-secondary-title" [customInnerHTML]="article.title"></h2>
          </div>
          <a href="{{ article.lien.url }}" class="news-secondary-link" href="">Accéder</a>
        </article>
      </swiper-slide>
      }
    </swiper-container>
    <div class="swiper-navigation">
      <div class="swiper-button-prev swiper-button-prev-1"></div>
      <div class="swiper-button-next swiper-button-next-1"></div>
    </div>
  </div>

  @if(flexible.medias && flexible.medias.length > 0) {
    <p class="flexible-similar-articles__others">Ces médias pourraient aussi vous intéresser...</p>

    <div class="flexible-similar-articles__on-pictures">
      <swiper-container #swiperContainer2 class="swiper-container" init="false">
        @for (media of flexible.medias; track media.id) {
          <swiper-slide>
            <div class="on-pictures__card">
              <div class="on-pictures__card-img">
                <img src="{{media.visuel.sizes?.['w255h145'] || media.visuel.url}}" width="255" height="145" alt="{{media.visuel.alt}}">
                <div class="news-secondary-tags-container">
                  <span *ngIf='media.theme' class="news-secondary-thematic">{{media.theme}}</span>
                  <span *ngIf='media.theme' class="news-secondary-tag">{{media.perimetre}}</span>
                </div>
              </div>
              <div class="on-pictures__card-content {{media.video? 'video' : ''}}">
                <p [customInnerHTML]="media.title"></p>
              </div>
              <a (click)="openDialogMedia(media)" class="on-pictures__card-link">Accéder</a>
            </div>
          </swiper-slide>
        }
      </swiper-container>
      <div class="swiper-navigation">
        <div class="swiper-button-prev swiper-button-prev-2"></div>
        <div class="swiper-button-next swiper-button-next-2"></div>
      </div>
    </div>
  }
</div>