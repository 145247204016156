import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef, HostListener,
  Input,
  ViewChild,
  Renderer2
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexibleProfilCard } from '../../../models/flexibles';
import {environment} from "../../../../environments/environment";
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-profil-card',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-profil-card.component.html',
  styleUrl: './flexible-profil-card.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FlexibleProfilCardComponent implements AfterViewInit {
  @Input() flexible!: FlexibleProfilCard;
  @ViewChild('swiperContainer') swiperContainer!: ElementRef;
  @ViewChild('prevButton') prevButton!: ElementRef;
  @ViewChild('nextButton') nextButton!: ElementRef;

  isSliderVisible: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.checkWindowWidth();

    const colTrees = this.el.nativeElement.querySelectorAll('.col-tree');
    const footer = this.el.nativeElement.querySelector('.card-item-footer');

    if (footer) {
      if (colTrees.length === 2) {
        this.renderer.setStyle(footer, 'justify-content', 'center');
      } else if (colTrees.length === 1) {
        this.renderer.setStyle(footer, 'justify-content', 'flex-start');
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkWindowWidth();
  }

  checkWindowWidth(): void {
    const width = window.innerWidth;
    const swiperEl = this.swiperContainer.nativeElement
    if (width < 768) {
      this.isSliderVisible = true;
      if(!swiperEl.swiper) {
        this.initializeSwiper();
      }
    } else {
      this.isSliderVisible = false;
    }
  }

  initializeSwiper(): void {
    const swiperEl = this.swiperContainer.nativeElement;
    // Swiper parameters
    const swiperParams = {
      slidesPerView: 'auto',
      direction: 'horizontal',
      loop: false,
      speed: 500,
      spaceBetween: 10,
      simulateTouch: true,  // Active les événements tactiles (inclut trackpad)
      allowTouchMove: true, // Permet le mouvement de swipe
      touchEventsTarget: 'container',  // Les événements sont capturés sur tout le conteneur
      cssMode: true, // Mode de défilement natif, fonctionne bien avec le trackpad
    };
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);
    // Initialize Swiper with the parameters
    swiperEl.initialize();
    environment.enableLogging && console.log('Swiper initialized');
  }

}
