<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <div [ngClass]="{'full': flexible.full_width}" class="flexible-arche flexible-content">
    <div class="flexible-arche--background"
         [ngClass]="{
           'background-darker-blue': flexible.aColor === 'bleu_fonce',
           'background-blue': flexible.aColor === 'bleu_clair',
           'background-white': flexible.aColor !== 'bleu_fonce' && flexible.aColor !== 'bleu_clair'
         }">
    </div>
    <h2 class="flexible-arche--title">{{ flexible.title }}</h2>
  </div>
</div>
