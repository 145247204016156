import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FlexibleSimilarArticles } from '../../../models/flexibles';
import { NgIf } from '@angular/common';
import { DialogTextImgVideoComponent } from '../../dialog/dialog-text-img-video/dialog-text-img-video.component';
import { MatDialog } from '@angular/material/dialog';
import { Visuel } from '../../../models/visuel';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-similar-articles',
  standalone: true,
  imports: [
    NgIf,
    CustomInnerHtmlDirective
  ],
  templateUrl: './flexible-similar-articles.component.html',
  styleUrl: './flexible-similar-articles.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FlexibleSimilarArticlesComponent implements AfterViewInit {
  @Input() flexible!: FlexibleSimilarArticles;
  readonly dialog = inject(MatDialog);
  @ViewChild('swiperContainer1', { static: false }) swiperContainer1!: ElementRef;
  @ViewChild('swiperContainer2', { static: false }) swiperContainer2!: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    if (this.swiperContainer1 && this.swiperContainer1.nativeElement) {
      this.initializeSwiper(this.swiperContainer1.nativeElement, 20, '.swiper-button-next-1', '.swiper-button-prev-1');
    }

    if (this.swiperContainer2 && this.swiperContainer2.nativeElement) {
      this.initializeSwiper(this.swiperContainer2.nativeElement, 20, '.swiper-button-next-2', '.swiper-button-prev-2');
    }
  }

  initializeSwiper(swiperEl: any, spaceBetween: number, nextButton: string, prevButton: string): void {
    const swiperParams = {
      slidesPerView: 'auto',
      direction: 'horizontal',
      cssMode: false,
      loop: false,
      speed: 500,
      spaceBetween: spaceBetween,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
    };

    Object.assign(swiperEl, swiperParams);

    swiperEl.initialize();
  }

  openDialogMedia(media: { video?: string, infographie?: Visuel, visuel?: Visuel }) {
    const dialogRef = this.dialog.open(DialogTextImgVideoComponent, {
      width: '1100px',
      height: '700px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { flexibleVideo: { url: media.video, visuel: media.visuel }, visuel: media.infographie }
    });
  }
}
