<div class="searchbar-layout">
    <div class="col-fullwidth">
        <app-searchbar [homeMessage]="homeMessage" [isHome]="true"></app-searchbar>
    </div>
</div>

<div class="main-layout no-padding-top">
    <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
    <div class="col-fullwidth mb-10">
        <app-personal-list [apps]="userApps" [bookmarks]="bookmarks"></app-personal-list>
    </div>
    <div class="col-fullwidth mb-10"></div>
    <div class="col-fullwidth mb-15"></div>
    <div class="col-fullwidth mb-15">
        <div class="news-headline-hp">
            <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-news"></span>L'Actu</h2>
            <a routerLink="/actualites" class="btn btn--primary hide-xs-mobile">Toute l'actu</a>
        </div>
    </div>
    <div class="col-left mb-40">
        <app-participation [appelsParticipations]="appelsParticipations"
            class="participation--home"></app-participation>
        <app-actualites class="layout" [actualites]="actualitesALaUne"></app-actualites>
    </div>
    <div class="col-right mb-60">
        <app-breves class="layout" [breves]="breves"></app-breves>
        <app-events class="layout" [events]="events"></app-events>
    </div>
    <div class="col-fullwidth mb-60" *ngIf="enImages && enImages.length">
        <app-en-images [enImages]="enImages"></app-en-images>
    </div>
    <div class="col-fullwidth wrapper-btn mb-60 hide-below-mobile">
        <a routerLink="/actualites" class="btn btn--primary btn-outline-big">Toute l'actu</a>
    </div>
    <div class="col-fullwidth">
        <app-essentiel-hp [titreQuotidien]="titreQuotidien" [titreIdentite]="titreIdentite" [titreCap]="titreCap" [titreIdentite]="titreIdentite" [chapoIdentite]="chapoIdentite" [chapoCap]="chapoCap" [chapoQuotidien]="chapoQuotidien"
            [quelCapEntries]="quelCapEntries" [quiSommesNousEntries]="quiSommesNousEntries"
            [auQuotidienEntries]="auQuotidienEntries" [quelCapZooms]="quelCapZooms"
            [quiSommesNousZooms]="quiSommesNousZooms" [auQuotidienZooms]="auQuotidienZooms"
            [auQuotidienTags]="auQuotidienTags"></app-essentiel-hp>
    </div>
</div>
