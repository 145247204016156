<div class="child-page__actions">
    <button class="previous-page" (click)="back()">Page précédente</button>

    <app-share [options]="shareOptions"></app-share>
</div>
<app-breadcrumb [links]="breadcrumbLinks"></app-breadcrumb>
<app-flexible-text class="layout" [flexible]="headerFlexible"></app-flexible-text>
<app-zoom-sur class=" col-left" [zoomsLien]="content.zoomLien"></app-zoom-sur>
<app-flexible-onglets class="layout" [flexible]="content.fOnglets"></app-flexible-onglets>

@if (content.flexiblesTerritoriaux && content.flexiblesTerritoriaux.length > 0) {
    <div class="bloc_title_select">
        <h2 class="bloc_title_select_title">Dans ma DT</h2>
        <app-select-implantation class="select-implantation" [keys]="keys" [selectedKey]="selectedKey"
            (selectedKeyChange)="onSelectedKeyChange($event)"></app-select-implantation>
        <app-flexibles [flexibles]="selectedFlexibles"></app-flexibles>
    </div>
}