import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Toast } from '../models/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  private toastSource = new BehaviorSubject<Toast | null>(null);
  currentToast = this.toastSource.asObservable();

  changeToast(data: Toast) {
    this.toastSource.next(data);
  }
}
