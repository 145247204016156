import { Component, Input } from '@angular/core';
import { Tool } from '../../models/tool';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-card-outil',
  standalone: true,
  imports: [NgIf],
  templateUrl: './card-outil.component.html',
  styleUrl: './card-outil.component.scss'
})
export class CardOutilComponent {
  @Input() tool!: Tool;
}
