
<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="col-left flexible-content pt-0 pb-0">
        <swiper-container #swiperContainer class="swiper-container flexible-img-slider" init="false">
            @for (visuel of flexible.visuels; track visuel.id) {
                <swiper-slide>
                    <div class="flexible-img">
                        <figure>
                            <picture>
                                <source srcset="{{ visuel.sizes?.['w885h397'] }}" width="885" height="397" media="(min-width: 992px)" />
                                <source srcset="{{ visuel.sizes?.['w740h332'] }}" width="740" height="332" media="(min-width: 768px)" />
                                <source srcset="{{ visuel.sizes?.['w360h190'] }}" width="360" height="190" media="(max-width: 420px)" />
                                <img src="{{ visuel.sizes?.['w885h397'] }}" loading="lazy" width="885" height="397" [alt]="visuel['alt']" />
                            </picture>
                            <figcaption>
                                <p>{{ visuel.caption }} - <time>{{ visuel.date }}</time></p>
                                <p>{{ visuel.description }}</p>
                            </figcaption>
                        </figure>
                    </div>
                </swiper-slide>
            }
            <!-- Decoupe slider image
             <swiper-slide>
                <div class="flexible-img">
                    <figure>
                        <picture>
                            <source srcset="assets/images/flexible-content/flexible-img/content-img-desktop.jpg" width="740" height="332" media="(min-width: 391px)" />
                            <source srcset="assets/images/flexible-content/flexible-img/content-img-mobile.jpg" width="390" height="175" media="(max-width: 390px)" />
                            <img src="assets/images/flexible-content/flexible-img/content-img-mobile.jpg" width="740" height="332" alt="Highlight article image" />
                        </picture>
                        <figcaption>
                            <p>1- Légende de l’image - <time datetime="2019-02-01">01.02.2019</time></p>
                            <p>Et ceci est la description de la légende. Nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim.</p>
                        </figcaption>
                    </figure>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="flexible-img">
                    <figure>
                        <picture>
                            <source srcset="assets/images/flexible-content/flexible-img/content-img-desktop.jpg" width="740" height="332" media="(min-width: 391px)" />
                            <source srcset="assets/images/flexible-content/flexible-img/content-img-mobile.jpg" width="390" height="175" media="(max-width: 390px)" />
                            <img src="assets/images/flexible-content/flexible-img/content-img-mobile.jpg" width="740" height="332" alt="Highlight article image" />
                        </picture>
                        <figcaption>
                            <p>1- Légende de l’image - <time datetime="2019-02-01">01.02.2019</time></p>
                            <p>Et ceci est la description de la légende. Nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim.</p>
                        </figcaption>
                    </figure>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="flexible-img">
                    <figure>
                        <picture>
                            <source srcset="assets/images/flexible-content/flexible-img/content-img-desktop.jpg" width="740" height="332" media="(min-width: 391px)" />
                            <source srcset="assets/images/flexible-content/flexible-img/content-img-mobile.jpg" width="390" height="175" media="(max-width: 390px)" />
                            <img src="assets/images/flexible-content/flexible-img/content-img-mobile.jpg" width="740" height="332" alt="Highlight article image" />
                        </picture>
                        <figcaption>
                            <p>1- Légende de l’image - <time datetime="2019-02-01">01.02.2019</time></p>
                            <p>Et ceci est la description de la légende. Nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim.</p>
                        </figcaption>
                    </figure>
                </div>
            </swiper-slide>
            -->
        </swiper-container>
    </div>
</div>