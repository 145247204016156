import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { FlexibleArche } from '../../../models/flexibles';

@Component({
  selector: 'app-flexible-arche',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flexible-arche.component.html',
  styleUrl: './flexible-arche.component.scss'
})
export class FlexibleArcheComponent {

  @Input() public flexible!: FlexibleArche;

}
