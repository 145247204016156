<div class="col-fullwidth mb-60">
    <div class="module_essentiel essentiel_heading" [ngClass]="{'module_select-list--reverse': essentielPictoList.pictoPos === 'droite'}">
        <div class="essentiel_heading--icon_title">
            <img *ngIf="essentielPictoList.picto"
                 src="{{essentielPictoList.picto_infography.sizes?.['w83h82']}}"
                 alt="{{essentielPictoList.picto.alt}}" width="83" height="82" />
            <p class="essentiel--title">{{ essentielPictoList.title }}</p>
        </div>
        <app-entries [entries]="essentielPictoList.national_entries" [iconType]="essentielPictoList.icon_type" class="essentiel_entries_wrapper"></app-entries>
    </div>
</div>