import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn = new BehaviorSubject<boolean>(false);

  private loginActionSubject = new Subject<void>();
  loginAction$ = this.loginActionSubject.asObservable();

  private logoutActionSubject = new Subject<void>();
  logoutAction$ = this.logoutActionSubject.asObservable();

  constructor() {}

  // Méthode pour mettre à jour l'état de connexion
  setLoggedIn(isLoggedIn: boolean) {
    this._isLoggedIn.next(isLoggedIn);
  }

  // Observable que d'autres composants peuvent écouter
  get isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn.asObservable();
  }

  triggerLoginAction() {
    this.loginActionSubject.next();
  }

  triggerLogoutAction() {
    this.logoutActionSubject.next();
  }
}
