<div class="mat-mdc-dialog-header">
    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>
@if(title) {
    <h2 mat-dialog-title>{{title}}</h2>
}
<mat-dialog-content class="mat-typography">
    @if(content) {
        <p [customInnerHTML]="content"></p>
    }
    @if(visuel){
        <a *ngIf="!isPdf" href="{{visuel.url}}" target="_blank">
            <img src="{{visuel.url}}" alt="{{visuel.alt}}" />
        </a>
        <div class="pdf-viewer" *ngIf="isPdf">
            <ng2-pdfjs-viewer [pdfSrc]="visuel.url" [viewBookmark]="false" [openFile]="false" [fullScreen]="true" [print]="false"></ng2-pdfjs-viewer>
        </div>
        @if(visuel.title){
            <p class="dialog-title" [customInnerHTML]="visuel.title"></p>
        }
    }
    @if (flexibleVideo && flexibleVideo.url) {
        <app-flexible-video [flexible]="flexibleVideo"></app-flexible-video>
        @if(flexibleVideo.visuel?.title){
            <p class="dialog-title" [customInnerHTML]="flexibleVideo.title"></p>
        }
    }
</mat-dialog-content>
@if(button?.url) {
    <mat-dialog-actions align="end">
        <a href="{{button?.url}}" class="btn btn--primary" [rel]="button?.type === 'ext'? 'external': ''" [target]="button?.type === 'ext'? '_blank': ''">En savoir plus</a>
    </mat-dialog-actions>
}
