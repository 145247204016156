import {Component, Input} from '@angular/core';
import {NgForOf} from "@angular/common";
import {SearchDocumentComponent} from "../search-document/search-document.component";
import {ElasticsearchService} from "../../../services/elasticsearch.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  standalone: true,
  imports: [
    NgForOf,
    SearchDocumentComponent
  ],
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {
  @Input('documents') documents: any[] = [];
  private loadingSubscription!: Subscription;
  isLoading: boolean = false;

  constructor(private elasticsearchService: ElasticsearchService) {
  }

  ngOnInit(): void {
    this.loadingSubscription = this.elasticsearchService.loading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

}
