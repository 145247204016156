<div class="dialog-confirm-email">
<div class="mat-mdc-dialog-header">
  <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>

<mat-dialog-content class="mat-typography">
  <p class="text-align-center">Votre email a bien été envoyé.</p>

</mat-dialog-content>
</div>
