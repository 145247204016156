<div class="service-intermediaire">
  <div class="news-headline-hp">
    <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-m-dossier-solid"></span>Mes données personnelles (contribution)</h2>
  </div>


  <p class="service-intermediaire__chapo">Retrouvez vos données administratives à tout momement dans votre espace individuel : sur "MonSelfMobile" pour les personnels publics, sur "Mypeopledoc" pour les personnels prviés.</p>


  <div class="service-intermediaire__content">
    <div class="service-intermediaire__content__item">
      <div class="rte">
        <img class="service-intermediaire__content__img" src="/assets/images/icons/agent-public-picto.svg" alt="icone">
        <p class="darkblue mb">"MonSelfMobile" est un service mis en place par le Ministère de la Transition écologique.</p>

        <p class="blue">Je peux consulter :</p>
        <ul class="blue">
          <li>Mes données personnelles</li>
          <li>Mes données de carrière</li>
          <li>Et modifier mes contacts personnels</li>
        </ul>

        <p class="icon-text"><span class="icon vnf-icons-icon-m-email-outline"></span>camil&#64;vnf.fr</p>

        <a href="#" class="btn btn--secondary">MonSelfMobile</a>

      </div>
    </div>


    <div class="service-intermediaire__content__item">
      <div class="rte">
        <img class="service-intermediaire__content__img" src="/assets/images/icons/salarie-prive-picto.svg" alt="icone">
        <p class="lightblue">"MyPeopleDoc" me permet de consulter :</p>
        <ul class="lightblue">
          <li>Mes fiches de paie</li>
          <li>Mon bulletin salarial individuel (BSI)</li>
          <li>Et je dispose, si je le souhaite, d'un espace de stockage personnel de 100Go</li>
        </ul>

        <a href="#" class="btn btn--secondary">MonSelfMobile</a>

      </div>
    </div>
  </div>
</div>
