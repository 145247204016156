<ng-container *ngIf="postType === 'page' && isEssentialPage()">
    <app-qui-somme-nous class="preview" *ngIf="postId === 200" [preview]="true"></app-qui-somme-nous>
    <app-quel-cap class="preview" *ngIf="postId === 202" [preview]="true"></app-quel-cap>
    <app-au-quotidien class="preview" *ngIf="postId === 204" [preview]="true"></app-au-quotidien>
</ng-container>

<ng-container *ngIf="postType === 'page' && !isEssentialPage()">
    <app-wp-page class="preview" [preview]="true" [postId]="postId"></app-wp-page>
</ng-container>

<ng-container *ngIf="postType === 'actualites'">
    <app-article class="preview" [preview]="true" [postId]="postId"></app-article>
</ng-container>

<ng-container *ngIf="postType === 'sujets'">
    <app-sujet class="preview" [preview]="true" [postId]="postId"></app-sujet>
</ng-container>