import { ChangeDetectionStrategy, Component, inject, Input, ElementRef, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlexibleDataVisualisation } from '../../../models/flexibles';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { DialogTextImgVideoComponent } from '../../dialog/dialog-text-img-video/dialog-text-img-video.component';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-datavisualisation',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-datavisualisation.component.html',
  styleUrl: './flexible-datavisualisation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexibleDatavisualisationComponent {
  @Input() flexible!: FlexibleDataVisualisation;
  readonly dialog = inject(MatDialog);

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    const colTrees = this.el.nativeElement.querySelectorAll('.col-tree');
    const footer = this.el.nativeElement.querySelector('.card-item-footer');

    if (footer) {
      if (colTrees.length === 2) {
        this.renderer.setStyle(footer, 'justify-content', 'center');
      } else if (colTrees.length === 1) {
        this.renderer.setStyle(footer, 'justify-content', 'flex-start');
      }
    }
  }

  openDialogDataVis(content?: string) {
    const dialogRef = this.dialog.open(DialogTextImgVideoComponent, {
      data: { content },
    });
  }
}
