<div class="mat-mdc-dialog-header">
    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>
@if(title) {
    <h2 mat-dialog-title>{{title}}</h2>
}
<mat-dialog-content class="mat-typography">
    @if(content) {
        <p [customInnerHTML]="content"></p>
    }
    @if(visuel){
        <img src="{{visuel.url}}" alt="{{visuel.alt}}" />
        @if(visuel.title){
        <p [customInnerHTML]="visuel.title"></p>
        }
    }
    @if (flexibleVideo && flexibleVideo.url) {
        <app-flexible-video [flexible]="flexibleVideo"></app-flexible-video>
    }
</mat-dialog-content>
@if(url) {
    <mat-dialog-actions align="end">
        <a href="{{url}}" class="btn btn--primary">En savoir plus</a>
    </mat-dialog-actions>
}