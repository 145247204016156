<div class="pagination">
    <a class="pagination--link previous"
       href="#"
       (click)="previousPage($event)"
       [class.disabled]="currentPage === 1">Précédent</a>
    <ng-container *ngFor="let page of getDisplayedPages()">
        <a *ngIf="Number.isInteger(page)"
           class="pagination--link"
           (click)="goToPage(page, $event)"
           [class.active]="page === currentPage"
        >{{ page }}</a>
        <span *ngIf="page === '...'" class="pagination--ellipsis">...</span>
    </ng-container>
    <a class="pagination--link next"
       href="#"
       (click)="nextPage($event)"
       [class.disabled]="currentPage === totalPages">Suivant</a>

    <button #paginationLink class="pagination--link" (click)="togglePerPageOptions()">...</button>
    <div #paginationOptions class="options" [class.active]="isPaginationOptionsActive">
        <ul class="options_list">
            <li class="options_list--item" *ngFor="let option of perPageOptions">
                @if (option > 0) {
                    <a href="#" (click)="setPerPage(option, $event)">Afficher {{ option }} éléments</a>
                } @else {
                    <a href="#" (click)="setPerPage(option, $event)">Afficher tout</a>
                }
            </li>
        </ul>
    </div>
</div>
