import { Component } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { sanitizeTitle, useCache } from '../../utils/utils.service';
import { environment } from '../../../environments/environment';
import { Visuel } from '../../models/visuel';
import { Link } from '../../models/link';
import { NgFor, NgIf } from '@angular/common';
import { Title } from '@angular/platform-browser';

interface ServiceIntermediaire {
  id: number;
  title: string;
  titlePicto: string;
  chapo: string;
  picto: string;
  blocs: {
    instruction: string;
    visuel: Visuel;
    btn: Link;
  }[];
}

@Component({
  selector: 'app-service-intermediaire',
  standalone: true,
  imports: [NgFor, NgIf],
  templateUrl: './service-intermediaire.component.html',
  styleUrl: './service-intermediaire.component.scss'
})
export class ServiceIntermediaireComponent {
  public isLoading: boolean = false;
  serviceIntermediaire!: ServiceIntermediaire | undefined;
  slug: string | null = null;

  constructor(private router: Router, private apiService: ApiService, private titleService: Title) {
  }

  async ngOnInit(): Promise<void> {
    this.slug = this.router.url?.substring(1); // get the slug from the URL and remove the first character '/'

    if (this.slug) {
      useCache(
        this.router.url,
        this,
        async () => {
          await this.getServiceIntermediaire(this.slug as string);
        },
        () => { return { serviceIntermediaire: this.serviceIntermediaire } }
      );
    } else {
      this.router.navigate(['/']);
    }
  }

  async getServiceIntermediaire(slug: string): Promise<void> {
    const wpSlug = slug.split('/')[1]; // on récupère le slug du service
    const { headers, body } = await this.apiService.getPage({ slug: wpSlug });

    environment.enableLogging && console.log(`Returned API service intermediaire for slug: ${wpSlug}`, body);
    if (body && body?.length > 0) {
      // Get the first page
      const page = body[0];

      // Format the page service
      this.serviceIntermediaire = this.formatServiceIntermediaire(page);

      this.titleService.setTitle(sanitizeTitle(this.serviceIntermediaire.title));

      environment.enableLogging && console.log(`Formated service intermediaire`, this.serviceIntermediaire);
    } else {
      // Dont forget to set this.page to undefine if the page is not found
      // If not the loaded cache page will be saved again
      // The saveDataCache function will check if the data is undefined and will not save it
      this.serviceIntermediaire = undefined;
      localStorage.removeItem(this.router.url);
      await this.router.navigate(['/page-non-trouvee']);
    }
  }

  formatServiceIntermediaire(page: any): ServiceIntermediaire {
    return {
      id: page.id,
      title: page.title?.rendered,
      titlePicto: `<span class="icon vnf-icons-${page.acf?.['font-icon-pictogramme']}"></span> ${page.title?.rendered}`,
      chapo: page.acf?.chapo,
      picto: page.acf?.['font-icon-pictogramme'],
      blocs: page.acf?.blocs?.map((bloc: any) => ({
        instruction: bloc?.instruction,
        visuel: bloc?.visuel,
        btn: {
          url: bloc?.bouton?.type_lien === 'interne' ? bloc?.bouton?.lien_interne?.url : bloc?.bouton?.lien_externe?.url,
          label: bloc?.bouton?.type_lien === 'interne' ? bloc?.bouton?.lien_interne?.title : bloc?.bouton?.lien_externe?.title,
          type: bloc?.bouton?.type_lien === 'interne' ? 'int' : 'ext',
        }
      })) || [],
    };
  }
}
