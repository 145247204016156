import { Component, Input } from '@angular/core';
import {
  Essentiel,
  isEssentielCards,
  isEssentielList2Visuels,
  isEssentielLists1Visuel,
  isEssentielMiniCards,
  isEssentielPictoList
} from "../../../models/essentiels";
import { EssentielCardsComponent } from "../essentiel-cards/essentiel-cards.component";
import { EssentielMiniCardsComponent } from '../essentiel-mini-cards/essentiel-mini-cards.component';
import { List2VisuelsComponent } from "../list-2-visuels/list-2-visuels.component";
import { Lists1VisuelComponent } from "../lists-1-visuel/lists-1-visuel.component";
import { PictoListComponent } from "../picto-list/picto-list.component";

@Component({
  selector: 'app-essentiel-list',
  standalone: true,
  imports: [
    List2VisuelsComponent,
    Lists1VisuelComponent,
    PictoListComponent,
    EssentielCardsComponent,
    EssentielMiniCardsComponent
],
  templateUrl: './essentiel-list.component.html',
  styleUrl: './essentiel-list.component.scss'
})
export class EssentielListComponent {
  @Input() essentiels: Essentiel[] = [];

  protected readonly isEssentielList2Visuels = isEssentielList2Visuels;
  protected readonly isEssentielPictoList = isEssentielPictoList;
  protected readonly isEssentielLists1Visuel = isEssentielLists1Visuel;
  protected readonly isEssentielCards = isEssentielCards;
  protected readonly isEssentielMiniCards = isEssentielMiniCards;
}
