<div class="main-layout">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <app-page-fille class="layout" *ngIf="page" [content]="page" [flexibleImg]="flexibleImg"></app-page-fille>

  <div class="col-left">
    <div class="participation_page--container" *ngIf="!isLoading">
      <a class="btn btn--gradient" [rel]="button?.type === 'ext'? 'external': ''"
        [target]="button?.type === 'ext'? '_blank': ''" href="{{button?.url}}">Je participe</a>
    </div>
  </div>
</div>