import {Component, HostListener, inject, Input} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {DialogContentExampleDialog6} from "../../../pages/recherche/recherche.component";
import {DatePipe, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {SearchPaginationComponent} from "../search-pagination/search-pagination.component";



@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  standalone: true,
  imports: [
    NgForOf,
    DatePipe,
    SearchPaginationComponent,
    NgIf,
    UpperCasePipe
  ],
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {
  @Input('documents') documents: any[] = [];
  readonly dialog = inject(MatDialog);
  isItemOptionsActive: boolean[] = [];
  currentActiveIndex: number | null = null;
  isPaginationOptionsActive = false;

  constructor() {
  }

  openDialogMedia() {
    this.dialog.open(DialogContentExampleDialog6, {
      width: '350px',
    });
  }

  toggleOptions(index: number): void {
    if (this.currentActiveIndex === index) {
      this.isItemOptionsActive[index] = false;
      this.currentActiveIndex = null;
    } else {
      this.isItemOptionsActive.fill(false);
      this.isItemOptionsActive[index] = true;
      this.currentActiveIndex = index;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    if (!targetElement.closest('.options_list--item') && !targetElement.closest('.search-results__options')) {
      this.isPaginationOptionsActive = false;
      this.isItemOptionsActive.fill(false);
    }
  }
}
