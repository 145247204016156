<div class="search-results">
    <div class="search-results__list">
        <div class="search-results__item" data-type="news" *ngFor="let doc of documents; let i = index">
            <div class="tag_list">
                <span *ngIf="doc.metadata.perimetre != ''" class="tag bg-blue">
                    {{ doc.metadata.perimetre|uppercase }}
                </span>
                <span *ngIf="doc.metadata.nature_du_document != ''" class="tag tag-section">
                    {{ doc.metadata.nature_du_document|uppercase }}
                </span>
            </div>
            <p class="search-results__title">{{ doc.name }}</p>
            <div class="search-results__content">
                <span class="search-results__date">{{ doc.createdDateTime|date: 'dd/MM/y' }}</span>
                <p class="search-results__text">{{ doc.description }}</p>
                <button class="search-results__options" (click)="toggleOptions(i)">Activer les options</button>

                <div class="options" [class.active]="isItemOptionsActive[i]">
                    <ul class="options_list">
                        <li class="options_list--item">
                            <button (click)="openDialogMedia()">Propriétés du document</button>
                        </li>
                        <li class="options_list--item"><a href="#">Copier le lien</a></li>
                        <li class="options_list--item"><a href="#">Envoyer par mail</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
