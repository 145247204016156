import {NgFor, NgIf, ViewportScroller} from '@angular/common';
import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {AnimationItem} from 'lottie-web';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';
import {EssentielEntry} from '../../../models/essentiels';
import {AuQuotidienTag} from '../../../models/tag';
import {Zoom} from '../../../models/zoom';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-essentiel-hp',
  standalone: true,
  imports: [LottieComponent, RouterModule, NgFor, NgIf],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './essentiel-hp.component.html',
  styleUrls: ['./essentiel-hp.component.scss'],
})
export class EssentielHpComponent implements AfterViewInit {
  @ViewChildren('swiperEssentialHomeContainer', {read: ElementRef}) swiperContainers!: QueryList<ElementRef>;
  @Input() quelCapEntries: EssentielEntry[] = [];
  @Input() quiSommesNousEntries: EssentielEntry[] = [];
  @Input() auQuotidienEntries: EssentielEntry[] = [];
  @Input() quiSommesNousZooms: Zoom[] = [];
  @Input() quelCapZooms: Zoom[] = [];
  @Input() auQuotidienZooms: Zoom[] = [];
  @Input() auQuotidienTags: AuQuotidienTag[] = [];
  @Input() chapoCap: string = '';
  @Input() chapoQuotidien: string = '';
  @Input() chapoIdentite: string = '';

  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Ramener le focus en haut de page après chaque navigation
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  // Stocker plusieurs animations dans un objet par nom
  private animationItems: { [key: string]: AnimationItem } = {};

  // Options d'animations
  optionsQui: AnimationOptions = {
    path: '/assets/animations/in/VNF_top_qui.json',
    loop: false,
    autoplay: false,
  };

  optionsCap: AnimationOptions = {
    path: '/assets/animations/in/VNF_top_cap.json',
    loop: false,
    autoplay: false,
  };


  optionsQuotidien: AnimationOptions = {
    path: '/assets/animations/in/VNF_top_quotidien.json',
    loop: false,
    autoplay: false,
  };


  animationCreated(animationName: string, animationItem: AnimationItem): void {
    this.animationItems[animationName] = animationItem;
  }

  playAnimation(type: string): void {
    const animationItem = this.animationItems[type];
    if (animationItem) {

      animationItem.setDirection(1);
      animationItem.goToAndStop(0, true);
      animationItem.play();

      animationItem.addEventListener('complete', () => {
        environment.enableLogging && console.log('Animation terminée, état final maintenu.');
      });
    }
  }

  playReverseAnimation(type: string): void {
    const animationItem = this.animationItems[type];

    if (animationItem) {
      const totalFrames = animationItem.totalFrames;
      animationItem.goToAndStop(totalFrames, true);

      animationItem.setDirection(-1);
      animationItem.play();
    }
  }

  ngAfterViewInit(): void {
    this.swiperContainers.forEach((swiperContainer) => {
      this.initializeSwiper(swiperContainer.nativeElement);
    });
  }

  initializeSwiper(swiperEl: any): void {
    const swiperParams = {
      slidesPerView: 1,
      direction: 'vertical',
      navigation: true,
      loop: false,
      cssMode: false,
      speed: 500,
      spaceBetween: 0,
      simulateTouch: true,
    };

    Object.assign(swiperEl, swiperParams);

    swiperEl.initialize();
  }
}
