import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, catchError, finalize, Observable, tap, throwError } from "rxjs";
import { SearchResult } from "../models/search-result";
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ElasticsearchService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  private searchResults = new BehaviorSubject<SearchResult>({
    searchQuery: '',
    total: 0,
    hits: [],
    facets: {},
    hasFilters: false,
    appliedFilters: {}
  });
  searchResults$ = this.searchResults.asObservable();

  constructor(private http: HttpClient) {
  }

  formatDate(date: Date): string {
    return new Intl.DateTimeFormat('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date);
  }

  escape(query: string): string {
    const specialCharacters = ['\\', '+', '-', '!', '(', ')', ':', '^', '[', ']', '"', '{', '}', '~', '*', '?', '|', '&', '/', '.'];
    let escapedQuery = '';

    for (const char of query) {
      // Vérifie si le caractère est spécial et doit être échappé
      if (specialCharacters.includes(char)) {
        escapedQuery += '\\'; // Ajoute un backslash devant le caractère spécial
      }
      escapedQuery += char; // Ajoute le caractère à la chaîne échappée
    }

    return escapedQuery;
  }

  queryDocuments(
    query: string,
    size: number = 10,
    page: number = 1,
    filtres: any = null,
    saveHistory: boolean = false
  ): Observable<any> {
    this.loadingSubject.next(true); // Activer
    const url = `${environment.apiUrl}/search/v1/search`;

    const body: any = {
      "query": query,
      "size": size,
      "page": page,
      "filters": [],
      "minimum_should_match": 0,
      "save_history": saveHistory,
    }

    if (filtres?.natures?.length > 0) {
      const natureFilters: string[] = [];

      // Récupérer les natures directement cochées et leurs subfilters cochés
      filtres.natures.forEach((filter: any) => {
        if (filter.checked) {
          natureFilters.push(filter.name);
        }

        // Vérifier et inclure les subfilters cochés
        if (filter.sub_filters?.length > 0) {
          filter.sub_filters.forEach((subFilter: any) => {
            if (subFilter.checked) {
              natureFilters.push(subFilter.name);
            }
          });
        }
      });

      // Ajouter les natures dans "optional_filters" si elles existent
      if (natureFilters.length > 0) {
        body.filters.push({
          "terms": { "nature_du_document": natureFilters }
        });
        body.minimum_should_match += 1;
      }
    }


    if (filtres?.perimetres?.length > 0) {
      const perimetreFilters: string[] = filtres.perimetres
        .filter((filter: any) => filter.checked) // Récupère seulement les filtres cochés
        .map((filter: any) => filter.name); // Récupère le champ `name`

      // Ajouter les périmètres dans "optional_filters" si elles existent
      if (perimetreFilters.length > 0) {
        body.filters.push({
          "terms": { "perimetre": perimetreFilters }
        });
        body.minimum_should_match += 1;
      }
    }

    if (filtres.startDate && filtres.endDate) {
      body.filters.push(
        { range: { date_d_effet: { gte: this.formatDate(filtres.startDate), lte: this.formatDate(filtres.endDate) } } }
      )
      body.minimum_should_match += 1;
    }

    // Vérifie s'il y a au moins un filtre utilisé dans la requête
    const hasFilters = body.filters?.length > 0;

    return this.http.post(url, body).pipe(
      tap((data: any) => {

        const hits = data.hits.hits.map(
          (hit: any) => ({
            ...hit._source,
            score: hit._score
          })
        );
        const total = data.hits.total.value;

        // Mise à jour des résultats avec les facettes et les filtres
        this.searchResults.next({
          searchQuery: query,
          total: total,
          hits: hits,
          facets: {
            natureDistribution: data.aggregations.natureDistribution.buckets,
            perimetreDistribution: data.aggregations.perimetreDistribution.buckets
          },
          hasFilters: hasFilters,
          appliedFilters: filtres
        });
      }),
      catchError((err: any) => {
        // En cas d'erreur, on envoie un objet vide
        this.searchResults.next({
          searchQuery: query,
          total: 0,
          hits: [],
          facets: {},
          hasFilters: hasFilters,
          appliedFilters: filtres
        })
        return throwError(() => new Error('Erreur lors de la recherche : ' + err.message));
      }),
      finalize(() => {
        this.loadingSubject.next(false); // Désactiver la loading
      })
    );
  }

  searchDocumentsAutocomplete(query: string, size: number = 5): Observable<any> {
    const url = `${environment.apiUrl}/search/v1/autocomplete`;
    const body: any = {
      query: query,
      size: 5
    }
    return this.http.post<any>(url, body);
  }

  searchUserHistory(query: string | null): Observable<any> {
    const url = `${environment.apiUrl}/search/v1/user-history`;
    const body = {
      query: query,
      size: 20
    };
    return this.http.post<any>(url, body);
  }

}
