<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <div class="flexible-push-medias flexible-content">
    @for(visuel of flexible.visuels; track visuel.id) {
      <div class="flexible-push-media" (click)="openDialogMedia(visuel)">
        <img src="{{visuel?.sizes?.['w338h226']}}" width="338" height="226" alt="{{visuel.alt}}" />
      </div>
    }
    <!-- <div class="flexible-push-media" (click)="openDialogMedia()">
      <img src="https://picsum.photos/338/226" alt="image" />
    </div>
    <div class="flexible-push-media" (click)="openDialogMedia()">
      <img src="https://picsum.photos/338/226" alt="image" />
    </div>
    <div class="flexible-push-media" (click)="openDialogMedia()">
      <img src="https://picsum.photos/338/226" alt="image" />
    </div>
    <div class="flexible-push-media" (click)="openDialogMedia()">
      <img src="https://picsum.photos/338/226" alt="image" />
    </div>
    <div class="flexible-push-media" (click)="openDialogMedia()">
      <img src="https://picsum.photos/338/226" alt="image" />
    </div>
    <div class="flexible-push-media" (click)="openDialogMedia()">
      <img src="https://picsum.photos/338/226" alt="image" />
    </div>
    <div class="flexible-push-media" (click)="openDialogMedia()">
      <img src="https://picsum.photos/338/226" alt="image" />
    </div> -->
  </div>
</div>

