import { NgForOf } from "@angular/common";
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatOption } from "@angular/material/autocomplete";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { App } from '../../models/app';
import { ApiService } from '../../services/api.service';
import { MesAppsService } from "../../services/mes-apps.service";
import { MesLiensService } from "../../services/mes-liens.service";
import { SidenavService } from "../../services/sidenav.service";
import { getUserFavorites, loadDataCache, saveDataCache, sendToast, useCache } from '../../utils/utils.service';

@Component({
  selector: 'app-mes-apps',
  standalone: true,
  templateUrl: './mes-apps.component.html',
  imports: [
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    PaginationComponent,
    LoadingOverlayComponent
  ],
  styleUrls: ['./mes-apps.component.scss']
})
export class MesAppsComponent {
  public isLoading: boolean = false;
  isOptionsActive = false;
  apps: App[] = [];
  filtres: { slug: string, name: string }[] = [];
  selectedSlug: string = '';
  currentPage = 1;
  limit = 50;
  totalPages = 0;
  currentFavApps: { title: string, url: string, flagId: number }[] = [];

  @ViewChild('optionsOpener', { static: false }) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu', { static: false }) optionsMenu!: ElementRef;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private mesAppsService: MesAppsService,
    private mesLiensService: MesLiensService,
    private sidenavService: SidenavService
  ) {
  }

  ngOnInit(): void {
    // mise à jour des apps de l'utilisateur en fonction de ce qu'il a modifié
    this.mesAppsService.currentMesApps.subscribe((mesApps) => {
      this.currentFavApps = mesApps;
    });

    // Récupérer les paramètres GET de la route actuelle
    this.route.queryParams.subscribe(params => {
      this.currentPage = !isNaN(params['p']) ? +params['p'] : this.currentPage; // récupérer le numéro de la page
      this.limit = !isNaN(params['limit']) ? +params['limit'] : this.limit; // récupérer le nombre d'éléments par page
    });

    // load apps data
    useCache('apps', this, async () => {
      loadDataCache('apps_limit', this);
      // load Breves, Events and Home data
      await Promise.all([
        this.getApps(this.currentPage, this.limit),
      ]);
    }, () => {
      return {
        apps: this.apps,
      }
    });
  }

  async getApps(page: number, limit: number = 10): Promise<void> {
    const { body, headers } = await this.apiService.getApps({
      page: this.currentPage,
      limit: this.limit,
      ...(this.selectedSlug !== '' && { categorie: this.selectedSlug })
    });
    // transform value
    if (body) {
      this.apps = this.formatApps(body.data);
      this.filtres = [{ slug: '', name: 'Toutes les Apps' }, ...body.filtres];
      this.totalPages = headers.get('x-wp-totalpages');
    }
    environment.enableLogging && console.log('apps', body, headers);

    this.isLoading = false;
  }

  formatApps(data: any[]): App[] {
    const apps: App[] = [];
    try {
      data.forEach((item: any) => {
        apps.push({
          id: item.id,
          flagId: item.flag_id,
          title: item.title.rendered,
          category: {
            name: item.acf?.categorie_application_transverse?.name,
            slug: item.acf?.categorie_application_transverse?.slug
          },
          lien: { url: item.acf?.lien?.url },
          isDefault: item.acf?.application_par_defaut,
          isFavorite: item.is_favorite
        });
      });
    } catch (e) {
      console.error('formatApps', e);
      sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
    }
    return apps;
  }

  toggleFavorite(event: Event, flagId: number): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const parent = target.parentElement as HTMLElement;
    const flag = parent.classList.toggle('favorite');
    // Update favorite status
    if (flag) {
      // Add to favorite
      this.apiService.flag('apps', flagId).then((e) => {
        environment.enableLogging && console.log('flag', e);
        // Update user favorites
        getUserFavorites(this.apiService, this.mesLiensService, this.mesAppsService, this.sidenavService);
      });
    } else {
      // Remove to favorite
      this.apiService.unflag('apps', flagId).then((e) => {
        environment.enableLogging && console.log('unflag', e);
        // Update user favorites
        getUserFavorites(this.apiService, this.mesLiensService, this.mesAppsService, this.sidenavService);
      });
    }
  }

  onSelectionChange(event: any) {
    this.selectedSlug = event.value;
    this.currentPage = 1;
    this.getApps(this.currentPage, this.limit);
  }

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener && this.optionsMenu) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      const clickedInsideMenu = this.optionsMenu.nativeElement.contains(targetElement);

      if (!clickedInsideOpener && !clickedInsideMenu) {
        this.isOptionsActive = false;
      }
    }
  }

  onPageChanged(page: number) {
    this.isLoading = true;
    this.currentPage = page;
    this.getApps(this.currentPage, this.limit);
  }

  onLimitChanged(limit: number) {
    this.isLoading = true;
    this.limit = limit;
    this.getApps(this.currentPage, this.limit);
    saveDataCache('apps_limit', { limit: this.limit });
  }

  redirect(app: App): void {
    if (app.lien.type == 'int') {
      window.open(app.lien.url, '_self');
    } else {
      window.open(app.lien.url, '_blank');
    }
  }
}
