import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Actualite } from '../../models/actualite';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-actualites',
  standalone: true,
  imports: [],
  templateUrl: './actualites.component.html',
  styleUrl: './actualites.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ActualitesComponent {
  @Input() actualites: Actualite[] = [];
  actuMajeure: Actualite | null = null;
  actusMineures: Actualite[] = [];
  isDesktop: boolean = window.innerWidth > 576;
  @ViewChildren('swiperContainer', { read: ElementRef }) swiperContainers!: QueryList<ElementRef>;
  constructor() { }

  ngOnInit(): void {
    this.checkIfDesktop();
  }

  @HostListener('window:resize')
  onResize() {
    this.checkIfDesktop();
  }

  checkIfDesktop(): void {
    this.isDesktop = window.innerWidth > 576;
  }

  ngAfterViewInit() {
    this.swiperContainers.forEach((container) => {
      this.initializeSwiper(container.nativeElement);
    });
  }

  ngOnChanges() {
    this.actuMajeure = this.actualites.find(actu => actu.important) || null;
    this.actusMineures = this.actualites.filter(actu => !actu.important);
    environment.enableLogging && console.log(this.actuMajeure, this.actusMineures);
  }

  initializeSwiper(swiperEl: any): void {
    // Swiper parameters
    const swiperParams = {
      slidesPerView: 2,
      direction: 'horizontal',
      navigation: false,
      cssMode: false,
      loop: false,
      speed: 500,
      spaceBetween: 20,
    };
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }
}
