<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-text-img flexible-content pt-0">
        <figure>
            <picture>
                <source srcset="{{ flexible.visuel.sizes?.['w575h280'] }}" width="575" height="280" media="(max-width: 575px)" />
                <source srcset="{{ flexible.visuel.sizes?.['w466h374'] }}" width="466" height="374" media="(min-width: 576px)" />
                <img src="{{ flexible.visuel.sizes?.['w466h374'] }}" width="466" height="374" [alt]="flexible.visuel.alt" />
            </picture>
            <figcaption>
                <h3 class="flexible-text-img-title" innerHTML="{{flexible.title}}<time>{{flexible.visuel.date}}</time>"></h3>
                <p [customInnerHTML]="flexible.content"></p>
            </figcaption>
        </figure>
    </div>
</div>