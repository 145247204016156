<div class="searchbar">
    <mat-form-field class="searchbar__field">
        <input #searchInput type="text" matInput
               [formControl]="searchControl"
               [matAutocomplete]="auto"
               (focus)="onFocus()"
               (keydown.enter)="onSearch()"
        >
        <button class="searchbar__delete-search" (click)="clearSearch()">Supprimer la recherche</button>
        <mat-autocomplete #auto="matAutocomplete"
                          [displayWith]="displayFn"
                          (optionSelected)="onSearch()">
            @for (option of filteredOptions$ | async; track option) {
                <mat-option [value]="option">{{ option }}</mat-option>
            }
            @for (option of filteredHistoryOptions$ | async; track option) {
                <mat-option class="previously-searched" [value]="option">{{ option }}</mat-option>
            }
            <div class="searchbar__submit--container">
                <button class="searchbar__submit btn btn--primary"
                        (click)="onSearch()">Rechercher</button>
            </div>
        </mat-autocomplete>
    </mat-form-field>
</div>