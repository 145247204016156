<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <div class="flexible-video flexible-content">
    <div class="video-container">
      <div class="video-frame">
        <iframe width="740" height="416" [src]="videoUrl" title="Video player" frameborder="0" role="button"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen tabindex="0">
        </iframe>
      </div>
    </div>
  </div>
</div>
