<div *ngIf="page && pageType === 'parent' && !preview" class="searchbar-layout">
    <div class="col-fullwidth">
        <app-searchbar></app-searchbar>
    </div>
</div>

<div class="main-layout" [ngClass]="{'no-padding-top': page && pageType === 'parent'}">
    <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
    @if (page && pageType === 'parent') {
        <app-page-parente class="layout" [content]="page"></app-page-parente>
    } @else if (pageType === 'fille') {
        <app-page-fille class="layout" [content]="page"></app-page-fille>
    }
</div>
