import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EssentielEntry, EssentielLists1Visuel } from "../../../models/essentiels";
import { SelectImplantationComponent } from "../../select-implantation/select-implantation.component";
import { EntriesComponent } from "../entries/entries.component";

@Component({
  selector: 'app-lists-1-visuel',
  standalone: true,
  imports: [
    NgIf,
    EntriesComponent,
    NgClass,
    SelectImplantationComponent
  ],
  templateUrl: './lists-1-visuel.component.html',
  styleUrl: './lists-1-visuel.component.scss'
})
export class Lists1VisuelComponent implements OnChanges {
  @Input() public essentielLists1Visuel!: EssentielLists1Visuel;
  selectedEntries: EssentielEntry[] = [];
  keys: { label: string, code: string }[] = [];
  selectedKey: number = 0;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  onSelectedKeyChange(selectedKey: number) {
    this.selectedKey = selectedKey;
    this.updateSelectedEntries();
  }

  //Detecte le changement des entrées pour modifier les clefs
  ngOnChanges(changes: SimpleChanges) {
    if (changes['essentielLists1Visuel']) {
      this.updateKeys();
      this.updateSelectedEntries();
      this.cdRef.detectChanges();
    }
  }

  updateKeys() {
    this.keys = this.essentielLists1Visuel.territorial_entries.map(
      (entry) => ({
        label: entry.label ?? null,
        code: entry.code ?? null
      })
    );

    this.updateSelectedEntries();
  }

  updateSelectedEntries() {
    this.selectedEntries = this.essentielLists1Visuel.territorial_entries[this.selectedKey].entries ?? [];
  }
}
