<div class="collaborateurs">
  <button class="collaborateurs-back__btn" routerLink="/" routerLinkActive="active">Revenir sur la page d'accueil</button>

  <div class="news-headline-hp">
    <h2 class="title-icons-underline">
      <span class="icon vnf-icons-icon-collabs"></span>Collaborateurs</h2>
  </div>

  <button class="collaborateurs-filters__btn-open" (click)="openFilters()">Filtrer</button>

  <div class="collaborateurs-filters__aside" [ngClass]="{'active': isFiltersActive}">
    <button class="collaborateurs-filters__btn-close" (click)="openFilters()">Fermer la liste des filtres</button>
      <p class="collaborateurs-filters__title">Filtrer</p>

      <p class="collaborateurs-filters__type">Localisation</p>

    <form>
      <mat-radio-group class="collaborateurs-filters__radio-group">
        <mat-radio-button>
          Siège - Béthune
        </mat-radio-button>
        <mat-radio-button>
          DT Nord-Est
        </mat-radio-button>
        <mat-radio-button>
          DT Rhône Saône
        </mat-radio-button>
        <mat-radio-button>
          DT Centre-Bourgogne
        </mat-radio-button>
        <mat-radio-button>
          UTI Moselle
        </mat-radio-button>
        <mat-radio-button>
          UTI Meuse Ardennes
        </mat-radio-button>
        <mat-radio-button>
          UTI Seine-Amont
        </mat-radio-button>
      </mat-radio-group>
    </form>

  </div>

  <div class="collaborateurs-list">
    <div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div><div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div><div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div><div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div><div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div><div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div><div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div><div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div><div class="collaborateurs-list__item">
      <div class="collaborateurs-list__image">
        <img src="https://picsum.photos/69/69" width="69" height="69" alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name">Christine Couvreur</p>
        <p class="collaborateurs-list__position">Chargée de gestion collective salariés privés et agents publics</p>
        <p class="collaborateurs-list__dt">DT Centre Bourgogne</p>
        <p class="collaborateurs-list__email">christine.couvreur&#64;vnf.fr</p>
        <div class="collaborateurs-list__phones">
            <span class="collaborateurs-list__phones-pro">03.21.63.49.78  </span>
            <span class="collaborateurs-list__phones-perso">07.60.58.45.47</span>
        </div>
      </div>
    </div>
  </div>

  <div class="pagination">
    <a class="pagination--link previous" href="#">Précédent</a>
    <a class="pagination--link active" href="#">1</a>
    <a class="pagination--link" href="#">2</a>
    <a class="pagination--link" href="#">3</a>
    <a class="pagination--link next" href="#">Suivant</a>
    <button class="pagination--link" (click)="toggleOptions()" #optionsOpener>...</button>

    <div #optionsMenu class="options" [class.active]="isOptionsActive">
      <ul class="options_list">
        <li class="options_list--item"><a href="#">Afficher 50 éléments</a></li>
        <li class="options_list--item"><a href="#">Afficher 100 éléments</a></li>
        <li class="options_list--item"><a href="#">Tout voir</a></li>
      </ul>
    </div>
  </div>
</div>
