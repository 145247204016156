import { Component, Input, CUSTOM_ELEMENTS_SCHEMA, OnInit, OnChanges, ViewChildren, ElementRef, HostListener, QueryList, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import moment from 'moment';
import 'moment/locale/fr';
import {ApiService} from '../../../services/api.service';
import {CommonModule} from '@angular/common';
import {Event as VnfEvent} from '../../../models/event';
import {EventComponent} from "../event/event.component";

const swiperBreakpointHome = 1343;
const swiperBreakpointActu = 576;

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [CommonModule, EventComponent],
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class EventsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren('swiperContainer', { read: ElementRef }) swiperContainers!: QueryList<ElementRef>;
  swiperDirection: 'horizontal' | 'vertical' = 'vertical';

  @Input() public events: VnfEvent[] = [];
  groupedEvents: any[][] = [];

  private page= window.location.pathname

  constructor(
    private apiService: ApiService,
    private eRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    moment.locale('fr');
  }

  async ngOnInit(): Promise<void> {
    this.updateSwiperDirection();
  }

  // Detect changes when the input 'events' is updated
  ngOnChanges(): void {
    this.groupEvents();
    this.cdr.detectChanges();
    this.initSliderEvent();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();  // Forcer la détection des changements
    this.initSliderEvent();
  }

  // Group breves in pairs for vertical slider display
  groupEvents(): void {
    this.groupedEvents = [];
    for (let i = 0; i < this.events.length; i += 2) {
      this.groupedEvents.push(this.events.slice(i, i + 2));
    }
  }

  updateSwiperDirection(): void {
    if(this.page == '/actualites') {
      this.swiperDirection = window.innerWidth < swiperBreakpointActu ? 'horizontal' : 'vertical';
    } else {
    this.swiperDirection = window.innerWidth < swiperBreakpointHome ? 'horizontal' : 'vertical';
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const targetElement = event.target as HTMLElement;

    this.events.forEach((e) => {
      const optionsElement = this.eRef.nativeElement.querySelector('.event--options.active');
      const openerElement = this.eRef.nativeElement.querySelector('.event--options-opener');

      // Check if the click was outside the active options or on the options opener
      if (optionsElement && !optionsElement.contains(targetElement) && !openerElement.contains(targetElement)) {
        e.isOptionsActive = false;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    // When the window is resized, check if the layout should switch between horizontal and vertical -> the value is updated in the HTML.
    this.updateSwiperDirection();
    // Apply the changes to the template:
    this.cdr.detectChanges();
    // Update the slider
    this.initSliderEvent();
  }

  initSliderEvent(): void {
    this.swiperContainers.forEach((swiperContainer) => {
      const swiperEl = swiperContainer.nativeElement;

      if (swiperEl.swiper) {
          swiperEl.swiper.update();
      } else {
          this.initializeSwiper(swiperEl);
      }
    });
  }

  initializeSwiper(swiperEl: any): void {
    // Swiper parameters
    let swiperParams;

    if(this.page == '/actualites') {
       swiperParams = {
        slidesPerView: 'auto',
        direction: 'horizontal',
        navigation: true,
        cssMode: false,
        loop: false,
        speed: 500,
        spaceBetween: 10,
        breakpoints: {
          576: {
            slidesPerView: 1,
            direction: 'vertical',
          }
        }
      }
    } else {
       swiperParams = {
        slidesPerView: 'auto',
        direction: 'horizontal',
        navigation: true,
        cssMode: false,
        loop: false,
        speed: 500,
        spaceBetween: 10,
        breakpoints: {
          1343: {
            slidesPerView: 1,
            direction: 'vertical',
          }
        }
      };
    }
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }
}
