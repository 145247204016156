import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EssentielCards } from '../../../models/essentiels';
import { FlexibleFlashInfoComponent } from '../../flexible/flexible-flash-info/flexible-flash-info.component';
import { EssentielCardComponent } from './essentiel-card/essentiel-card.component';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-essentiel-cards',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgForOf,
    FlexibleFlashInfoComponent,
    EssentielCardComponent,
    CustomInnerHtmlDirective
  ],
  templateUrl: './essentiel-cards.component.html',
  styleUrl: './essentiel-cards.component.scss'
})
export class EssentielCardsComponent {
  @Input() public essentielCards!: EssentielCards;
}
