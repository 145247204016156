import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Title } from '@angular/platform-browser';
import { formatLien, formatPageFille, sanitizeTitle, sendToast, useCache } from '../../utils/utils.service';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { PageFilleComponent } from '../../components/page-fille/page-fille.component';
import { FlexibleImgComponent } from '../../components/flexible/flexible-img/flexible-img.component';
import { FlexibleImg } from '../../models/flexibles';
import { Link } from '../../models/link';

@Component({
  selector: 'app-appels-participation',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    PageFilleComponent,
    NgIf
  ],
  templateUrl: './appels-participation.component.html',
  styleUrl: './appels-participation.component.scss'
})
export class AppelsParticipationComponent {
  public isLoading: boolean = false;
  fullPath: string = '';
  slug: string = '';
  page: any = null;
  chapo: string = '';
  flexibleImg: FlexibleImg | undefined = undefined;
  button: Link | undefined = undefined;

  constructor(private router: Router, private apiService: ApiService, private titleService: Title) {
  }

  async ngOnInit(): Promise<void> {
    this.fullPath = this.router.url;
    this.slug = this.router.url?.split('/').pop() || '';

    useCache(
      this.router.url,
      this,
      async () => {
        await this.getAppelsParticipation();
      },
      () => {
        return { page: this.page }
      }
    );
  }

  async getAppelsParticipation(): Promise<void> {
    try {
      const { body } = await this.apiService.getAppelsParticipation({ slug: this.slug });
      environment.enableLogging && console.log(`Returned API body for path: ${this.fullPath}`, body);

      // Get the content, depending on the mode
      const page = body[0];

      this.chapo = page.acf?.chapo;
      this.flexibleImg = { visuel: page.acf?.visuel } as FlexibleImg;
      this.page = formatPageFille(page, this.fullPath);
      this.button = formatLien(page.acf?.formulaire);
      // Set the title of the page if page is in cache
      this.page?.title && this.titleService.setTitle(sanitizeTitle(this.page.title));
    } catch (e) {
      console.error('Error in getAppelsParticipation : ', e);
      sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
    }
  }
}
