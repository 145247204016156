import { Component, Input } from '@angular/core';
import { CardOutilComponent } from '../../card-outil/card-outil.component';
import { FlexiblePushTools } from '../../../models/flexibles';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule

@Component({
  selector: 'app-flexible-push-outils',
  standalone: true,
  imports: [CardOutilComponent, CommonModule],
  templateUrl: './flexible-push-outils.component.html',
  styleUrl: './flexible-push-outils.component.scss'
})
export class FlexiblePushOutilsComponent {
  @Input() flexible!: FlexiblePushTools;
}
