import { Component, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { htmlDecode, useCache } from '../../utils/utils.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  @Input() public header_type: number | null = null;
  @Input() public type: 'diamond' | 'triangle' | 'circle' = 'diamond';
  @Input() public sup: string = '';
  @Input() public inf: string = '';
  @Input() public sup_link: string = '';
  @Input() public inf_link: string = '';
  @Input() public title: string = '';
  chapoCap: string = '';
  chapoQuotidien: string = '';
  chapoIdentite: string = '';

  constructor(private apiService: ApiService) { }

  async ngOnInit(): Promise<void> {

    if (this.header_type === 0) {
      await useCache('mainPageChapo', this, async () => {
        switch (this.type) {
          case 'diamond':
            this.chapoIdentite = htmlDecode(await this.apiService.getChapo('identite')).replace(/<\/?[^>]+(>|$)/g, "");
            break;
          case 'triangle':
            this.chapoCap = htmlDecode(await this.apiService.getChapo('cap')).replace(/<\/?[^>]+(>|$)/g, "");
            break;
          case 'circle':
            this.chapoQuotidien = htmlDecode(await this.apiService.getChapo('quotidien')).replace(/<\/?[^>]+(>|$)/g, "");
            break;
        }
      }, () => {
        return {
          chapoCap: this.chapoCap,
          chapoQuotidien: this.chapoQuotidien,
          chapoIdentite: this.chapoIdentite,
        }
      });
    }
  }

}
