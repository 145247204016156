import { formatDate, registerLocaleData } from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import { Injectable } from '@angular/core';
import { Client } from "@microsoft/microsoft-graph-client";
import { Event as CalendarEvent } from "@microsoft/microsoft-graph-types";
import { MsalAuthProvider } from "../graph/msal-auth-provider";
import { Event as VnfEvent } from "../models/event";
import { objToHttpParams } from "../utils/utils.service";

// Enregistre la locale "fr-FR"
registerLocaleData(localeFr, 'fr-FR');

@Injectable({
  providedIn: 'root'
})
export class GraphApiService {

  constructor(private authProvider: MsalAuthProvider) {
  }

  /**
   * Récupère le client Graph, à effectuer avant chaque appel à l'API Graph
   * de manière à ce que le token d'authentification soit toujours à jour
   * l'authProvider est un service custom qui gère l'authentification de l'utilisateur et récupère
   * l'access token via MSAL. Le client s'occupe de l'ajouter dans les headers de chaque requête.
   */
  private getGraphClient(): Client {
    return Client.initWithMiddleware({
      authProvider: this.authProvider
    });
  }

  /**
   * Récupère les informations selectionnées de l'utilisateur connecté
   */
  getMe(): Promise<any> {
    const client = this.getGraphClient();
    const selectParams = [
      'department',
      'onPremisesExtensionAttributes',
      'displayName',
      'mail',
      'jobTitle',
      'officeLocation',
      'preferredLanguage',
      'userPrincipalName'];
    return client.api('/me').select(selectParams.join(',')).get();
  }

  /**
   * Récupère les calendriers de l'utilisateur connecté
   */
  getCalendars(): Promise<any> {
    const client = this.getGraphClient();
    return client.api('/me/calendars').get();
  }

  /**
   * Récupère les événements du calendrier par défaut de l'utilisateur connecté
   */
  getCalendarEvents(): Promise<any> {
    const client = this.getGraphClient();
    return client.api('/me/events').get();
  }

  /**
   * Recherche un événement dans le calendrier de l'utilisateur connecté par son sujet et sa date de début
   */
  async findEventBySubjectAndStartDate(subject: string, startDate: Date): Promise<CalendarEvent | null> {
    const client = this.getGraphClient();
    const events = await client.api('/me/events').get();
    const formattedStartDate = formatDate(startDate, 'yyyy-MM-ddTHH:mm:ss', 'fr-FR', 'UTC');

    for (const event of events.value) {
      let formattedEventStartDate = formatDate(
        new Date(event.start.dateTime),
        'yyyy-MM-ddTHH:mm:ss',
        'fr-FR', event.timeZone
      );
      if (event.subject === subject && formattedEventStartDate === formattedStartDate) {
        // Si l'événement est trouvé, on le retourne il doit avoir le même sujet et la même date de début
        return event;
      }
    }
    return null;
  }

  /**
   * Crée un événement dans le calendrier de l'utilisateur connecté à partir d'un événement VNF
   * @param vnfEvent
   */
  createCalendarEvent(vnfEvent: VnfEvent): Promise<any> {
    const client = this.getGraphClient();
    const newEvent: CalendarEvent = {
      subject: vnfEvent.text,
      isAllDay: false,
      isOnlineMeeting: false,
      start: {
        dateTime: formatDate(vnfEvent.eventStartDate, 'yyyy-MM-ddTHH:mm:ss', 'fr-FR', 'UTC'),
        timeZone: "UTC"
      },
      end: {
        dateTime: formatDate(vnfEvent.eventStartDate, 'yyyy-MM-ddTHH:mm:ss', 'fr-FR', 'UTC'),
        timeZone: "UTC"
      },
      location: null,
      body: {
        content: vnfEvent.text,
        contentType: "text"
      },
      attendees: []
    };
    return client.api('/me/events').post(newEvent);
  }

  /**
   * Récupère la photo de l'utilisateur connecté
   */
  getProfilPicture(): Promise<Blob> {
    const client = this.getGraphClient();
    return client.api('/me/photo/$value').get();
  }

  /**
  * Récupère la photo d'un collaborateur par son email
  */
  getProfilPictureByEmail(email: string): Promise<Blob> {
    const client = this.getGraphClient();
    return client.api(`/users/${email}/photo/$value`).get();
  }

  /**
  * Récupère la list des collaborateurs
  */
  getUsers(p?: { top?: number, skiptoken?: string, dt?: string }): Promise<any> {
    let filter = "onPremisesExtensionAttributes/extensionAttribute4 eq 'Privé' or onPremisesExtensionAttributes/extensionAttribute4 eq 'Public'";

    // add filter on extensionAttribute1 if dt is provided
    if (p?.dt) {
      filter = `onPremisesExtensionAttributes/extensionAttribute1 eq '${p?.dt}' and (${filter})`;
    }

    const paramsObj = {
      '$count': true,
      '$filter': filter,
      '$orderby': 'displayName',
      '$select': 'id,displayName,givenName,surname,mail,userPrincipalName,jobTitle,department,officeLocation,city,businessPhones,mobilePhone,onPremisesExtensionAttributes',
      ...(p?.top && { '$top': p?.top }),
      ...(p?.skiptoken && { '$skiptoken': p?.skiptoken }),
    };
    const params = objToHttpParams(paramsObj);
    const client = this.getGraphClient();
    return client.api(`/users${params ? '?' + params : ''}`).header('ConsistencyLevel', 'eventual').get();
  }

  getOfficeLocations(p?: { skiptoken?: string }): Promise<any> {
    const paramsObj = {
      '$count': true,
      '$filter': "onPremisesExtensionAttributes/extensionAttribute4 eq 'Privé' or onPremisesExtensionAttributes/extensionAttribute4 eq 'Public'",
      '$orderby': 'displayName',
      '$select': 'onPremisesExtensionAttributes',
      '$top': 999,
      ...(p?.skiptoken && { '$skiptoken': p?.skiptoken }),
    };
    const params = objToHttpParams(paramsObj);
    const client = this.getGraphClient();
    return client.api(`/users${params ? '?' + params : ''}`).header('ConsistencyLevel', 'eventual').get();
  }

}
