import { Location, NgIf, NgClass} from '@angular/common';
import {Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import {
  FlexibleSimilarArticlesComponent
} from "../../components/flexible/flexible-similar-articles/flexible-similar-articles.component";
import { FlexiblesComponent } from "../../components/flexible/flexibles/flexibles.component";
import { Article } from '../../models/article';
import { ApiService } from '../../services/api.service';
import { copyUrlToClipboard, formatFlexibles, sanitizeTitle, useCache } from '../../utils/utils.service';
import { ShareComponent } from '../../components/share/share.component';
import { ShareOption } from '../../models/share-option';
import { MatDialog } from '@angular/material/dialog';
import { DialogSendEmailComponent } from '../../components/dialog/dialog-send-email/dialog-send-email.component';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-article',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    FlexibleSimilarArticlesComponent,
    FlexiblesComponent,
    NgIf,
    NgClass,
    ShareComponent
  ],
  templateUrl: './article.component.html',
  styleUrl: './article.component.scss'
})
export class ArticleComponent {
  @Input() preview: boolean = false
  @Input() postId: number | null = null;
  public isLoading: boolean = false;
  article!: Article | undefined;
  slug: string | null = null;
  shareOptions: ShareOption[] = [];

  constructor(private location: Location, private router: Router, private apiService: ApiService, private dialog: MatDialog, private titleService: Title) {
    this.shareOptions = [
      {
        label: 'Envoyer par mail',
        onclick: () => this.openDialogShareByEmail()
      },
      {
        label: 'Copier le lien',
        onclick: () => copyUrlToClipboard()
      }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.slug = this.router.url?.substring(1); // get the slug from the URL and remove the first character '/'

    if (this.slug) {
      useCache(
        this.router.url,
        this,
        async () => { await this.getArticle(this.slug as string); },
        () => { return { article: this.article } }
      );
    } else {
      this.router.navigate(['/']);
    }
  }

  async getArticle(slug: string): Promise<void> {
    const wpSlug = slug.split('/')[1]; // on récupère le slug de l'article
    const { body } = this.preview && this.postId ?
      await this.apiService.getPreview({ id: this.postId, postType: 'actualites' })
      : await this.apiService.getActualites({ slug: wpSlug })

    environment.enableLogging && console.log(`Returned API article for slug: ${wpSlug}`, body);
    if (
      (!this.preview && body && body?.length > 0) // Vérification du contenu du tableau si on est pas en mode preview
      || (this.preview && body?.id) // Vérification de l'objet si on est en mode preview
    ) {

      // Get the content, depending on the mode
      const page = this.preview ? body : body[0];

      // Format the page
      this.article = this.formatArticle(page);

      this.titleService.setTitle(sanitizeTitle(this.article.title));

      environment.enableLogging && console.log(`Formated article`, this.article);
    } else {
      // Dont forget to set this.page to undefine if the page is not found
      // If not the loaded cache page will be saved again
      // The saveDataCache function will check if the data is undefined and will not save it
      this.article = undefined;
      localStorage.removeItem(this.router.url);
      await this.router.navigate(['/page-non-trouvee']);
    }
  }

  formatArticle(page: any): Article {
    const moment_date = moment(page.acf?.date);
    return {
      id: page.id,
      title: page.title.rendered,
      chapo: page.acf?.chapo,
      date: {
        format_day: moment_date.format('DD'),
        format_month: moment_date.format('MMM'),
        format_year: moment_date.format('YYYY'),
        full: moment_date.format('YYYY-MM-DD'),
        date: moment_date.toDate()
      },
      dt: page.acf?.dt?.name,
      perimetre: page.acf?.perimetre?.name,
      theme: page.acf?.theme_actualite?.name,
      important: page.acf?.important,
      visuel: page.acf?.visuel,
      ...(page.acf?.contenus_flexibles && { flexibles: formatFlexibles(page.acf?.contenus_flexibles) }),
      ...(page.acf?.actualites_similaires && { articleSimilaires: formatFlexibles([page.acf?.actualites_similaires])[0] })
    };
  }

  back(): void {
    this.location.back();
  }

  openDialogShareByEmail(): void {
    this.dialog.open(DialogSendEmailComponent, {
      width: '100%',
      maxWidth: '740px',
      data: { slug: this.slug },
    });
  }

}
