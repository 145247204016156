import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexibleText } from '../../../models/flexibles';
import { CustomInnerHtmlDirective } from '../../../directives/custom-inner-html.directive';

@Component({
  selector: 'app-flexible-text',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-text.component.html',
  styleUrls: ['./flexible-text.component.scss']
})
export class FlexibleTextComponent implements OnInit {
  @Input() public flexible?: FlexibleText;
  public content: string = '';

  constructor() {}

  ngOnInit(): void {
    this.content = this.flexible?.content ?? '';
  }
}
