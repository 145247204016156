<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-text-img flexible-content pt-0">
        <figure (click)="redirect()" [ngClass]="{
                'right': flexible.img_pos !== 'gauche',
                'left': flexible.img_pos === 'gauche'
        }">
            @if(flexible.img_pos === 'gauche') {
                <div class="flexible-text-img-picture">
                <picture>
                    <source srcset="{{ flexible.visuel.sizes?.['w575h280'] }}" width="575" height="280" media="(max-width: 575px)" />
                    <source srcset="{{ flexible.visuel.sizes?.['w466h374'] }}" width="466" height="374" media="(min-width: 576px)" />
                    <img src="{{ flexible.visuel.sizes?.['w466h374'] }}" width="466" height="374" [alt]="flexible.visuel.alt" />
                </picture>
                </div>
            }
            <figcaption [ngClass]="{
                'background-darker-blue': flexible.color === 'bleu-fonce',
                'background-green': flexible.color === 'vert',
                'background-blue': flexible.color === 'bleu',
                'right': flexible.img_pos !== 'gauche',
                'left': flexible.img_pos === 'gauche'
                }">
                <h3 class="flexible-text-img-title" [innerHTML]="flexible.title"></h3>
                <p [customInnerHTML]="flexible.content"></p>
                <span class="btn btn--primary">En savoir plus</span>
            </figcaption>
            @if(flexible.img_pos !== 'gauche') {
          <div class="flexible-text-img-picture">
          <picture>
              <source srcset="{{ flexible.visuel.sizes?.['w575h280'] }}" width="575" height="280" media="(max-width: 575px)" />
                    <source srcset="{{ flexible.visuel.sizes?.['w466h374'] }}" width="466" height="374" media="(min-width: 576px)" />
                    <img src="{{ flexible.visuel.sizes?.['w466h374'] }}" width="466" height="374" [alt]="flexible.visuel.alt" />
                </picture>
                </div>
            }
          <a *ngIf="flexible.lien.url" href="{{flexible.lien.url}}" [rel]="flexible.lien.type === 'ext'? 'external': ''" [target]="flexible.lien.type === 'ext'? '_blank': ''" class="flexible-text-img--link">En savoir plus</a>
        </figure>
    </div>
</div>
