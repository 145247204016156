import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {environment} from '../../../environments/environment';
import {BlocRedirectionComponent} from '../../components/bloc-redirection/bloc-redirection.component';
import {BoiteAOutilsComponent} from '../../components/boite-a-outils/boite-a-outils.component';
import {EssentielListComponent} from "../../components/essentiel/essentiel-list/essentiel-list.component";
import {HeaderComponent} from '../../components/header/header.component';
import {LoadingOverlayComponent} from '../../components/loading-overlay/loading-overlay.component';
import {Essentiel,} from "../../models/essentiels";
import {Tool} from '../../models/tool';
import {ApiService} from '../../services/api.service';
import {formatEssentiel, formatTools, formatZoom, useCache} from '../../utils/utils.service';
import {ZoomSurComponent} from '../../components/zoom-sur/zoom-sur.component';
import {Zoom} from '../../models/zoom';
import {SearchbarComponent} from "../../components/search/searchbar/searchbar.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-quel-cap',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    HeaderComponent,
    BlocRedirectionComponent,
    MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule,
    BoiteAOutilsComponent, EssentielListComponent, ZoomSurComponent, SearchbarComponent, NgIf
  ],
  templateUrl: './quel-cap.component.html',
  styleUrl: './quel-cap.component.scss'
})
export class QuelCapComponent {
  @Input() preview: boolean = false;
  public isLoading: boolean = false;
  cap: any;
  zoomsLien: Zoom[] = [];
  zoomsVideo: Zoom[] = [];
  essentiels: Essentiel[] = [];
  tools: Tool[] = [];


  constructor(private apiService: ApiService) {
  }

  async ngOnInit(): Promise<void> {

    useCache('quel-cap', this, async () => {
      await this.getCap();
    }, () => {
      return {
        cap: this.cap,
        tools: this.tools,
        essentiels: this.essentiels,
        zoomsLien: this.zoomsLien,
        zoomsVideo: this.zoomsVideo
      }
    });
  }

  async getCap() {
    const {body} = this.preview ? await this.apiService.getPreview({
      id: 202,
      postType: 'page'
    }) : await this.apiService.getCap();
    this.cap = body;
    this.zoomsLien = formatZoom(body?.acf?.bloc_zooms_lien_video?.['zooms-lien'], 'url');
    this.zoomsVideo = formatZoom(body?.acf?.bloc_zooms_lien_video?.['zooms-video'], 'video');
    this.essentiels = formatEssentiel(body?.acf?.contenus_modules_essentiel, 'triangle');
    this.tools = formatTools(body?.acf?.module_boite_a_outils);
    environment.enableLogging && console.log(this.cap);
  }

}
