import {Component, Input} from '@angular/core';
import {loadMainPageChapo} from '../../utils/utils.service';
import {AnimationOptions, LottieComponent} from "ngx-lottie";
import {AnimationItem} from "lottie-web";
import {ApiService} from '../../services/api.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-bloc-redirection',
  standalone: true,
  imports: [
    LottieComponent
  ],
  templateUrl: './bloc-redirection.component.html',
  styleUrl: './bloc-redirection.component.scss'
})
export class BlocRedirectionComponent {
  @Input() cap: boolean = false;
  @Input() quotidien: boolean = false;
  @Input() identite: boolean = false;
  chapoCap: string = '';
  chapoQuotidien: string = '';
  chapoIdentite: string = '';

  private animationItems: { [key: string]: AnimationItem } = {};

  constructor(private apiService: ApiService) {
  }

  async ngOnInit(): Promise<void> {
    loadMainPageChapo(this, this.apiService);
  }

  // Options d'animations
  optionsQui: AnimationOptions = {
    path: '/assets/animations/in/VNF_top_qui.json',
    loop: false,
    autoplay: false,
  };

  optionsCap: AnimationOptions = {
    path: '/assets/animations/in/VNF_top_cap.json',
    loop: false,
    autoplay: false,
  };


  optionsQuotidien: AnimationOptions = {
    path: '/assets/animations/in/VNF_top_quotidien.json',
    loop: false,
    autoplay: false,
  };


  animationCreated(animationName: string, animationItem: AnimationItem): void {
    this.animationItems[animationName] = animationItem;
  }

  playAnimation(type: string): void {
    const animationItem = this.animationItems[type];
    if (animationItem) {

      animationItem.setDirection(1);
      animationItem.goToAndStop(0, true);
      animationItem.play();

      animationItem.addEventListener('complete', () => {
        environment.enableLogging && console.log('Animation terminée, état final maintenu.');
      });
    }
  }

  playReverseAnimation(type: string): void {
    const animationItem = this.animationItems[type];

    if (animationItem) {
      const totalFrames = animationItem.totalFrames;
      animationItem.goToAndStop(totalFrames, true);

      animationItem.setDirection(-1);
      animationItem.play();
    }
  }
}
