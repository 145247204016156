
@if (tools.length > 0) {
  <div class="tools tools-slider">
    <div class="tools_list">
    <swiper-container #swiperContainer class="swiper-container slider-tools" init="false">
      @for (tool of tools; track tool.id) {
        <swiper-slide>
          <div class="tools_list--item">
            <a target="_blank" class="tools_list--link" href="{{tool.url}}">
              <p class="tools_list--title">{{tool.title}}</p>
            </a>
            <div class="tag_list">
              <span class="tag bg-blue">{{tool.perimetre}}</span>
              @if (tool.rubrique) {
                <span class="tag tag-section {{tool.rubrique.icon_type}}">{{tool.rubrique.title}}</span>
              }
            </div>
            <p class="tools_list--text" innerHTML="{{tool.text.length > 130 ? (tool.text.slice(0, 130) + '...') : tool.text}}"></p>
          </div>
        </swiper-slide>
      }
    </swiper-container>
      <div class="swiper-navigation">
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
}
