<div class="main-layout child-page">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <div class="child-page__actions">
    <button class="previous-page" (click)="back()">Page précédente</button>

    <app-share [options]="shareOptions"></app-share>
  </div>

  <app-breadcrumb [links]="breadcrumbLinks"></app-breadcrumb>
  <div class="col-fullwidth mb-62">
    <h1>Tous nos outils com'</h1>
  </div>

  <app-slider-outils [tools]="forwardTools"></app-slider-outils>

  <div class="col-fullwidth mb-60">
    <div class="tools tools-2-cols">
      <div class="tools_list">
        @for (tool of pushTools; track tool.id) {
          <app-card-outil class="tools_list--item-wrapper" [tool]="tool"></app-card-outil>
        }
      </div>

      <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" (pageChanged)="onPageChanged($event)"></app-pagination>
    </div>
  </div>
</div>
