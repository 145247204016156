import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private previousUrl: string = '';
  private currentUrl: string = '';

  constructor(private router: Router) {
    // Récupérer les URL précédentes et actuelles du stockage local
    this.previousUrl = localStorage.getItem('previousUrl') || '';
    this.currentUrl = localStorage.getItem('currentUrl') || '';

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        // Mettre à jour l'URL précédente avant de changer l'URL actuelle
        if (this.currentUrl) {
          this.previousUrl = this.previousUrl != this.currentUrl && this.currentUrl != event.urlAfterRedirects ? this.currentUrl : this.previousUrl;
        }
        // Mettre à jour l'URL actuelle
        this.currentUrl = event.urlAfterRedirects;

        // Stocker les URL dans le stockage local
        localStorage.setItem('previousUrl', this.previousUrl);
        localStorage.setItem('currentUrl', this.currentUrl);
      }
    });
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }

  getCurrentUrl(): string {
    return this.currentUrl;
  }
}