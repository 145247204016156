import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { Component, Input } from '@angular/core';
import { FlexibleDossiers } from '../../../models/flexibles';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-dossier',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-dossiers.component.html',
  styleUrl: './flexible-dossiers.component.scss'
})
export class FlexibleDossierComponent {
  @Input() public flexible!: FlexibleDossiers;
}
