<div class="layout">
    <div class="col-fullwidth mb-60">
        <div class="module_select-list"
             [ngClass]="{'module_select-list--reverse': essentielLists1Visuel.infography_pos === 'gauche'}">
            <div class="select-list_heading--content">
                <div class="section-title">
                    <img *ngIf="essentielLists1Visuel.picto"
                         src="{{essentielLists1Visuel.picto.url}}"
                         alt="{{essentielLists1Visuel.picto.alt}}" width="40" height="40"/>
                    <h2>{{ essentielLists1Visuel.title }}</h2>
                </div>
                <div class="select-list_heading--list select-list_heading--list--hightlight {{essentielLists1Visuel.icon_type}}">
                    <app-entries class="essentiel_entries_wrapper"
                                 [entries]="essentielLists1Visuel.national_entries"
                                 [iconType]="essentielLists1Visuel.icon_type">
                    </app-entries>
                </div>
                <div class="select-list_heading--list select-list_heading--list--hightlight {{essentielLists1Visuel.icon_type}}">
                    <div class="select-list_heading--list-interaction">
                        <h3 class="select-list_heading--list-interaction-title">{{ essentielLists1Visuel.territorial_subtitle }}</h3>
                        <app-select-implantation class="select-implantation"
                                                 [keys]="keys"
                                                 [selectedKey]="selectedKey"
                                                 (selectedKeyChange)="onSelectedKeyChange($event)"
                        ></app-select-implantation>
                    </div>
                    <app-entries class="essentiel_entries_wrapper"
                                 [entries]="selectedEntries"
                                 [iconType]="essentielLists1Visuel.icon_type">
                    </app-entries>
                </div>
            </div>
            <div class="{{essentielLists1Visuel.icon_type}} module_select-list_img hide-mobile">
                <picture>
                    <source srcset="{{essentielLists1Visuel.infography.sizes?.['w807h442']}}" width="807" height="442"
                            media="(min-width: 1343px)"/>
                    <source srcset="{{essentielLists1Visuel.infography.sizes?.['w582h496']}}" width="582" height="496"
                            media="(min-width: 1343px)"/>
                    <source srcset="{{essentielLists1Visuel.infography.sizes?.['w355h548']}}" width="355" height="548"
                            media="(min-width: 768px)"/>
                    <img src="{{essentielLists1Visuel.infography.sizes?.['w807h442']}}" width="807" height="442"
                         alt="{{essentielLists1Visuel.infography.alt}}"/>
                </picture>
            </div>
        </div>
    </div>
</div>