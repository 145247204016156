<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-highlight-card flexible-content {{flexible.full_width? 'full-width' : ''}}">
        <div class="pt-0 pb-0">
            <div class="flexible-highlight-card-inner">
                <!-- <h2 class="flexible-highlight-card-title">{{flexible.content}}</h2> -->
                <div class="flexible-highlight-card-content" [customInnerHTML]="flexible.content">
                </div>
                <a href="{{flexible.url}}" class="btn btn--primary">En savoir plus</a>
            </div>
        </div>
    </div>
</div>