<div class="share-page">
    <button #shareButton class="share-page-btn" (click)="toggleShareOptionsMenu()">Partager la page</button>
    <div #optionsMenu class="options" [ngClass]="{'active': isOptionsMenuActive}">
        <ul class="options_list">
            @for (option of options; track option.label) {
                <li class="options_list--item"><a (click)="option.onclick()">{{option.label}}</a></li>
            }
        </ul>
    </div>
</div>
