import { Component, Input } from '@angular/core';
import { PageEnfant } from '../../models/page-enfant';

@Component({
  selector: 'app-page-enfant',
  standalone: true,
  imports: [],
  templateUrl: './page-enfant.component.html',
  styleUrl: './page-enfant.component.scss'
})
export class PageEnfantComponent {
  @Input() public content!: PageEnfant;
}
