import { Component, Input, signal } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexibleAccordions, isFlexibleImg, isFlexibleListLinks, isFlexibleText } from '../../../models/flexibles';
import { FlexibleTextComponent } from '../flexible-text/flexible-text.component';
import { FlexibleImgComponent } from '../flexible-img/flexible-img.component';
import { FlexibleListLinksComponent } from '../flexible-list-links/flexible-list-links.component';
import { NgClass, NgIf } from '@angular/common';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-accordions',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatExpansionModule,
    FlexibleTextComponent,
    FlexibleImgComponent,
    FlexibleListLinksComponent,
    CustomInnerHtmlDirective
  ],
templateUrl: './flexible-accordions.component.html',
  styleUrl: './flexible-accordions.component.scss'
})
export class FlexibleAccordionsComponent {
  @Input() flexible!: FlexibleAccordions;
  readonly panelOpenState = signal(false);
  protected readonly isFlexibleText = isFlexibleText;
  protected readonly isFlexibleImg = isFlexibleImg;
  protected readonly isFlexibleListLinks = isFlexibleListLinks;
}
