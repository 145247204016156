@if (zoomsLien.length > 0 || zoomsVideo.length > 0) {
<div class="col-fullwidth mb-15">
    <div class="section-title">
        <img src="/assets/images/icons/icons-titles/energy.svg" alt="" width="40" height="40" />
        <h2>Zoom sur</h2>
    </div>
</div>

<div class="{{zoomsVideo.length? 'col-half' : 'col-fullwidth'}}">
    <swiper-container #swiperContainer class="swiper-container flexible-img-slider" init="false">
        @for (zoom of zoomsLien; track zoom.id) {
            <swiper-slide>
                <div class="zooms zooms--url">
                    <div class="zoom">
                        <div class="zoom--image">
                            <picture>
                                <!-- Image < 767px -->
                                <source srcset="{{ zoom.visuel.sizes?.['w712h151'] }}" width="712" height="151" media="(max-width: 767px)" />
                                <!-- Image entre 768px et 1342px -->
                                <source srcset="{{zoom.visuel.sizes?.['w341h151']}}" width="341" height="151" media="(min-width: 768px) and (max-width: 1342px)" />
                                <!-- Image > 1342px -->
                                <source srcset="{{zoom.visuel.sizes?.['w266h151']}}" width="266" height="151" media="(min-width: 1343px)" />
                                <img src="{{zoom.visuel.sizes?.['w712h151']? zoom.visuel.sizes?.['w712h151'] : zoom.visuel.url}}" alt="{{zoom.visuel?.alt}}" />

                            </picture>
                        </div>
                        <div class="zoom--content">
                            <h3 class="zoom--title" innerHTML="{{zoom.title}}"></h3>
                            <p class="zoom--text" [customInnerHTML]="zoom.text"></p>
                        </div>
                        <a class="zoom--link" href="{{zoom.lien.url}}" rel="external"
                            target="_blank">
                            {{zoom.lien?.label||"Lien vers le contenu"}}
                        </a>
                    </div>
                </div>
            </swiper-slide>
        }
    </swiper-container>
</div>

<div class="{{zoomsLien.length? 'col-half' : 'col-fullwidth'}}">
    <swiper-container #swiperContainer class="swiper-container flexible-img-slider" init="false">
        @for (zoom of zoomsVideo; track zoom.id) {
            <swiper-slide>
                <div class="zooms zooms--video">
                    <div class="zoom">
                        <div class="zoom--image">
                            <picture>
                                <!-- Image < 767px -->
                                <source srcset="{{zoom.visuel.sizes?.['w712h151']}}" width="712" height="151" media="(max-width: 767px)" />
                                <!-- Image entre 768px et 1342px -->
                                <source srcset="{{zoom.visuel.sizes?.['w341h151']}}" width="341" height="151" media="(min-width: 768px) and (max-width: 1342px)" />
                                <!-- Image > 1342px -->
                                <source srcset="{{zoom.visuel.sizes?.['w266h151']}}" width="266" height="151" media="(min-width: 1343px)" />
                                <img src="{{zoom.visuel.sizes?.['w712h151']? zoom.visuel.sizes?.['w712h151'] : zoom.visuel.url}}" alt="{{zoom.visuel?.alt}}" />
                              </picture>
                        </div>
                        <div class="zoom--content">
                            <h3 class="zoom--title">{{zoom.title}}</h3>
                        </div>
                        <a class="zoom--modale-opener" (click)="openDialogMedia(zoom.videoUrl, zoom.visuel)">
                            {{zoom.lien?.label||"Ouverture de la modale"}}
                        </a>
                    </div>
                </div>
            </swiper-slide>
        }
    </swiper-container>
</div>
}