<div class="filters-dropdown__list">

    <div *ngIf="filtres.themes !== null" class="filters-dropdown" data-type="theme">
        <span class="filters-dropdown__label">Thème</span>
        <button class="filters-dropdown__opener" (click)="toggleDropdown(0)"
                [ngClass]="{'open': openDropdownIndex === 0}">Tous
        </button>

        <div class="filters-dropdown__popin">
            <div class="d-flex j-space-between desktop-only mb-15">
                <button class="filters-dropdown__select-all" (click)="selectAll('themes')">Tout sélectionner</button>
                <button class="filters-dropdown__unselect-all" (click)="unselectAll('themes')"
                        [disabled]="!isAnyChecked('themes')">Tout déselectionner
                </button>
            </div>
            <div class="filters-dropdown__filters-list__container">
                <div class="filters-dropdown__filters-list double">
                    <div class="filters-dropdown__item" *ngFor="let checkbox of filtres.themes; let i = index">
                        <input id="theme-checkbox-{{ i }}" class="filters-dropdown__check-checkbox" type="checkbox"
                               [checked]="checkbox.checked" (change)="onCheckboxChange($event, i, 'themes')"/>
                        <label class="filters-dropdown__check-label" for="theme-checkbox-{{ i }}">
                            {{ checkbox.name }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="filters-dropdown__filters-submit__container d-flex j-space-between">
                <button class="filters-dropdown__cancel btn btn--primary desktop-only"
                        (click)="toggleDropdown(0)">Annuler
                </button>

                <button class="filters-dropdown__unselect-all mobile-only" (click)="unselectAll('themes')"
                        [disabled]="!isAnyChecked('themes')">Tout déselectionner
                </button>
                <button class="filters-dropdown__validate btn btn--secondary"
                        (click)="search()">Voir ({{ countChecked('themes') }})
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="filtres.perimetres !== null" class="filters-dropdown" data-type="perimetre">
        <span class="filters-dropdown__label">Périmètre</span>
        <button class="filters-dropdown__opener" (click)="toggleDropdown(1)"
                [ngClass]="{'open': openDropdownIndex === 1}">Tous
        </button>
        <div class="filters-dropdown__popin">
            <div class="d-flex j-space-between desktop-only mb-15">
                <button class="filters-dropdown__select-all" (click)="selectAll('perimetres')">Tout sélectionner
                </button>
                <button class="filters-dropdown__unselect-all" (click)="unselectAll('perimetres')"
                        [disabled]="!isAnyChecked('perimetres')">Tout déselectionner
                </button>
            </div>
            <div class="filters-dropdown__filters-list__container">
                <div class="filters-dropdown__filters-list">
                    <div class="{{perimetre.sub_filters? 'filters-dropdown__item--dropdown' : 'filters-dropdown__item'}}"
                         *ngFor="let perimetre of this.filtres.perimetres; let id = index">
                        @if (perimetre.sub_filters) {
                            <span class="filters-dropdown__item--dropdown-opener" [ngClass]="{'active': isActive}"
                                  (click)="toggleActive()">{{ perimetre.name }}</span>
                            <div class="filters-dropdown__item--sublevel">
                                <div class="sublevel__item"
                                     *ngFor="let subfilter of perimetre.sub_filters; let i = index">
                                    <input id="{{subfilter.value}}-checkbox-{{i}}" [checked]="subfilter.checked"
                                           class="sublevel__check-checkbox" type="checkbox"
                                           (change)="onCheckboxChange($event, id, subfilter.value, i)"/>
                                    <label for="{{subfilter.value}}-checkbox-{{i}}"
                                           class="sublevel__check-label">{{ subfilter.name }}</label>
                                </div>
                            </div>
                        } @else {
                            <input id="perimetre-checkbox-{{ id }}" class="filters-dropdown__check-checkbox"
                                   type="checkbox"
                                   [checked]="perimetre.checked" (change)="onCheckboxChange($event, id, 'perimetres')"/>
                            <label class="filters-dropdown__check-label" for="perimetre-checkbox-{{ id }}">
                                {{ perimetre.name }}
                            </label>
                        }
                    </div>
                </div>
            </div>

            <div class="filters-dropdown__filters-submit__container d-flex j-space-between">
                <button class="filters-dropdown__cancel btn btn--primary desktop-only"
                        (click)="toggleDropdown(1)">Annuler
                </button>
                <button class="filters-dropdown__unselect-all mobile-only" (click)="unselectAll('perimetres')"
                        [disabled]="!isAnyChecked('perimetres')">Tout déselectionner
                </button>
                <button class="filters-dropdown__validate btn btn--secondary" (click)="search()">Voir ({{
                        countChecked('perimetres')
                    }})
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="filtres.natures !== null" class="filters-dropdown" data-type="documentType">
        <span class="filters-dropdown__label">Type de document</span>
        <button class="filters-dropdown__opener" (click)="toggleDropdown(2)"
                [ngClass]="{'open': openDropdownIndex === 2}">Tous
        </button>

        <div class="filters-dropdown__popin">
            <div class="d-flex j-space-between desktop-only mb-15">
                <button class="filters-dropdown__select-all" (click)="selectAll(asFilterKey('natures'))">Tout
                    sélectionner
                </button>
                <button class="filters-dropdown__unselect-all" (click)="unselectAll(asFilterKey('natures'))"
                        [disabled]="!isAnyChecked(asFilterKey('natures'))">Tout déselectionner
                </button>
            </div>
            <div class="filters-dropdown__filters-list__container">
                <div class="filters-dropdown__filters-list double">
                    <div class="filters-dropdown__item" *ngFor="let checkbox of filtres.natures; let i = index">
                        <input id="theme-checkbox-{{ i }}" class="filters-dropdown__check-checkbox" type="checkbox"
                               [checked]="checkbox.checked"
                               (change)="onCheckboxChange($event, i, asFilterKey('natures'))"/>
                        <label class="filters-dropdown__check-label" for="theme-checkbox-{{ i }}">
                            {{ checkbox.name }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="filters-dropdown__filters-submit__container d-flex j-space-between">
                <button class="filters-dropdown__cancel btn btn--primary desktop-only"
                        (click)="toggleDropdown(0)">Annuler
                </button>

                <button class="filters-dropdown__unselect-all mobile-only" (click)="unselectAll('natures')"
                        [disabled]="!isAnyChecked('natures')">Tout déselectionner
                </button>
                <button class="filters-dropdown__validate btn btn--secondary"
                        (click)="search()">Voir ({{ countChecked('natures') }})
                </button>
            </div>
        </div>
    </div>

    <mat-form-field>
        <mat-date-range-input #dateRange="matDateRangeInput" [rangePicker]="picker">
            <input matStartDate placeholder="Date début" (dateChange)="onDateChange(dateRange)"/>
            <input matEndDate placeholder="Date fin" (dateChange)="onDateChange(dateRange)"/>
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

</div>