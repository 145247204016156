import {HttpEvent, HttpInterceptorFn} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {MsalService} from "@azure/msal-angular";
import {inject} from "@angular/core";
import {firstValueFrom, from, lastValueFrom} from "rxjs";
import {DepartmentService} from "../services/department.service";

// Fonction pour acquérir un token de manière silencieuse
async function getTokenSilently(
  msalService: MsalService,
  forceRefresh: boolean = false): Promise<string | undefined> {
  try {
    const result = await lastValueFrom(msalService.acquireTokenSilent({
      account: msalService.instance.getActiveAccount() ?? undefined,
      scopes: environment.azureApiConfig.scopes,
      forceRefresh: forceRefresh
    }));
    return result?.idToken;
  } catch (error: any) {
    environment.enableLogging && console.error("Erreur lors de l'acquisition silencieuse du token:", error);
    return undefined;
  }
}


// Fonction pour acquérir un token via popup en cas d'interaction requise
async function getTokenWithPopup(msalService: MsalService): Promise<string | undefined> {
  try {
    const result = await lastValueFrom(msalService.acquireTokenPopup({
      account: msalService.instance.getActiveAccount() ?? undefined,
      scopes: environment.azureApiConfig.scopes
    }));
    environment.enableLogging && console.log('Token acquis via popup:', result?.idToken);
    return result?.idToken;
  } catch (error: any) {
    environment.enableLogging && console.error("Erreur lors de l'acquisition du token via popup:", error);
    return undefined;
  }
}


async function signIn(msalService: MsalService): Promise<void> {
  try {
    // Vous pouvez choisir d'utiliser loginRedirect ou loginPopup
    await lastValueFrom(msalService.loginRedirect({
      scopes: environment.azureApiConfig.scopes
    }));
    // Note : loginRedirect ne retourne pas de valeur, vous devrez gérer la redirection dans votre application
  } catch (error: any) {
    environment.enableLogging && console.error("Erreur lors de la redirection de connexion :", error);
  }
}


export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const msalService = inject(MsalService);
  const departmentService = inject(DepartmentService);

  if (req.url.startsWith(environment.apiUrl)) {
    const handleRequest = async (): Promise<HttpEvent<any>> => {
      try {
        // Get idToken from active account and check if it's expired
        const activeAccount = msalService.instance.getActiveAccount();
        let exp = activeAccount?.idTokenClaims?.exp;
        let forceRefresh = exp ? (Date.now() >= exp * 1000) : true;

        // Tente d'obtenir le token de manière silencieuse, si expiré on force le rafraîchissement
        let idToken = await getTokenSilently(msalService, forceRefresh);
        if (!idToken) {
          // Si aucune interaction silencieuse n'est possible, on tente la connexion classique
          await signIn(msalService);
        }

        const department = await firstValueFrom(departmentService.department$);
        const headers: { [name: string]: string } = {
          'x-department': department,
          'x-id-token': 'test'
        };
        if (idToken) {
          headers['x-id-token'] = idToken;
        }
        const clonedRequest = req.clone({
          setHeaders: headers
        });

        return await lastValueFrom(next(clonedRequest));
      } catch (error: any) {
        environment.enableLogging && console.error("Erreur générale lors du traitement de la requête:", error);
        return await lastValueFrom(next(req));
      }
    }
    return from(handleRequest());
  } else {
    return next(req);
  }
};
