<div class="collaborateurs">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <button class="collaborateurs-back__btn" routerLink="/" routerLinkActive="active">Revenir sur la page
    d'accueil</button>

  <div class="news-headline-hp">
    <h2 class="title-icons-underline">
      <span class="icon vnf-icons-icon-collabs"></span>Collaborateurs
    </h2>
  </div>

  <button class="collaborateurs-filters__btn-open" (click)="openFilters()">Filtrer</button>

  <div class="collaborateurs-filters__aside" [ngClass]="{'active': isFiltersActive}">
    <button class="collaborateurs-filters__btn-close" (click)="openFilters()">Fermer la liste des filtres</button>
    <p class="collaborateurs-filters__title">Filtrer</p>

    <p class="collaborateurs-filters__type">Localisation</p>

    <form>
      <mat-radio-group class="collaborateurs-filters__radio-group" [(ngModel)]="selectedOption"
        (change)="onSelectionChange($event)" [ngModelOptions]="{standalone: true}">
        <mat-radio-button *ngFor="let officeLocation of officeLocations; let i = index" value="{{officeLocation}}">{{officeLocation}}</mat-radio-button>
      </mat-radio-group>
      <button class="reset-filters" (click)="clearSelection()">Effacer le filtre</button>
    </form>

  </div>

  <div class="collaborateurs-list">
    <div class="collaborateurs-list__item" *ngFor="let collaborateur of collaborateursToDisplay; let i = index">
      <div class="collaborateurs-list__image">
        <img *ngIf="collaborateur.photoUrl" [src]="collaborateur.photoUrl" width="48" height="48"
          alt="image collaborateur">
      </div>
      <div class="collaborateurs-list__content">
        <p class="collaborateurs-list__name" [innerHTML]="collaborateur.displayName"></p>
        <p class="collaborateurs-list__position" *ngIf="collaborateur.jobTitle" [innerHTML]="collaborateur.jobTitle">
        </p>
        <p class="collaborateurs-list__position" *ngIf="collaborateur.shortDepartement"
          [innerHTML]="collaborateur.shortDepartement"></p>
        <p class="collaborateurs-list__dt" *ngIf="collaborateur.city" [innerHTML]="collaborateur.city"></p>
        <div class="collaborateurs-list__email" *ngIf="collaborateur.mail"><a href="mailto:{{collaborateur.mail}}" [innerHTML]="collaborateur.mail">Envoyer un email</a></div>
        <div class="collaborateurs-list__phones" *ngIf="collaborateur.businessPhones || collaborateur.mobilePhone">
          <span class="collaborateurs-list__phones-pro" *ngIf="collaborateur.businessPhones" [innerHTML]="collaborateur.businessPhones"></span>
          <span class="collaborateurs-list__phones-perso" *ngIf="collaborateur.mobilePhone" [innerHTML]="collaborateur.mobilePhone"></span>
        </div>
      </div>
    </div>
  </div>

  <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" (pageChanged)="onPageChanged($event)"
    [displayPageNumber]="false"></app-pagination>

</div>
