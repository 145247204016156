import { NgClass, NgFor } from "@angular/common";
import { AfterViewInit, Component, Renderer2, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MsalService } from "@azure/msal-angular";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../services/auth.service";
import { ChangeDetectorRef, HostListener } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { getUserFavorites, saveDataCache, useCache } from "../../utils/utils.service";
import { Service } from "../../models/service";
import { SidenavService } from "../../services/sidenav.service";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollReached } from "ngx-scrollbar/reached-event";
import { MesLiensService } from "../../services/mes-liens.service";
import { MesAppsService } from "../../services/mes-apps.service";

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    RouterModule,
    NgScrollbarModule,
    NgScrollReached
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent implements AfterViewInit {
  @ViewChildren('myListItems') listItems!: QueryList<ElementRef>;
  isScrolled: boolean = false;

  // Stockage des états des listes (ouvertes/fermées)
  private listStates: { [key: string]: boolean } = {};

  private currentRoute: string = '';

  protected isOpen = false;

  userServices: Service[] = [];

  isMobile = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private msalService: MsalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private sidenavService: SidenavService,
    private mesLiensService: MesLiensService,
    private mesAppsService: MesAppsService
  ) {
    // Initialisez les états des listes à 'ouvert' (true)
    this.listStates['menuExplorer'] = true;
    this.listStates['menuServices'] = true;
    // Ajoutez d'autres états de liste ici si nécessaire
  }

  ngOnInit(): void {
    // charger l'état sauvegardé par l'utilisateur ou mettre par defaut fermé si on est en mode mobile
    this.isOpen = localStorage.getItem('navbarIsOpen') ? localStorage.getItem('navbarIsOpen') === 'true' ? true : false : !this.checkIfMobile();
    this.updateMenuStates();
    // Récupérer le chemin de la route actuelle
    this.currentRoute = this.route.snapshot.url.join('/');
    environment.enableLogging && console.log("Current route: ", this.currentRoute);

    // mise à jour des services de l'utilisateur en fonction de ce qu'il a enregistré dans la page Mes Services
    this.sidenavService.currentServices.subscribe((services) => {
      this.userServices = services;
      saveDataCache('sidenav', { userServices: this.userServices });
    });

    useCache('sidenav', this, async () => {
      await Promise.all([
        getUserFavorites(this.apiService, this.mesLiensService, this.mesAppsService, this.sidenavService),
      ]);
    }, () => {
      return {
        userServices: this.userServices,
      }
    }, true);
  }

  private checkIfMobile(): boolean {
    const isMobile = window.innerWidth <= 768;
    this.isMobile = isMobile;
    return isMobile;
  }

  ngAfterViewInit(): void {
    const listContainers = this.el.nativeElement.querySelectorAll('.vnf-sidenav-list') as NodeListOf<HTMLElement>;

    listContainers.forEach((listContainer: HTMLElement) => {
      const links = listContainer.querySelectorAll('.vnf-sidenav-list-container a') as NodeListOf<HTMLElement>;

      links.forEach((link: HTMLElement) => {
        this.renderer.listen(link, 'mouseover', (event: MouseEvent) => {
          const target = event.currentTarget as HTMLElement;
          const span = target.querySelector('.vnf-sidenav-menu-item') as HTMLElement;

          if (span) {
            const offsetTop = target.offsetTop;
            const scrollTop = listContainer.scrollTop;

            // Ajuster la position 'top' du span
            this.renderer.setStyle(span, 'top', `${offsetTop - scrollTop}px`);
          }
        });
      });
    });
  }

  onScroll(event: any) {
    this.isScrolled = (event.target.scrollTop > 0);
  }

  updateMenuStates() {
    for (const [key, value] of Object.entries(this.listStates)) {
      const actualValue = localStorage.getItem(key + 'IsOpen');
      this.listStates[key] = actualValue ? actualValue === 'true' ? true : false : this.listStates[key];
    }
  }

  // Méthode pour basculer l'état d'une liste
  toggleList(id: string): void {
    this.listStates[id] = !this.listStates[id];
    localStorage.setItem(id + 'IsOpen', JSON.stringify(this.listStates[id]));
  }

  // Méthode pour vérifier si une liste est ouverte
  isListOpen(id: string): boolean {
    return this.listStates[id];
  }

  toggleSidenav() {
    this.isOpen = !this.isOpen;
    localStorage.setItem('navbarIsOpen', JSON.stringify(this.isOpen));
  }

  onLogout() {
    this.authService.triggerLogoutAction();
  }

  hoveredElementY = 0;
  hoveredElementName = '';
  onHover(event: MouseEvent, elementName: string) {
    const target = event.target as HTMLElement;
    const tooltip = document.querySelector('.vnf-sidenav-menu-tooltip') as HTMLElement;
    const menuItem = target.querySelector('.vnf-sidenav-menu-item') as HTMLElement;
    const sidenav = document.querySelector('.vnf-sidenav') as HTMLElement;

    if (tooltip && sidenav && !sidenav.classList.contains('sidenav-deployed')) {
      const pageScrollY = window.scrollY || document.documentElement.scrollTop;
      this.hoveredElementY = target.getBoundingClientRect().top;
      this.hoveredElementName = elementName;
      tooltip.innerHTML = menuItem.innerHTML;
      tooltip.style.top = `${this.hoveredElementY + pageScrollY}px`;
      tooltip.classList.add('active');
    }
  }

  onLeave() {
    const tooltip = document.querySelector('.vnf-sidenav-menu-tooltip') as HTMLElement;

    if (tooltip) {
      this.hoveredElementY = 0;
      this.hoveredElementName = '';
      tooltip.classList.remove('active');
    }
  }

  // Méthode pour replier le sidenav en mobile
  @HostListener('window:resize')
  closeSidenavOnMobile() {
    if (window.innerWidth <= 768) {
      this.isOpen = false;
      localStorage.setItem('navbarIsOpen', 'false');
      this.cdr.markForCheck();
    }
  }

  // Fermer le sidenav lors du clic sur un lien
  onLinkClick() {
    if (window.innerWidth <= 768) {
      this.isOpen = false;
      localStorage.setItem('navbarIsOpen', 'false');
    }
  }
}
