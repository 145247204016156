import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'dialog-send-email',
  templateUrl: './dialog-send-email.component.html',
  styleUrl: './dialog-send-email.component.scss',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatFormField, MatLabel, MatInput, NgClass, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogSendEmailComponent {
  isInvalid: boolean = false;
  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<DialogSendEmailComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  async sendEmail(email: string) {
    if (!email || email.trim() === '') {
      this.isInvalid = true;
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(email)) {
      await this.apiService.sharePageByEmail(this.data.slug, email);
    } else {
      console.error('Email is not valid.');
    }
    this.dialogRef.close();
  }
}
