import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EssentielEntry, EssentielList2Visuels} from '../../../models/essentiels';
import {KeyValuePipe, NgClass, NgForOf, NgIf, TitleCasePipe, UpperCasePipe} from "@angular/common";
import {EntriesComponent} from "../entries/entries.component";
import {SelectImplantationComponent} from "../../select-implantation/select-implantation.component";

@Component({
  selector: 'app-list-2-visuels',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgForOf,
    KeyValuePipe,
    TitleCasePipe,
    UpperCasePipe,
    EntriesComponent,
    SelectImplantationComponent
  ],
  templateUrl: './list-2-visuels.component.html',
  styleUrl: './list-2-visuels.component.scss'
})
export class List2VisuelsComponent implements OnChanges, OnInit {
  @Input() public essentielList2Visuels!: EssentielList2Visuels;
  keys: {label: string|null, code: string|null }[] = [];
  selectedEntries: EssentielEntry[] = [];
  selectedKey: number = 0;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.selectFirstKey();
  }

  selectFirstKey() {
    this.selectedKey = 0;
    this.updateSelectedEntries();
  }

  //Detecte le changement des entrées pour modifier les clefs
  ngOnChanges(changes: SimpleChanges) {
    if (changes['essentielList2Visuels']) {
      this.updateKeys();
      this.updateSelectedEntries();
      this.cdRef.detectChanges();
    }
  }

  onSelectedKeyChange(selectedKey: number) {
    this.selectedKey = selectedKey; // Met à jour la clé sélectionnée
    this.updateSelectedEntries(); // Met à jour les entrées sélectionnées
  }

  updateKeys() {
    this.keys = this.essentielList2Visuels.territorial_entries.map(
      (entry) => ({
        label: entry.label ?? null,
        code: entry.code ?? null
      })
    );
  }

  updateSelectedEntries() {
    this.selectedEntries = this.essentielList2Visuels.territorial_entries[this.selectedKey].entries ?? [];
  }
}
