// configuration d'preproduction (Azure)
export const environment = {
  production: false,
  enableLogging: true,
  cacheExpiration: 3600, //seconds
  apiUrl: 'https://vnfetmoi-api-prep2.vnf.fr/wp-json',
  msalConfig: {
    // Configuration Azure VNF INT
    auth: {
      clientId: '6b7c7606-e66c-4e26-8753-cbae49c7f3ef',
      authority: 'https://login.microsoftonline.com/e656fd6a-aaca-470b-a737-8d19eb341c97/v2.0',
    },
  },
  azureApiConfig: {
    scopes: ['user.read', 'profile', 'openid', 'Calendars.readWrite'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
};