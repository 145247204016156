import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from '../../../pipes/safe-url.pipe';
import { FlexibleVideo } from '../../../models/flexibles';

@Component({
  selector: 'app-flexible-video',
  standalone: true,
  imports: [CommonModule, SafeUrlPipe],
  templateUrl: './flexible-video.component.html',
  styleUrl: './flexible-video.component.scss'
})
export class FlexibleVideoComponent {
  @Input() public flexible!: FlexibleVideo;
  videoLoaded = false;
  videoUrl: SafeResourceUrl = '';
  currentVideoUrl: string = '';

  constructor(private sanitizer: DomSanitizer, private el: ElementRef, private renderer: Renderer2) { }

  onThumbnailClick(eventTarget: EventTarget | null) {
    if (eventTarget instanceof HTMLElement) {
      const videoUrl = eventTarget.getAttribute('data-video-url');
      this.currentVideoUrl = videoUrl || '';

      if (videoUrl) {
        if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
          this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getYouTubeEmbedUrl(videoUrl));
        } else if (videoUrl.includes('vimeo.com')) {
          this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getVimeoEmbedUrl(videoUrl));
        } else {
          console.error('Unsupported video URL:', videoUrl);
          return;
        }

        this.videoLoaded = true;
      } else {
        console.error('No data-video-url attribute found.');
      }
    } else {
      console.error('Event target is not an HTML element.');
    }
  }

  getYouTubeEmbedUrl(url: string): string {
    let videoId: string = '';

    try {
      if (url.includes('youtu.be')) {
        videoId = url.split('youtu.be/')[1] || '';
      } else if (url.includes('youtube.com') && !url.includes('embed')) {
        const urlParams = new URLSearchParams(new URL(url).search);
        videoId = urlParams.get('v') || '';
      } else if (url.includes('embed')) {
        return url;
      }

      if (!videoId) throw new Error('Video ID could not be extracted.');
      return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
    } catch (error) {
      console.error('Error extracting YouTube video ID:', error);
      return '';
    }
  }

  getVimeoEmbedUrl(url: string): string {
    if (url.includes('player') && url.includes('video')) return url;
    const videoId = url.split('vimeo.com/')[1];
    return `https://player.vimeo.com/video/${videoId}?autoplay=1`;
  }

  onVideoEnd() {
    this.videoLoaded = false;
    this.videoUrl = '';
  }

  ngAfterViewInit() {
    const iframe = this.el.nativeElement.querySelector('#videoIframe');
    if (iframe) {
      const player = (window as any).YT ? new (window as any).YT.Player(iframe) : null;

      if (player) {
        player.addEventListener('onStateChange', (event: any) => {
          if (event.data === (window as any).YT.PlayerState.ENDED) {
            this.onVideoEnd();
          }
        });
      } else {
        const vimeoPlayer = new (window as any).Vimeo.Player(iframe);

        vimeoPlayer.on('ended', () => {
          this.onVideoEnd();
        });
      }
    }
  }
}
