
<div class="{{flashInfo.bColor == 'blanc'? 'bg-white' : ''}}">
    <div class="flexible-flash-info flexible-content">
        <div class="flexible-flash-info-content">
            <a class="flexible-flash-info-content-link" rel="{{flashInfo.lien.type == 'ext'? 'external': ''}}"
                href="{{flashInfo.lien.url}}">
                <p class="flexible-flash-info-description" [customInnerHTML]="flashInfo.info">
                </p>
            </a>
        </div>
    </div>
</div>