import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { RouterLink, RouterLinkActive } from "@angular/router";
import { SliderOutilsComponent } from "../../components/slider-outils/slider-outils.component";
import { GraphApiService } from '../../services/graph-api.service';
import { sendToast, useCache } from '../../utils/utils.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { PaginationComponent } from '../../components/pagination/pagination.component';

interface Collaborateur {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  mail: string;
  jobTitle: string;
  departement: string;
  shortDepartement: string;
  localisation: string;
  city: string;
  businessPhones: string;
  mobilePhone: string;
  photoUrl: SafeUrl | null;
}

@Component({
  selector: 'app-collaborateurs',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    RouterLink,
    RouterLinkActive,
    NgFor,
    NgIf,
    LoadingOverlayComponent,
    PaginationComponent,
    FormsModule
  ],
  templateUrl: './collaborateurs.component.html',
  styleUrl: './collaborateurs.component.scss'
})
export class CollaborateursComponent {
  public isLoading: boolean = false;
  collaborateurs: Collaborateur[] = [];
  collaborateursToDisplay: Collaborateur[] = [];
  officeLocations: string[] = [];
  isFiltersActive: boolean = false;
  isOptionsActive: boolean = false;
  limit = 15;
  currentPage = 1;
  totalPages = 0;
  skiptoken: string | null = null;
  previousSelectedOption: string | null = null;
  selectedOption: string | null = null;

  openFilters(): void {
    this.isFiltersActive = !this.isFiltersActive;
  }

  @ViewChild('optionsOpener', { static: false }) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu', { static: false }) optionsMenu!: ElementRef;

  constructor(private graphApiService: GraphApiService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    useCache('collaborateurs', this, async () => {
      await Promise.all([
        this.getOfficeLocations(),
        this.getCollaborateurs(this.currentPage, this.limit)
      ]);
    }, () => {
      return {
        collaborateurs: this.collaborateurs,
      }
    });
  }

  async getOfficeLocations(offLocations?: string[], skiptoken?: string | null): Promise<void> {
    try {
      let officeLocations: string[] = offLocations || [];
      const data = await this.graphApiService.getOfficeLocations({
        ...(skiptoken && { skiptoken: skiptoken })
      });
      // on récupères les dt et périmètres
      officeLocations.push(...data?.value?.map((d: any) => d.onPremisesExtensionAttributes?.extensionAttribute1).filter((item: any) => item));
      // on supprime les doublons
      officeLocations = [...new Set(officeLocations)].sort();

      // si il y a d'autre page on continue...
      if (data['@odata.nextLink']) {
        const url = new URL(data['@odata.nextLink']);
        const token = url.searchParams.get('$skiptoken');
        return await this.getOfficeLocations(officeLocations, token);
      }

      this.officeLocations = officeLocations;
      environment.enableLogging && console.log('getOfficeLocations', data, this.officeLocations);
    } catch (e) {
      console.error('getOfficeLocations', e);
      sendToast({type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.'});
    }
  }

  async getCollaborateurs(page: number, limit: number = 15) {
    try {
      const startIndex = (page - 1) * limit;
      const endIndex = page * limit;
      const collaborateursToDisplay = this.collaborateurs.slice(startIndex, endIndex);

      if (!collaborateursToDisplay.length || this.selectedOption !== this.previousSelectedOption) {
        const data = await this.graphApiService.getUsers({
          top: limit,
          ...(this.skiptoken && { skiptoken: this.skiptoken }),
          ...(this.selectedOption && { dt: this.selectedOption })
        });
        // on récupère le lien de la page suivante avec le skiptoken
        if (data['@odata.nextLink']) {
          const url = new URL(data['@odata.nextLink']);
          this.skiptoken = url.searchParams.get('$skiptoken');
        } else {
          this.skiptoken = null;
        }

        // on stock le nombre total de pages
        if (data['@odata.count'])
          this.totalPages = Math.ceil(data['@odata.count'] / limit);

        // on formate les collaborateurs récupérés
        const newCollaborateurs = await this.formatCollaborateurs(data.value);
        // on ajoute les collaborateurs récupérés à la liste
        this.collaborateurs = this.removeDuplicatesById([...this.collaborateurs, ...newCollaborateurs]);
        // on met à jour les collaborateurs à afficher
        this.collaborateursToDisplay = newCollaborateurs;
        this.previousSelectedOption = this.selectedOption;

        environment.enableLogging && console.log('getCollaborateurs', data);
        environment.enableLogging && console.log('newCollaborateurs', newCollaborateurs);
        environment.enableLogging && console.log('this.collaborateurs', this.collaborateurs);
      } else {
        // on met à jour les collaborateurs à afficher
        this.collaborateursToDisplay = collaborateursToDisplay;

        environment.enableLogging && console.log('collaborateursToDisplay', collaborateursToDisplay);
      }
      this.isLoading = false;
    } catch (e) {
      console.error('getCollaborateurs', e);
      sendToast({type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.'});
    }
  }

  async formatCollaborateurs(data: any): Promise<Collaborateur[]> {
    const collaborateurs = [];
    try {
      for (const collaborateur of data) {
        let photoUrl = null;

        // on tente de récupérer la photo de profil de l'utilisateur
        try {
          if (collaborateur.mail) {
            const pictureBlob = await this.graphApiService.getProfilPictureByEmail(collaborateur.mail);
            const objectUrl = URL.createObjectURL(pictureBlob);
            photoUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
          }
        } catch (e) {
          // environment.enableLogging && console.error(e);
        }

        // on formate les données du collaborateur
        collaborateurs.push({
          id: collaborateur.id,
          displayName: collaborateur.displayName,
          givenName: collaborateur.givenName || '',
          surname: collaborateur.surname || '',
          mail: collaborateur.mail,
          jobTitle: collaborateur.jobTitle,
          departement: collaborateur.department,
          shortDepartement: collaborateur.onPremisesExtensionAttributes?.extensionAttribute1,
          localisation: collaborateur.officeLocation,
          city: collaborateur.city,
          businessPhones: collaborateur.businessPhones[0],
          mobilePhone: collaborateur.mobilePhone,
          photoUrl: photoUrl,
        } as Collaborateur);
      }
    } catch (e) {
      console.error('formatCollaborateurs', e);
      sendToast({type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.'});
    }
    return collaborateurs;
  }


  removeDuplicatesById(array: any[], key: string = 'id') {
    return array.reduce((uniqueArray, currentObject) => {
      // Vérifie si l'ID de l'objet actuel est déjà présent dans le tableau unique
      if (!uniqueArray.some((obj: any) => obj[key] === currentObject[key])) {
        uniqueArray.push(currentObject);  // Ajoute l'objet si l'ID est unique
      }
      return uniqueArray;
    }, []);
  }

  onPageChanged(page: number) {
    this.isLoading = true;
    this.currentPage = page;
    this.getCollaborateurs(this.currentPage, this.limit);
  }

  onSelectionChange(event: any) {
    this.clearPaginationData();
    this.selectedOption = event.value;
    this.getCollaborateurs(this.currentPage, this.limit);
    environment.enableLogging && console.log('selectedOption', this.selectedOption);
  }

  clearSelection() {
    this.clearPaginationData();
    this.selectedOption = null;
    this.getCollaborateurs(this.currentPage, this.limit);
    this.isFiltersActive = !this.isFiltersActive;
  }

  clearPaginationData() {
    this.isLoading = true;
    this.totalPages = 0;
    this.currentPage = 1;
    this.collaborateurs = [];
    this.skiptoken = null;
  }

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener && this.optionsMenu) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      const clickedInsideMenu = this.optionsMenu.nativeElement.contains(targetElement);

      if (!clickedInsideOpener && !clickedInsideMenu) {
        this.isOptionsActive = false;
      }
    }
  }
}
