import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { KeyValuePipe, NgForOf, UpperCasePipe } from "@angular/common";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
import { useCache } from '../../utils/utils.service';
import { App } from '../../models/app';

@Component({
  selector: 'app-mes-apps',
  standalone: true,
  templateUrl: './mes-apps.component.html',
  imports: [
    KeyValuePipe,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    UpperCasePipe
  ],
  styleUrls: ['./mes-apps.component.scss']
})
export class MesAppsComponent {
  isOptionsActive = false;
  apps: App[] = [];
  filtres: string[] = [];
  selectedKey: number = -1;

  toggleFavorite(event: Event): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const parent = target.parentElement as HTMLElement;
    parent.classList.toggle('favorite');
  }

  @ViewChild('optionsOpener', { static: false }) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu', { static: false }) optionsMenu!: ElementRef;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    // load homepage data
    useCache('apps', this, async () => {
      // load Breves, Events and Home data
      await Promise.all([
        this.getApps(),
      ]);
    }, () => {
      return {
        apps: this.apps,
      }
    });
  }

  async getApps(): Promise<void> {
    const { body, headers } = await this.apiService.getApps({ ...(this.selectedKey > 0 && { categorie: this.filtres[this.selectedKey] }) });
    // transform value
    if (body) {
      this.apps = this.formatApps(body.data);
      this.filtres = ['Toutes les Apps', ...body.filtres];
    }
    environment.enableLogging && console.log('apps', body, headers);
  }

  formatApps(data: any[]): App[] {
    const apps: App[] = [];
    try {
      data.forEach((item: any) => {
        apps.push({
          id: item.id,
          title: item.title.rendered,
          category: { name: item.acf?.categorie_application_transverse?.name, slug: item.acf?.categorie_application_transverse?.slug },
          lien: { url: item.acf?.lien },
          isDefault: item.acf?.application_par_defaut,
        });
      });
    } catch (e) {
      environment.enableLogging && console.error('formatApps', e);
    }
    return apps;
  }

  onSelectionChange(selectedKey: number) {
    if (selectedKey !== this.selectedKey) {
      if (selectedKey > 0)
        this.selectedKey = selectedKey;
      else
        this.selectedKey = -1;
      this.getApps();
    }
  }

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener && this.optionsMenu) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      const clickedInsideMenu = this.optionsMenu.nativeElement.contains(targetElement);

      if (!clickedInsideOpener && !clickedInsideMenu) {
        this.isOptionsActive = false;
      }
    }
  }
}
