<div class="searchbar-layout">
    <div class="col-fullwidth">
        <app-searchbar *ngIf="!preview"></app-searchbar>
    </div>
</div>

<div class="main-layout no-padding-top">
    <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
    <app-header *ngIf="!preview" class="layout" [header_type]="0" type="circle" sup="Qui sommes-nous ?" inf="Quel cap ?"
        sup_link="/qui-sommes-nous" inf_link="/quel-cap" title="Au quotidien" />

    <app-timeline *ngIf="timeline" class="layout mb-40"
                  [timelineTitle]="timeline.title"
                  [timelineDescription]="timeline.description"
                  [timelineEvents]="timeline.timelineEvents"
    ></app-timeline>

    <app-zoom-sur class="layout" [zoomsLien]="zoomsLien" />

    <div class="col-left mb-30">
        <!-- module essentiels -->
        <app-essentiel-list class="layout" [essentiels]="essentielsLeft"></app-essentiel-list>
    </div>
    <div class="col-right mb-30">
        <!-- module essentiels -->
        <app-essentiel-list class="layout" [essentiels]="essentielsRight"></app-essentiel-list>
        <!-- boîte à outils -->
        <app-boite-a-outils class="layout" [tools]="tools" />
    </div>

    <app-bloc-redirection *ngIf="!preview" class="layout" [identite]="true" [cap]="true" />
</div>