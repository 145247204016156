import {NgClass, NgForOf, NgIf} from "@angular/common";
import {Component} from '@angular/core';
import {MatNativeDateModule, provideNativeDateAdapter} from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangeInput
} from '@angular/material/datepicker';
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from '@angular/material/input';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {environment} from '../../../environments/environment';
import {ActualitesALaUneComponent} from "../../components/actualites-a-la-une/actualites-a-la-une.component";
import {EventsComponent} from "../../components/agenda/events/events.component";
import {BrevesComponent} from "../../components/breves/breves.component";
import {EnImagesComponent} from "../../components/en-images/en-images.component";
import {ListActualitesComponent} from "../../components/list-actualites/list-actualites.component";
import {PaginationComponent} from "../../components/pagination/pagination.component";
import {ParticipationComponent} from "../../components/participation/participation.component";
import {Actualite} from '../../models/actualite';
import {AppelsParticipation} from '../../models/appels-participation';
import {Breve} from "../../models/breve";
import {EnImage} from '../../models/en-images';
import {Event} from '../../models/event';
import {ApiService} from '../../services/api.service';
import {
  formatActualites,
  formatActualitesALaUne,
  formatAppelsParticipationPush,
  formatBreves,
  formatEnImages,
  formatEvents,
  useCache
} from '../../utils/utils.service';
import {FiltersBarComponent} from "../../components/search/filters-bar/filters-bar.component";
import {SearchFilter} from "../../models/search-filters";

@Component({
  selector: 'app-actualites',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    NgClass,
    MatFormField,
    MatDatepickerToggle,
    MatDatepicker,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerInput,
    RouterLink, EventsComponent,
    EnImagesComponent,
    BrevesComponent,
    ParticipationComponent,
    NgForOf,
    ListActualitesComponent,
    ActualitesALaUneComponent,
    PaginationComponent,
    NgIf, FiltersBarComponent
  ],
  templateUrl: './actualites.component.html',
  styleUrl: './actualites.component.scss'
})
export class ActualitesComponent {
  actualitesALaUne: Actualite[] = [];
  actualites: Actualite[] = [];
  enImages: EnImage[] = [];
  appelsParticipations: AppelsParticipation[] = [];
  breves: Breve[] = [];
  events: Event[] = [];
  filtres: {
    perimetres: SearchFilter[],
    themes: SearchFilter[],
    natures: SearchFilter[]|null,
    startDate: Date | null,
    endDate: Date | null
  } = {perimetres: [], themes: [], natures: null, startDate: null, endDate: null};
  currentPage = 1;
  limit = 10;
  totalPages = 0;

  openDropdownIndex: number | null = null;
  isActive: boolean = false;

  constructor(private apiService: ApiService, private route: ActivatedRoute,) {
  }

  async ngOnInit(): Promise<void> {
    // Récupérer les paramètres GET de la route actuelle
    this.route.queryParams.subscribe(params => {
      this.currentPage = !isNaN(params['p']) ? +params['p'] : 1; // récupérer le numéro de la page
      this.limit = !isNaN(params['limit']) ? +params['limit'] : 10; // récupérer le nombre d'éléments par page
    });

    useCache('actualites', this, async () => {
      await Promise.all([
        this.getGlobalContent(),
        // this.getAppelsParticipation(),
        // this.getActuALaUne(),
        // this.getEnImages(),
        this.getActualites(this.currentPage)
      ]);
    }, () => {
      return {
        actualites: this.actualites,
        actualitesALaUne: this.actualitesALaUne,
        enImages: this.enImages,
        appelsParticipations: this.appelsParticipations,
        breves: this.breves,
        events: this.events
      }
    });
  }

  async onDateChange(dateRangeInput: MatDateRangeInput<Date>) {
    // Mettre à jour les dates de début et de fin
    this.filtres.startDate = dateRangeInput.value?.start ? new Date(dateRangeInput.value?.start) : null;
    this.filtres.endDate = dateRangeInput.value?.end ? new Date(dateRangeInput.value?.end) : null;

    environment.enableLogging && console.log(this.filtres.startDate, this.filtres.endDate);

    // Récupérer les actualités avec les filtres
    this.getGlobalContent();
    this.getActualites(this.currentPage);
  }

  async getGlobalContent() {
    try {
      // Récupérer les filtres
      const dts = this.filtres.perimetres.find(perimetre => perimetre.value === 'direction-territoriale')?.sub_filters?.filter(dt => dt.checked).map(dt => dt.value);
      const perimetres = this.filtres.perimetres.filter(perimetre => perimetre.checked).map(perimetre => perimetre.value);
      const themes = this.filtres.themes.filter(theme => theme.checked).map(theme => theme.value);

      // Récupérer les actualités avec les filtres
      const {body, headers} = await this.apiService.getPageActualites({
        ...(dts?.length && {dt: dts.join(',')}),
        ...(perimetres.length && {perimetre: perimetres.join(',')}),
        ...(themes.length && {'theme-actu': themes.join(',')}),
        ...(this.filtres.startDate && {'from-date': this.filtres.startDate}),
        ...(this.filtres.endDate && {'to-date': this.filtres.endDate})
      });
      environment.enableLogging && console.log('Page Actualites', body, headers);

      if (body) {
        // transform value
        if (body.actualites_a_la_une)
          this.actualitesALaUne = formatActualitesALaUne(body.actualites_a_la_une);
        if (body.medias)
          this.enImages = formatEnImages(body.medias);
        if (body.appels_participation)
          this.appelsParticipations = formatAppelsParticipationPush(body.appels_participation);
        if (body.breves)
          this.breves = formatBreves(body.breves);
        if (body.evenements)
          this.events = formatEvents(body.evenements);
        if (body.filtres)
          this.filtres = {
            perimetres: body.filtres?.perimetres?.map((perimetre: any) => ({
              name: perimetre.name,
              value: perimetre.slug,
              ...(!perimetre.sous_filtres && {checked: this.filtres.perimetres.some(filtre => filtre.value === perimetre.slug && filtre.checked)}),
              ...(perimetre.sous_filtres && {
                sous_filtres: perimetre.sous_filtres.map((sous_filtre: any) => ({
                  name: sous_filtre.name,
                  value: sous_filtre.slug,
                  checked: this.filtres.perimetres.find(filtre => filtre.value === perimetre.slug)?.sub_filters.some(filtre => filtre.value === sous_filtre.slug && filtre.checked)
                }))
              }),
            })),
            themes: body.filtres?.['themes-actualite']?.map((theme: any) => ({
              name: theme.name,
              value: theme.slug,
              checked: this.filtres.themes.some(filtre => filtre.value === theme.slug && filtre.checked)
            })),
            natures: null,
            startDate: this.filtres.startDate ? this.filtres.startDate : null,
            endDate: this.filtres.endDate ? this.filtres.endDate : null
          };
      }
    } catch (error) {
      environment.enableLogging && console.error(error);
    }
  }

  onFiltresChange(updatedFiltres: any): void {
    this.filtres = updatedFiltres;
  }

  async onSearch() {
    this.getGlobalContent();
    this.getActualites(this.currentPage);
  }

  async getActuALaUne() {
    const {body, headers} = await this.apiService.getActuALaUne();
    environment.enableLogging && console.log(body, headers);

    if (body && body.actualites) {
      // transform value
      this.actualitesALaUne = formatActualitesALaUne(body.actualites);
    }

    environment.enableLogging && console.log(this.actualitesALaUne);
  }

  async getActualites(page: number, limit: number = 10) {
    try {
      // Récupérer les filtres
      const dts = this.filtres.perimetres.find(perimetre => perimetre.value === 'direction-territoriale')?.sub_filters?.filter(dt => dt.checked).map(dt => dt.value);
      const perimetres = this.filtres.perimetres.filter(perimetre => perimetre.checked).map(perimetre => perimetre.value);
      const themes = this.filtres.themes.filter(theme => theme.checked).map(theme => theme.value);

      // Récupérer les actualités avec les filtres
      const {body, headers} = await this.apiService.getActualites({
        page,
        limit,
        ...(dts?.length && {dt: dts.join(',')}),
        ...(perimetres.length && {perimetre: perimetres.join(',')}),
        ...(themes.length && {'theme-actualite': themes.join(',')}),
        ...(this.filtres.startDate && {'from-date': this.filtres.startDate}),
        ...(this.filtres.endDate && {'to-date': this.filtres.endDate})
      });
      environment.enableLogging && console.log('Actualites', body, headers);

      if (body) {
        // transform value
        this.actualites = formatActualites(body);

        this.totalPages = headers.get('x-wp-totalpages');
      }

      environment.enableLogging && console.log(this.actualites);
    } catch (error) {
      environment.enableLogging && console.error(error);
    }
  }

  async getEnImages() {
    const {body, headers} = await this.apiService.getEnImages();
    environment.enableLogging && console.log(body, headers);

    if (body) {
      // transform value
      this.enImages = formatEnImages(body);
    }

    environment.enableLogging && console.log(this.enImages);
  }

  async getAppelsParticipation() {
    const {body, headers} = await this.apiService.getAppelsParticipation();
    environment.enableLogging && console.log(body, headers);

    if (body) {
      // transform value
      this.appelsParticipations = formatAppelsParticipationPush(body);
    }

    environment.enableLogging && console.log(this.appelsParticipations);
  }

  onPageChanged(page: number) {
    this.currentPage = page;
    this.getActualites(this.currentPage, this.limit);
  }

  onLimitChanged(limit: number) {
    this.limit = limit;
    this.getActualites(this.currentPage, this.limit);
  }
}

