import { FlashInfo } from "./flexibles";
import { Visuel } from "./visuel";
import {Link} from "./link";

export interface Essentiel {
    id: number;
    title?: string;
    type: 'list-2-visuels' | 'picto-list' | 'lists-1-visuel' | 'cards' | 'mini-cards';
    icon_type: 'diamond' | 'triangle' | 'circle';
    picto?: { url: string, alt: string };
}

export interface EssentielList2Visuels extends Essentiel {
    infography_natio: Visuel;
    infography_natio_pos: 'gauche' | 'droite';
    national_entries: EssentielEntry[];
    territorial_subtitle: string;
    infography_territo: Visuel;
    infographic_territo_pos: 'gauche' | 'droite';
    territorial_entries: { label: string, code: string, entries: EssentielEntry[] }[];
}

export interface EssentielPictoList extends Essentiel {
    national_entries: EssentielEntry[];
    text: string;
    pictoPos: 'gauche' | 'droite';
    picto_infography: Visuel;
}

export interface EssentielLists1Visuel extends Essentiel {
    infography: Visuel;
    infography_pos: 'gauche' | 'droite';
    national_entries: EssentielEntry[];
    territorial_subtitle: string;
    territorial_entries: { label: string, code: string, entries: EssentielEntry[] }[];
}

export interface EssentielCards extends Essentiel {
    description?: string;
    flashInfo?: FlashInfo;
    cards: EssentielCard[];
}

export interface EssentielCard extends Essentiel {
    description?: string;
    visuel: any;
    lien: Link;
    couleurs_cards: 'clair' | 'fonce';
}

export interface EssentielMiniCards extends Essentiel {
    description?: string;
    cards: EssentielCard[];
    cardComplementaire?: CardComplementaire;
}

export interface CardComplementaire extends Essentiel {
    description?: string;
    visuel: any;
    lien: Link;
    couleurs_cards: 'clair' | 'fonce';
}

export function isEssentielList2Visuels(essentiel: Essentiel): essentiel is EssentielList2Visuels {
    return essentiel.type === 'list-2-visuels';
}

export function isEssentielPictoList(essentiel: Essentiel): essentiel is EssentielPictoList {
    return essentiel.type === 'picto-list';
}

export function isEssentielLists1Visuel(essentiel: Essentiel): essentiel is EssentielLists1Visuel {
    return essentiel.type === 'lists-1-visuel';
}

export function isEssentielCards(essentiel: Essentiel): essentiel is EssentielCards {
    return essentiel.type === 'cards';
}

export function isEssentielMiniCards(essentiel: Essentiel): essentiel is EssentielMiniCards {
    return essentiel.type === 'mini-cards';
}

export interface EssentielEntry {
    id: number;
    description: string;
    visuel: any;
    lien: Link;
}

