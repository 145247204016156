import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChildren, QueryList, ElementRef, OnInit, Input, AfterViewInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { FlexibleSliderImg } from '../../../models/flexibles';

registerSwiperElements();

@Component({
  selector: 'app-flexible-slider-img',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flexible-slider-img.component.html',
  styleUrl: './flexible-slider-img.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FlexibleSliderImgComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() flexible!: FlexibleSliderImg;
  @ViewChildren('swiperContainer', { read: ElementRef }) swiperContainers!: QueryList<ElementRef>;

  constructor(private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {

  }

  // Detect changes when the input 'breves' is updated
  ngOnChanges(): void {
    this.cdr.detectChanges();
    this.initSliderEvent();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.initSliderEvent();
  }

  initSliderEvent(): void {
    this.swiperContainers.forEach((swiperContainer) => {
      const swiperEl = swiperContainer.nativeElement;

      if (swiperEl.swiper) {
        swiperEl.swiper.update();
      } else {
        this.initializeSwiper(swiperEl);
      }
    });
  }

  initializeSwiper(swiperEl: any): void {
    // Swiper parameters
    const swiperParams = {
      slidesPerView: 1,
      direction: 'horizontal',
      navigation: false,
      cssMode: false,
      loop: false,
      speed: 500,
      spaceBetween: 0,
      pagination: {
        clickable: true,
      },
    };
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }
}
