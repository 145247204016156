<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <div class="flexible-push-portrait flexible-content">
    <div class="flexible-push-portrait--pushs">
      @for (portrait of flexible.portraits; track portrait.id) {
        <div class="flexible-push-portrait--push">
          <div class="flexible-push-portrait--push-content">
            <p class="line-name" [customInnerHTML]="portrait.nom"></p>
            <p class="line-assignment" [customInnerHTML]="portrait.fonction"></p>
            <p class="line-text" [customInnerHTML]="portrait.content"></p>
            <div class="flexible-push-portrait--push-img">
              <img src="{{ portrait.visuel.sizes?.['w172h375'] }}"
              loading="lazy"
              width="172"
              height="375"
              [alt]="portrait.visuel.alt" />
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
