const DEFAULT_DATA_LAYER_NAME = "dataLayer";
let dataLayerName = DEFAULT_DATA_LAYER_NAME;
function setDataLayerName(name) {
  dataLayerName = name;
}
function push$1(data) {
  if (typeof window[dataLayerName] !== "object") {
    window[dataLayerName] = [];
  }
  IS_DEBUG && console.log("DataLayer push", data);
  return window[dataLayerName].push(data);
}
const dataLayer_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  push: push$1,
  setDataLayerName
}, Symbol.toStringTag, {
  value: "Module"
}));
function getConfig(nonceOrOptions) {
  if (typeof nonceOrOptions === "string") {
    return {
      dataLayerName: DEFAULT_DATA_LAYER_NAME,
      nonce: nonceOrOptions
    };
  }
  if (!nonceOrOptions) {
    return {
      dataLayerName: DEFAULT_DATA_LAYER_NAME
    };
  }
  return nonceOrOptions;
}
function init(containerId, containerUrl, nonceOrOptions) {
  if (!containerId) {
    console.error("Empty tracking code for Piwik Pro.");
    return;
  }
  if (!containerUrl) {
    console.error("Empty tracking URL for Piwik Pro.");
    return;
  }
  if (typeof window === "undefined") {
    console.error("Was not possible to access window. Make sure this module is running in a browser");
    return;
  }
  const config = getConfig(nonceOrOptions);
  if (config.dataLayerName) {
    setDataLayerName(config.dataLayerName);
  }
  const scriptEl = document.createElement("script");
  scriptEl.id = "PiwikPROInitializer";
  scriptEl.async = true;
  if (config.nonce) {
    scriptEl.nonce = config.nonce;
  }
  scriptEl.text = getInitScript({
    containerId,
    containerUrl,
    dataLayerName: config.dataLayerName,
    nonceValue: config.nonce
  });
  const body = document.getElementsByTagName("body")[0];
  body.appendChild(scriptEl);
}
function getInitScript({
  containerId,
  containerUrl,
  dataLayerName: dataLayerName2,
  nonceValue
}) {
  const dataLayer = dataLayerName2 || DEFAULT_DATA_LAYER_NAME;
  const nonceTag = nonceValue ? `,tags.nonce="${nonceValue}"` : "";
  return `(function(window, document, dataLayerName, id) {
  window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
  function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
  var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
  var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName)${nonceTag},isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
  tags.async=!0,tags.src="${containerUrl}/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
  !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
  })(window, document, '${dataLayer}', '${containerId}');`;
}
const IS_DEBUG = typeof process !== "undefined" && process.env.NODE_ENV === "development" || typeof window !== "undefined" && window.IS_DEBUG || false;
var ECOMMERCE_TRACK_EVENT;
(function (ECOMMERCE_TRACK_EVENT2) {
  ECOMMERCE_TRACK_EVENT2["TRACK_ECOMMERCE_CART_UPDATE"] = "trackEcommerceCartUpdate";
  ECOMMERCE_TRACK_EVENT2["TRACK_ECOMMERCE_ORDER"] = "trackEcommerceOrder";
  ECOMMERCE_TRACK_EVENT2["REMOVE_ECOMMERCE_ITEM"] = "removeEcommerceItem";
  ECOMMERCE_TRACK_EVENT2["SET_ECOMMERCE_VIEW"] = "setEcommerceView";
  ECOMMERCE_TRACK_EVENT2["CLEAR_ECOMMERCE_CART"] = "clearEcommerceCart";
  ECOMMERCE_TRACK_EVENT2["ADD_ECOMMERCE_ITEM"] = "addEcommerceItem";
})(ECOMMERCE_TRACK_EVENT || (ECOMMERCE_TRACK_EVENT = {}));
var ECOMMERCE_V2_TRACK_EVENT;
(function (ECOMMERCE_V2_TRACK_EVENT2) {
  ECOMMERCE_V2_TRACK_EVENT2["ECOMMERCE_ADD_TO_CART"] = "ecommerceAddToCart";
  ECOMMERCE_V2_TRACK_EVENT2["ECOMMERCE_REMOVE_FROM_CART"] = "ecommerceRemoveFromCart";
  ECOMMERCE_V2_TRACK_EVENT2["ECOMMERCE_PRODUCT_DETAIL_VIEW"] = "ecommerceProductDetailView";
  ECOMMERCE_V2_TRACK_EVENT2["ECOMMERCE_CART_UPDATE"] = "ecommerceCartUpdate";
  ECOMMERCE_V2_TRACK_EVENT2["ECOMMERCE_ORDER"] = "ecommerceOrder";
})(ECOMMERCE_V2_TRACK_EVENT || (ECOMMERCE_V2_TRACK_EVENT = {}));
var COOKIE_TRACK_EVENT;
(function (COOKIE_TRACK_EVENT2) {
  COOKIE_TRACK_EVENT2["DISABLE_COOKIES"] = "disableCookies";
  COOKIE_TRACK_EVENT2["ENABLE_COOKIES"] = "enableCookies";
  COOKIE_TRACK_EVENT2["DELETE_COOKIES"] = "deleteCookies";
  COOKIE_TRACK_EVENT2["SET_COOKIE_NAME_PREFIX"] = "setCookieNamePrefix";
  COOKIE_TRACK_EVENT2["SET_COOKIE_DOMAIN"] = "setCookieDomain";
  COOKIE_TRACK_EVENT2["SET_COOKIE_PATH"] = "setCookiePath";
  COOKIE_TRACK_EVENT2["SET_SECURE_COOKIE"] = "setSecureCookie";
  COOKIE_TRACK_EVENT2["SET_VISITOR_COOKIE_TIMEOUT"] = "setVisitorCookieTimeout";
  COOKIE_TRACK_EVENT2["SET_SESSION_COOKIE_TIMEOUT"] = "setSessionCookieTimeout";
  COOKIE_TRACK_EVENT2["SET_VISITOR_ID_COOKIE"] = "setVisitorIdCookie";
  COOKIE_TRACK_EVENT2["SET_REFERRAL_COOKIE_TIMEOUT"] = "setReferralCookieTimeout";
})(COOKIE_TRACK_EVENT || (COOKIE_TRACK_EVENT = {}));
var CONTENT_TRACK_EVENT;
(function (CONTENT_TRACK_EVENT2) {
  CONTENT_TRACK_EVENT2["ALL_CONTENT_IMPRESSIONS"] = "trackAllContentImpressions";
  CONTENT_TRACK_EVENT2["VISIBLE_CONTENT_IMPRESSIONS"] = "trackVisibleContentImpressions";
  CONTENT_TRACK_EVENT2["CONTENT_IMPRESSIONS_WITH_NODE"] = "trackContentImpressionsWithinNode";
  CONTENT_TRACK_EVENT2["CONTENT_IMPRESSION"] = "trackContentImpression";
  CONTENT_TRACK_EVENT2["LOG_ALL_CONTENT_BLOCKS_ON_PAGE"] = "logAllContentBlocksOnPage";
  CONTENT_TRACK_EVENT2["CONTENT_INTERACTION_NODE"] = "trackContentInteractionNode";
  CONTENT_TRACK_EVENT2["CONTENT_INTERACTION"] = "trackContentInteraction";
})(CONTENT_TRACK_EVENT || (CONTENT_TRACK_EVENT = {}));
var CUSTOM_DIMENSIONS_TRACK_EVENT;
(function (CUSTOM_DIMENSIONS_TRACK_EVENT2) {
  CUSTOM_DIMENSIONS_TRACK_EVENT2["SET_CUSTOM_DIMENSION_VALUE"] = "setCustomDimensionValue";
  CUSTOM_DIMENSIONS_TRACK_EVENT2["DELETE_CUSTOM_DIMENSION"] = "deleteCustomDimension";
  CUSTOM_DIMENSIONS_TRACK_EVENT2["SET_CUSTOM_DIMENSION"] = "setCustomDimension";
})(CUSTOM_DIMENSIONS_TRACK_EVENT || (CUSTOM_DIMENSIONS_TRACK_EVENT = {}));
var CUSTOM_EVENT_TRACK_EVENT;
(function (CUSTOM_EVENT_TRACK_EVENT2) {
  CUSTOM_EVENT_TRACK_EVENT2["CUSTOM_EVENT"] = "trackEvent";
})(CUSTOM_EVENT_TRACK_EVENT || (CUSTOM_EVENT_TRACK_EVENT = {}));
var DOWNLOAD_AND_OUTLINK_TRACK_EVENT;
(function (DOWNLOAD_AND_OUTLINK_TRACK_EVENT2) {
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["LINK"] = "trackLink";
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["ENABLE_LINK_TRACKING"] = "enableLinkTracking";
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["SET_IGNORE_CLASSES"] = "setIgnoreClasses";
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["SET_LINK_CLASSES"] = "setLinkClasses";
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["SET_DOWNLOAD_CLASSES"] = "setDownloadClasses";
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["SET_DOWNLOAD_EXTENSIONS"] = "setDownloadExtensions";
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["ADD_DOWNLOAD_EXTENSIONS"] = "addDownloadExtensions";
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["REMOVE_DOWNLOAD_EXTENSIONS"] = "removeDownloadExtensions";
  DOWNLOAD_AND_OUTLINK_TRACK_EVENT2["SET_LINK_TRACKING_TIMER"] = "setLinkTrackingTimer";
})(DOWNLOAD_AND_OUTLINK_TRACK_EVENT || (DOWNLOAD_AND_OUTLINK_TRACK_EVENT = {}));
var GOAL_CONVERSIONS_TRACK_EVENT;
(function (GOAL_CONVERSIONS_TRACK_EVENT2) {
  GOAL_CONVERSIONS_TRACK_EVENT2["GOAL"] = "trackGoal";
})(GOAL_CONVERSIONS_TRACK_EVENT || (GOAL_CONVERSIONS_TRACK_EVENT = {}));
var PAGE_VIEWS_TRACK_EVENT;
(function (PAGE_VIEWS_TRACK_EVENT2) {
  PAGE_VIEWS_TRACK_EVENT2["PAGE_VIEW"] = "trackPageView";
})(PAGE_VIEWS_TRACK_EVENT || (PAGE_VIEWS_TRACK_EVENT = {}));
var SITE_SEARCH_TRACK_EVENT;
(function (SITE_SEARCH_TRACK_EVENT2) {
  SITE_SEARCH_TRACK_EVENT2["SEARCH"] = "trackSiteSearch";
})(SITE_SEARCH_TRACK_EVENT || (SITE_SEARCH_TRACK_EVENT = {}));
var USER_MANAGEMENT_TRACK_EVENT;
(function (USER_MANAGEMENT_TRACK_EVENT2) {
  USER_MANAGEMENT_TRACK_EVENT2["SET_USER_ID"] = "setUserId";
  USER_MANAGEMENT_TRACK_EVENT2["RESET_USER_ID"] = "resetUserId";
})(USER_MANAGEMENT_TRACK_EVENT || (USER_MANAGEMENT_TRACK_EVENT = {}));
var PAQ_SERVICE_TRACK_EVENT;
(function (PAQ_SERVICE_TRACK_EVENT2) {
  PAQ_SERVICE_TRACK_EVENT2["SET_CUSTOM_URL"] = "setCustomUrl";
  PAQ_SERVICE_TRACK_EVENT2["SET_DOCUMENT_TITLE"] = "setDocumentTitle";
})(PAQ_SERVICE_TRACK_EVENT || (PAQ_SERVICE_TRACK_EVENT = {}));
var ERROR_TRACKING_TRACK_EVENT;
(function (ERROR_TRACKING_TRACK_EVENT2) {
  ERROR_TRACKING_TRACK_EVENT2["ENABLE_JS_ERROR_TRACKING"] = "enableJSErrorTracking";
  ERROR_TRACKING_TRACK_EVENT2["TRACK_ERROR"] = "trackError";
})(ERROR_TRACKING_TRACK_EVENT || (ERROR_TRACKING_TRACK_EVENT = {}));
function push(collection) {
  if (!window._paq) {
    window._paq = [];
  }
  window._paq.push([PAQ_SERVICE_TRACK_EVENT.SET_CUSTOM_URL, window.location.href]);
  window._paq.push([PAQ_SERVICE_TRACK_EVENT.SET_DOCUMENT_TITLE, document.title]);
  IS_DEBUG && console.log("Push", collection);
  return window._paq.push(collection);
}
function trackPageView(customPageTitle) {
  push([PAGE_VIEWS_TRACK_EVENT.PAGE_VIEW, ...(customPageTitle ? [customPageTitle] : [])]);
}
const pageViews_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  trackPageView
}, Symbol.toStringTag, {
  value: "Module"
}));
function trackEvent(category, action, name, value, dimensions) {
  const eventArguments = [category, action, name, value, dimensions];
  push([CUSTOM_EVENT_TRACK_EVENT.CUSTOM_EVENT, ...eventArguments]);
}
const customEvents_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  trackEvent
}, Symbol.toStringTag, {
  value: "Module"
}));
function trackAllContentImpressions() {
  push([CONTENT_TRACK_EVENT.ALL_CONTENT_IMPRESSIONS]);
}
function trackVisibleContentImpressions(checkOnScroll = true, watchInterval = 750) {
  push([CONTENT_TRACK_EVENT.VISIBLE_CONTENT_IMPRESSIONS, checkOnScroll, watchInterval]);
}
function trackContentImpressionsWithinNode(domNode) {
  push([CONTENT_TRACK_EVENT.CONTENT_IMPRESSIONS_WITH_NODE, domNode]);
}
function trackContentImpression(contentName, contentPiece, contentTarget) {
  push([CONTENT_TRACK_EVENT.CONTENT_IMPRESSION, contentName, contentPiece, contentTarget]);
}
function logAllContentBlocksOnPage() {
  push([CONTENT_TRACK_EVENT.LOG_ALL_CONTENT_BLOCKS_ON_PAGE]);
}
function trackContentInteractionNode(domNode, contentInteraction = "Unknown") {
  push([CONTENT_TRACK_EVENT.CONTENT_INTERACTION_NODE, domNode, contentInteraction]);
}
function trackContentInteraction(contentInteraction, contentName, contentPiece, contentTarget) {
  push([CONTENT_TRACK_EVENT.CONTENT_INTERACTION, contentInteraction, contentName, contentPiece, contentTarget]);
}
const contentTracking_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  logAllContentBlocksOnPage,
  trackAllContentImpressions,
  trackContentImpression,
  trackContentImpressionsWithinNode,
  trackContentInteraction,
  trackContentInteractionNode,
  trackVisibleContentImpressions
}, Symbol.toStringTag, {
  value: "Module"
}));
function disableCookies() {
  push([COOKIE_TRACK_EVENT.DISABLE_COOKIES]);
}
function enableCookies() {
  push([COOKIE_TRACK_EVENT.ENABLE_COOKIES]);
}
function deleteCookies() {
  push([COOKIE_TRACK_EVENT.DELETE_COOKIES]);
}
function hasCookies() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.hasCookies());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function getCookieDomain() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getCookieDomain());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function getCookiePath() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getCookiePath());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function getConfigVisitorCookieTimeout() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getConfigVisitorCookieTimeout());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function setReferralCookieTimeout(seconds) {
  push([COOKIE_TRACK_EVENT.SET_REFERRAL_COOKIE_TIMEOUT, seconds]);
}
function getSessionCookieTimeout() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getSessionCookieTimeout());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function setCookieNamePrefix(prefix) {
  push([COOKIE_TRACK_EVENT.SET_COOKIE_NAME_PREFIX, prefix]);
}
function setCookieDomain(domain) {
  push([COOKIE_TRACK_EVENT.SET_COOKIE_DOMAIN, domain]);
}
function setCookiePath(path) {
  push([COOKIE_TRACK_EVENT.SET_COOKIE_PATH, path]);
}
function setSecureCookie(secure) {
  push([COOKIE_TRACK_EVENT.SET_SECURE_COOKIE, secure]);
}
function setVisitorCookieTimeout(seconds) {
  push([COOKIE_TRACK_EVENT.SET_VISITOR_COOKIE_TIMEOUT, seconds]);
}
function setSessionCookieTimeout(seconds) {
  push([COOKIE_TRACK_EVENT.SET_SESSION_COOKIE_TIMEOUT, seconds]);
}
function setVisitorIdCookie() {
  push([COOKIE_TRACK_EVENT.SET_VISITOR_ID_COOKIE]);
}
const cookieManagement_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  deleteCookies,
  disableCookies,
  enableCookies,
  getConfigVisitorCookieTimeout,
  getCookieDomain,
  getCookiePath,
  getSessionCookieTimeout,
  hasCookies,
  setCookieDomain,
  setCookieNamePrefix,
  setCookiePath,
  setReferralCookieTimeout,
  setSecureCookie,
  setSessionCookieTimeout,
  setVisitorCookieTimeout,
  setVisitorIdCookie
}, Symbol.toStringTag, {
  value: "Module"
}));
function setCustomDimensionValue(customDimensionId, customDimensionValue) {
  push([CUSTOM_DIMENSIONS_TRACK_EVENT.SET_CUSTOM_DIMENSION_VALUE, customDimensionId, customDimensionValue]);
}
function deleteCustomDimension(customDimensionId) {
  push([CUSTOM_DIMENSIONS_TRACK_EVENT.DELETE_CUSTOM_DIMENSION, customDimensionId]);
}
function getCustomDimensionValue(customDimensionId) {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getCustomDimensionValue(customDimensionId));
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
const customDimensions_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  deleteCustomDimension,
  getCustomDimensionValue,
  setCustomDimensionValue
}, Symbol.toStringTag, {
  value: "Module"
}));
function trackLink(url, linkType, dimensions, callback) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.LINK, url, linkType, dimensions, callback]);
}
function enableLinkTracking(trackAlsoMiddleAndRightClicks = true) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.ENABLE_LINK_TRACKING, trackAlsoMiddleAndRightClicks]);
}
function setLinkClasses(classes) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.SET_LINK_CLASSES, classes]);
}
function setDownloadClasses(classes) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.SET_DOWNLOAD_CLASSES, classes]);
}
function setDownloadExtensions(extensions) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.SET_DOWNLOAD_EXTENSIONS, extensions]);
}
function addDownloadExtensions(extensions) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.ADD_DOWNLOAD_EXTENSIONS, extensions]);
}
function removeDownloadExtensions(extensions) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.REMOVE_DOWNLOAD_EXTENSIONS, extensions]);
}
function setLinkTrackingTimer(time) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.SET_LINK_TRACKING_TIMER, time]);
}
function getLinkTrackingTimer() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getLinkTrackingTimer());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function setIgnoreClasses(classes) {
  push([DOWNLOAD_AND_OUTLINK_TRACK_EVENT.SET_IGNORE_CLASSES, classes]);
}
const downloadAndOutlink_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  addDownloadExtensions,
  enableLinkTracking,
  getLinkTrackingTimer,
  removeDownloadExtensions,
  setDownloadClasses,
  setDownloadExtensions,
  setIgnoreClasses,
  setLinkClasses,
  setLinkTrackingTimer,
  trackLink
}, Symbol.toStringTag, {
  value: "Module"
}));
function addEcommerceItem(productSKU, productName, productCategory, productPrice, productQuantity) {
  push([ECOMMERCE_TRACK_EVENT.ADD_ECOMMERCE_ITEM, productSKU, productName, productCategory, productPrice, productQuantity]);
}
function ecommerceAddToCart(products) {
  push([ECOMMERCE_V2_TRACK_EVENT.ECOMMERCE_ADD_TO_CART, products]);
}
function removeEcommerceItem(productSKU) {
  push([ECOMMERCE_TRACK_EVENT.REMOVE_ECOMMERCE_ITEM, productSKU]);
}
function ecommerceRemoveFromCart(products) {
  push([ECOMMERCE_V2_TRACK_EVENT.ECOMMERCE_REMOVE_FROM_CART, products]);
}
function getEcommerceItems() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getEcommerceItems());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function trackEcommerceOrder(orderId, orderGrandTotal, orderSubTotal, orderTax, orderShipping, orderDiscount) {
  push([ECOMMERCE_TRACK_EVENT.TRACK_ECOMMERCE_ORDER, orderId, orderGrandTotal, orderSubTotal, orderTax, orderShipping, orderDiscount]);
}
function ecommerceOrder(products, paymentInformation) {
  push([ECOMMERCE_V2_TRACK_EVENT.ECOMMERCE_ORDER, products, paymentInformation]);
}
function trackEcommerceCartUpdate(cartAmount) {
  push([ECOMMERCE_TRACK_EVENT.TRACK_ECOMMERCE_CART_UPDATE, cartAmount]);
}
function ecommerceCartUpdate(products, grandTotal) {
  push([ECOMMERCE_V2_TRACK_EVENT.ECOMMERCE_CART_UPDATE, products, grandTotal]);
}
function ecommerceProductDetailView(products) {
  push([ECOMMERCE_V2_TRACK_EVENT.ECOMMERCE_PRODUCT_DETAIL_VIEW, products]);
}
function clearEcommerceCart() {
  push([ECOMMERCE_TRACK_EVENT.CLEAR_ECOMMERCE_CART]);
}
function setEcommerceView(productSKU, productName, productCategory, productPrice) {
  push([ECOMMERCE_TRACK_EVENT.SET_ECOMMERCE_VIEW, productSKU, productName, productCategory, productPrice]);
}
const eCommerce_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  addEcommerceItem,
  clearEcommerceCart,
  ecommerceAddToCart,
  ecommerceCartUpdate,
  ecommerceOrder,
  ecommerceProductDetailView,
  ecommerceRemoveFromCart,
  getEcommerceItems,
  removeEcommerceItem,
  setEcommerceView,
  trackEcommerceCartUpdate,
  trackEcommerceOrder
}, Symbol.toStringTag, {
  value: "Module"
}));
function trackGoal(goalId, conversionValue, dimensions) {
  push([GOAL_CONVERSIONS_TRACK_EVENT.GOAL, goalId, conversionValue, ...(dimensions ? [{
    ...dimensions
  }] : [])]);
}
const goalConversions_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  trackGoal
}, Symbol.toStringTag, {
  value: "Module"
}));
function trackSiteSearch(keyword, category, searchCount, dimensions) {
  push([SITE_SEARCH_TRACK_EVENT.SEARCH, keyword, category, searchCount, dimensions]);
}
const siteSearch_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  trackSiteSearch
}, Symbol.toStringTag, {
  value: "Module"
}));
function getUserId() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getUserId());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function setUserId(userId) {
  push([USER_MANAGEMENT_TRACK_EVENT.SET_USER_ID, userId]);
}
function resetUserId() {
  push([USER_MANAGEMENT_TRACK_EVENT.RESET_USER_ID]);
}
function getVisitorId() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getVisitorId());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
function getVisitorInfo() {
  return new Promise((resolve, reject) => {
    try {
      push([function () {
        resolve(this.getVisitorInfo());
      }]);
    } catch (e) {
      if (e instanceof ReferenceError) {
        reject(e);
      }
    }
  });
}
const userManagement_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  getUserId,
  getVisitorId,
  getVisitorInfo,
  resetUserId,
  setUserId
}, Symbol.toStringTag, {
  value: "Module"
}));
function enableJSErrorTracking(unique = true) {
  push([ERROR_TRACKING_TRACK_EVENT.ENABLE_JS_ERROR_TRACKING, unique]);
}
function trackError(error) {
  push([ERROR_TRACKING_TRACK_EVENT.TRACK_ERROR, error]);
}
const errorTracking_service = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
  __proto__: null,
  enableJSErrorTracking,
  trackError
}, Symbol.toStringTag, {
  value: "Module"
}));
const index = {
  initialize: init,
  getInitScript
};
export { contentTracking_service as ContentTracking, cookieManagement_service as CookieManagement, customDimensions_service as CustomDimensions, customEvents_service as CustomEvent, dataLayer_service as DataLayer, downloadAndOutlink_service as DownloadAndOutlink, errorTracking_service as ErrorTracking, goalConversions_service as GoalConversions, pageViews_service as PageViews, siteSearch_service as SiteSearch, userManagement_service as UserManagement, index as default, eCommerce_service as eCommerce };
