<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-content col-left pb-0 pt-0">
        <h2 class="card-item-title">{{flexible.title}}</h2>
        <div class="layout gap-10">
            @for (element of flexible.elements; track element.id) {
                <div class="col-tree">
                    <div class="card-item">
                        <div class="flexible-datavisualisation {{element.style}}">
                            <div class="flexible-datavisualisation-iteraction">
                                <button (click)="openDialogDataVis(element.visuel_texte)" class="flexible-datavisualisation-opener">Accéder à la version textuelle</button>
                                <a href="{{element.lien.url}}" class="flexible-datavisualisation-link">Lire l'intégralité</a>
                            </div>
                            <div class="flexible-datavisualisation-img">
                                <img src="{{element.visuel.url}}" alt="" />
                            </div>
                            <div class="flexible-datavisualisation-legend">
                                <p class="line-1" [customInnerHTML]="element.line1"></p>
                                <p class="line-2" [customInnerHTML]="element.line2"></p>
                                @if(element.style == "style-2" && element.line3){
                                    <p class="line-3" [customInnerHTML]="element.line3"></p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div class="card-item-footer" *ngIf="flexible.button.url">
            <a class="btn btn--primary" href="{{flexible.button.url}}" [rel]="flexible.button.type === 'ext'? 'external': ''" [target]="flexible.button.type === 'ext'? '_blank': ''">{{flexible.button.label}}</a>
        </div>
    </div>
</div>
