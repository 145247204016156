import {Component, Input} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BlocRedirectionComponent} from '../../components/bloc-redirection/bloc-redirection.component';
import {BoiteAOutilsComponent} from '../../components/boite-a-outils/boite-a-outils.component';
import {EssentielListComponent} from "../../components/essentiel/essentiel-list/essentiel-list.component";
import {HeaderComponent} from '../../components/header/header.component';
import {LoadingOverlayComponent} from '../../components/loading-overlay/loading-overlay.component';
import {ZoomSurComponent} from '../../components/zoom-sur/zoom-sur.component';
import {Essentiel} from '../../models/essentiels';
import {Tool} from '../../models/tool';
import {Zoom} from '../../models/zoom';
import {ApiService} from '../../services/api.service';
import {formatEssentiel, formatTimeline, formatTools, formatZoom, useCache} from '../../utils/utils.service';
import {SearchbarComponent} from "../../components/search/searchbar/searchbar.component";
import {Timeline} from "../../models/timeline";
import {NgIf} from "@angular/common";
import {TimelineComponent} from "../../components/timeline/timeline.component";

interface Tooping {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-au-quotidien',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    HeaderComponent,
    BlocRedirectionComponent,
    ZoomSurComponent,
    BoiteAOutilsComponent,
    EssentielListComponent,
    SearchbarComponent,
    TimelineComponent,
    NgIf
  ],
  templateUrl: './au-quotidien.component.html',
  styleUrl: './au-quotidien.component.scss'
})
export class AuQuotidienComponent {
  @Input() preview: boolean = false;
  public isLoading: boolean = false;
  quotidien: any = null;
  zoomsLien: Zoom[] = [];
  tools: Tool[] = [];
  essentielsLeft: Essentiel[] = [];
  essentielsRight: Essentiel[] = [];
  timeline: Timeline | null = null;

  constructor(private apiService: ApiService) {
  }

  async ngOnInit(): Promise<void> {

    useCache('au-quotidien', this, async () => {
      await this.getQuotidien();
    }, () => {
      return {
        quotidien: this.quotidien,
        zoomsLien: this.zoomsLien,
        tools: this.tools,
        essentielsLeft: this.essentielsLeft,
        essentielsRight: this.essentielsRight
      }
    });
  }

  async getQuotidien() {
    const {body, headers} = this.preview ? await this.apiService.getPreview({
      id: 204,
      postType: 'page'
    }) : await this.apiService.getQuotidien();
    environment.enableLogging && console.log(body, headers);
    // transform value
    this.zoomsLien = formatZoom(body?.acf?.bloc_zooms_lien?.['zooms-lien'], 'url');
    this.essentielsLeft = formatEssentiel(body?.acf?.contenus_modules_essentiel_gauche, 'circle');
    this.essentielsRight = formatEssentiel(body?.acf?.contenus_modules_essentiel_droite, 'circle');
    this.tools = formatTools(body?.acf?.module_boite_a_outils);
    this.quotidien = body;
    this.timeline = formatTimeline(body?.acf?.frise_temps_forts);
    environment.enableLogging && console.log('timeline', this.timeline);

    environment.enableLogging && console.log(this.quotidien);
  }
}
