import { Component, Input, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChildren, QueryList, OnChanges, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Breve } from '../../models/breve';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import {CustomInnerHtmlDirective} from "../../directives/custom-inner-html.directive";

registerSwiperElements();
const swiperBreakpoint = 1343;

@Component({
  selector: 'app-breves',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './breves.component.html',
  styleUrl: './breves.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class BrevesComponent implements OnInit, OnChanges {
  @ViewChildren('swiperContainer', { read: ElementRef }) swiperContainers!: QueryList<ElementRef>;
  isModalOpen = false;
  swiperDirection: 'horizontal' | 'vertical' = 'vertical';

  @Input() public breves: Breve[] = [];
  groupedBreves: any[][] = [];

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.updateSwiperDirection();
  }

  // Detect changes when the input 'breves' is updated
  ngOnChanges(): void {
    this.breves.forEach(breve => breve.isModalOpen = false);
    this.groupBreves();
    this.cdr.detectChanges();
    this.initSliderBreve();
  }

  ngAfterViewInit(): void {
    // init Slider after construction DOM (on ngOnInit)
    this.cdr.detectChanges();
    this.initSliderBreve();
  }

  // Group breves in pairs for vertical slider display
  groupBreves(): void {
    this.groupedBreves = [];
    for (let i = 0; i < this.breves.length; i += 2) {
      this.groupedBreves.push(this.breves.slice(i, i + 2));
    }
  }

  openModal(event: Event, breve: any) {
    event.preventDefault();
    breve.isModalOpen = true;
    // Focus on the first focusable element in the modal
    setTimeout(() => {
      const modal = document.querySelectorAll('.modale.show')[0];
      const focusableElements = modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstFocusableElement = focusableElements[0] as HTMLElement;
      firstFocusableElement.focus();
    }, 100);
  }

  closeModal(event: Event, breve: any) {
    event.preventDefault();
    breve.isModalOpen = false;
  }

  // Fonction pour fermer toutes les modales ouvertes
  closeAnyOpenModal() {
    this.groupedBreves.forEach(group => {
      group.forEach(breve => {
        if (breve.isModalOpen) {
          breve.isModalOpen = false;
        }
      });
    });
  }

  updateSwiperDirection(): void {
    this.swiperDirection = window.innerWidth < swiperBreakpoint ? 'horizontal' : 'vertical';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    // When the window is resized, check if the layout should switch between horizontal and vertical -> the value is updated in the HTML.
    this.updateSwiperDirection();
    // Apply the changes to the template:
    this.cdr.detectChanges();
    // Update the slider
    this.initSliderBreve();
  }

  // Close all modals when pressing the 'Escape' key
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeAnyOpenModal();
    }
  }

  initSliderBreve(): void {
    this.swiperContainers.forEach((swiperContainer) => {
      const swiperEl = swiperContainer.nativeElement;

      if (swiperEl.swiper) {
          swiperEl.swiper.update();
      } else {
          this.initializeSwiper(swiperEl);
      }
    });
  }

  initializeSwiper(swiperEl: any): void {
    // Swiper parameters
    const swiperParams = {
      slidesPerView: 'auto',
      direction: 'horizontal',
      navigation: true,
      cssMode: false,
      loop: false,
      speed: 500,
      spaceBetween: 10,
      simulateTouch: true,
      breakpoints: {
        1343: {
          slidesPerView: 1,
          direction: 'vertical',
        }
      },
    };
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }

}
