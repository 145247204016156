import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { DatePipe, JsonPipe, NgIf } from "@angular/common";
import { environment } from "../../../../environments/environment";
import moment from 'moment';

@Component({
  selector: 'app-dialog-document-info',
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogContent,
    DatePipe,
    NgIf,
    JsonPipe
  ],
  templateUrl: './dialog-document-info.component.html',
  styleUrl: './dialog-document-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDocumentInfoComponent {
  document!: any;
  dateFinValidite: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.document = data.document;
    this.dateFinValidite = this.document.date_de_fin_validite ? moment(this.document.date_de_fin_validite).format('DD.MM.YYYY') : '';
  }

  protected readonly environment = environment;
}
