import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BlocRedirectionComponent } from '../../components/bloc-redirection/bloc-redirection.component';
import { BoiteAOutilsComponent } from '../../components/boite-a-outils/boite-a-outils.component';
import { EssentielListComponent } from "../../components/essentiel/essentiel-list/essentiel-list.component";
import { HeaderComponent } from '../../components/header/header.component';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { ZoomSurComponent } from '../../components/zoom-sur/zoom-sur.component';
import { Essentiel } from '../../models/essentiels';
import { Tool } from '../../models/tool';
import { Zoom } from '../../models/zoom';
import { ApiService } from '../../services/api.service';
import {formatEssentiel, formatTools, formatZoom, useCache} from '../../utils/utils.service';

interface Tooping {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-au-quotidien',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    HeaderComponent,
    BlocRedirectionComponent,
    ZoomSurComponent,
    BoiteAOutilsComponent,
    EssentielListComponent
],
  templateUrl: './au-quotidien.component.html',
  styleUrl: './au-quotidien.component.scss'
})
export class AuQuotidienComponent {
  public isLoading: boolean = false;
  quotidien: any = null;
  zoomsLien: Zoom[] = [];
  tools: Tool[] = [];
  essentielsLeft: Essentiel[] = [];
  essentielsRight: Essentiel[] = [];

  toppings: Tooping[] = [
    {value: 'value-1', viewValue: 'Value 1'},
    {value: 'value-2', viewValue: 'Value 2'},
    {value: 'value-3', viewValue: 'Value 3'},
  ];

  constructor(private apiService: ApiService) {
  }

  async ngOnInit(): Promise<void> {

    useCache('au-quotidien', this, async () => {
      await this.getQuotidien();
    }, () => {
      return {
        quotidien: this.quotidien,
        zoomsLien: this.zoomsLien,
        tools: this.tools,
        essentielsLeft: this.essentielsLeft,
        essentielsRight: this.essentielsRight
      }
    });
  }

  async getQuotidien() {
    const { body, headers } = await this.apiService.getQuotidien();
    environment.enableLogging && console.log(body, headers);
    // transform value
    this.zoomsLien = formatZoom(body?.acf?.bloc_zooms_lien?.['zooms-lien'], 'url');
    this.essentielsLeft = formatEssentiel(body?.acf?.contenus_modules_essentiel_gauche, 'circle');
    this.essentielsRight = formatEssentiel(body?.acf?.contenus_modules_essentiel_droite, 'circle');
    this.tools = formatTools(body?.acf?.module_boite_a_outils);

    this.quotidien = body;

    environment.enableLogging && console.log(this.quotidien);
  }
}
