<div class="searchbar">
    <div *ngIf="isHome" class="home-search">
      <p class="date">{{date}}</p>
        <div class="profil">
            <img class="profil-picture" *ngIf="photoUrl" [src]="photoUrl" alt="Profile Photo" />
        </div>
        <h1 class="profil-onboard" [innerHTML]="homeMessage"></h1>
    </div>
    <mat-form-field class="searchbar__field">
        <input #searchInput type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto"
            (focus)="onFocus()" (keydown.enter)="onSearch()">
        <button class="searchbar__delete-search" (click)="clearSearch()">Supprimer la recherche</button>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSearch()">
            @for (option of filteredHistoryOptions$ | async; track option) {
            <mat-option class="previously-searched" [value]="option">{{ option }}</mat-option>
            }
            @for (option of filteredOptions$ | async; track option) {
            <mat-option [value]="option.value">
                <div>
                    {{ option.value }}
                </div>
                <div class="autocomplete-option-tags">
                    <span class="tag bg-blue">Document</span>
                    <span *ngIf="option.perimetre" class="tag tag-section">{{ option.perimetre }}</span>
                    <span *ngIf="option.nature" class="tag tag-section">{{ option.nature }}</span>
                </div>
            </mat-option>
            }
            <div class="searchbar__submit--container">
                <button class="searchbar__submit btn btn--primary" [disabled]="isSearchEmpty()"
                    (click)="onSearch()">Rechercher
                </button>
                <p class="submit-info">Touche "Entrée"</p>
            </div>
        </mat-autocomplete>
    </mat-form-field>
    <div class="searchbar__trends">
        <div class="searchbar__trends--list">
            <button *ngFor="let trend of trends" class="btn btn--outline-blue searchbar__trends--item hidden"
                    (click)="onTrendClick(trend.keywords)">{{ trend.titre }}</button>
        </div>
    </div>
</div>
