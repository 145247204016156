<div class="pagination" *ngIf="totalPages > 1 || isNbElementsChangeable">

    <!-- Informations sur les pages -->
    <span class="pagination--info" *ngIf="!displayPageNumber">
    Page {{ currentPage }} / {{ totalPages }}
  </span>

    <!-- Bouton précédent -->
    <a class="pagination--link previous"
       [ngClass]="{ 'disabled': currentPage <= 1 }"
       (click)="goToPage(currentPage - 1)">
        Précédent
    </a>

    <!-- Numéros de page -->
    <ng-container *ngIf="displayPageNumber">
        <ng-container *ngFor="let page of getDisplayedPages()">
            <span class="pagination--ellipsis" *ngIf="page === '...'">...</span>
            <a class="pagination--link active" *ngIf="page === currentPage">{{ page }}</a>
            <a class="pagination--link"
               *ngIf="page !== '...' && page !== currentPage"
               (click)="(Number.isInteger(Number(page))) ? goToPage(Number(page)): null">
                {{ page }}
            </a>
        </ng-container>
    </ng-container>

    <!-- Bouton suivant -->
    <a class="pagination--link next"
       [ngClass]="{ 'disabled': currentPage == totalPages }"
       (click)="goToPage(currentPage + 1)">
        Suivant
    </a>

    <!-- Options de modification du nombre d'éléments -->
    <ng-container *ngIf="isNbElementsChangeable">
        <div class="pagination--options">
            <button #optionsOpener class="pagination--link dots-options" (click)="toggleOptions()">Voir les options
            </button>
            <div #optionsMenu class="options" [class.active]="isOptionsActive">
                <ul class="options_list">
                    <li class="options_list--item" *ngFor="let limit of listLimit">
                        <a (click)="editLimit(limit)">Afficher {{ limit }} éléments</a>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>

</div>
