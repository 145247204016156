<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="col-left flexible-content pb-0 pt-0">
        <h2 class="card-item-title" [customInnerHTML]="flexible.title"></h2>
        <div class="layout gap-10" [ngClass]="{'d-none': isSliderVisible}">
            @for(profil of flexible.profils; track profil.id) {
                <div class="col-tree">
                    <div class="card-item">
                        <div class="flexible-profil">
                            <div class="flexible-profil-img">
                                <img *ngIf="profil.visuel" src="{{profil.visuel.sizes?.['w68h68']}}" height="68" width="68" alt="{{profil.visuel.alt}}" />
                            </div>
                            <div class="flexible-profil-legend">
                                <p class="line-1" [customInnerHTML]="profil.name"></p>
                                <p class="line-2" [customInnerHTML]="profil.fonction"></p>
                                <p class="line-3">
                                    <a href="tel:+33{{profil.phone}}"
                                       [customInnerHTML]="profil.phone"
                                       title="appeler le +33{{profil.phone}}">
                                    </a>
                                </p>
                                <p class="line-4">
                                    <a href="mailto:{{profil.email}}"
                                       [customInnerHTML]="profil.email"
                                       title="envoyer un mail à {{profil.email}}">
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div class="layout" [ngClass]="{'d-none': !isSliderVisible}">
            <div class="flexible-profil-slider">
                <swiper-container #swiperContainer class="swiper-container" init="false">
                    @for(profil of flexible.profils; track profil.id) {
                        <swiper-slide>
                            <div>
                                <div class="card-item">
                                    <div class="flexible-profil">
                                        <div class="flexible-profil-img">
                                            <img src="{{profil.visuel?.sizes?.['w68h68']}}" height="68" width="68" alt="{{profil.visuel?.alt}}" />
                                        </div>
                                        <div class="flexible-profil-legend">
                                            <p class="line-1" [customInnerHTML]="profil.name"></p>
                                            <p class="line-2" [customInnerHTML]="profil.fonction"></p>
                                            <p class="line-3">
                                                <a href="tel:+33{{profil.phone}}"
                                                   [customInnerHTML]="profil.phone"
                                                   title="appeler le +33{{profil.phone}}">
                                                </a>
                                            </p>
                                            <p class="line-4">
                                                <a href="mailto:{{profil.email}}"
                                                   [customInnerHTML]="profil.email"
                                                   title="envoyer un mail à {{profil.email}}">
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </swiper-slide>
                    }
                </swiper-container>
            </div>
        </div>
        <div class="card-item-footer" *ngIf="flexible.lien.url">
            <a class="btn btn--primary" href="{{flexible.lien.url}}" [customInnerHTML]="flexible.lien.label" [rel]="flexible.lien.type === 'ext'? 'external': ''" [target]="flexible.lien.type === 'ext'? '_blank': ''">Voir tous les profils</a>
        </div>
    </div>
</div>
