@if (totalPages > 1) {
  <div class="pagination">
    @if (currentPage > 1) {
      <a class="pagination--link previous" (click)="goToPage(currentPage-1)">Précédent</a>
    }
    @if (currentPage-3 >= 1) {
      <a class="pagination--link" (click)="goToPage(1)">{{1}}</a>
      <a class="pagination--link">...</a>
    }
    @if (currentPage-2 >= 1) {
      <a class="pagination--link" (click)="goToPage(currentPage-2)">{{currentPage-2}}</a>
    }
    @if (currentPage-1 >= 1) {
      <a class="pagination--link" (click)="goToPage(currentPage-1)">{{currentPage-1}}</a>
    }

    <a class="pagination--link active">{{currentPage}}</a>

    @if (currentPage+1 <= totalPages) {
      <a class="pagination--link" (click)="goToPage(currentPage+1)">{{currentPage+1}}</a>
    }
    @if (currentPage+2 <= totalPages) {
      <a class="pagination--link" (click)="goToPage(currentPage+2)">{{currentPage+2}}</a>
    }
    @if (currentPage+3 <= totalPages) {
      <a class="pagination--link">...</a>
      <a class="pagination--link" (click)="goToPage(totalPages)">{{totalPages}}</a>
    }
    @if (currentPage < totalPages) {
      <a class="pagination--link next" (click)="goToPage(currentPage+1)">Suivant</a>
    }

    @if(isNbElementsChangeable) {
      <button class="pagination--link" (click)="toggleOptions()" #optionsOpener>...</button>
      <div #optionsMenu class="options" [class.active]="isOptionsActive">
        <ul class="options_list">
          <li class="options_list--item"><a (click)="editLimit(10)">Afficher 10 éléments</a></li>
          <li class="options_list--item"><a (click)="editLimit(20)">Afficher 20 éléments</a></li>
          <li class="options_list--item"><a (click)="editLimit(30)">Afficher 30 éléments</a></li>
        </ul>
      </div>
    }
  </div>
}
