<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <div class="flexible-push-medias flexible-content">
    @for(media of flexible.medias; track media.id) {
    <div class="flexible-push-media" [ngClass]="{'video': media.typeMedia === 'video'}" (click)="openDialogMedia(media)">

      <div class="flexible-push-media--img">
        <img src="{{media?.visuel?.sizes?.['w338h226'] || media?.visuel?.url}}" width="338" height="226" alt="{{
    media.visuel.alt}}" />
      </div>
    </div>
    }
  </div>
</div>