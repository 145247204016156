<div class="essentiel_entries {{iconType}}">
    @for (entry of entries; track entry.id) {
        <div class="essentiel_entries__list-item">
            <div class="essentiel_entries__list-item-content">
                <a [href]="entry.url"
                   [rel]="entry.type === 'ext'? 'external': ''"
                   [target]="entry.type === 'ext'? '_blank': ''"
                >
                    <h3>{{ entry.title }}</h3>
                </a>
                <div [customInnerHTML]="entry.description"></div>
            </div>
        </div>
    }
</div>
