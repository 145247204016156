<div class="mes-apps">
  <div class="news-headline-hp">
    <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-apps"></span>Mes Apps</h2>
  </div>


  <p class="line-1">Mes Apps métier à mettre en favori dans le menu.</p>
  <p class="line-2">Je clique sur une app pour la mettre en favoris et la déclique pour l’enlever, c’est à vous.</p>

  <mat-form-field>
    <mat-label>Toutes les Apps</mat-label>
    <mat-select (selectionChange)="onSelectionChange($event.value)">
      <mat-option [value]="i" *ngFor="let filtre of filtres; let i = index" [innerHtml]="filtre">{{filtre}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="mes-apps-list">
    <div class="mes-apps-list__item" [attr.id]="'appItem' + i" *ngFor="let app of apps; let i = index">
      <a class="mes-apps__btn-favorite" href="#" (click)="toggleFavorite($event)">Bouton favori</a>
      <p [innerHTML]="app.title"></p>
    </div>
  </div>

  <div class="pagination">
    <a class="pagination--link previous" href="#">Précédent</a>
    <a class="pagination--link active" href="#">1</a>
    <a class="pagination--link" href="#">2</a>
    <a class="pagination--link" href="#">3</a>
    <a class="pagination--link next" href="#">Suivant</a>
    <button class="pagination--link" (click)="toggleOptions()" #optionsOpener>...</button>

    <div #optionsMenu class="options" [class.active]="isOptionsActive">
      <ul class="options_list">
        <li class="options_list--item"><a href="#">Afficher 50 éléments</a></li>
        <li class="options_list--item"><a href="#">Afficher 100 éléments</a></li>
        <li class="options_list--item"><a href="#">Tout voir</a></li>
      </ul>
    </div>
  </div>

  <div class="d-flex j-center">
    <a href="#" class="btn btn--primary">Enregistrer</a>
  </div>

</div>