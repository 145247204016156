<div class="searchbar-layout">
  <div class="col-fullwidth">
    <app-searchbar></app-searchbar>
  </div>
</div>

<div class="main-layout no-padding-top">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <div class="layout">

    <div class="col-fullwidth">
      <div class="news-headline-hp back-button">
        <a class="back-button" routerLink="/">Revenir à l'accueil</a>
        <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-news"></span>Actualités</h2>
      </div>
    </div>

    <div class="col-fullwidth mb-15">
      <app-filters-bar
        [filtres]="filtres"
        (filtresChange)="onFiltresChange($event)"
        (searchTriggerred)="onSearch()"
      >
      </app-filters-bar>
    </div>

    <div class="cols-container mb-15">
      <div class="col-half">
        <app-participation [appelsParticipations]="appelsParticipations"></app-participation>
      </div>
      <div class="col-half hide-s-mobile">
        <app-events [events]="events"></app-events>
      </div>
    </div>

    <div class="cols-tabs">
      <ul class="cols-tabs--list">
        <li class="cols-tabs--item">
          <button class="cols-tabs--selector active" data-tab="news" [ngClass]="{ 'active': activeTab === 'news' }"
                  (click)="setActiveTab('news')">Actualités
          </button>
        </li>
        <li class="cols-tabs--item">
          <button class="cols-tabs--selector" data-tab="pictures" [ngClass]="{ 'active': activeTab === 'pictures' }"
                  (click)="setActiveTab('pictures')">En images
          </button>
        </li>
        <li class="cols-tabs--item">
          <button class="cols-tabs--selector" data-tab="resume" [ngClass]="{ 'active': activeTab === 'resume' }"
                  (click)="setActiveTab('resume')">En bref
          </button>
        </li>
        <li class="cols-tabs--item">
          <button class="cols-tabs--selector" data-tab="agenda" [ngClass]="{ 'active': activeTab === 'agenda' }"
                  (click)="setActiveTab('agenda')">Agenda
          </button>
        </li>
      </ul>

      <div class="col-fullwidth mb-30 col-tab col-pictures" *ngIf="enImages?.length"
           [ngClass]="{ 'active': activeTab === 'pictures' }">
        <app-en-images [enImages]="enImages"></app-en-images>
      </div>

      <div class="cols-container cols-news">
        <div class="col-left mb-10 col-tab col-news active"
             [ngClass]="{ 'active': activeTab === 'news' }">

          <div class="news-headline">

            <h3>À la une</h3>

            <div class="news-headline__list">
              <app-actualites-a-la-une class="tools_list--item-wrapper"
                                       [actualites]="actualitesALaUne"></app-actualites-a-la-une>
            </div>
          </div>

          <div class="news-feed">
            <h3>Fil d'actu</h3>
            <div class="news-feed__list">
              <app-list-actualites class="tools_list--item-wrapper"
                                   [actualitesALaUne]="actualitesALaUne"
                                   [actualites]="actualites">
              </app-list-actualites>
            </div>
          </div>
          <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" (pageChanged)="onPageChanged($event)"
                          [isNbElementsChangeable]="true" (limitChanged)="onLimitChanged($event)"></app-pagination>
        </div>

        <div class="col-right mb-10 col-tab col-resume"
             [ngClass]="{ 'active': activeTab === 'resume' }">
          <app-breves [breves]="breves"></app-breves>
        </div>
      </div>

      <div class="col-mobile col-tab col-agenda"
           [ngClass]="{ 'active': activeTab === 'agenda' }">
        <app-events [events]="events"></app-events>
      </div>
    </div>

  </div>
</div>
