import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Flexible, isFlexibleAccordions, isFlexibleArche, isFlexibleButton, isFlexibleDataVisualisation, isFlexibleDossiers, isFlexibleFlashInfo, isFlexibleHighlightCard, isFlexibleImg, isFlexibleOnglets, isFlexibleProfilCard, isFlexiblePushMedias, isFlexiblePushMediasTitle, isFlexiblePushPage, isFlexiblePushPortraits, isFlexiblePushReportings, isFlexiblePushServices, isFlexiblePushTools, isFlexibleReporting, isFlexibleSeparateur, isFlexibleSimilarArticles, isFlexibleSliderImg, isFlexibleSpacer, isFlexibleText, isFlexibleTextImg, isFlexibleVideo } from '../../../models/flexibles';
import { FlexibleAccordionsComponent } from '../flexible-accordions/flexible-accordions.component';
import { FlexibleArcheComponent } from '../flexible-arche/flexible-arche.component';
import { FlexibleButtonComponent } from '../flexible-button/flexible-button.component';
import { FlexibleDatavisualisationComponent } from '../flexible-datavisualisation/flexible-datavisualisation.component';
import { FlexibleFlashInfoComponent } from '../flexible-flash-info/flexible-flash-info.component';
import { FlexibleFormContactComponent } from '../flexible-form-contact/flexible-form-contact.component';
import { FlexibleHighlightCardComponent } from '../flexible-highlight-card/flexible-highlight-card.component';
import { FlexibleImgComponent } from '../flexible-img/flexible-img.component';
import { FlexibleListLinksComponent } from '../flexible-list-links/flexible-list-links.component';
import { FlexibleOngletsComponent } from '../flexible-onglets/flexible-onglets.component';
import { FlexiblePushPortraitComponent } from '../flexible-portrait/flexible-push-portrait.component';
import { FlexibleProfilCardComponent } from '../flexible-profil-card/flexible-profil-card.component';
import { FlexiblePushMediasTitleComponent } from '../flexible-push-medias-title/flexible-push-medias-title.component';
import { FlexiblePushMediasComponent } from '../flexible-push-medias/flexible-push-medias.component';
import { FlexiblePushOutilsComponent } from "../flexible-push-outils/flexible-push-outils.component";
import { FlexiblePushPageComponent } from '../flexible-push-page/flexible-push-page.component';
import { FlexiblePushReportingComponent } from '../flexible-push-reporting/flexible-push-reporting.component';
import { FlexiblePushServiceComponent } from '../flexible-push-service/flexible-push-service.component';
import { FlexibleReportingComponent } from '../flexible-reporting/flexible-reporting.component';
import { FlexibleSimilarArticlesComponent } from '../flexible-similar-articles/flexible-similar-articles.component';
import { FlexibleSliderImgComponent } from '../flexible-slider-img/flexible-slider-img.component';
import { FlexibleSpacerComponent } from '../flexible-spacer/flexible-spacer.component';
import { FlexibleTextImgComponent } from '../flexible-text-img/flexible-text-img.component';
import { FlexibleTextComponent } from '../flexible-text/flexible-text.component';
import { FlexibleVideoComponent } from '../flexible-video/flexible-video.component';
import {FlexibleSeparateurComponent} from "../flexible-separateur/flexible-separateur.component";
import { FlexibleDossierComponent } from "../flexible-dossiers/flexible-dossiers.component";

@Component({
  selector: 'app-flexibles',
  standalone: true,
  imports: [
    FlexibleArcheComponent,
    FlexibleButtonComponent,
    FlexibleDatavisualisationComponent,
    FlexibleFlashInfoComponent,
    FlexibleFlashInfoComponent,
    FlexibleFormContactComponent,
    FlexibleHighlightCardComponent,
    FlexibleImgComponent,
    FlexibleListLinksComponent,
    FlexibleOngletsComponent,
    FlexibleAccordionsComponent,
    FlexiblePushPortraitComponent,
    FlexibleProfilCardComponent,
    FlexiblePushMediasComponent,
    FlexiblePushMediasTitleComponent,
    FlexiblePushPageComponent,
    FlexiblePushReportingComponent,
    FlexiblePushServiceComponent,
    FlexibleReportingComponent,
    FlexibleSimilarArticlesComponent,
    FlexibleSliderImgComponent,
    FlexibleSpacerComponent,
    FlexibleTextComponent,
    FlexibleTextImgComponent,
    FlexibleVideoComponent,
    NgIf,
    FlexiblePushOutilsComponent,
    FlexibleSeparateurComponent,
    FlexibleDossierComponent
  ],
  templateUrl: './flexibles.component.html',
  styleUrl: './flexibles.component.scss'
})
export class FlexiblesComponent {
  @Input() flexibles: Flexible[] = [];
  protected readonly isFlexibleText = isFlexibleText;
  protected readonly isFlexibleImg = isFlexibleImg;
  protected readonly isFlexibleTextImg = isFlexibleTextImg;
  protected readonly isFlexibleHighlightCard = isFlexibleHighlightCard;
  protected readonly isFlexibleFlashInfo = isFlexibleFlashInfo;
  protected readonly isFlexiblePushPage = isFlexiblePushPage;
  protected readonly isFlexibleOnglets = isFlexibleOnglets;
  protected readonly isFlexibleSliderImg = isFlexibleSliderImg;
  protected readonly isFlexibleAccordions = isFlexibleAccordions;
  protected readonly isFlexibleProfilCard = isFlexibleProfilCard;
  protected readonly isFlexiblePushPortraits = isFlexiblePushPortraits;
  protected readonly isFlexiblePushMedias = isFlexiblePushMedias;
  protected readonly isFlexiblePushMediasTitle = isFlexiblePushMediasTitle;
  protected readonly isFlexiblePushServices = isFlexiblePushServices;
  protected readonly isFlexiblePushReportings = isFlexiblePushReportings;
  protected readonly isFlexibleReporting = isFlexibleReporting;
  protected readonly isFlexibleSimilarArticles = isFlexibleSimilarArticles;
  protected readonly isFlexibleDataVisualisation = isFlexibleDataVisualisation;
  protected readonly isFlexibleArche = isFlexibleArche;
  // protected readonly isFlexibleFormContact = isFlexibleFormContact;
  protected readonly isFlexibleVideo = isFlexibleVideo;
  protected readonly isFlexibleButton = isFlexibleButton;
  protected readonly isFlexibleSeparateur = isFlexibleSeparateur;
  protected readonly isFlexibleSpacer = isFlexibleSpacer;
  protected readonly isFlexiblePushTools = isFlexiblePushTools;
  protected readonly isFlexibleDossiers = isFlexibleDossiers;
}
