import {AfterViewInit, Component, ElementRef, HostListener, inject, Input, OnInit, ViewChild} from '@angular/core';
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {TimelineEvent} from "../../models/timeline";
import {FrenchDatePipe} from "../../pipes/french-date.pipe";
import {MatDialog} from "@angular/material/dialog";
import {
  DialogTimelineEventInfoComponent
} from "../dialog/dialog-timeline-event-info/dialog-timeline-event-info.component";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [
    NgForOf,
    DatePipe,
    NgClass,
    FrenchDatePipe,
    NgIf
  ],
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss'
})
export class TimelineComponent implements OnInit, AfterViewInit{
  readonly dialog = inject(MatDialog);
  months: { index: number, month: string, year: number }[] = [];
  @Input() timelineTitle: string = '';
  @Input() timelineDescription: string = '';
  @Input() timelineEvents: TimelineEvent[] = [];
  @ViewChild('timelineWrapper') timelineWrapper!: ElementRef;

  currentTranslateX: number = 0;
  translateStep: number = 300;
  transformStyle: string = 'translateX(0)';
  leftArrowVisible: boolean = false;
  rightArrowVisible: boolean = true;

  private touchStartX: number = 0;
  private touchCurrentX: number = 0;

  constructor() {}

  ngOnInit() {
    this.generateMonths();
  }

  ngAfterViewInit() {
    const timelineWrapperElement = this.timelineWrapper.nativeElement;
    timelineWrapperElement.addEventListener('touchstart', this.onTouchStart.bind(this));
    timelineWrapperElement.addEventListener('touchmove', this.onTouchMove.bind(this));
    timelineWrapperElement.addEventListener('touchend', this.onTouchEnd.bind(this));
  }

  generateMonths() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Trouver la dernière date dans la liste des événements
    const lastEventDate = this.timelineEvents.reduce((latest, event) => {
      return event.date > latest ? event.date : latest;
    }, new Date(0)); // Initialiser à une date très ancienne

    const lastEventMonth = lastEventDate.getMonth();
    const lastEventYear = lastEventDate.getFullYear();

    // Calculer le nombre total de mois à générer
    let totalMonths =
      (lastEventYear - currentYear) * 12 + (lastEventMonth - currentMonth) + 2;

    // Forcer un minimum de 4 mois
    totalMonths = Math.max(totalMonths, 6);

    // Réinitialiser le tableau months
    this.months = [];

    for (let i = 0; i < totalMonths; i++) {
      const monthDate = new Date(currentYear, currentMonth + i);
      const monthName = monthDate.toLocaleString('default', {month: 'long'});
      const monthIndex = monthDate.getMonth();
      this.months.push({index: monthIndex, month: monthName, year: monthDate.getFullYear()});
    }
  }

  getColumnsInMonth(monthIndex: number, year: number): any[] {
    const eventsInMonth = this.timelineEvents.filter(event =>
      event.date.getMonth() === monthIndex && event.date.getFullYear() === year
    );

    const majorEvents = eventsInMonth.filter(event => event.type === 'major');
    const minorEvents = eventsInMonth.filter(event => event.type === 'minor');
    const columns: any[] = [];

    // Add major events to columns
    majorEvents.forEach(event => {
      columns.push([event]);
    });

    // Add minor events to columns, grouped by 3
    for (let i = 0; i < minorEvents.length; i += 3) {
      columns.push(minorEvents.slice(i, i + 3));
    }

    // Limit to a maximum of 2 columns
    return columns.slice(0, 2);
  }

  getUniqueDatesInMonth(monthIndex: number, year: number): Date[] {
    const dates = this.timelineEvents
      .filter(event => event.date.getMonth() === monthIndex && event.date.getFullYear() === year)
      .map(event => event.date);
    const uniqueDates = Array.from(new Set(dates.map(date => date.toDateString()))).map(dateStr => new Date(dateStr));
    return uniqueDates.sort((a, b) => a.getTime() - b.getTime());
  }

  getTimelineEventsOnDate(date: Date): TimelineEvent[] {
    return this.timelineEvents.filter(event => event.date.toDateString() === date.toDateString());
  }

  prev() {
    this.currentTranslateX = Math.min(this.currentTranslateX + this.translateStep, 0);
    this.updateTransformStyle();
  }

  next() {
    const wrapperElement = document.querySelector('.timeline__wrapper')!;
    const monthsElement = document.querySelector('.timeline__months')!;
    const maxTranslateX = wrapperElement.clientWidth - monthsElement.clientWidth;
    this.currentTranslateX = Math.max(this.currentTranslateX - this.translateStep, maxTranslateX);
    this.updateTransformStyle();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const wrapperElement = document.querySelector('.timeline__wrapper')!;
    const monthsElement = document.querySelector('.timeline__months')!;
    const maxTranslateX = wrapperElement.clientWidth - monthsElement.clientWidth;

    if (this.currentTranslateX < maxTranslateX) {
      this.currentTranslateX = maxTranslateX;
      this.updateTransformStyle();
    }
  }

  updateTransformStyle() {
    this.transformStyle = `translateX(${this.currentTranslateX}px)`;
    this.updateArrowsVisibility();
  }

  updateArrowsVisibility() {
    // Left arrow
    this.leftArrowVisible = (this.currentTranslateX !== 0);

    // Right arrow
    const wrapperElement = document.querySelector('.timeline__wrapper')!;
    const monthsElement = document.querySelector('.timeline__months')!;
    const maxTranslateX = wrapperElement.clientWidth - monthsElement.clientWidth;
    this.rightArrowVisible = (this.currentTranslateX !== maxTranslateX);
  }


  openDialogTimelineEventInfo(timelineEvent: TimelineEvent) {
    this.dialog.open(DialogTimelineEventInfoComponent, {
      width: '100%',
      maxWidth: '740px',
      data: {timelineEvent},
    });
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
  }

  onTouchMove(event: TouchEvent) {
    this.touchCurrentX = event.touches[0].clientX;
  }

  onTouchEnd() {
    const swipeDistance = this.touchCurrentX - this.touchStartX;
    if (swipeDistance > 50) {
      this.prev();
    } else if (swipeDistance < -50) {
      this.next();
    }
  }

}
