<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <ul class="flexible_list_links flexible-content">

        @for(link of flexible.links; track link.id) {
            <li class="flexible_list_links_item">
                <a href="{{link.url}}" class="flexible_list_links_link" rel="{{link.type == 'ext'? 'external' : ''}}">
                    <span class="flexible_list_links_text1" [customInnerHTML]="link.label"></span>
                    <span class="flexible_list_links_text2" [customInnerHTML]="link.category"></span>
                </a>
            </li>
        }

        <!--
    <li class="flexible_list_links_item">
        <a href="" class="flexible_list_links_link" rel="external">
            <span class="flexible_list_links_text1">2311PA003 - Prestation de traiteur (4 lots)</span>
            <span class="flexible_list_links_text2">Bibliothèque de documents</span>
        </a>
    </li>
    <li class="flexible_list_links_item">
        <a href="" class="flexible_list_links_link">
            <span class="flexible_list_links_text1">2311PA003 - Prestation de traiteur (4 lots)</span>
            <span class="flexible_list_links_text2">Bibliothèque de documents</span>
        </a>
    </li>
    <li class="flexible_list_links_item">
        <a href="" class="flexible_list_links_link" rel="external">
            <span class="flexible_list_links_text1">2311PA003 - Prestation de traiteur (4 lots)</span>
            <span class="flexible_list_links_text2">Bibliothèque de documents</span>
        </a>
    </li>
    <li class="flexible_list_links_item">
        <a href="" class="flexible_list_links_link">
            <span class="flexible_list_links_text1">2311PA003 - Prestation de traiteur (4 lots)</span>
            <span class="flexible_list_links_text2">Bibliothèque de documents</span>
        </a>
    </li>
    -->
    </ul>
</div>