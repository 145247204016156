import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { FlexibleImg } from '../../../models/flexibles';

@Component({
  selector: 'app-flexible-img',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flexible-img.component.html',
  styleUrl: './flexible-img.component.scss'
})
export class FlexibleImgComponent {
  @Input() public flexible!: FlexibleImg;
}
