import {NgClass, NgFor, NgIf} from '@angular/common';
import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    NgIf
  ],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() limitChanged = new EventEmitter<number>();
  @Input() isNbElementsChangeable: boolean = false;
  @Input() listLimit: number[] = [10, 20, 30];
  @Input() displayPageNumber: boolean = true;
  isOptionsActive: boolean = false;

  @ViewChild('optionsOpener', {static: false}) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu', {static: false}) optionsMenu!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  goToPage(p: number): void {
    if (this.displayPageNumber) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {p: p},
        queryParamsHandling: 'merge'
      });
    }
    this.pageChanged.emit(p);
  }

  editLimit(l: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {limit: l},
      queryParamsHandling: 'merge'
    });
    this.limitChanged.emit(l);
    this.isOptionsActive = !this.isOptionsActive;
  }

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener && this.optionsMenu) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      const clickedInsideMenu = this.optionsMenu.nativeElement.contains(targetElement);

      if (!clickedInsideOpener && !clickedInsideMenu) {
        this.isOptionsActive = false;
      }
    }
  }

  /**
   * Calculate the pages to display based on the current page, total pages, and max pages to show.
   * @returns (number | string)[]
   */
  getDisplayedPages(): (number | string)[] {
    const pages: (number | string)[] = [];
    const maxPagesToShow = 5; // Adjust the max number of pages to display
    const half = Math.floor(maxPagesToShow / 2);

    if (this.totalPages <= maxPagesToShow) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else if (this.currentPage <= half) {
      for (let i = 1; i < maxPagesToShow; i++) {
        pages.push(i);
      }
      pages.push('...');
      pages.push(this.totalPages);
    } else if (this.currentPage > this.totalPages - half) {
      pages.push(1);
      pages.push('...');
      for (let i = this.totalPages - maxPagesToShow + 2; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      pages.push('...');
      for (let i = this.currentPage - half; i <= this.currentPage + half; i++) {
        pages.push(i);
      }
      pages.push('...');
      pages.push(this.totalPages);
    }

    return pages;
  }

  protected readonly Number = Number;
}
