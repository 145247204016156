<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-img flexible-content">
        <div class="col-left cp-30 pt-0 pb-0">
            @if (flexible.legend) {
                <figure>
                    <picture>
                        <source srcset="{{flexible.visuel.sizes?.['w885h397']}}" width="885" height="397" media="(min-width: 992px)" />
                        <source srcset="{{flexible.visuel.sizes?.['w740h332']}}" width="740" height="332" media="(min-width: 768px)" />
                        <source srcset="{{flexible.visuel.sizes?.['w360h190']}}" width="360" height="190" media="(max-width: 420px)" />
                        <img src="{{flexible.visuel.sizes?.['w885h397']}}" width="885" height="397" 
                             [alt]="flexible.visuel.alt || 'Highlight article image'" />
                    </picture>
                    <figcaption *ngIf="flexible.legend">
                        {{flexible.legend}}
                    </figcaption>
                </figure>
            } @else {
                <picture>
                    <source srcset="{{flexible.visuel.sizes?.['w885h397']}}" width="885" height="397" media="(min-width: 992px)" />
                    <source srcset="{{flexible.visuel.sizes?.['w740h332']}}" width="740" height="332" media="(min-width: 768px)" />
                    <source srcset="{{flexible.visuel.sizes?.['w360h190']}}" width="360" height="190" media="(max-width: 420px)" />
                    <img src="{{flexible.visuel.sizes?.['w885h397']}}" width="885" height="397" 
                         [alt]="flexible.visuel.alt || 'Highlight article image'" />
                </picture>
            }
        </div>
    </div>
</div>
<!-- <div class="flexible-img">
    <figure>
        <picture>
            <source srcset="assets/images/flexible-content/flexible-img/content-img-desktop.jpg" width="740"
                height="332" media="(min-width: 391px)" />
            <source srcset="assets/images/flexible-content/flexible-img/content-img-mobile.jpg" width="390" height="175"
                media="(max-width: 390px)" />
            <img src="assets/images/flex-content/flexible-img/content-img-mobile.jpg" width="740" height="332"
                alt="Highlight article image" />
        </picture>
        <figcaption>
            <p>Légende de l’image - <time datetime="2019-02-01">01.02.2019</time></p>
            <p>Et ceci est la description de la légende. Nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
                erat volutpat. Ut wisi enim.</p>
        </figcaption>
    </figure>
</div>
<div class="flexible-img">
    <picture>
        <source srcset="assets/images/flexible-content/flexible-img/content-img-desktop.jpg" width="740" height="332"
            media="(min-width: 391px)" />
        <source srcset="assets/images/flexible-content/flexible-img/content-img-mobile.jpg" width="390" height="175"
            media="(max-width: 390px)" />
        <img src="assets/images/flex-content/flexible-img/content-img-mobile.jpg" width="740" height="332"
            alt="Highlight article image" />
    </picture>
</div> -->