<div class="searchbar-layout">
    <div class="col-fullwidth">
        <app-searchbar *ngIf="!preview"></app-searchbar>
    </div>
</div>

<div class="main-layout no-padding-top">
    <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
    <app-header *ngIf="!preview" class="layout" [header_type]="0" type="diamond" sup="Quel cap ?" inf="Au quotidien" sup_link="/quel-cap"
                inf_link="/au-quotidien" title="Qui sommes-nous ?"/>

    <app-zoom-sur class="layout" [zoomsLien]="zoomsLien" [zoomsVideo]="zoomsVideo"/>

    <!-- module essentiels -->
    <app-essentiel-list class="layout" [essentiels]="essentiels"></app-essentiel-list>

    <!-- boîte à outils -->
    <app-boite-a-outils class="layout" [tools]="tools"/>

    <!-- blocs redirections -->
    <app-bloc-redirection *ngIf="!preview" class="layout" [cap]="true" [quotidien]="true"/>
</div>