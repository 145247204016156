import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FlexiblePushMediasTitle } from '../../../models/flexibles';
import { FlexibleVideoComponent } from "../flexible-video/flexible-video.component";
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { Visuel } from '../../../models/visuel';
import { DialogTextImgVideoComponent } from '../../dialog/dialog-text-img-video/dialog-text-img-video.component';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-push-medias-title',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, FlexibleVideoComponent, CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-push-medias-title.component.html',
  styleUrl: './flexible-push-medias-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class FlexiblePushMediasTitleComponent {
  @Input() flexible!: FlexiblePushMediasTitle;
  readonly dialog = inject(MatDialog);

  openDialogMedia(visuel: Visuel) {
    const dialogRef = this.dialog.open(DialogTextImgVideoComponent, {
      width: '1100px',
      height: '700px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { visuel: visuel }
    });
  }
}

