import { Location, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { CardOutilComponent } from '../../components/card-outil/card-outil.component';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { SliderOutilsComponent } from "../../components/slider-outils/slider-outils.component";
import { Link } from '../../models/link';
import { Tool } from '../../models/tool';
import { ApiService } from '../../services/api.service';
import { UrlService } from '../../shared/url.service';
import { copyUrlToClipboard, formatTools, useCache } from '../../utils/utils.service';
import { ShareComponent } from "../../components/share/share.component";
import { ShareOption } from '../../models/share-option';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DialogSendEmailComponent } from '../../components/dialog/dialog-send-email/dialog-send-email.component';

@Component({
  selector: 'app-boite-a-outils',
  standalone: true,
  imports: [
    CardOutilComponent,
    SliderOutilsComponent,
    BreadcrumbComponent,
    RouterModule,
    PaginationComponent,
    LoadingOverlayComponent,
    ShareComponent
  ],
  templateUrl: './boite-a-outils.component.html',
  styleUrl: './boite-a-outils.component.scss'
})
export class BoiteAOutilsComponent {
  public isLoading: boolean = false;

  shareOptions: ShareOption[] = [];
  forwardTools: Tool[] = [];
  pushTools: Tool[] = [];
  breadcrumbLinks: Link[] = [{ url: '/', label: 'Accueil' }];
  currentPage = 1;
  limit = 6;
  totalPages = 0;

  constructor(
    private apiService: ApiService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private urlService: UrlService,
    private dialog: MatDialog
  ) {
    this.shareOptions = [
      {
        label: 'Envoyer par mail',
        onclick: () => this.openDialogShareByEmail()
      },
      {
        label: 'Copier le lien',
        onclick: () => copyUrlToClipboard()
      }
    ];
  }

  async ngOnInit(): Promise<void> {
    // get previous url
    const previousUrl = this.urlService.getPreviousUrl();
    if (previousUrl) {
      // get previous page title
      const previousPageTitle = this.getTitleByUrl(previousUrl);
      // breadcrumb
      this.breadcrumbLinks.push({ url: previousUrl, label: previousPageTitle as string });
    }

    // Récupérer les paramètres GET de la route actuelle
    this.route.queryParams.subscribe(params => {
      this.currentPage = !isNaN(params['p']) ? +params['p'] : 1; // récupérer le numéro de la page
    });

    // load data
    useCache('boite-a-outils', this, async () => {
      await Promise.all([this.getTools(), this.getPushTools(this.currentPage)]);
    }, () => {
      return {
        forwardTools: this.forwardTools,
        pushTools: this.pushTools
      }
    });
  }

  async getTools() {
    const { body, headers } = await this.apiService.getPage({ slug: 'boite-a-outils' });
    environment.enableLogging && console.log(body, headers);
    if (body && body?.length > 0) {
      // transform value
      this.forwardTools = formatTools(body[0]?.acf?.outils_en_avant);

      environment.enableLogging && console.log(this.forwardTools);
    } else {
      // TODO : si la page n'existe pas, rediriger vers la page 404
    }
  }

  async getPushTools(page: number = 1) {
    this.isLoading = true;
    const { body, headers } = await this.apiService.getPushOutils(page, this.limit);
    environment.enableLogging && console.log(body, headers);

    // transform value
    this.pushTools = formatTools(body?.push_outils);
    this.totalPages = Math.ceil(body.total_items / this.limit);

    this.isLoading = false;

    environment.enableLogging && console.log(this.pushTools);
  }

  back(): void {
    this.location.back();
  }

  // Fonction pour obtenir le titre d'une route
  getTitleByUrl(url: string) {
    const matchingRoute = this.router.config.find(route => route.path === url.replace('/', ''));
    return matchingRoute?.title;
  }

  onPageChanged(page: number) {
    this.getPushTools(page);
  }

  openDialogShareByEmail(): void {
    const dialogRef = this.dialog.open(DialogSendEmailComponent, {
      width: '100%',
      maxWidth: '740px',
      data: { slug: 'boite-a-outils' },
    });
  }
}
