<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-highlight-card flexible-content {{flexible.full_width? 'full-width' : ''}}">
        <div class="pt-0 pb-0">
            <div class="flexible-highlight-card-inner">
                <!-- <h2 class="flexible-highlight-card-title">{{flexible.content}}</h2> -->
                <div class="flexible-highlight-card-content" [customInnerHTML]="flexible.content"></div>
                <a *ngIf="flexible.lien.url" href="{{flexible.lien.url}}" rel="{{flexible.lien.type == 'ext'? 'external' : ''}}"  target="{{flexible.lien.type == 'ext'? '_blank' : ''}}" class="btn btn--primary">En savoir plus</a>
            </div>
        </div>
    </div>
</div>
