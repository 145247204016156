import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {FlexibleVideoComponent} from "../../components/flexible/flexible-video/flexible-video.component";
import {SearchbarComponent} from "../../components/search/searchbar/searchbar.component";
import {SearchResultsComponent} from "../../components/search/search-results/search-results.component";
import {FiltersBarComponent} from "../../components/search/filters-bar/filters-bar.component";
import {SearchResult} from "../../models/search-result";
import {ElasticsearchService} from "../../services/elasticsearch.service";
import {SearchPaginationComponent} from "../../components/search/search-pagination/search-pagination.component";
import {SearchFilter, SearchSubFilter} from "../../models/search-filters";

@Component({
  selector: 'app-recherche',
  standalone: true,
  imports: [CommonModule, SearchbarComponent, SearchResultsComponent, FiltersBarComponent, SearchPaginationComponent],
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.scss']
})
export class RechercheComponent {
  readonly dialog = inject(MatDialog);
  currentPage: number = 1;
  perPage: number = 5;
  searchQuery: string = '';
  results: any[] = [];
  totalResults: number = 0;

  filtres: {
    perimetres: SearchFilter[] | null,
    themes: SearchFilter[] | null,
    natures: SearchFilter[] | null,
    startDate: Date | null,
    endDate: Date | null
  } = {themes: null, perimetres: [], natures: [], startDate: null, endDate: null};

  openDialogMedia() {
    this.dialog.open(DialogContentExampleDialog6, {
      width: '350px',
    });
  }

  constructor(private elasticSearchService: ElasticsearchService) {
  }

  ngOnInit(): void {

    // Récupère la requête de recherche depuis l'URL si elle existe
    this.checkQueryUrl();
    if (this.searchQuery) {
      this.search();
    }

    // Souscrire aux changements des résultats de recherche
    this.elasticSearchService.searchResults$.subscribe((searchResult: SearchResult) => {

      // Mets à jour les résultats de recherche
      this.results = searchResult.hits;
      this.totalResults = searchResult.total;

      // Mets à jour les filtres de nature seulement si aucun filtre n'est appliqué
      if (!searchResult.hasFilters && searchResult.facets?.natureDistribution) {
        this.filtres.natures = searchResult.facets?.natureDistribution
          .filter((bucket: any) => bucket.key !== '')
          .map((bucket: any) => {
            return {
              name: bucket.key,
              value: bucket.doc_count,
              checked: false
            }
          });
      }

      // Mets à jour les filtres de périmètre seulement si aucun filtre n'est appliqué
      if (!searchResult.hasFilters && searchResult.facets?.perimetreDistribution) {
        this.filtres.perimetres = searchResult.facets?.perimetreDistribution
          .filter((bucket: any) => bucket.key !== '')
          .map((bucket: any) => {
            return {
              name: bucket.key,
              value: bucket.doc_count,
              checked: false,
              sous_filtres: []
            }
          });
      }
    });
  }

  isItemOptionsActive: boolean[] = [];
  isPaginationOptionsActive = false;


  togglePaginationOptions(): void {
    this.isPaginationOptionsActive = !this.isPaginationOptionsActive;
    if (this.isPaginationOptionsActive) {
      this.isItemOptionsActive.fill(false);
    }
  }

  toggleOptions(index: number): void {
    // Si l'option cliquée est déjà active, la désactiver uniquement
    if (this.isItemOptionsActive[index]) {
      this.isItemOptionsActive[index] = false;
    } else {
      // Désactiver toutes les autres options
      this.isItemOptionsActive.fill(false);
      // Activer l'option sélectionnée
      this.isItemOptionsActive[index] = true;
    }
    // Désactiver les options de pagination
    this.isPaginationOptionsActive = false;
  }

  uncheckedAllFilters() {
    this.filtres.themes?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.natures?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.perimetres?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.perimetres?.forEach((filter: SearchFilter) => filter.sub_filters?.forEach((subFilter: SearchSubFilter) => subFilter.checked = false));
  }

  checkQueryUrl(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('q');
    if (searchQuery) {
      this.searchQuery = decodeURIComponent(searchQuery) ?? '';
    }
  }

  onPaginationChange(event: any): void {
    // Mettre à jour les infos de pagination
    if (this.perPage !== event.perPage) {
      this.perPage = event.perPage;
      this.currentPage = 1;
    } else {
      this.currentPage = event.page;
    }
    // Lancer une nouvelle recherche
    this.search();
  }

  onSearchTrigerred(query: string): void {
    this.uncheckedAllFilters();
    this.searchQuery = query;
    this.search();
  }

  search(): void {
    this.elasticSearchService.saveNewHistorySearch(this.searchQuery).subscribe();
    this.elasticSearchService.queryDocuments(this.searchQuery, this.perPage, this.currentPage, this.filtres).subscribe();
  }


}

@Component({
  selector: 'dialog-content-example-dialog6',
  templateUrl: '../../components/content-exemple/dialog-content-exemple-dialog6.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, FlexibleVideoComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentExampleDialog6 {
}


