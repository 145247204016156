import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { PageFilleComponent } from '../../components/page-fille/page-fille.component';
import { PageParenteComponent } from '../../components/page-parente/page-parente.component';
import { PageParente } from '../../models/page-parente';
import { ApiService } from '../../services/api.service';
import { formatFlexibles, formatPageFille, formatZoom, sanitizeTitle, sendToast, useCache } from '../../utils/utils.service';
import { Title } from "@angular/platform-browser";
import { SearchbarComponent } from "../../components/search/searchbar/searchbar.component";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: 'app-wp-page',
  standalone: true,
  imports: [LoadingOverlayComponent, PageParenteComponent, PageFilleComponent, SearchbarComponent, NgIf, NgClass],
  templateUrl: './wp-page.component.html',
  styleUrl: './wp-page.component.scss'
})
export class WpPageComponent implements OnInit, OnChanges {
  @Input() preview: boolean = false
  @Input() postId: number | null = null;
  public isLoading: boolean = false;
  fullPath: string = '';
  pageType: 'parent' | 'fille' = 'parent';
  page: any;


  constructor(private router: Router, private apiService: ApiService, private titleService: Title) {
  }

  async ngOnInit(): Promise<void> {
    this.fullPath = this.router.url;
    useCache(
      this.router.url,
      this,
      async () => {
        // Set the title of the page if page is in cache
        this.page?.title && this.titleService.setTitle(sanitizeTitle(this.page.title));
        await this.getPage();
      },
      () => {
        return { page: this.page }
      }
    );
  }

  async getPage(): Promise<void> {
    try {
      const { body } = this.preview && this.postId ?
        await this.apiService.getPreview({ id: this.postId, postType: 'page' })
        : await this.apiService.getPage({ full_path: this.fullPath });

      environment.enableLogging && console.log(`Returned API body for path: ${this.fullPath}`, body);

      // Si c'est en mode preview on a un seul objet page dans le body car on passe par l'id
      // Sinon on a un tableau de pages lorsqu'on passe par le slug
      if (
        (!this.preview && body && body?.length > 0) // Vérification du contenu du tableau si on est pas en mode preview
        || (this.preview && body?.id) // Vérification de l'objet si on est en mode preview
      ) {

        // Get the content, depending on the mode
        const page = this.preview ? body : body[0];

        // Check if the page is a parent or a child
        switch (page?.type_page) {
          case 'page_parente':
            this.pageType = 'parent';
            break;
          case 'page_fille':
            this.pageType = 'fille';
            break;
          default:
            this.pageType = 'parent';
            break;
        }

        if (this.pageType === 'parent')
          this.page = this.formatPageParente(page);
        else
          this.page = formatPageFille(page, this.fullPath);

        // Set the title of the page after api call
        this.page.title && this.titleService.setTitle(sanitizeTitle(this.page.title));

        environment.enableLogging && console.log(`Formated page`, this.page);
      } else {
        // Dont forget to set this.page to undefine if the page is not found
        // If not the loaded cache page will be saved again
        // The saveDataCache function will check if the data is undefined and will not save it
        this.page = undefined;
        localStorage.removeItem(this.router.url);
        await this.router.navigate(['/page-non-trouvee']);
      }
    } catch (e) {
      console.error('Error in getPage : ', e);
      sendToast({type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.'});
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['page']) {
      this.titleService.setTitle(sanitizeTitle(this.page.title));
    }
  }

  formatPageParente(data: any): PageParente | null {
    try {
      const pageParente: PageParente = {
        id: data.id,
        path: this.fullPath,
        slug: data.slug,
        title: data.title?.rendered,
        chapo: data.acf?.chapo,
        zoomLien: formatZoom(data.acf?.bloc_zooms_lien?.['zooms-lien'], 'url'),
        fOnglets: {
          type: 'onglets',
          onglets: data.acf.onglets?.map((onglet: any, index: number) => {
            return {
              id: index,
              title: onglet?.titre,
              flexibles: onglet.nationaux?.contenus_flexibles && formatFlexibles(onglet.nationaux?.contenus_flexibles),
            }
          }),
        },
        flexiblesTerritoriaux: data.acf?.territoriaux && Object.entries(data.acf?.territoriaux).map(([key, value]: [string, any], index: number) => {
          return {
            id: index,
            title: value.label,
            code: value.code,
            flexibles: value.contenus_flexibles && formatFlexibles(value.contenus_flexibles),
          }
        }),
        parentPages: data.parent_post?.map((parent: any) => ({
          id: parent?.id,
          title: parent?.titre,
          url: parent?.url,
        }))
      };
      return pageParente;
    } catch (e) {
      console.error('Error in formatPageParente : ', e);
      sendToast({type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.'});
    }
    return null;
  }
}

