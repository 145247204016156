import * as i0 from '@angular/core';
import { InjectionToken, APP_INITIALIZER, PLATFORM_ID, isDevMode, Injectable, APP_BOOTSTRAP_LISTENER, inject, Inject, NgModule } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, CommonModule } from '@angular/common';
import PiwikPro, { PageViews, ContentTracking, CookieManagement, CustomDimensions, SiteSearch, CustomEvent, DownloadAndOutlink, eCommerce, GoalConversions, UserManagement, DataLayer } from '@piwikpro/tracking-base-library';
import * as i2 from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { filter, skipWhile } from 'rxjs/operators';
import * as i1 from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
var TRACK_EVENT;
(function (TRACK_EVENT) {
  TRACK_EVENT["ADD_ECOMMERCE_ITEM"] = "addEcommerceItem";
  TRACK_EVENT["REMOVE_ECOMMERCE_ITEM"] = "removeEcommerceItem";
  TRACK_EVENT["CLEAR_ECOMMERCE_CART"] = "clearEcommerceCart";
  TRACK_EVENT["SET_ECOMMERCE_VIEW"] = "setEcommerceView";
  TRACK_EVENT["UPDATE_ECOMMERCE_CART"] = "trackEcommerceCartUpdate";
  TRACK_EVENT["ORDER_ECOMMERCE"] = "trackEcommerceOrder";
})(TRACK_EVENT || (TRACK_EVENT = {}));
var CustomVariableScope;
(function (CustomVariableScope) {
  CustomVariableScope["VISIT"] = "visit";
  CustomVariableScope["PAGE"] = "page";
})(CustomVariableScope || (CustomVariableScope = {}));

/**
 * Provide a Injection Token to global settings.
 */
const NGX_PIWIK_PRO_SETTINGS_TOKEN = new InjectionToken('ngx-piwik-pro-settings', {
  factory: () => ({
    containerId: '',
    containerURL: ''
  })
});
const NGX_PIWIK_PRO_INITIALIZER_PROVIDER = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: PiwikProInitializer,
  deps: [NGX_PIWIK_PRO_SETTINGS_TOKEN, DOCUMENT, PLATFORM_ID]
};
function PiwikProInitializer(settings, document, platformId) {
  if (window) {
    window._paq = window._paq || [];
  }
  return async () => {
    if (!isPlatformBrowser(platformId)) {
      return;
    }
    if (!settings.containerId) {
      if (!isDevMode()) {
        console.error('Empty tracking code for Piwik Pro. Make sure to provide one when initializing NgxPiwikProModule.');
      }
      return;
    }
    if (!settings.containerURL) {
      if (!isDevMode()) {
        console.error('Empty tracking URL for Piwik Pro. Make sure to provide one when initializing NgxPiwikProModule.');
      }
      return;
    }
    if (!document) {
      if (!isDevMode()) {
        console.error('Was not possible to access Document interface. Make sure this module is running on a Browser w/ access do Document interface.');
      }
    }
    const s = document.createElement('script');
    s.async = true;
    if (settings.nonce) {
      s.setAttribute("nonce", settings.nonce);
    }
    s.text = PiwikPro.getInitScript({
      containerId: settings.containerId,
      containerUrl: settings.containerURL,
      nonceValue: settings.nonce
    });
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(s);
  };
}
const NGX_PIWIK_PRO_ROUTING_SETTINGS_TOKEN = new InjectionToken('ngx-piwik-pro-routing-settings', {
  factory: () => ({})
});
class PageViewsService {
  trackPageView(...params) {
    PageViews.trackPageView(...params);
  }
  static {
    this.ɵfac = function PageViewsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PageViewsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PageViewsService,
      factory: PageViewsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PageViewsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const NGX_PIWIK_PRO_ROUTER_INITIALIZER_PROVIDER = {
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: PiwikProRouterInitializer,
  deps: [NGX_PIWIK_PRO_ROUTING_SETTINGS_TOKEN, Title, PageViewsService]
};
function PiwikProRouterInitializer(settings, titleService, pageViewsService) {
  return async c => {
    const router = c.injector.get(Router);
    const {
      include = [],
      exclude = [],
      skipFirstPageView
    } = settings ?? {};
    const includeRules = normalizePathRules(include);
    const excludeRules = normalizePathRules(exclude);
    const subs = router.events.pipe(filter(event => event instanceof NavigationEnd), skipWhile((_, index) => {
      // Preventing double views on the first trigger (because PiwikPro Already send one ping on setup by default)
      if (skipFirstPageView) {
        return index === 0;
      }
      return false;
    }), filter(event => includeRules.length > 0 ? includeRules.some(rule => rule.test(event.urlAfterRedirects)) : true), filter(event => excludeRules.length > 0 ? !excludeRules.some(rule => rule.test(event.urlAfterRedirects)) : true)).subscribe(() => {
      pageViewsService.trackPageView();
    });
    // Cleanup
    c.onDestroy(() => subs.unsubscribe());
  };
}
/** Converts all path rules from string to Regex instances */
function normalizePathRules(rules) {
  return rules.map(rule => rule instanceof RegExp ? rule : new RegExp(`^${rule.replace('*', '.*')}$`, 'i'));
}
const NGX_WINDOW = new InjectionToken("ngx-window", {
  providedIn: "root",
  factory: () => {
    const {
      defaultView
    } = inject(DOCUMENT);
    if (!defaultView) {
      throw new Error("Window is not available");
    }
    return defaultView;
  }
});
class PaqService {
  constructor(titleService, routerService, _window) {
    this.titleService = titleService;
    this.routerService = routerService;
    this._window = _window;
  }
  push(collection) {
    if (!this._window._paq) {
      throw new Error("_paq is not available");
    }
    this._window._paq.push(["setCustomUrl", this.routerService.url]);
    this._window._paq.push(["setDocumentTitle", this.titleService.getTitle()]);
    return this._window._paq.push(collection);
  }
  static {
    this.ɵfac = function PaqService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PaqService)(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i2.Router), i0.ɵɵinject(NGX_WINDOW));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PaqService,
      factory: PaqService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaqService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Title
  }, {
    type: i2.Router
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_WINDOW]
    }]
  }], null);
})();
class ContentTrackingService {
  trackAllContentImpressions(...params) {
    ContentTracking.trackAllContentImpressions(...params);
  }
  trackVisibleContentImpressions(...params) {
    ContentTracking.trackVisibleContentImpressions(...params);
  }
  trackContentImpressionsWithinNode(...params) {
    ContentTracking.trackContentImpressionsWithinNode(...params);
  }
  trackContentImpression(...params) {
    ContentTracking.trackContentImpression(...params);
  }
  logAllContentBlocksOnPage(...params) {
    ContentTracking.logAllContentBlocksOnPage(...params);
  }
  trackContentInteractionNode(...params) {
    ContentTracking.trackContentInteractionNode(...params);
  }
  trackContentInteraction(...params) {
    ContentTracking.trackContentInteraction(...params);
  }
  static {
    this.ɵfac = function ContentTrackingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ContentTrackingService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ContentTrackingService,
      factory: ContentTrackingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentTrackingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CookieManagementService {
  disableCookies(...params) {
    CookieManagement.disableCookies(...params);
  }
  enableCookies(...params) {
    CookieManagement.enableCookies(...params);
  }
  deleteCookies(...params) {
    CookieManagement.deleteCookies(...params);
  }
  hasCookies(...params) {
    return CookieManagement.hasCookies(...params);
  }
  setCookieNamePrefix(...params) {
    CookieManagement.setCookieNamePrefix(...params);
  }
  setCookieDomain(...params) {
    CookieManagement.setCookieDomain(...params);
  }
  setCookiePath(...params) {
    CookieManagement.setCookiePath(...params);
  }
  setSecureCookie(...params) {
    CookieManagement.setSecureCookie(...params);
  }
  setVisitorCookieTimeout(...params) {
    CookieManagement.setVisitorCookieTimeout(...params);
  }
  setSessionCookieTimeout(...params) {
    CookieManagement.setSessionCookieTimeout(...params);
  }
  setVisitorIdCookie(...params) {
    CookieManagement.setVisitorIdCookie(...params);
  }
  static {
    this.ɵfac = function CookieManagementService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CookieManagementService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CookieManagementService,
      factory: CookieManagementService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CookieManagementService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CustomDimensionsService {
  getCustomDimensionValue(...params) {
    return CustomDimensions.getCustomDimensionValue(...params);
  }
  setCustomDimensionValue(...params) {
    CustomDimensions.setCustomDimensionValue(...params);
  }
  deleteCustomDimension(...params) {
    CustomDimensions.deleteCustomDimension(...params);
  }
  static {
    this.ɵfac = function CustomDimensionsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CustomDimensionsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomDimensionsService,
      factory: CustomDimensionsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomDimensionsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class SiteSearchService {
  trackSiteSearch(...params) {
    SiteSearch.trackSiteSearch(...params);
  }
  static {
    this.ɵfac = function SiteSearchService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SiteSearchService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SiteSearchService,
      factory: SiteSearchService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SiteSearchService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CustomEventsService {
  trackEvent(...params) {
    CustomEvent.trackEvent(...params);
  }
  static {
    this.ɵfac = function CustomEventsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CustomEventsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomEventsService,
      factory: CustomEventsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomEventsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class DownloadAndOutlinkService {
  trackLink(...params) {
    DownloadAndOutlink.trackLink(...params);
  }
  enableLinkTracking(...params) {
    DownloadAndOutlink.enableLinkTracking(...params);
  }
  setLinkClasses(...params) {
    DownloadAndOutlink.setLinkClasses(...params);
  }
  setDownloadClasses(...params) {
    DownloadAndOutlink.setDownloadClasses(...params);
  }
  setDownloadExtensions(...params) {
    DownloadAndOutlink.setDownloadExtensions(...params);
  }
  addDownloadExtensions(...params) {
    DownloadAndOutlink.addDownloadExtensions(...params);
  }
  removeDownloadExtensions(...params) {
    DownloadAndOutlink.removeDownloadExtensions(...params);
  }
  setLinkTrackingTimer(...params) {
    DownloadAndOutlink.setLinkTrackingTimer(...params);
  }
  getLinkTrackingTimer(...params) {
    return DownloadAndOutlink.getLinkTrackingTimer(...params);
  }
  setIgnoreClasses(...params) {
    DownloadAndOutlink.setIgnoreClasses(...params);
  }
  static {
    this.ɵfac = function DownloadAndOutlinkService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DownloadAndOutlinkService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DownloadAndOutlinkService,
      factory: DownloadAndOutlinkService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DownloadAndOutlinkService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ECommerceService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  /**
   * @deprecated Please use the ecommerceAddToCart instead.
   */
  addEcommerceItem(productSKU, productName, productCategory, productPrice, productQuantity) {
    this.paqService.push([TRACK_EVENT.ADD_ECOMMERCE_ITEM, productSKU, productName, productCategory, productPrice, productQuantity]);
  }
  ecommerceAddToCart(...params) {
    eCommerce.ecommerceAddToCart(...params);
  }
  /**
   * @deprecated Please use the ecommerceRemoveFromCart instead.
   */
  removeEcommerceItem(productSKU) {
    this.paqService.push([TRACK_EVENT.REMOVE_ECOMMERCE_ITEM, productSKU]);
  }
  ecommerceRemoveFromCart(...params) {
    eCommerce.ecommerceRemoveFromCart(...params);
  }
  /**
   * @deprecated
   */
  clearEcommerceCart() {
    this.paqService.push([TRACK_EVENT.CLEAR_ECOMMERCE_CART]);
  }
  /**
   * @deprecated
   */
  getEcommerceItems() {
    return new Promise((resolve, reject) => {
      try {
        this.paqService.push([function () {
          resolve(this.getEcommerceItems());
        }]);
      } catch (e) {
        if (e instanceof ReferenceError) {
          reject(e);
        }
      }
    });
  }
  /**
   * @deprecated Please use the ecommerceOrder instead.
   */
  trackEcommerceOrder(orderId, orderGrandTotal, orderSubTotal, orderTax, orderShipping, orderDiscount) {
    this.paqService.push([TRACK_EVENT.ORDER_ECOMMERCE, orderId, orderGrandTotal, orderSubTotal, orderTax, orderShipping, orderDiscount]);
  }
  ecommerceOrder(...params) {
    eCommerce.ecommerceOrder(...params);
  }
  /**
   * @deprecated Please use the ecommerceCartUpdate instead.
   */
  trackEcommerceCartUpdate(cartAmount) {
    this.paqService.push([TRACK_EVENT.UPDATE_ECOMMERCE_CART, cartAmount]);
  }
  ecommerceCartUpdate(...params) {
    eCommerce.ecommerceCartUpdate(...params);
  }
  /**
   * @deprecated
   */
  setEcommerceView(productSKU, productName, productCategory, productPrice) {
    this.paqService.push([TRACK_EVENT.SET_ECOMMERCE_VIEW, productSKU, productName, productCategory, productPrice]);
  }
  ecommerceProductDetailView(...params) {
    eCommerce.ecommerceProductDetailView(...params);
  }
  static {
    this.ɵfac = function ECommerceService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ECommerceService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ECommerceService,
      factory: ECommerceService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ECommerceService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: PaqService
  }], null);
})();
class GoalConversionsService {
  trackGoal(...params) {
    GoalConversions.trackGoal(...params);
  }
  static {
    this.ɵfac = function GoalConversionsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GoalConversionsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GoalConversionsService,
      factory: GoalConversionsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoalConversionsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class UserManagementService {
  getUserId(...params) {
    return UserManagement.getUserId(...params);
  }
  setUserId(...params) {
    UserManagement.setUserId(...params);
  }
  resetUserId(...params) {
    UserManagement.resetUserId(...params);
  }
  getVisitorId(...params) {
    return UserManagement.getVisitorId(...params);
  }
  getVisitorInfo(...params) {
    return UserManagement.getVisitorInfo(...params);
  }
  static {
    this.ɵfac = function UserManagementService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserManagementService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserManagementService,
      factory: UserManagementService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserManagementService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class DataLayerService {
  push(...params) {
    return DataLayer.push(...params);
  }
  static {
    this.ɵfac = function DataLayerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DataLayerService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DataLayerService,
      factory: DataLayerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataLayerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Check if there is some global function called _paq on Window object, or create an empty function to doesn't brake codes...
 */
function getPaqFn(window) {
  return window ? window['_paq'] = window['_paq'] || [] : null;
}
/**
 * Provides an injection token to access Piwik Pro Paq
 */
const NGX_PAQ = new InjectionToken('ngx-paq', {
  providedIn: 'root',
  factory: () => getPaqFn(inject(NGX_WINDOW))
});
class NgxPiwikProModule {
  constructor() {}
  static forRoot(containerId, containerURL, nonce) {
    return {
      ngModule: NgxPiwikProModule,
      providers: [{
        provide: NGX_PIWIK_PRO_SETTINGS_TOKEN,
        useValue: {
          containerId,
          containerURL,
          nonce
        }
      }, NGX_PIWIK_PRO_INITIALIZER_PROVIDER]
    };
  }
  static {
    this.ɵfac = function NgxPiwikProModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPiwikProModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPiwikProModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPiwikProModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [],
      exports: []
    }]
  }], () => [], null);
})();
class NgxPiwikProRouterModule {
  static forRoot(settings) {
    return {
      ngModule: NgxPiwikProRouterModule,
      providers: [{
        provide: NGX_PIWIK_PRO_ROUTING_SETTINGS_TOKEN,
        useValue: settings ?? {
          skipFirstPageView: true
        }
      }]
    };
  }
  static {
    this.ɵfac = function NgxPiwikProRouterModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPiwikProRouterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPiwikProRouterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NGX_PIWIK_PRO_ROUTER_INITIALIZER_PROVIDER],
      imports: [CommonModule, NgxPiwikProModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPiwikProRouterModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NgxPiwikProModule],
      providers: [NGX_PIWIK_PRO_ROUTER_INITIALIZER_PROVIDER],
      declarations: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-piwik-pro
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ContentTrackingService, CookieManagementService, CustomDimensionsService, CustomEventsService, CustomVariableScope, DataLayerService, DownloadAndOutlinkService, ECommerceService, GoalConversionsService, NGX_PAQ, NGX_PIWIK_PRO_INITIALIZER_PROVIDER, NGX_PIWIK_PRO_ROUTER_INITIALIZER_PROVIDER, NGX_PIWIK_PRO_ROUTING_SETTINGS_TOKEN, NGX_PIWIK_PRO_SETTINGS_TOKEN, NGX_WINDOW, NgxPiwikProModule, NgxPiwikProRouterModule, PageViewsService, PaqService, PiwikProInitializer, PiwikProRouterInitializer, SiteSearchService, TRACK_EVENT, UserManagementService, getPaqFn };
