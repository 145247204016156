import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BehaviorSubject, debounceTime, distinctUntilChanged} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {MatAutocompleteModule, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ElasticsearchService} from "../../../services/elasticsearch.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-searchbar',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe
  ],
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit, OnChanges {
  @Input() query: string = '';
  @Output() searchTriggered = new EventEmitter<string>()
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;

  searchControl = new FormControl('');
  filteredOptions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  filteredHistoryOptions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);


  constructor(
    private elasticsearchService: ElasticsearchService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    // Mettre à jour la valeur de searchQuery automatiquement
    this.searchControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.query = value ?? '';
    })

    // Mettre à jour les options filtrées automatiquement
    this.searchControl.valueChanges
      .pipe(
        debounceTime(500), // Attendre 2 secondes après la dernière saisie
        distinctUntilChanged() // Éviter les requêtes redondantes si la valeur n'a pas changé
      )
      .subscribe(() => {
        this.filterOptions(this.query);
        this.filterHistoryOptions(this.query);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['query']) {
      this.searchControl.setValue(this.query);
    }
  }

  onFocus(): void {
    this.fetchHistoryOptions(this.query);
  }

  filterOptions(value: string | null): void {
    if (value && value.length > 2) { // Lancer la recherche après avoir saisi au moins 3 caractères
      this.fetchOptions(value); // Appelle la méthode pour récupérer les données de l'API
    } else {
      this.filteredOptions$.next([]);
    }
  }

  filterHistoryOptions(value: string | null): void {
    if (value) {
      this.fetchHistoryOptions(value);
    }
  }

  // Méthode fetchOptions pour récupérer les données depuis l'API Elasticsearch avec le service ElasticsearchService
  fetchOptions(value: string): void {
    this.elasticsearchService.searchDocumentsByName(value).subscribe((data: any) => {
      const options = data.hits.hits.map((hit: any) => hit._source.name);
      this.filteredOptions$.next(options);  // Mettre à jour les options via le BehaviorSubject
    });
  }

  fetchHistoryOptions(value: string | null): void {
    this.elasticsearchService.searchUserHistory(value).subscribe((data: any) => {
      const allNewOptions = data.hits.hits.map((hit: any) => hit._source.search_query);
      let newOptions: string[] = [];
      let limit = (this.query && this.query.length > 2) ? 3 : 10;
      allNewOptions.forEach((option: string) => {
        if (newOptions.length < limit && !newOptions.includes(option)) {
          newOptions.push(option);
        }
      });
      this.filteredHistoryOptions$.next(newOptions);
    });
  }

  displayFn(option: string): string {
    return option;
  }

  onSearch(): void {
    // Lancer la recherche si la requête contient au moins 3 caractères
    if (this.query && this.query.length > 2) {
      // Redirige vers la page de recherche avec la requête en paramètre
      this.redirectToSearch(this.query);
      this.searchTriggered.emit(this.query);

      this.autocompleteTrigger.closePanel();
      this.searchInput.nativeElement.blur();
    }

  }

  redirectToSearch(searchQuery: string | null): void {
    const encodedQuery = searchQuery ? encodeURIComponent(searchQuery) : '';
    this.router.navigate(['/recherche'], {queryParams: {q: encodedQuery}});
  }

  clearSearch(): void {
    this.searchControl.setValue('');
    this.autocompleteTrigger.closePanel();
  }


}
