<div class="mat-mdc-dialog-header">
    <div class="modale-timeline-event-title-container">
        <p class="modale-timeline-event-title">{{ timelineEvent.name }}</p>
        <div *ngIf="timelineEvent.status !== 'tous'"
             class="timeline__event-status timeline__event-status--{{timelineEvent.status}} ">
            {{ timelineEvent.status }}
        </div>
    </div>
    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="modale-timeline-event-description" [customInnerHTML]="timelineEvent.htmlDescription"></div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="modale-timeline-event-footer">
        <div class="timeline__event-date">{{ timelineEvent.date | frenchDate }}</div>

        <a *ngIf="timelineEvent.lien?.url" href="{{timelineEvent.lien.url}}"
           [rel]="timelineEvent.lien.type === 'ext'? 'external': ''"
           [target]="timelineEvent.lien.type === 'ext'? '_blank': ''" class="btn btn--primary">En savoir plus</a>
    </div>
</mat-dialog-actions>
