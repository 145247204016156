<div class="mat-mdc-dialog-header">

    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
  </div>
  
  <mat-dialog-content class="links-dialog">
    <h3>Mes Liens</h3>
  <p>Je choisis mes liens préférés à mettre en avant sur mon bureau</p>
    <mat-tab-group animationDuration="500ms">
      <mat-tab label="Modifier">
        <ul class="links-modify">
            <li class="links-modify__item" *ngFor="let bookmark of bookmarks; let i = index">
                <a href="{{bookmark.url}}" rel="external" [innerHTML]="bookmark.title" target="_blank"></a>
                <button class="links-modify__edit" [class.active]="bookmark.isEditing" (click)="openEditForm(bookmark)">
                  Modifier le lien
                </button>
                <button class="links-modify__delete" (click)="deleteLink(bookmark)">Supprimer le lien</button>
      
                <form *ngIf="bookmark.isEditing" class="links-modify__form">
                  <mat-form-field appearance="outline">
                    <mat-label>Nom</mat-label>
                    <input matInput [(ngModel)]="oldBookmark.title" name="name" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>URL</mat-label>
                    <input matInput [(ngModel)]="oldBookmark.url" name="url" />
                  </mat-form-field>
                  <button type="button" class="links-modify__close-form" (click)="closeEditForm(bookmark)">
                    Annuler la modification
                  </button>
                  <button class="links-modify__submit" type="submit" (click)="submitEditForm(bookmark)">
                    Valider la modification
                  </button>
                </form>
              </li>
        </ul>
      </mat-tab>
  
      <mat-tab label="Ajouter">
        <ul class="links-add">
          <li class="links-add__item" *ngFor="let bookmark of bookmarks; let i = index">
            <a href="{{bookmark.url}}" rel="external" [innerHTML]="bookmark.title" target="_blank"></a>
          </li>
        </ul>
  
        <form class="links-add__form">
          <mat-form-field appearance="outline">
            <mat-label>Nom</mat-label>
            <input matInput [(ngModel)]="newLink.name" name="name">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>URL</mat-label>
            <input matInput [(ngModel)]="newLink.url" name="url">
          </mat-form-field>
          <button type="button" class="links-add__close-form" (click)="clearAddForm()">
            Annuler la modification
          </button>
          <button class="links-add__submit" (click)="submitAddForm()" type="submit">
            Valider la modification
          </button>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  