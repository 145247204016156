import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import {FlexibleSeparateur} from "../../../models/flexibles";

@Component({
  selector: 'app-flexible-separateur',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flexible-separateur.component.html',
  styleUrl: './flexible-separateur.component.scss'
})
export class FlexibleSeparateurComponent {
  @Input() flexible!: FlexibleSeparateur;
}
