<div class="main-layout">
  <div class="layout layout-error">
    <div class="error">

      <div class="error--content">
        <div class="error--visual">
          <span class="icon vnf-icons-icon-missing-file"></span>
        </div>
        <h1 title="page not found">Page non trouvée</h1>
      </div>

      <p>Il semblerait que la page que la page que vous cherchez n'existe pas.</p>
      <a class="btn--primary btn-xl" href="#">Revenir à l'accueil</a>
    </div>
  </div>
</div>
