import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  HostListener
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexiblePushPage } from '../../../models/flexibles';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

const swiperBreakpoint = 992;

@Component({
  selector: 'app-flexible-push-page',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-push-page.component.html',
  styleUrls: ['./flexible-push-page.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FlexiblePushPageComponent implements AfterViewInit {
  @Input() flexible!: FlexiblePushPage;
  @ViewChild('swiperContainer') swiperContainer!: ElementRef;
  @ViewChild('prevButton') prevButton!: ElementRef;
  @ViewChild('nextButton') nextButton!: ElementRef;

  isSliderVisible: boolean = false;

  constructor() { }

  ngAfterViewInit(): void {
    this.checkWindowWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkWindowWidth();
  }

  checkWindowWidth(): void {
    const width = window.innerWidth;
    const swiperEl = this.swiperContainer.nativeElement
    if (width < 992) {
      this.isSliderVisible = true;
      if (!swiperEl.swiper) {
        this.initializeSwiper();
      }
    } else {
      this.isSliderVisible = false;
    }
  }


  initializeSwiper(): void {
    const swiperEl = this.swiperContainer.nativeElement;
    const swiperParams = {
      slidesPerView: 1,
      direction: 'horizontal',
      loop: false,
      speed: 500,
      spaceBetween: 10,
      simulateTouch: true,  // Active les événements tactiles (inclut trackpad)
      allowTouchMove: true, // Permet le mouvement de swipe
      touchEventsTarget: 'container',  // Les événements sont capturés sur tout le conteneur
      cssMode: true, // Mode de défilement natif, fonctionne bien avec le trackpad
      navigation: {
        nextEl: this.nextButton.nativeElement,
        prevEl: this.prevButton.nativeElement,
      },
    };
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }

}
