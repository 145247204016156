import { Component } from '@angular/core';
import {QuelCapComponent} from "../../../pages/quel-cap/quel-cap.component";
import {AuQuotidienComponent} from "../../../pages/au-quotidien/au-quotidien.component";
import {QuiSommeNousComponent} from "../../../pages/qui-somme-nous/qui-somme-nous.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgIf} from "@angular/common";
import {WpPageComponent} from "../../../pages/wp-page/wp-page.component";
import {ArticleComponent} from "../../../pages/article/article.component";
import {SujetComponent} from "../../../pages/sujet/sujet.component";

@Component({
  selector: 'app-preview',
  standalone: true,
  imports: [
    QuelCapComponent,
    AuQuotidienComponent,
    QuiSommeNousComponent,
    NgIf,
    WpPageComponent,
    ArticleComponent,
    SujetComponent
  ],
  templateUrl: './preview.component.html',
  styleUrl: './preview.component.scss'
})
export class PreviewComponent {

  postId: number | null = null;
  postType: string | null = null;
  essentialPages: number[] = [200,202,204]

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // Récupérer les paramètres de l'URL
    this.route.queryParamMap.subscribe(params => {
      this.postId = Number(params.get('p'));
      this.postType = params.get('post_type');

      if (!this.postId || !this.postType) {
        this.router.navigate(['/page-non-trouvee']);
      }
    });
  }

  isEssentialPage(): boolean {
    if (this.postId) {
      return this.essentialPages.includes(this.postId);
    }
    return false;
  }

}
