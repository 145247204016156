<aside class="vnf-sidenav" [class.sidenav-deployed]="isOpen">

  <div class="vnf-sidenav-toggle-container">
    <button class="vnf-sidenav-toggle" (click)="toggleSidenav()">
      <svg id="icon-shutters" width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd"
          d="M14.114 1.73242H2.40039C1.84811 1.73242 1.40039 2.18014 1.40039 2.73242V10.8977C1.40039 11.45 1.84811 11.8977 2.40039 11.8977H14.114C14.6663 11.8977 15.114 11.45 15.114 10.8977V2.73242C15.114 2.18014 14.6663 1.73242 14.114 1.73242ZM2.40039 0.732422C1.29582 0.732422 0.400391 1.62785 0.400391 2.73242V10.8977C0.400391 12.0023 1.29582 12.8977 2.40039 12.8977H14.114C15.2186 12.8977 16.114 12.0023 16.114 10.8977V2.73242C16.114 1.62785 15.2186 0.732422 14.114 0.732422H2.40039Z"
          fill="white" />
        <rect x="1.40039" y="1.73242" width="7.40625" height="10.1641" rx="1" fill="white" />
      </svg>
    </button>
  </div>

  <div class="vnf-sidenav-menu-container">
    <a routerLink="/">
      <img src="/assets/images/logo-vnf&moi.svg" width="131" height="33" alt="logo vnf" class="vnf-sidenav-logo-app">
    </a>

    <div class="vnf-sidenav-list-container">
      <button class="vnf-sidenav-list-toggle" [ngClass]="{'open': isListOpen('menuExplorer')}"
        (click)="toggleList('menuExplorer')">Explorer</button>
      <ul class="vnf-sidenav-list">
        <li>
          <a (click)="onLinkClick()" routerLink="/" routerLinkActive="active" ariaCurrentWhenActive="page"
            [routerLinkActiveOptions]="{ exact: true }">
            <span class="vnf-sidenav-icon vnf-icons-icon-home"></span>
            <span class="vnf-sidenav-menu-item">Accueil</span>
          </a>
        </li>

        <li>
          <a (click)="onLinkClick()" routerLink="/actualites" routerLinkActive="active" ariaCurrentWhenActive="page">
            <span class="vnf-sidenav-icon vnf-icons-icon-news"></span><span class="vnf-sidenav-menu-item">L'Actu</span></a>
        </li>

        <li>
          <a (click)="onLinkClick()" routerLink="/qui-sommes-nous" routerLinkActive="active" ariaCurrentWhenActive="page">
            <span class="vnf-sidenav-icon vnf-icons-icon-menu-identity"></span><span class="vnf-sidenav-menu-item">Qui sommes-nous
              ?</span></a>
        </li>

        <li>
          <a (click)="onLinkClick()" routerLink="/quel-cap" routerLinkActive="active" ariaCurrentWhenActive="page">
            <span class="vnf-sidenav-icon vnf-icons-icon-menu-cap"></span><span class="vnf-sidenav-menu-item">Quel cap
              ?</span></a>
        </li>

        <li>
          <a (click)="onLinkClick()" routerLink="/au-quotidien" routerLinkActive="active" ariaCurrentWhenActive="page">
            <span class="vnf-sidenav-icon vnf-icons-icon-menu-daily"></span><span class="vnf-sidenav-menu-item">Au
              quotidien</span></a>
        </li>

        <li>
          <a (click)="onLinkClick()" routerLink="/collaborateurs" routerLinkActive="active" ariaCurrentWhenActive="page">
            <span class="vnf-sidenav-icon vnf-icons-icon-collabs"></span><span
              class="vnf-sidenav-menu-item">Collaborateurs</span></a>
        </li>
      </ul>
    </div>

    <div class="vnf-sidenav-list-container">
      <button class="vnf-sidenav-list-toggle" [ngClass]="{'open': isListOpen('menuServices')}"
        (click)="toggleList('menuServices')">Services</button>
      <ul class="vnf-sidenav-list">
        <li><a (click)="onLinkClick()" routerLink="/mes-services" routerLinkActive="active" ariaCurrentWhenActive="page">
            <span class="vnf-sidenav-icon vnf-icons-icon-modify"></span>
            <span class="vnf-sidenav-menu-item">Modifier mes services</span>
          </a></li>
      </ul>
    </div>

    <a class="vnf-sidenav-vnf-link" href="https://vnf.fr">
      <img src="/assets/images/logo-vnf.svg" width="125" height="51" alt="logo vnf" class="vnf-sidenav-logo-vnf">
      <span class="vnf-sidenav-menu-item">vnf.fr</span>
    </a>
      <!--
    <button class="vnf-sidenav-logout btn btn--primary" (click)="onLogout()">
        Logout
    </button>
    -->

  </div>

</aside>

<div class="top-bar">
  <div class="top-bar-container">
    <button class="top-bar-toggle" (click)="toggleSidenav()">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M7.5 11.4648C7.5 10.6364 8.17157 9.96484 9 9.96484H26.1253C26.9537 9.96484 27.6253 10.6364 27.6253 11.4648C27.6253 12.2933 26.9537 12.9648 26.1253 12.9648H9C8.17157 12.9648 7.5 12.2933 7.5 11.4648ZM12.3748 18.5007C12.3748 17.6723 13.0463 17.0007 13.8748 17.0007H31C31.8284 17.0007 32.5 17.6723 32.5 18.5007C32.5 19.3292 31.8284 20.0007 31 20.0007H13.8748C13.0463 20.0007 12.3748 19.3292 12.3748 18.5007ZM9 24.0366C8.17157 24.0366 7.5 24.7082 7.5 25.5366C7.5 26.365 8.17157 27.0366 9 27.0366H26.1253C26.9537 27.0366 27.6253 26.365 27.6253 25.5366C27.6253 24.7082 26.9537 24.0366 26.1253 24.0366H9Z"
          fill="white" />
      </svg>
    </button>
    <img src="assets/images/logo-vnf&moi.svg" width="131" height="33" alt="logo vnf" class="vnf-sidenav-logo-app">
  </div>
</div>
