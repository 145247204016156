<div class="mat-mdc-dialog-header">

  <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>

<mat-dialog-content class="links-dialog">
  <h3>Mes Liens</h3>
<p>Je choisis mes liens préférés à mettre en avant sur mon bureau</p>
  <mat-tab-group animationDuration="500ms">
    <mat-tab label="Modifier">
      <ul class="links-modify">
        <li class="links-modify__item" #item1>
          <a href="#" rel="external">Intranet du Ministère de la Transition écologique et sanitaire</a>
          <button class="links-modify__edit" [class.active]="isEditing['item1']" (click)="openEditForm('item1')">
            Modifier le lien
          </button>
          <button class="links-modify__delete">Supprimer le lien</button>

          <form *ngIf="isEditing['item1']" class="links-modify__form">
            <mat-form-field appearance="outline">
              <mat-label>Nom</mat-label>
              <input matInput value="Intranet du Ministère">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>URL</mat-label>
              <input matInput value="https://intranet.ministere.fr">
            </mat-form-field>
            <button type="button" class="links-modify__close-form" (click)="closeEditForm('item1')">
              Annuler la modification
            </button>
            <button class="links-modify__submit" type="submit" (click)="submitEditForm('item1')">
              Valider la modification
            </button>
          </form>
        </li>

        <li class="links-modify__item" #item2>
          <a href="#" rel="external">Google</a>
          <button class="links-modify__edit" [class.active]="isEditing['item2']" (click)="openEditForm('item2')">
            Modifier le lien
          </button>
          <button class="links-modify__delete">Supprimer le lien</button>

          <form *ngIf="isEditing['item2']" class="links-modify__form">
            <mat-form-field appearance="outline">
              <mat-label>Nom</mat-label>
              <input matInput value="Google">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>URL</mat-label>
              <input matInput value="https://www.google.com">
            </mat-form-field>
            <button type="button" class="links-modify__close-form" (click)="closeEditForm('item2')">
              Annuler la modification
            </button>
            <button class="links-modify__submit" type="submit" (click)="submitEditForm('item2')">
              Valider la modification
            </button>
          </form>
        </li>

        <li class="links-modify__item" #item3>
          <a href="#" rel="external">Annuaire du ministre</a>
          <button class="links-modify__edit" [class.active]="isEditing['item3']" (click)="openEditForm('item3')">
            Modifier le lien
          </button>
          <button class="links-modify__delete">Supprimer le lien</button>

          <form *ngIf="isEditing['item3']" class="links-modify__form">
            <mat-form-field appearance="outline">
              <mat-label>Nom</mat-label>
              <input matInput value="Annuaire du ministre">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>URL</mat-label>
              <input matInput value="https://annuaire.ministere.fr">
            </mat-form-field>
            <button type="button" class="links-modify__close-form" (click)="closeEditForm('item3')">
              Annuler la modification
            </button>
            <button class="links-modify__submit" type="submit" (click)="submitEditForm('item3')">
              Valider la modification
            </button>
          </form>
        </li>

        <li class="links-modify__item" #item4>
          <a href="#" rel="external">Postes vacants VNF</a>
          <button class="links-modify__edit" [class.active]="isEditing['item4']" (click)="openEditForm('item4')">
            Modifier le lien
          </button>
          <button class="links-modify__delete">Supprimer le lien</button>

          <form *ngIf="isEditing['item4']" class="links-modify__form">
            <mat-form-field appearance="outline">
              <mat-label>Nom</mat-label>
              <input matInput value="Postes vacants VNF">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>URL</mat-label>
              <input matInput value="https://postes-vacants.vnf.fr">
            </mat-form-field>
            <button type="button" class="links-modify__close-form" (click)="closeEditForm('item4')">
              Annuler la modification
            </button>
            <button class="links-modify__submit" type="submit" (click)="submitEditForm('item4')">
              Valider la modification
            </button>
          </form>
        </li>
      </ul>
    </mat-tab>

    <mat-tab label="Ajouter">
      <ul class="links-add">
        <li class="links-add__item">
          <a href="#" rel="external">Intranet du Ministère de la Transition écologique et sanitaire</a>
        </li>
        <li class="links-add__item">
          <a href="#" rel="external">Google</a>
        </li>
        <li class="links-add__item">
          <a href="#" rel="external">Annuaire du ministre</a>
        </li>
        <li class="links-add__item">
          <a href="#" rel="external">Postes vacants VNF</a>
        </li>
      </ul>

      <form class="links-add__form">
        <mat-form-field appearance="outline">
          <mat-label>Nom</mat-label>
          <input matInput [(ngModel)]="newLink.name" name="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>URL</mat-label>
          <input matInput [(ngModel)]="newLink.url" name="url">
        </mat-form-field>
        <button type="button" class="links-add__close-form" (click)="clearAddForm()">
          Annuler la modification
        </button>
        <button class="links-add__submit" (click)="submitAddForm()" type="submit">
          Valider la modification
        </button>
      </form>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
