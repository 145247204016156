

<div class="mat-mdc-dialog-header">

  <p class="document-dialog__title">Nom du document</p>


  <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>


<mat-dialog-content>

  <div class="document-dialog">
    <p class="document-dialog__label">Collaborateur</p>
    <p class="document-dialog__info">Sophie Turner</p>
    <p class="document-dialog__label">Service</p>
    <p class="document-dialog__info">DSIN</p>
    <p class="document-dialog__label">Période de mise à disposition</p>
    <p class="document-dialog__info">Disponible jusqu'au 18.06.24</p>
    <p class="document-dialog__label">Thème</p>
    <p class="document-dialog__info">Etablissement/Institutionnel</p>
    <p class="document-dialog__label">Périmètre</p>
    <p class="document-dialog__info">Siège</p>
    <p class="document-dialog__label">Type de document</p>
    <p class="document-dialog__info">Document officiel</p>
  </div>

</mat-dialog-content>
