import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { FlexiblePushServices } from '../../../models/flexibles';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-push-service',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-push-service.component.html',
  styleUrl: './flexible-push-service.component.scss'
})
export class FlexiblePushServiceComponent {
  @Input() flexible!: FlexiblePushServices;
}
