<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <div class="flexible-video flexible-content">
    <div class="video-container">
      <div *ngIf="!videoLoaded" class="thumbnail-container">
        <picture>
          <source srcset="{{flexible.visuel?.sizes?.['w360h202']}}" width="360" height="202"
            media="(max-width: 429px)" />
          <img
            src="{{flexible.visuel?.sizes?.['w740h416'] ? flexible.visuel?.sizes?.['w740h416'] : flexible.visuel?.url}}"
            width="740" height="416" alt="Thumbnail" (click)="onThumbnailClick($event.target)" class="thumbnail"
            [attr.data-video-url]="flexible.url" />
        </picture>
        <!-- <img src="https://picsum.photos/740/416" alt="Thumbnail" (click)="onThumbnailClick($event.target)" class="thumbnail"
             data-video-url="https://youtu.be/NpEaa2P7qZI?si=s842Bsv_EZaz12Dm" /> -->
      </div>

      <div *ngIf="videoLoaded" class="video-frame">
        <iframe width="740" height="416" [src]="videoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
</div>