import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatSelectModule} from "@angular/material/select";
import {NgForOf, UpperCasePipe} from "@angular/common";
import {Subscription} from "rxjs";
import {DepartmentService} from "../../services/department.service";

@Component({
  selector: 'app-select-implantation',
  standalone: true,
  imports: [
    MatSelectModule,
    NgForOf,
    UpperCasePipe
  ],
  templateUrl: './select-implantation.component.html',
  styleUrl: './select-implantation.component.scss'
})
export class SelectImplantationComponent implements OnInit, OnDestroy {
  @Input() keys: {label: string|null, code: string|null }[] = [];
  @Input() selectedKey!: number;
  @Output() selectedKeyChange = new EventEmitter<number>();
  private departmentSubscription!: Subscription;
  private department!: string;

  constructor(private departmentService: DepartmentService) {
    this.department = departmentService.defaultDepartment;
  }

  ngOnInit() {
    // On s'abonne à l'observable du service de département
    // On met à jour le département sélectionné et l'index correspondant
    this.departmentSubscription = this.departmentService.department$.subscribe(
      (department: string) => {
        this.department = department;
        this.updateSelectedKey();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entries']) {
      this.updateSelectedKey();
    }
  }

  updateSelectedKey() {
    // On cherche l'index de l'entrée correspondant au département sinon on renvoie sur le premier
    let index = this.keys.findIndex(
      (key) => key.code?.toUpperCase() === this.department
    );
    this.selectedKey = (index !== -1) ? index : 0;
    this.selectedKeyChange.emit(this.selectedKey);
  }

  onSelectionChange(selectedKey: number) {
    this.selectedKey = selectedKey;
    this.selectedKeyChange.emit(selectedKey);
  }

  ngOnDestroy() {
    if (this.departmentSubscription) {
      this.departmentSubscription.unsubscribe();
    }
  }
}
