<div class="mat-mdc-dialog-header">
    <p class="document-dialog__title">{{ document.titre? document.titre : document.name }}</p>
    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>

<mat-dialog-content>

    <div class="document-dialog">
        <p class="document-dialog__label">Nom du fichier</p>
        <p class="document-dialog__info">{{ document.name }}</p>
        <p class="document-dialog__label">Collaborateur</p>
        <p class="document-dialog__info">(En attente d'indexation...)</p>
        <!-- <p class="document-dialog__label">Date d'effet</p>
        <p class="document-dialog__info">{{ document.date_d_effet|date: 'dd/MM/y' }}</p> -->
        <p class="document-dialog__label">Période de mise à disposition</p>
        <p class="document-dialog__info">Disponible jusqu'au {{dateFinValidite}}</p>
        <!-- <p class="document-dialog__info">{{ 'Disponible pendant ' + document.duree_validite }}</p> -->
        <p class="document-dialog__label">Périmètre</p>
        <p class="document-dialog__info">{{ document.perimetre }}</p>
        <p class="document-dialog__label">Type de document</p>
        <p class="document-dialog__info">{{ document.nature_du_document }}</p>
        <p *ngIf="environment.elasticSearchConfig.documentDetail"
           class="document-dialog__label">JSON</p>
        <pre *ngIf="environment.elasticSearchConfig.documentDetail"
           class="document-dialog__info" style="width: 100%; word-wrap: break-word; overflow-wrap: break-word; white-space: pre-wrap">{{document | json}}</pre>

    </div>

</mat-dialog-content>
