<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <div class="flexible-reporting flexible-content">
    <div class="flexible-reporting-img">
      <picture>
        <source srcset="{{flexible.visuel.url}}" width="200" height="200" media="(max-width: 460px)" />
        <source srcset="{{flexible.visuel.url}}" width="400" height="400" media="(max-width: 767px)" />
        <source srcset="{{flexible.visuel.url}}" width="578" height="578"/>
        <img src="{{flexible.visuel.url}}" width="578" height="578" alt="{{flexible.visuel.alt}}"/>
      </picture>
    </div>

    <div class="flexible-reporting-content">
      <p class="flexible-reporting-title" [customInnerHTML]="flexible.title"></p>
      <p class="flexible-reporting-text" [customInnerHTML]="flexible.content"></p>
    </div>
  </div>
</div>
