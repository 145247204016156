import {CommonModule} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {environment} from '../../../../environments/environment';
import {Bookmark} from '../../../models/bookmark';
import {ApiService} from '../../../services/api.service';
import {MesLiensService} from '../../../services/mes-liens.service';

@Component({
  selector: 'app-dialog-mes-liens',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatTab, MatTabGroup, MatFormField, MatInput, MatLabel, CommonModule, FormsModule],
  templateUrl: './dialog-mes-liens.component.html',
  styleUrl: './dialog-mes-liens.component.scss'
})
export class DialogMesLiensComponent {
  bookmarks: Bookmark[] = [];
  oldBookmark!: Bookmark;
  newLink = {
    name: '',
    url: ''
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private mesLiensService: MesLiensService
  ) {
    this.bookmarks = [...data.bookmarks.map((b: { uuid: string, title: string, url: string }) => ({
      ...b,
      isEditing: false
    }))];
  }

  ngOnInit(): void {
    // mise à jour des liens de l'utilisateur en fonction de ce qu'il a modifié
    this.mesLiensService.currentMesLiens.subscribe((mesLiens) => {
      this.bookmarks = mesLiens;
    });
  }

  openEditForm(bookmark: Bookmark): void {
    this.bookmarks.forEach(b => b.isEditing = false);

    // on sauvegarde le lien avant modification
    this.oldBookmark = {...bookmark};

    bookmark.isEditing = !bookmark.isEditing;

    environment.enableLogging && console.log('bookmark', bookmark);
  }

  closeEditForm(bookmark: Bookmark): void {
    bookmark.isEditing = false;
  }

  submitEditForm(bookmark: Bookmark): void {
    // on vérifie que les champs ne sont pas vides et que le lien n'a pas changé
    if (!this.oldBookmark.title.trim() || !this.oldBookmark.url.trim() || (bookmark.title === this.oldBookmark.title && bookmark.url === this.oldBookmark.url)) return;

    const title = this.oldBookmark.title;
    const url = this.oldBookmark.url;

    // on met à jour le lien
    this.apiService.editLink(bookmark.uuid, title, url).then((result) => {
      if (environment.enableLogging) console.log('editLink', result);
      // on met à jour le lien dans la liste
      bookmark.isEditing = false;
      bookmark.title = title;
      bookmark.url = url;
      // on trie la liste
      this.bookmarks.sort((a, b) => a.title.localeCompare(b.title));
      // mettre à jour mes liens 
      this.mesLiensService.changeMesLiens(this.bookmarks);
    });
  }

  clearAddForm(): void {
    this.newLink.name = '';
    this.newLink.url = '';
  }

  submitAddForm() {
    // on vérifie que les champs ne sont pas vides
    if (!this.newLink.name.trim() || !this.newLink.url.trim()) return;
    const title = this.newLink.name;
    const url = this.newLink.url;
    // on vide le formulaire
    this.clearAddForm();
    // on ajoute le nouveau lien
    this.apiService.addLink(title, url).then((result) => {
      if (environment.enableLogging) console.log('addLink', result);
      // on ajoute le nouveau lien à la liste
      this.bookmarks = [...this.bookmarks, {uuid: result?.body?.id, title: title, url: url, isEditing: false}];
      // this.bookmarks.push({ uuid: result?.body?.id, title: title, url: url, isEditing: false });
      // on trie la liste
      this.bookmarks.sort((a, b) => a.title.localeCompare(b.title));
      // mettre à jour mes liens 
      this.mesLiensService.changeMesLiens(this.bookmarks);
    });
  }

  deleteLink(bookmark: Bookmark) {
    this.apiService.removeLink(bookmark.uuid).then((result) => {
      if (environment.enableLogging) console.log('deleteLink', result);
      // on supprime le lien de la liste
      this.bookmarks = [...this.bookmarks.filter(b => b.uuid !== bookmark.uuid)];
      // on trie la liste
      this.bookmarks.sort((a, b) => a.title.localeCompare(b.title));
      // mettre à jour mes liens 
      this.mesLiensService.changeMesLiens(this.bookmarks);
    });
  }
}
