import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { FlexibleSpacer } from '../../../models/flexibles';

@Component({
  selector: 'app-flexible-spacer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flexible-spacer.component.html',
  styleUrl: './flexible-spacer.component.scss'
})
export class FlexibleSpacerComponent {
  @Input() flexible!: FlexibleSpacer;
}
