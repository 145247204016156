<div class="main-layout">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <div class="child-page__actions">
    <button class="previous-page" (click)="back()">Page précédente</button>
  </div>

  <div class="document-request">

    <div class="news-headline-hp">
      <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-missing-file"></span>Demande de document</h2>
    </div>

    <div  class="document-request__infos">
      <div class="document-request__title">Ma recherche</div>

      <p class="document-request__text">Mots-clé : <span [innerHTML]="query"></span></p>
      <p class="document-request__text" *ngIf="perimetres.length">Périmètres : <span [innerHTML]="perimetres.join(', ')"></span></p>
      <p class="document-request__text" *ngIf="natures.length">Type de document : <span [innerHTML]="natures.join(', ')"></span></p>
      <!-- <p class="document-request__text" *ngIf="themes.length">Thèmes : <span [innerHTML]="themes"></span></p> -->
      <p class="document-request__text" *ngIf="startDate">Date de début : <span [innerHTML]="startDate.toLocaleDateString()"></span></p>
      <p class="document-request__text" *ngIf="endDate">Date de fin : <span [innerHTML]="endDate.toLocaleDateString()"></span></p>

    </div>
  <form class="document-request__form" [formGroup]="documentRequestForm" (ngSubmit)="onSubmit($event)">
    <mat-form-field class="document-request__select" floatLabel="auto">
    <mat-label>Demande d'aide</mat-label>
    <mat-select formControlName="assistanceRequest">
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.viewValue }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="documentRequestForm.get('assistanceRequest')?.hasError('required')">
      Ce champ est requis.
    </mat-error>
  </mat-form-field>

  <mat-form-field class="document-request__textarea">
    <textarea matInput rows="16" placeholder="Votre message ..." formControlName="message"></textarea>
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </mat-form-field>

  <div class="d-flex j-space-between">
    <button class="btn btn--primary" type="button" (click)="back()">Annuler</button>
    <button class="btn btn--secondary" (click)="onSubmit($event)">Envoyer</button>
  </div>
</form>

  </div>
</div>
