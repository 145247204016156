import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {LoadingOverlayComponent} from '../../components/loading-overlay/loading-overlay.component';
import {PageEnfantComponent} from '../../components/page-enfant/page-enfant.component';
import {PageParenteComponent} from '../../components/page-parente/page-parente.component';
import {PageParente} from '../../models/page-parente';
import {ApiService} from '../../services/api.service';
import {formatFlexibles, formatZoom, useCache} from '../../utils/utils.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-wp-page',
  standalone: true,
  imports: [LoadingOverlayComponent, PageParenteComponent, PageEnfantComponent],
  templateUrl: './wp-page.component.html',
  styleUrl: './wp-page.component.scss'
})
export class WpPageComponent implements OnInit, OnChanges {
  public isLoading: boolean = false;
  slug: string | null = null;
  pageType: 'parent' | 'enfant' = 'parent';
  page: any;


  constructor(private router: Router, private apiService: ApiService, private titleService: Title) {
  }

  async ngOnInit(): Promise<void> {
    this.slug = this.router.url?.substring(1); // get the slug from the URL and remove the first character '/'

    if (this.slug) {
      useCache(
        this.router.url,
        this,
        async () => {
          // Set the title of the page if page is in cache
          (this.page && this.page.title) && this.titleService.setTitle(this.sanitizeTitle(this.page.title));
          await this.getPage(this.slug as string);
        },
        () => {
          return {page: this.page}
        }
      );
    } else {
      this.router.navigate(['/']);
    }
  }

  async getPage(slug: string): Promise<void> {
    const { headers, body } = await this.apiService.getPage({ slug });

    environment.enableLogging && console.log(`Returned API body for slug: ${slug}`, body);
    if (body && body?.length > 0) {
      // Get the first page
      const page = body[0];
      // Check if the page is a parent or a child
      switch (page?.type_page) {
        case 'page_parente':
          this.pageType = 'parent';
          break;
        case 'page_enfant':
          this.pageType = 'enfant';
          break;
        default:
          this.pageType = 'parent';
          break;
      }

      if (this.pageType === 'parent')
        this.page = this.formatPageParente(body[0]);
      else
        this.page = ''; // TODO page enfant

      // Set the title of the page after api call
      this.page.title && this.titleService.setTitle(this.sanitizeTitle(this.page.title));

      environment.enableLogging && console.log(`Formated page`, this.page);
    } else {
      // Dont forget to set this.page to undefine if the page is not found
      // If not the loaded cache page will be saved again
      // The saveDataCache function will check if the data is undefined and will not save it
      this.page = undefined;
      localStorage.removeItem(this.router.url);
      await this.router.navigate(['/page-non-trouvee']);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes', changes);
    if (changes['page']) {
      this.titleService.setTitle(this.sanitizeTitle(this.page.title));
    }
  }

  sanitizeTitle(text: string): string {
    const textarea = document.createElement('div');
    textarea.innerHTML = text;
    return textarea.innerHTML;
  }

  formatPageParente(data: any): PageParente | null {
    try {
      const pageParente: PageParente = {
        id: data.id,
        slug: data.slug,
        title: data.title?.rendered,
        chapo: data.acf?.chapo,
        zoomLien: formatZoom(data.acf?.bloc_zooms_lien?.['zooms-lien'], 'url'),
        fOnglets: {
          type: 'onglets',
          onglets: data.acf.onglets?.map((onglet: any, index: number) => {
            return {
              id: index,
              title: onglet?.titre,
              flexibles: onglet.nationaux?.contenus_flexibles && formatFlexibles(onglet.nationaux?.contenus_flexibles),
            }
          }),
        },
        flexiblesTerritoriaux: data.acf?.territoriaux && Object.entries(data.acf?.territoriaux).map(([key, value]: [string, any], index: number) => {
          return {
            id: index,
            title: value.label,
            code: value.code,
            flexibles: value.contenus_flexibles && formatFlexibles(value.contenus_flexibles),
          }
        })
      };
      return pageParente;
    } catch (e) {
      console.error('Error in formatPageParente : ', e);
    }
    return null;
  }
}

