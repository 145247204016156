import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Tool } from "../../models/tool";
import { CardOutilComponent } from '../card-outil/card-outil.component';

@Component({
  selector: 'app-boite-a-outils',
  standalone: true,
  imports: [CardOutilComponent, CommonModule, RouterModule],
  templateUrl: './boite-a-outils.component.html',
  styleUrl: './boite-a-outils.component.scss'
})
export class BoiteAOutilsComponent {
  @Input() tools: Tool[] = [];
}
