import { Component, inject } from '@angular/core';
import { ShareComponent } from "../../components/share/share.component";
import { Location, NgForOf } from "@angular/common";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatError } from '@angular/material/form-field';
import { DialogConfirmEmailComponent } from "../../components/dialog/dialog-confirm-email/dialog-confirm-email.component";
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
import { objToHttpParams } from '../../utils/utils.service';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';

@Component({
  selector: 'app-demande-document',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatOption,
    MatSelect,
    NgForOf,
    MatError,
    MatDialogModule,
    LoadingOverlayComponent
  ],
  templateUrl: './demande-document.component.html',
  styleUrl: './demande-document.component.scss'
})
export class DemandeDocumentComponent {
  public isLoading: boolean = false;
  documentRequestForm: FormGroup;
  errorMessage: string | null = null;
  readonly dialog = inject(MatDialog);
  query: string | null = null;
  themes: string[] = [];
  natures: string[] = [];
  perimetres: string[] = [];
  startDate: Date | null = null;
  endDate: Date | null = null;
  filtres: string = '';
  options: { value: string, viewValue: string }[] = [];

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.documentRequestForm = this.fb.group({
      assistanceRequest: ['', Validators.required],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
  }

  ngOnInit(): void {
    // Récupérer les paramètres GET de la route actuelle
    this.route.queryParams.subscribe(params => {
      this.query = params['q'] || null; // récupère la recherche
      this.themes = (params['themes'] ? params['themes'].split(',') : []).map((str: string) => str.charAt(0).toUpperCase() + str.slice(1)); // récupère les thèmes
      this.natures = (params['natures'] ? params['natures'].split(',') : []).map((str: string) => str.charAt(0).toUpperCase() + str.slice(1)); // récupère les natures
      this.perimetres = (params['perimetres'] ? params['perimetres'].split(',') : []).map((str: string) => str.charAt(0).toUpperCase() + str.slice(1)); // récupère les périmètres
      this.startDate = params['startDate'] ? new Date(params['startDate']) : null; // récupère la date de début
      this.endDate = params['endDate'] ? new Date(params['endDate']) : null; // récupère la date
      this.filtres = this.perimetres.concat(this.themes).concat(this.natures).join(', ') + " " + (this.startDate ? ' du ' + this.startDate.toLocaleDateString() : '') + (this.endDate ? ' au ' + this.endDate.toLocaleDateString() : '');
    });

    this.apiService.getOptionsGenerales().then((data) => {
      environment.enableLogging && console.log('Options générales : ', data);

      data.body?.liste_objets?.forEach((objet: string) => {
        this.options.push({ value: objet, viewValue: objet });
      });
    });
  }

  back(): void {
    this.location.back();
  }

  openDialogConfirmEmail() {
    const dialogRef = this.dialog.open(DialogConfirmEmailComponent, {
      width: '100%',
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(() => {
      const paramsObj = {
        ...(this.query && { q: this.query }),
      };
      const params = objToHttpParams(paramsObj);
      window.open(`recherche?${params.toString()}`, '_self');
    });
  }

  sendEmail() {
    this.apiService.share({
      doc: {
        docFilters: this.filtres,
        docKeywords: this.query,
        docMessage: this.documentRequestForm.value?.message,
        docObject: this.documentRequestForm.value?.assistanceRequest
      }
    });
  }

  onSubmit(event: Event) {
    this.errorMessage = null;
    event.preventDefault();
    if (this.documentRequestForm.valid) {
      environment.enableLogging && console.log('Formulaire valide : ', this.documentRequestForm.value);
      this.sendEmail();
      this.openDialogConfirmEmail();
    } else {
      environment.enableLogging && console.log('Erreur dans le formulaire');
      this.errorMessage = 'Veuillez sélectionner un motif d\'aide et le champ texte nécessite au moins 10 caractères.';
    }
  }
}
