<div class="main-layout">
    <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
    <app-header class="layout" [header_type]="0" type="triangle" sup="Au quotidien" inf="Qui sommes-nous ?"
        sup_link="/au-quotidien" inf_link="/qui-sommes-nous" title="Quel cap ?" />

    <app-zoom-sur class="layout" [zoomsLien]="zoomsLien" [zoomsVideo]="zoomsVideo" />
    
    <!-- module essentiels -->
    <app-essentiel-list class="layout" [essentiels]="essentiels"></app-essentiel-list>

    <!-- boîte à outils -->
    <app-boite-a-outils class="layout" [tools]="tools" />

    <!-- blocs redirections -->
    <app-bloc-redirection class="layout" [identite]="true" [quotidien]="true" />
</div>