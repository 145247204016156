import { Component, Input } from '@angular/core';
import { FlexiblePushPortraits } from '../../../models/flexibles';
import { CommonModule } from '@angular/common';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive"; // Ajout de CommonModule

@Component({
  selector: 'app-flexible-push-portrait',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-push-portrait.component.html',
  styleUrl: './flexible-push-portrait.component.scss'
})

export class FlexiblePushPortraitComponent {
  @Input() flexible!: FlexiblePushPortraits;

}
