import { NgClass, NgForOf, NgIf } from "@angular/common";
import {Component, HostListener} from '@angular/core';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangeInput
} from '@angular/material/datepicker';
import { MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, RouterLink } from "@angular/router";
import { environment } from '../../../environments/environment';
import { ActualitesALaUneComponent } from "../../components/actualites-a-la-une/actualites-a-la-une.component";
import { EventsComponent } from "../../components/agenda/events/events.component";
import { BrevesComponent } from "../../components/breves/breves.component";
import { EnImagesComponent } from "../../components/en-images/en-images.component";
import { ListActualitesComponent } from "../../components/list-actualites/list-actualites.component";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { ParticipationComponent } from "../../components/participation/participation.component";
import { Actualite } from '../../models/actualite';
import { AppelsParticipation } from '../../models/appels-participation';
import { Breve } from "../../models/breve";
import { EnImage } from '../../models/en-images';
import { Event } from '../../models/event';
import { ApiService } from '../../services/api.service';
import {
  formatActualites,
  formatActualitesALaUne,
  formatAppelsParticipationPush,
  formatBreves,
  formatEnImages,
  formatEvents,
  loadDataCache,
  saveDataCache,
  useCache
} from '../../utils/utils.service';
import { FiltersBarComponent } from "../../components/search/filters-bar/filters-bar.component";
import { SearchFilter } from "../../models/search-filters";
import { LoadingOverlayComponent } from "../../components/loading-overlay/loading-overlay.component";
import { DepartmentService } from "../../services/department.service";
import { SearchbarComponent } from "../../components/search/searchbar/searchbar.component";
import {SearchPaginationComponent} from "../../components/search/search-pagination/search-pagination.component";

@Component({
  selector: 'app-actualites',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    NgClass,
    MatFormField,
    MatDatepickerToggle,
    MatDatepicker,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerInput,
    RouterLink, EventsComponent,
    EnImagesComponent,
    BrevesComponent,
    ParticipationComponent,
    NgForOf,
    ListActualitesComponent,
    ActualitesALaUneComponent,
    PaginationComponent,
    NgIf,
    FiltersBarComponent,
    LoadingOverlayComponent, SearchbarComponent, SearchPaginationComponent
  ],
  templateUrl: './actualites.component.html',
  styleUrl: './actualites.component.scss'
})
export class ActualitesComponent {
  public isMobile: boolean = window.innerWidth <= 576;
  public isLoading: boolean = false;
  firstLoad: boolean = true;
  actualitesALaUne: Actualite[] = [];
  actualites: Actualite[] = [];
  enImages: EnImage[] = [];
  appelsParticipations: AppelsParticipation[] = [];
  breves: Breve[] = [];
  events: Event[] = [];
  filtres: {
    perimetres: SearchFilter[],
    themes: SearchFilter[],
    natures: SearchFilter[] | null,
    startDate: Date | null,
    endDate: Date | null
  } = { perimetres: [], themes: [], natures: null, startDate: null, endDate: null };
  currentPage = 1;
  limit = 10;
  totalPages = 0;
  activeTab: string = 'news';

  openDropdownIndex: number | null = null;
  isActive: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobile = event.target.innerWidth <= 576;
  }

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private departmentService: DepartmentService
  ) { }

  async ngOnInit(): Promise<void> {
    // Récupérer les paramètres GET de la route actuelle
    this.route.queryParams.subscribe(params => {
      this.currentPage = !isNaN(params['p']) ? +params['p'] : this.currentPage; // récupérer le numéro de la page
      this.limit = !isNaN(params['limit']) ? +params['limit'] : this.limit; // récupérer le nombre d'éléments par page
    });

    // Récupérer les filtres
    this.loadFilters();

    useCache('actualites', this, async () => {
      loadDataCache('actualites_limit', this);
      await Promise.all([
        this.getContent(),
      ]);
    }, () => {
      return {
        actualites: this.actualites,
        actualitesALaUne: this.actualitesALaUne,
        enImages: this.enImages,
        appelsParticipations: this.appelsParticipations,
        breves: this.breves,
        events: this.events
      }
    });
  }

  async onDateChange(dateRangeInput: MatDateRangeInput<Date>) {
    // Mettre à jour les dates de début et de fin
    this.filtres.startDate = dateRangeInput.value?.start ? new Date(dateRangeInput.value?.start) : null;
    this.filtres.endDate = dateRangeInput.value?.end ? new Date(dateRangeInput.value?.end) : null;

    environment.enableLogging && console.log(this.filtres.startDate, this.filtres.endDate);

    // Récupérer les actualités avec les filtres
    this.getContent();
  }

  checkEmptyColumns(): void {
    setTimeout(() => {
      const columns = document.querySelectorAll('.col-half');
      columns.forEach(column => {
        const hasDivChildren = column.querySelectorAll('div').length > 0;

        if (!hasDivChildren) {
          column.classList.add('col-empty');
        } else {
          console.log('col not empty');
          column.classList.remove('col-empty');
        }
      });
    }, 100);
  }

  async getContent() {
    try {
      // Récupérer les filtres
      const dts = this.filtres.perimetres.find(perimetre => perimetre.value === 'direction-territoriale')?.sub_filters?.filter(dt => dt.checked).map(dt => dt.value);
      const perimetres = this.filtres.perimetres.filter(perimetre => perimetre.checked).map(perimetre => perimetre.value);
      const themes = this.filtres.themes.filter(theme => theme.checked).map(theme => theme.value);

      // Récupérer les actualités avec les filtres
      const { body, headers } = await this.apiService.getPageActualites({
        ...(dts?.length && { dt: dts.join(',') }),
        ...(perimetres.length && { perimetre: perimetres.join(',') }),
        ...(themes.length && { 'theme-actu': themes.join(',') }),
        ...(this.filtres.startDate && { 'from-date': this.filtres.startDate }),
        ...(this.filtres.endDate && { 'to-date': this.filtres.endDate })
      });
      environment.enableLogging && console.log('Page Actualites', body, headers);

      if (body) {
        // transform value
        if (body.actualites_a_la_une)
          this.actualitesALaUne = formatActualitesALaUne(body.actualites_a_la_une);
        if (body.medias)
          this.enImages = formatEnImages(body.medias);
        if (body.appels_participation)
          this.appelsParticipations = formatAppelsParticipationPush(body.appels_participation);
        if (body.breves)
          this.breves = formatBreves(body.breves);
        if (body.evenements)
          this.events = formatEvents(body.evenements);
        if (body.filtres)
          this.filtres = {
            perimetres: body.filtres?.perimetres?.map((perimetre: any): SearchFilter => ({
              name: perimetre.name,
              value: perimetre.slug,
              code: null,
              ...(!perimetre.sous_filtres && { checked: this.filtres.perimetres.some(filtre => filtre.value.toUpperCase() === perimetre.slug.toUpperCase() && filtre.checked) }),
              ...(perimetre.sous_filtres && {
                sub_filters: perimetre.sous_filtres.map((sous_filtre: any) => ({
                  name: sous_filtre.name,
                  value: sous_filtre.slug,
                  code: sous_filtre.code,
                  checked: this.filtres.perimetres.find(filtre => filtre.value === perimetre.slug)?.sub_filters.some(filtre => filtre.value === sous_filtre.slug && filtre.checked),
                  active: false
                }))
              }),
            })),
            themes: body.filtres?.['themes-actualite']?.map((theme: any): SearchFilter => ({
              name: theme.name,
              value: theme.slug,
              code: null,
              checked: this.filtres.themes.some(filtre => filtre.value === theme.slug && filtre.checked),
              active: false,
              sub_filters: []
            })),
            natures: null,
            startDate: this.filtres.startDate ? this.filtres.startDate : null,
            endDate: this.filtres.endDate ? this.filtres.endDate : null
          };
      }
    } catch (error) {
      environment.enableLogging && console.error(error);
    }
    // Set active department filter on first load
    if (this.firstLoad) {
      this.activeDepartmentFilter();
      this.firstLoad = false;
    }

    // Récupérer les actualités avec les filtres
    this.getActualites(this.currentPage, this.limit);

    this.checkEmptyColumns();
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  activeDepartmentFilter() {
    const department = this.departmentService.getDepartmentFromLocalStorage();
    // Si le departement est renseigné on active le filtre correspondant, correspondant à la une de valeurs des subfilters de perimetres
    if (department) {
      this.filtres.perimetres.forEach(perimetre => {
        if (perimetre.value.toUpperCase() === department) {
          perimetre.checked = true;
          perimetre.active = true;
        }
        if (perimetre.sub_filters) {
          perimetre.sub_filters.forEach(subFilter => {
            if (subFilter.code?.toUpperCase() === department) {
              subFilter.checked = true;
              subFilter.active = true;
            }
          });
        }
      });
    }
  }

  onFiltresChange(updatedFiltres: any): void {
    this.filtres = updatedFiltres;
  }

  async onSearch() {
    this.getContent();
  }

  async getActuALaUne() {
    const { body, headers } = await this.apiService.getActuALaUne();
    environment.enableLogging && console.log(body, headers);

    this.actualitesALaUne = body?.actualites ? formatActualitesALaUne(body.actualites) : [];
    environment.enableLogging && console.log('Actualités à la une', this.actualitesALaUne);
  }

  loadFilters(): void {
    // Charger les filtres depuis le localstorage
    const savedFilters = localStorage.getItem('actuFilters');
    if (savedFilters) {
      this.filtres = JSON.parse(savedFilters);
    }
  }

  saveFilters(): void {
    // Sauvegarde les filtres dans le localstorage
    localStorage.setItem('actuFilters', JSON.stringify(this.filtres));
  }

  async getActualites(page: number, limit: number = 10) {
    try {
      // Récupérer les filtres
      const dts = this.filtres.perimetres.find(perimetre => perimetre.value === 'direction-territoriale')?.sub_filters?.filter(dt => dt.checked).map(dt => dt.value);
      const perimetres = this.filtres.perimetres.filter(perimetre => perimetre.checked).map(perimetre => perimetre.value);
      const themes = this.filtres.themes.filter(theme => theme.checked).map(theme => theme.value);

      // Récupérer les actualités avec les filtres
      const { body, headers } = await this.apiService.getActualites({
        page,
        limit,
        ...(dts?.length && { dt: dts.join(',') }),
        ...(perimetres.length && { perimetre: perimetres.join(',') }),
        ...(themes.length && { 'theme-actualite': themes.join(',') }),
        ...(this.filtres.startDate && { 'from-date': this.filtres.startDate }),
        ...(this.filtres.endDate && { 'to-date': this.filtres.endDate })
      });
      environment.enableLogging && console.log('Actualites', body, headers);

      if (body) {
        // transform value
        this.actualites = formatActualites(body);

        this.totalPages = headers.get('x-wp-totalpages');
      }

      // Sauvegarder les filtres
      this.saveFilters();

      environment.enableLogging && console.log(this.actualites);
    } catch (error) {
      environment.enableLogging && console.error(error);
    }
    this.isLoading = false;
    this.checkEmptyColumns();
  }

  async getEnImages() {
    const { body, headers } = await this.apiService.getEnImages();
    environment.enableLogging && console.log(body, headers);

    if (body) {
      // transform value
      this.enImages = formatEnImages(body);
    }

    environment.enableLogging && console.log(this.enImages);
  }

  async getAppelsParticipation() {
    const { body, headers } = await this.apiService.getAppelsParticipation();
    environment.enableLogging && console.log(body, headers);

    if (body) {
      // transform value
      this.appelsParticipations = formatAppelsParticipationPush(body);
    }

    environment.enableLogging && console.log(this.appelsParticipations);
  }

  onPageChanged(page: number) {
    this.isLoading = true;
    this.currentPage = page;
    this.getActualites(this.currentPage, this.limit);
  }

  onLimitChanged(limit: number) {
    this.isLoading = true;
    this.limit = limit;
    this.getActualites(this.currentPage, this.limit);
    saveDataCache('actualites_limit', { limit: this.limit });
  }
}

