<div>
    <!-- Bloc actu main home -->
    <section class="news-hp">
        @if (actuMajeure && isDesktop) {
            <article class="news-highlight" [ngClass]="{'important': actuMajeure.important}">
                <picture>
                    <source srcset="{{actuMajeure.visuel.url}}" width="740" height="421" media="(min-width: 391px)"/>
                    <source srcset="{{actuMajeure.visuel.url}}" width="360" height="310" media="(max-width: 390px)"/>
                    <img src="{{actuMajeure.visuel.url}}" width="740" height="421" alt="{{actuMajeure.visuel.alt}}"/>
                </picture>
                <div class="news-highlight-content">
                    <div class="news-highlight-date">
                        <time datetime="2024-04-08">
                            <span class="news-highlight-day" [innerHTML]="actuMajeure.date.format_day"></span>
                            <span class="news-highlight-month" [innerHTML]="actuMajeure.date.format_month"></span>
                            <span class="news-highlight-year" [innerHTML]="actuMajeure.date.format_year"></span>
                        </time>
                    </div>
                    <div class="news-highlight-tags-container">
                        <span *ngIf="actuMajeure.important" class="news-highlight-important">Important</span>
                        <span *ngIf="actuMajeure.theme" class="news-highlight-thematic"
                              [innerHTML]="actuMajeure.theme"></span>
                        <span *ngIf="actuMajeure.dt" class="news-highlight-tag"
                              [innerHTML]="'DT '+actuMajeure.dt"></span>
                        <span *ngIf="actuMajeure.perimetre === 'Siège'" class="news-highlight-tag"
                              [innerHTML]="actuMajeure.perimetre"></span>
                    </div>
                    <h1 class="news-highlight-title" [innerHTML]="actuMajeure.title"></h1>
                    <a class="news-highlight-link" href="{{actuMajeure.lien.url}}"></a>
                </div>
            </article>
        }

        <swiper-container class="swiper-container" #swiperContainer init="false">
            @if (actuMajeure && !isDesktop) {

                <swiper-slide class="swiper-slide">
                    <article class="news-highlight" [ngClass]="{'important': actuMajeure.important}">
                        <picture>
                            <source srcset="{{actuMajeure.visuel.url}}" width="740" height="421"
                                    media="(min-width: 391px)"/>
                            <source srcset="{{actuMajeure.visuel.url}}" width="360" height="310"
                                    media="(max-width: 390px)"/>
                            <img src="{{actuMajeure.visuel.url}}" width="740" height="421"
                                 alt="{{actuMajeure.visuel.alt}}"/>
                        </picture>
                        <div class="news-highlight-content">
                            <div class="news-highlight-date">
                                <time datetime="2024-04-08">
                                    <span class="news-highlight-day" [innerHTML]="actuMajeure.date.format_day"></span>
                                    <span class="news-highlight-month"
                                          [innerHTML]="actuMajeure.date.format_month"></span>
                                    <span class="news-highlight-year" [innerHTML]="actuMajeure.date.format_year"></span>
                                </time>
                            </div>
                            <div class="news-highlight-tags-container">
                                <span *ngIf="actuMajeure.important" class="news-highlight-important">Important</span>
                                <span *ngIf="actuMajeure.theme" class="news-highlight-thematic"
                                      [innerHTML]="actuMajeure.theme"></span>
                                <span *ngIf="actuMajeure.dt" class="news-highlight-tag"
                                      [innerHTML]="'DT '+actuMajeure.dt"></span>
                                <span *ngIf="actuMajeure.perimetre === 'Siège'" class="news-highlight-tag"
                                      [innerHTML]="actuMajeure.perimetre"></span>
                            </div>
                            <h1 class="news-highlight-title" [innerHTML]="actuMajeure.title"></h1>
                            <a class="news-highlight-link" href="{{actuMajeure.lien.url}}"></a>
                        </div>
                    </article>
                </swiper-slide>
            }
            @for (actualite of actusMineures; track actualite.id) {
                <swiper-slide class="swiper-slide">
                    <article class="news-secondary" [ngClass]="{'important': actualite.important}">
                        <div class="flexible-similar-articles-img-wrapper">
                            <picture>
                                <source srcset="{{actualite.visuel.url}}" width="360" height="190"
                                        media="(min-width: 391px)"/>
                                <source srcset="{{actualite.visuel.url}}" width="266" height="176"
                                        media="(max-width: 390px)"/>
                                <img src="{{actualite.visuel.url}}" width="360" height="190"
                                     alt="{{actualite.visuel.alt}}"/>
                            </picture>
                            <div class="news-secondary-tags-container">
                                <span *ngIf="actualite.important" class="news-secondary-important minor-important">Important</span>
                                <span *ngIf="actualite.theme" class="news-secondary-thematic"
                                      [innerHTML]="actualite.theme"></span>
                                <span *ngIf="actualite.dt" class="news-secondary-tag"
                                      [innerHTML]="'DT '+actualite.dt"></span>
                                <span *ngIf="actualite.perimetre === 'Siège'" class="news-secondary-tag"
                                      [innerHTML]="actualite.perimetre"></span>
                            </div>
                        </div>
                        <div class="news-secondary-date">
                            <time>
                                <span class="news-secondary-day" [innerHTML]="actualite.date.format_day"></span>
                                <span class="news-secondary-month" [innerHTML]="actualite.date.format_month"></span>
                                <span class="news-secondary-year" [innerHTML]="actualite.date.format_year"></span>
                            </time>
                        </div>
                        <div class="news-secondary-content">
                            <h2 class="news-secondary-title" [innerHTML]="actualite.title"></h2>
                            <a class="news-secondary-link" href="{{actualite.lien.url}}"></a>
                        </div>
                    </article>
                </swiper-slide>
            }
        </swiper-container>
    </section>
</div>
