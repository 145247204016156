import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { CustomInnerHtmlDirective } from "../../../directives/custom-inner-html.directive";
import { FlexibleVideo } from '../../../models/flexibles';
import { Visuel } from '../../../models/visuel';
import { FlexibleVideoComponent } from '../../flexible/flexible-video/flexible-video.component';
import { NgIf } from '@angular/common';
import { Link } from '../../../models/link';

@Component({
  selector: 'app-dialog-text-img-video',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, FlexibleVideoComponent, CustomInnerHtmlDirective, PdfJsViewerModule, NgIf],
  templateUrl: './dialog-text-img-video.component.html',
  styleUrl: './dialog-text-img-video.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTextImgVideoComponent {
  title!: string;
  content!: string;
  button: Link | null = null;
  flexibleVideo: FlexibleVideo | null = null;
  visuel: Visuel | null = null;
  isPdf = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    this.button = this.data.button;
    this.visuel = this.data.visuel;
    this.flexibleVideo = this.data.flexibleVideo;

    if (this.visuel) {
      // Remove query params from url
      this.visuel.url = this.visuel.url.split('?')[0] || this.visuel?.url;

      this.isPdf = this.visuel?.url && this.visuel?.url?.endsWith('.pdf') || false;

      if (this.isPdf)
        this.visuel.url = encodeURI(this.visuel?.url);
    }
  }
}
