<div class="mes-services">
  <div class="news-headline-hp">
    <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-modify"></span>Autres services</h2>
  </div>

  <p class="line-1">Mes services à mettre en favori dans le menu (Psst : sur le fond vert !)</p>
  <p class="line-2">Je clique sur un service pour le mettre en favoris et le déclique pour l’enlever, c’est à vous.</p>

  <div class="mes-services-list">
    <div class="mes-services-list__item" #serviceItem1>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem1, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem2>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem2, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem3>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem3, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem4>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem4, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem5>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem5, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem6>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem6, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem7>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem7, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem8>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem8, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem9>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem9, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem10>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem10, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem11>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem11, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem12>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem12, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem13>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem13, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem14>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem14, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem15>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem15, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem16>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem16, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem17>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem17, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem18>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem18, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem19>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem19, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem20>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem20, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem21>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem21, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem22>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem22, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem23>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem23, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem24>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem24, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem25>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem25, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem26>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem26, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem27>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem27, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem28>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem28, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem29>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem29, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem30>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem30, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem31>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem31, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem32>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem32, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
    <div class="mes-services-list__item" #serviceItem33>
      <button class="mes-services__btn-favorite" (click)="toggleFavorite(serviceItem33, $event)">Bouton favori</button>
      <div class="mes-services__img">
        <span class="icon vnf-icons-icon-modify"></span>
      </div>
      <span class="mes-services__name">One Drive</span>
    </div>
  </div>
</div>
