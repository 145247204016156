import { AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, Input, OnChanges, QueryList, ViewChildren } from '@angular/core';
import { EnImage } from '../../models/en-images';
import { NgIf } from '@angular/common';
import { DialogTextImgVideoComponent } from '../dialog/dialog-text-img-video/dialog-text-img-video.component';
import { MatDialog } from '@angular/material/dialog';
import { Visuel } from '../../models/visuel';

const swiperBreakpointActu = 576;

@Component({
  selector: 'app-en-images',
  standalone: true,
  imports: [NgIf],
  templateUrl: './en-images.component.html',
  styleUrl: './en-images.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EnImagesComponent implements AfterViewInit, OnChanges {
  @Input() enImages: EnImage[] = [];
  readonly dialog = inject(MatDialog);
  @ViewChildren('swiperContainer', { read: ElementRef }) swiperContainers!: QueryList<ElementRef>;

  private page= window.location.pathname

  constructor(private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {

  }

  // Detect changes when the input 'breves' is updated
  ngOnChanges(): void {
    this.cdr.detectChanges();
    this.initSliderEvent();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.initSliderEvent();
  }

  shouldDisplaySimplePictures(): boolean {
    return this.page === '/actualites' && window.innerWidth <= swiperBreakpointActu;
  }

  initSliderEvent(): void {
    this.swiperContainers.forEach((swiperContainer) => {
      const swiperEl = swiperContainer.nativeElement;

      if (swiperEl.swiper) {
        swiperEl.swiper.update();
      } else {
        this.initializeSwiper(swiperEl);
      }
    });
  }

  initializeSwiper(swiperEl: any): void {
    // Swiper parameters
    const swiperParams = {
      slidesPerView: 'auto',
      direction: 'horizontal',
      loop: false,
      speed: 500,
      spaceBetween: 10,
      simulateTouch: true,  // Active les événements tactiles (inclut trackpad)
      allowTouchMove: true, // Permet le mouvement de swipe
      touchEventsTarget: 'container',  // Les événements sont capturés sur tout le conteneur
      cssMode: true, // Mode de défilement natif, fonctionne bien avec le trackpad
      navigation: {
        nextEl: '.on-pictures__slider .swiper-button-next',
        prevEl: '.on-pictures__slider .swiper-button-prev',
      },
    };
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }

  openDialogMedia(media: EnImage): void {
    const dialogRef = this.dialog.open(DialogTextImgVideoComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        title: media.title,
        typeInfographie: media.typeInfographie,
        ...(media.lien_video && {
          flexibleVideo: {
            url: media.lien_video,
            visuel: media.visuel,
            title: media.title
          }
        }),
        ...(media.infographie && { infographie: { ...media.infographie, title: media.title } }),
        ...(media.lien_infographie && { lien_infographie: media.lien_infographie }),
        ...(media.button && { button: media.button }),
      }
    });
  }
}
