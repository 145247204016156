@if (tools.length > 0) {
<div class="col-fullwidth mb-15">
    <div class="section-title">
        <img src="/assets/images/icons/icons-titles/tools.svg" alt="" width="40" height="40" />
        <h2>boîte à outils com'</h2>
    </div>
</div>

<div class="col-fullwidth mb-60">
    <div class="tools">
        <div class="tools_list">
            @for (tool of tools; track tool.id) {
                <app-card-outil class="tools_list--item-wrapper" [tool]="tool"></app-card-outil>
            }
        </div>
        <a routerLink="/boite-a-outils" class="btn btn--primary">Retrouvez ici tous nos outils com</a>
    </div>
</div>
}