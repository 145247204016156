import { AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, Input, OnChanges, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Visuel } from '../../models/visuel';
import { DialogTextImgVideoComponent } from '../dialog/dialog-text-img-video/dialog-text-img-video.component';
import {CustomInnerHtmlDirective} from "../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-zoom-sur',
  standalone: true,
  imports: [
    CustomInnerHtmlDirective
  ],
  templateUrl: './zoom-sur.component.html',
  styleUrl: './zoom-sur.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ZoomSurComponent implements AfterViewInit, OnChanges {
  @Input() zoomsLien: any[] = [];
  @Input() zoomsVideo: any[] = [];
  readonly dialog = inject(MatDialog);

  @ViewChildren('swiperContainer', { read: ElementRef }) swiperContainers!: QueryList<ElementRef>;

  constructor(private cdr: ChangeDetectorRef) { }

  // Detect changes when the input 'breves' is updated
  ngOnChanges(): void {
    this.cdr.detectChanges();
    this.initSliders();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.initSliders();
  }

  initSliders(): void {
    this.swiperContainers.forEach((swiperContainer) => {
      const swiperEl = swiperContainer.nativeElement;

      if (swiperEl.swiper) {
        swiperEl.swiper.update();
      } else {
        this.initializeSwiper(swiperEl);
      }
    });
  }

  initializeSwiper(swiperEl: any): void {
    // Swiper parameters
    const swiperParams = {
      slidesPerView: 1,
      direction: 'horizontal',
      navigation: false,
      cssMode: false,
      loop: false,
      speed: 500,
      spaceBetween: 0,
      pagination: {
        clickable: true,
      },
    };
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }

  openDialogMedia(url: string, visuel: Visuel) {
    const dialogRef = this.dialog.open(DialogTextImgVideoComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { flexibleVideo: { url, visuel } }
    });
  }
}
