<div class="on-pictures">
  <p class="on-pictures__title">En images</p>
  <div class="on-pictures__slider">
    <swiper-container #swiperContainer class="swiper-container" init="false">
      @for (media of enImages; track media.id) {
        <swiper-slide>
          <div class="on-pictures__card">
            <div class="on-pictures__card-img">
              <img src="{{media.visuel.sizes?.['w255h145']}}" width="255" height="145" alt="{{media.visuel.alt}}">
              <div class="news-secondary-tags-container">
                <span *ngIf='media.theme' class="news-secondary-thematic">{{media.theme}}</span>
                <span *ngIf='media.theme' class="news-secondary-tag">{{media.perimetre}}</span>
              </div>
            </div>

            <div class="on-pictures__card-content {{media.typeMedia == 'video'? 'video' : ''}}">
              <p [innerHTML]="media.title"></p>
            </div>
          </div>
          <a (click)="openDialogMedia({video: media.lien?.url, infographie: media.infographie, visuel: media.visuel})"
            class="on-pictures__card-link">Accéder</a>
        </swiper-slide>
      }

    </swiper-container>
    <div class="swiper-navigation">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</div>