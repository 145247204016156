@if (cap) {
<div class="col-half mb-30">
    <div class="redirection triangle">
        <div class="redirection--animation">
          <ng-lottie [options]="optionsCap" width="100" height="100"
          (animationCreated)="animationCreated('cap', $event)">
          </ng-lottie>
        </div>
        <div class="redirection--content">
            <a class="redirection--link" href="/quel-cap" (mouseenter)="playAnimation('cap')" (mouseleave)="playReverseAnimation('cap')">
                <h3 class="redirection--title">Quel cap</h3>
            </a>
            <!-- <p class="redirection--text">Suivez le projet de l’établissement à 10 ans et nos orientations de travail</p> -->
            <p class="redirection--text">{{chapoCap}}</p>
        </div>
    </div>
</div>
}
@if (quotidien) {
<div class="col-half mb-30">
    <div class="redirection circle">
        <div class="redirection--animation">
          <ng-lottie [options]="optionsQuotidien" width="100" height="100"
          (animationCreated)="animationCreated('quotidien', $event)">
          </ng-lottie>
        </div>
        <div class="redirection--content">
            <a class="redirection--link" href="/au-quotidien" (mouseenter)="playAnimation('quotidien')" (mouseleave)="playReverseAnimation('quotidien')">
                <h3 class="redirection--title">Au quotidien</h3>
            </a>
            <!-- <p class="redirection--text">Accédez rapidement aux outils pour faciliter votre quotidien à VNF</p> -->
            <p class="redirection--text">{{chapoQuotidien}}</p>
        </div>
    </div>
</div>
}
@if (identite) {
<div class="col-half mb-30">
    <div class="redirection diamond">
        <div class="redirection--animation">
          <ng-lottie [options]="optionsQui" width="100" height="100"
          (animationCreated)="animationCreated('qui', $event)">
          </ng-lottie>
        </div>
        <div class="redirection--content">
            <a class="redirection--link" href="/qui-sommes-nous" (mouseenter)="playAnimation('qui')" (mouseleave)="playReverseAnimation('qui')">
                <h3 class="redirection--title">Qui sommes-nous ?</h3>
            </a>
            <!-- <p class="redirection--text">Entrez dans l’univers VNF : quelle est notre raison d'être, notre mission, notre organisation, notre fonctionnement</p> -->
            <p class="redirection--text">{{chapoIdentite}}</p>
        </div>
    </div>
</div>
}
