<div class="filters-dropdown__list" [class.opened]="isMobileFilterBarOpen">

    <div *ngIf="filtres.perimetres !== null" class="filters-dropdown" data-type="perimetre">
        <span class="filters-dropdown__label">Périmètre</span>
        <button class="filters-dropdown__opener" (click)="toggleDropdown(1)"
            [ngClass]="{'open': openDropdownIndex === 1}">{{getSelectedLabel('perimetres')|titlecase}}
        </button>
        <div class="filters-dropdown__popin">
            <div class="filters-dropdown__popin-mobile-header">
                <p class="filters-dropdown__popin-mobile-label">Périmètre</p>
                <button class="filters-dropdown__popin-mobile-close" (click)="toggleDropdown(1)"></button>
            </div>
            <div class="d-flex j-space-between desktop-only mb-15">
                <button class="filters-dropdown__select-all" [disabled]="isAllChecked('perimetres')"
                    (click)="selectAll('perimetres')">Tout sélectionner
                </button>
                <button class="filters-dropdown__unselect-all" (click)="unselectAll('perimetres')"
                    [disabled]="!isAnyChecked('perimetres')">Tout déselectionner
                </button>
            </div>
            <div class="filters-dropdown__filters-list__container">
                <div class="filters-dropdown__filters-list">
                    <div class="{{perimetre.sub_filters? 'filters-dropdown__item--dropdown' : 'filters-dropdown__item'}}"
                        *ngFor="let perimetre of this.filtres.perimetres; let id = index;">
                        @if (perimetre.sub_filters) {
                        <span class="filters-dropdown__item--dropdown-opener"
                            [ngClass]="{'active': openedToggles.includes('perimetreToggle-'+id)}"
                            (click)="openToggle('perimetreToggle-'+id)">{{ perimetre.name }}</span>
                        <div class="filters-dropdown__item--sublevel">
                            <div class="sublevel__item" *ngFor="let subfilter of perimetre.sub_filters; let i = index">
                                <input id="perimetre-checkbox-{{id}}-{{i}}" [checked]="subfilter.checked"
                                    class="sublevel__check-checkbox" type="checkbox"
                                    (change)="onCheckboxChange($event, id, 'perimetre-subfilter', i)" />
                                <label for="perimetre-checkbox-{{id}}-{{i}}" class="sublevel__check-label">{{
                                    subfilter.name|titlecase }}</label>
                            </div>
                        </div>
                        } @else {
                        <input id="perimetre-checkbox-{{ id }}" class="filters-dropdown__check-checkbox" type="checkbox"
                            [checked]="perimetre.checked" (change)="onCheckboxChange($event, id, 'perimetres')" />
                        <label class="filters-dropdown__check-label" for="perimetre-checkbox-{{ id }}">
                            {{ perimetre.name }}
                        </label>
                        }
                    </div>
                </div>
            </div>

            <div class="filters-dropdown__filters-submit__container desktop-only d-flex j-space-between">
                <button class="filters-dropdown__cancel btn btn--primary desktop-only"
                    (click)="toggleDropdown(1)">Annuler
                </button>
                <button class="filters-dropdown__unselect-all mobile-only" (click)="unselectAll('perimetres')"
                    [disabled]="!isAnyChecked('perimetres')">Tout déselectionner
                </button>
                <button class="filters-dropdown__validate btn btn--secondary" (click)="search()">Voir
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="filtres.themes !== null" class="filters-dropdown" data-type="theme">
        <span class="filters-dropdown__label">Thèmes</span>
        <button class="filters-dropdown__opener" (click)="toggleDropdown(0)"
            [ngClass]="{'open': openDropdownIndex === 0}">{{getSelectedLabel('themes')|titlecase}}
        </button>

        <div class="filters-dropdown__popin">
            <div class="filters-dropdown__popin-mobile-header">
                <p class="filters-dropdown__popin-mobile-label">Thèmes</p>
                <button class="filters-dropdown__popin-mobile-close" (click)="toggleDropdown(0)"></button>
            </div>
            <div class="d-flex j-space-between desktop-only mb-15">
                <button class="filters-dropdown__select-all" [disabled]="isAllChecked('themes')"
                    (click)="selectAll('themes')">Tout sélectionner</button>
                <button class="filters-dropdown__unselect-all" (click)="unselectAll('themes')"
                    [disabled]="!isAnyChecked('themes')">Tout déselectionner
                </button>
            </div>
            <div class="filters-dropdown__filters-list__container">
                <div class="filters-dropdown__filters-list double">
                    <div class="filters-dropdown__item" *ngFor="let checkbox of filtres.themes; let i = index">
                        <input id="theme-checkbox-{{ i }}" class="filters-dropdown__check-checkbox" type="checkbox"
                            [checked]="checkbox.checked" (change)="onCheckboxChange($event, i, 'themes')" />
                        <label class="filters-dropdown__check-label" for="theme-checkbox-{{ i }}">
                            {{ checkbox.name }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="filters-dropdown__filters-submit__container desktop-only d-flex j-space-between">
                <button class="filters-dropdown__cancel btn btn--primary desktop-only"
                    (click)="toggleDropdown(0)">Annuler
                </button>

                <button class="filters-dropdown__unselect-all mobile-only" (click)="unselectAll('themes')"
                    [disabled]="!isAnyChecked('themes')">Tout déselectionner
                </button>
                <button class="filters-dropdown__validate btn btn--secondary" (click)="search()">Voir
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="filtres.natures !== null" class="filters-dropdown" data-type="type-de-document">
        <span class="filters-dropdown__label">Type de document</span>
        <button class="filters-dropdown__opener" (click)="toggleDropdown(2)"
            [ngClass]="{'open': openDropdownIndex === 2}">{{getSelectedLabel('natures')|titlecase}}
        </button>

        <div class="filters-dropdown__popin">
            <div class="filters-dropdown__popin-mobile-header">
                <p class="filters-dropdown__popin-mobile-label">Type de document</p>
                <button class="filters-dropdown__popin-mobile-close" (click)="toggleDropdown(2)"></button>
            </div>
            <div class="d-flex j-space-between desktop-only mb-15">
                <button class="filters-dropdown__select-all" [disabled]="isAllChecked('natures')"
                    (click)="selectAll('natures')">Tout
                    sélectionner
                </button>
                <button class="filters-dropdown__unselect-all" (click)="unselectAll('natures')"
                    [disabled]="!isAnyChecked('natures')">Tout déselectionner
                </button>
            </div>
            <div class="filters-dropdown__filters-list__container">
                <div class="filters-dropdown__filters-list">
                    <div class="{{nature.sub_filters? 'filters-dropdown__item--dropdown' : 'filters-dropdown__item'}}"
                        *ngFor="let nature of this.filtres.natures; let id = index">
                        @if (nature.sub_filters) {
                        <span class="filters-dropdown__item--dropdown-opener"
                            [ngClass]="{'active': openedToggles.includes('natureToggle-'+id)}"
                            (click)="openToggle('natureToggle-'+id)">{{ nature.name }}</span>
                        <div class="filters-dropdown__item--sublevel">
                            <div class="sublevel__item" *ngFor="let subfilter of nature.sub_filters; let i = index">
                                <input id="nature-checkbox-{{id}}-{{i}}" [checked]="subfilter.checked"
                                    class="sublevel__check-checkbox" type="checkbox"
                                    (change)="onCheckboxChange($event, id, 'nature-subfilter', i)" />
                                <label for="nature-checkbox-{{id}}-{{i}}" class="sublevel__check-label">{{
                                    subfilter.name|titlecase }}</label>
                            </div>
                        </div>
                        } @else {
                        <input id="nature-checkbox-{{ id }}" class="filters-dropdown__check-checkbox" type="checkbox"
                            [checked]="nature.checked" (change)="onCheckboxChange($event, id, 'natures')" />
                        <label class="filters-dropdown__check-label" for="nature-checkbox-{{ id }}">
                            {{ nature.name }}
                        </label>
                        }
                    </div>
                </div>
            </div>

            <div class="filters-dropdown__filters-submit__container desktop-only d-flex j-space-between">
                <button class="filters-dropdown__cancel btn btn--primary desktop-only"
                    (click)="toggleDropdown(2)">Annuler
                </button>

                <button class="filters-dropdown__unselect-all mobile-only" (click)="unselectAll('natures')"
                    [disabled]="!isAnyChecked('natures')">Tout déselectionner
                </button>
                <button class="filters-dropdown__validate btn btn--secondary" (click)="search()">Voir
                </button>
            </div>
        </div>
    </div>

    <div class="filters-dropdown__date">
        <mat-form-field>
            <mat-date-range-input #dateRange="matDateRangeInput" [rangePicker]="picker">
                <input matStartDate placeholder="Date début" (dateChange)="onDateChange(dateRange)" />
                <input matEndDate placeholder="Date fin" (dateChange)="onDateChange(dateRange)" />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker>
                <mat-date-range-picker-actions>
                    <button mat-button class="clear" (click)="clearDates(picker)">Effacer</button>
                    <button mat-raised-button matDateRangePickerApply>Voir</button>
                </mat-date-range-picker-actions>
            </mat-date-range-picker>
        </mat-form-field>
        <div *ngIf="filtres.startDate && filtres.endDate" class="date-selected-indicator"></div>
    </div>

    <div class="filters-dropdown__mobile-actions">
        <button *ngIf="!openDropdownIndex" class="btn btn--light" (click)="closeMobileFilterBar()">Annuler</button>
        <button *ngIf="openDropdownIndex" class="filters-dropdown__unselect-all"
            (click)="unselectAll(getFilterKeyByIndex(openDropdownIndex))"
            [disabled]="!isAnyChecked(getFilterKeyByIndex(openDropdownIndex))">Tout déselectionner
        </button>
        <button *ngIf="openDropdownIndex" (click)="search()" class="btn btn--light">Voir</button>
    </div>

</div>