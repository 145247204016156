import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-search-pagination',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './search-pagination.component.html',
  styleUrl: './search-pagination.component.scss'
})
export class SearchPaginationComponent implements OnInit, OnChanges {
  @ViewChild('paginationOptions', {static: false}) paginationOptions?: ElementRef;
  @ViewChild('paginationLink', {static: false}) paginationLink?: ElementRef;
  @Input() totalItems: number = 0; // Total d'éléments venant de l'API
  @Input() perPage: number = 5; // Nombre d'éléments par page
  @Input() maxPagesToShow: number = 5; // Nombre maximum de pages à afficher
  @Output() paginationChange = new EventEmitter<{ page: number, perPage: number }>();  // Émet le numéro de page lors du changement


  protected readonly Number = Number;

  currentPage: number = 1; // Page courante
  totalPages: number = 1; // Nombre total de pages
  perPageOptions: number[] = [5, 50, 100, 0]; // Options de nombre d'éléments par page
  isPaginationOptionsActive = false;


  ngOnInit(): void {
    this.calculateTotalPages();
  }

  ngOnChanges(): void {
    this.calculateTotalPages();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    //  On vérifie si paginationOptions existe et si le clic est à l'intérieur de cet élément
    if (this.paginationOptions && this.paginationLink
      && this.isPaginationOptionsActive
      && !this.paginationOptions.nativeElement.contains(event.target)
      && !this.paginationLink.nativeElement.contains(event.target)
    ) {
      this.closePerPageOptions();
    }
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(this.totalItems / this.perPage);
  }

  getDisplayedPages(): (number | string)[] {
    const pages: (number | string)[] = [];
    const half = Math.floor(this.maxPagesToShow / 2);

    if (this.totalPages <= this.maxPagesToShow) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else if (this.currentPage <= half) {
      for (let i = 1; i <= this.maxPagesToShow - 1; i++) {
        pages.push(i);
      }
      pages.push('...');
      pages.push(this.totalPages);
    } else if (this.currentPage > this.totalPages - half) {
      pages.push(1);
      pages.push('...');
      for (let i = this.totalPages - this.maxPagesToShow + 2; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      pages.push('...');
      for (let i = this.currentPage - half + 1; i <= this.currentPage + half - 1; i++) {
        pages.push(i);
      }
      pages.push('...');
      pages.push(this.totalPages);
    }

    return pages;
  }

  goToPage(page: any, event: Event): void {
    event.preventDefault();
    // get page number from the data-page attribute
    if (Number.isInteger(page) && page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.paginationChange.emit({page: this.currentPage, perPage: this.perPage});
    }
  }

  previousPage(event: Event): void {
    event.preventDefault();
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginationChange.emit({page: this.currentPage, perPage: this.perPage});
    }
  }

  nextPage(event: Event): void {
    event.preventDefault();
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginationChange.emit({page: this.currentPage, perPage: this.perPage});
    }
  }

  togglePerPageOptions(): void {
    this.isPaginationOptionsActive = !this.isPaginationOptionsActive;
  }

  closePerPageOptions(): void {
    this.isPaginationOptionsActive = false;
  }

  setPerPage(perPage: number, event: Event): void {
    event.preventDefault();
    if (perPage > 0) {
      this.perPage = perPage;
    } else {
      this.perPage = this.totalItems;
    }
    this.currentPage = 1;
    this.calculateTotalPages();

    // Emit page change event
    this.paginationChange.emit({page: this.currentPage, perPage: this.perPage});

    // close per page options
    this.isPaginationOptionsActive = false;

  }
}
