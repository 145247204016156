import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationProvider, AuthenticationProviderOptions } from '@microsoft/microsoft-graph-client';
import {environment} from "../../environments/environment";
import {lastValueFrom} from "rxjs";

/**
 * Classe pour fournir un jeton d'authentification via MSAL
 */
@Injectable({
  providedIn: 'root'
})
export class MsalAuthProvider implements AuthenticationProvider {

  constructor(private msalService: MsalService) {}

  /**
   * Méthode pour obtenir un jeton d'accès via MSAL
   * @param {AuthenticationProviderOptions} [authenticationProviderOptions] - Les options fournies par le fournisseur d'authentification
   * @returns {Promise<string>} - Retourne une promesse qui se résout avec le jeton d'accès
   */
  async getAccessToken(authenticationProviderOptions?: AuthenticationProviderOptions): Promise<string> {
    const account = this.msalService.instance.getActiveAccount();
    if (!account) {
      throw new Error('No active account found. The user must be logged in.');
    }

    try {
      const response = await lastValueFrom(this.msalService.acquireTokenSilent({
        account: account,
        scopes: environment.azureApiConfig.scopes
      }));
      return response.accessToken;  // Retourne le jeton d'accès
    } catch (error) {
      console.error('Erreur lors de la récupération du jeton d\'accès', error);
      throw error;
    }
  }
}
