<div class="col-fullwidth mb-15">
    <div class="section-title">
        <img *ngIf="essentielCards.picto" src="{{essentielCards.picto.url}}" alt="{{essentielCards.picto.alt}}" width="40" height="40" />
        <h2>{{essentielCards.title}}</h2>
    </div>
</div>
<div class="layercard__wrapper">
    <h3 *ngIf="essentielCards.description" [customInnerHTML]="essentielCards.description"></h3>
    <app-flexible-flash-info *ngIf="essentielCards.flashInfo"
        [flashInfo]="essentielCards.flashInfo"></app-flexible-flash-info>
    <div class="layercard__2cols">
        @for (card of essentielCards.cards; track card.id) {
            <app-essentiel-card [card]="card"></app-essentiel-card>
        }
    </div>
</div>