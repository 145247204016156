<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible_push_service flexible-content">
        @for(service of flexible.services; track service.id) {
            <div class="flexible_push_service_item">
                <div class="flexible_push_service_img">
                    <span class="vnf-icons-{{service.fontPicto}}"></span>
                </div>
                <div class="flexible_push_service_content">
                    <h3 class="flexible_push_service_title">{{service.title}}</h3>
                    <p [customInnerHTML]="service.description"></p>
                </div>
                <a href="{{service.lien.url}}" class="flexible_push_service_link">Accéder</a>
            </div>
        }
        <!-- <div class="flexible_push_service_item">
            <div class="flexible_push_service_img">
                <img src="assets/images/flexible-content/flexible-img/picto-push.svg" alt="" />
            </div>
            <div class="flexible_push_service_content">
                <h3 class="flexible_push_service_title">Me déplacer</h3>
                <p>Option congue nihil imper diet adipiscing lorem 2026 !</p>
            </div>
            <a href="" class="flexible_push_service_link" rel="external">Accéder</a>
        </div> -->
    </div>
</div>