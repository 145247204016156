import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ToastService } from '../services/toast.service';

export const serverErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(ToastService); // Injecter ToastService

  return next(req).pipe(
    catchError((error) => {
      if (error.status >= 500 && error.status < 600) {
        const errorMessage = `Une erreur a été rencontrée, veuillez contacter votre administrateur.`;
        // Affiche un toast pour les erreurs 50X
        toastService.changeToast({ type: 'error', msg: errorMessage });
      }
      return throwError(() => error); // Relancer l'erreur pour d'autres gestionnaires
    })
  );
};
