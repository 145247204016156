import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlexibleDataVisualisation } from '../../../models/flexibles';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { DialogTextImgVideoComponent } from '../../dialog/dialog-text-img-video/dialog-text-img-video.component';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-datavisualisation',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-datavisualisation.component.html',
  styleUrl: './flexible-datavisualisation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexibleDatavisualisationComponent {
  @Input() flexible!: FlexibleDataVisualisation;
  readonly dialog = inject(MatDialog);

  openDialogDataVis(content?: string) {
    const dialogRef = this.dialog.open(DialogTextImgVideoComponent, {
      data: { content },
    });
  }
}
