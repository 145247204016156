<!-- module essentiels 2 visuels wip -->
@for (essentiel of essentiels; track essentiel.id) {
    @if (isEssentielList2Visuels(essentiel)) {
        <app-list-2-visuels [essentielList2Visuels]="essentiel" class="layout"></app-list-2-visuels>
    } @else if (isEssentielPictoList(essentiel)) {
        <app-picto-list [essentielPictoList]="essentiel" class="layout"></app-picto-list>
    } @else if (isEssentielLists1Visuel(essentiel)) {
        <app-lists-1-visuel [essentielLists1Visuel]="essentiel" class="layout"></app-lists-1-visuel>
    } @else if (isEssentielCards(essentiel)) {
        <app-essentiel-cards [essentielCards]="essentiel" class="layout"></app-essentiel-cards>
    } @else if (isEssentielMiniCards(essentiel)) {
        <app-essentiel-mini-cards [essentielMiniCards]="essentiel" class="layout"></app-essentiel-mini-cards>
    }
}