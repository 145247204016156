import { Location, NgIf } from '@angular/common';
import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { PageFille } from '../../models/page-fille';
import { ShareOption } from '../../models/share-option';
import { copyUrlToClipboard } from '../../utils/utils.service';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { DialogSendEmailComponent } from '../dialog/dialog-send-email/dialog-send-email.component';
import { FlexiblesComponent } from '../flexible/flexibles/flexibles.component';
import { ShareComponent } from '../share/share.component';
import { Link } from '../../models/link';
import { FlexibleImg, FlexibleText } from '../../models/flexibles';
import { FlexibleTextComponent } from '../flexible/flexible-text/flexible-text.component';
import { FlexibleImgComponent } from "../flexible/flexible-img/flexible-img.component";

@Component({
  selector: 'app-page-fille',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ShareComponent,
    forwardRef(() => FlexiblesComponent),
    MatExpansionModule,
    MatTabsModule,
    FlexibleTextComponent,
    NgIf,
    FlexibleImgComponent
],
  templateUrl: './page-fille.component.html',
  styleUrl: './page-fille.component.scss'
})
export class PageFilleComponent implements OnChanges {
  @Input() public content!: PageFille;
  @Input() public flexibleImg: FlexibleImg | undefined = undefined;
  breadcrumbLinks: Link[] = [{ url: '/', label: 'Accueil' }];
  shareOptions: ShareOption[] = [];
  headerFlexible: FlexibleText = { type: 'text', content: '', bColor: 'gris' };

  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.shareOptions = [
      {
        label: 'Envoyer par mail',
        onclick: () => this.openDialogShareByEmail()
      },
      {
        label: 'Copier le lien',
        onclick: () => copyUrlToClipboard()
      }
    ];
  }

  ngOnInit() {
    // create header
    this.headerFlexible.content = `<h1>${this.content?.title}</h1><div class="chapo">${this.content?.chapo}</div>`;

    // Ajout du parent dans le fil d'ariane
    this.content.parentPages?.forEach((parentPage) => {
      this.breadcrumbLinks.push({
        url: `/${parentPage.url}`,
        label: parentPage.title
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['content']) {
      this.headerFlexible.content = `<h1>${this.content?.title}</h1>`;
    }
  }


  openDialogShareByEmail(): void {
    this.dialog.open(DialogSendEmailComponent, {
      width: '100%',
      maxWidth: '740px',
      data: { slug: this.content.path },
    });
  }

  back(): void {
    this.location.back();
  }

}
