<div class="mat-mdc-dialog-header">
    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="infography-content">
    @if(content) {
        <p [customInnerHTML]="content"></p>
    }
    @if (typeInfographie === 'mediatheque' && !flexibleVideo) {
        <a *ngIf="!isPdf" href="{{infographie.url}}" target="_blank">
            <img src="{{infographie.url}}" alt="{{infographie.alt}}" />
        </a>
        <div class="pdf-viewer" *ngIf="isPdf">
            <ng2-pdfjs-viewer [pdfSrc]="infographie.url" [viewBookmark]="false" [openFile]="false" [fullScreen]="true" [print]="false"></ng2-pdfjs-viewer>
        </div>
        <p class="dialog-title" [customInnerHTML]="infographie.title"></p>
    }
    @if (typeInfographie === 'ged' && !flexibleVideo) {
        <a [href]="lien_infographie" class="btn btn--primary" rel="external" target="_blank">Voir le document</a>
        <p class="dialog-title" [customInnerHTML]="infographie.title"></p>
    }
    @if (flexibleVideo && flexibleVideo.url) {
        <app-flexible-video [flexible]="flexibleVideo"></app-flexible-video>
        @if(flexibleVideo.visuel?.title){
            <p class="dialog-title" [customInnerHTML]="flexibleVideo.title"></p>
        }
    }
    </div>
</mat-dialog-content>
@if(button?.url) {
    <mat-dialog-actions align="end">
        <a href="{{button?.url}}" class="btn btn--primary" [rel]="button?.type === 'ext'? 'external': ''" [target]="button?.type === 'ext'? '_blank': ''">En savoir plus</a>
    </mat-dialog-actions>
}
