<div class="mes-services">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <div class="news-headline-hp">
    <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-modify"></span>Autres services</h2>
  </div>

  <p class="line-1">Mes services à mettre en favori dans le menu</p>
  <p class="line-2">Je clique sur un service pour le mettre en favoris et le déclique pour l’enlever, c’est à vous.</p>

  <div class="mes-services-list">
    <div class="mes-services-list__item {{service.isFavorite? 'favorite' : ''}}" [attr.id]="'serviceItem' + i"
      *ngFor="let service of services; let i = index">
      <button class="mes-services__btn-favorite" (click)="toggleFavorite($event, service)">Bouton favori</button>
      <div class="mes-services__img" (click)="redirect(service)">
        <span class="icon vnf-icons-{{service.fontPicto}}"></span>
      </div>
      <span class="mes-services__name" [innerHTML]="service.title" (click)="redirect(service)"></span>
    </div>
  </div>
</div>