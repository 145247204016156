<span class="event--date">
        <span class="day">{{ vnfEvent.format_day }}</span>
        <span class="month">{{ vnfEvent.format_month }}</span>
        <span class="year">{{ vnfEvent.format_year }}</span>
      </span>
<div class="event--content">
    <span *ngIf="vnfEvent.dt" class="event--tag tag bg-darkblue">{{'DT ' + vnfEvent.dt}}</span>
    <p class="event--paragraph" innerHTML="{{vnfEvent.text.length > 130 ? (vnfEvent.text.slice(0, 130) + '...') :
          vnfEvent.text}}"></p>
</div>

<button #optionsOpener class="options-opener options-opener--agenda" (click)="toggleOptions(vnfEvent)">Afficher les
    options
</button>

<div #optionsMenu class="options options--agenda" [class.active]="this.isOptionsActive">
    <ul class="options_list">
        <li class="options_list--item"><a (click)="addToCalendar()" href="#">Ajouter à mon agenda</a></li>
        @if (vnfEvent.lien) {
            <li class="options_list--item"><a href="{{vnfEvent.lien.url}}" target="_blank">En savoir plus</a></li>
        }
    </ul>
</div>


<!-- Message ajouté -->
<div *ngIf="alertMessage"
     [ngClass]="{'error': alertMessage.type === 'error', 'success': alertMessage.type === 'success'}"
     class="event--alert-message">
    {{ alertMessage.text }}
</div>
