import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from '../../../pipes/safe-url.pipe';
import { FlexibleVideo } from '../../../models/flexibles';

@Component({
  selector: 'app-flexible-video',
  standalone: true,
  imports: [CommonModule, SafeUrlPipe],
  templateUrl: './flexible-video.component.html',
  styleUrl: './flexible-video.component.scss'
})
export class FlexibleVideoComponent {
  @Input() public flexible!: FlexibleVideo;
  videoUrl: SafeResourceUrl = '';

  constructor(private sanitizer: DomSanitizer, private el: ElementRef) { }

  ngOnInit() {
    if (this.flexible?.url) {
      const parentDiv = this.el.nativeElement.closest('.mat-mdc-dialog-container');
      const autoplay = parentDiv ? '1' : '0'; // Active l'autoplay seulement si la div parent a la classe

      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.getEmbedUrl(this.flexible.url, autoplay)
      );
    }
  }

  getEmbedUrl(url: string, autoplay: string): string {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return this.getYouTubeEmbedUrl(url, autoplay);
    } else if (url.includes('vimeo.com')) {
      return this.getVimeoEmbedUrl(url, autoplay);
    }
    console.error('Unsupported video URL:', url);
    return '';
  }

  getYouTubeEmbedUrl(url: string, autoplay: string): string {
    let videoId: string = '';

    if (url.includes('youtu.be')) {
      videoId = url.split('youtu.be/')[1] || '';
    } else if (url.includes('youtube.com') && !url.includes('embed')) {
      const urlParams = new URLSearchParams(new URL(url).search);
      videoId = urlParams.get('v') || '';
    } else if (url.includes('embed')) {
      videoId = url.split('/embed/')[1]?.split('?')[0] || '';
    }

    if (!videoId) {
      console.error('Video ID not found');
      return '';
    }

    return `https://www.youtube.com/embed/${videoId}?autoplay=${autoplay}&rel=0&modestbranding=1`;
  }

  getVimeoEmbedUrl(url: string, autoplay: string): string {
    console.log('Vimeo URL reçue:', url); // Debug

    // Expression régulière améliorée pour capturer l'ID Vimeo
    const match = url.match(/(?:vimeo\.com\/(?:.*\/)?|player\.vimeo\.com\/video\/)(\d+)/);
    const videoId = match ? match[1] : '';

    if (!videoId) {
      console.error('Vimeo video ID not found for URL:', url);
      return '';
    }

    const embedUrl = `https://player.vimeo.com/video/${videoId}?autoplay=${autoplay}`;
    console.log('Vimeo Embed URL générée:', embedUrl); // Debug

    return embedUrl;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const iframe: HTMLIFrameElement | null = this.el.nativeElement.querySelector('iframe');
      if (iframe) {
        iframe.onload = () => {
          iframe.focus(); // Met le focus dès que l'iframe est chargée
        };
      }
    }, 500);
  }
}
