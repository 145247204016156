<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-content col-left pb-0 pt-0">
        <h2 class="card-item-title">{{flexible.title}}</h2>
        <div class="layout gap-10">
            @for (element of flexible.elements; track element.id) {
                <div class="col-tree">
                    <div class="card-item">
                        <div class="flexible-datavisualisation {{element.style}}">
                            <div class="flexible-datavisualisation-iteraction">
                                <button (click)="openDialogDataVis(element.visuel_texte)" class="flexible-datavisualisation-opener">Accéder à la version textuelle</button>
                                <a href="{{element.url}}" class="flexible-datavisualisation-link">Lire l'intégralité</a>
                            </div>
                            <div class="flexible-datavisualisation-img">
                                <img src="{{element.visuel.url}}" alt="" />
                            </div>
                            <div class="flexible-datavisualisation-legend">
                                <p class="line-1" [customInnerHTML]="element.line1"></p>
                                <p class="line-2" [customInnerHTML]="element.line2"></p>
                                @if(element.style == "style-2" && element.line3){
                                    <p class="line-3" [customInnerHTML]="element.line3"></p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <!--
            <div class="col-tree">
                <div class="card-item">
                    <div class="flexible-datavisualisation style-1">
                        <div class="flexible-datavisualisation-iteraction">
                            <button (click)="openDialogDataVis()" class="flexible-datavisualisation-opener">Accéder à la version textuelle</button>
                            <a href="#" class="flexible-datavisualisation-link">Lire l'intégralité</a>
                        </div>
                        <div class="flexible-datavisualisation-img">
                            <img src="assets/images/flexible-content/flexible-datavis/chiffre-cle1.svg" alt="" />
                        </div>
                        <div class="flexible-datavisualisation-legend">
                            <p class="line-1">89% agents publics</p>
                            <p class="line-2">11% salariés privés</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-tree">
                <div class="card-item">
                    <div class="flexible-datavisualisation style-2">
                        <div class="flexible-datavisualisation-iteraction">
                            <button (click)="openDialogDataVis()" class="flexible-datavisualisation-opener">Accéder à la version textuelle</button>
                            <a href="#" class="flexible-datavisualisation-link">Lire l'intégralité</a>
                        </div>
                        <div class="flexible-datavisualisation-img">
                            <img src="assets/images/flexible-content/flexible-datavis/chiffre-cle2.svg" alt="" />
                        </div>
                        <div class="flexible-datavisualisation-legend">
                            <p class="line-1">500</p>
                            <p class="line-2">implantations</p>
                            <p class="line-3">sur le territoire national</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-tree">
                <div class="card-item">
                    <div class="flexible-datavisualisation style-3">
                        <div class="flexible-datavisualisation-iteraction">
                            <button (click)="openDialogDataVis()" class="flexible-datavisualisation-opener">Accéder à la version textuelle</button>
                            <a href="#" class="flexible-datavisualisation-link">Lire l'intégralité</a>
                        </div>
                        <div class="flexible-datavisualisation-img">
                            <img src="assets/images/flexible-content/flexible-datavis/chiffre-cle3.svg" alt="" />
                        </div>
                        <div class="flexible-datavisualisation-legend">
                            <p class="line-1">6 700 Km</p>
                            <p class="line-2">réseau fluviale<br /> géré par VNF</p>
                        </div>
                    </div>
                </div>
            </div>
        -->
        </div>
        <div class="card-item-footer">
            <a class="btn btn--primary" href="{{flexible.bUrl}}">{{flexible.bTitle}}</a>
        </div>
    </div>
</div>
