import { Component } from '@angular/core';

@Component({
  selector: 'app-service-intermediaire',
  standalone: true,
  imports: [],
  templateUrl: './service-intermediaire.component.html',
  styleUrl: './service-intermediaire.component.scss'
})
export class ServiceIntermediaireComponent {

}
