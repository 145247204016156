import { Component, Input } from '@angular/core';
import { FlexibleListLinks } from '../../../models/flexibles';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-list-links',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    NgClass,
    CustomInnerHtmlDirective,
  ],
  templateUrl: './flexible-list-links.component.html',
  styleUrl: './flexible-list-links.component.scss'
})
export class FlexibleListLinksComponent {
  @Input() flexible!: FlexibleListLinks;
}
