<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
<div class="flexible-push-reporting flexible-content">
  <div class="flexible-push-reporting--background"></div>
  <h2 class="flexible-push-reporting--title" [customInnerHTML]="flexible.title"></h2>
  <div class="flexible-push-reporting--container">
    <div class="flexible-push-reporting--pushs">
      @for(reporting of flexible.reportings; track reporting.id) {
        <div class="flexible-push-reporting--push">
          <div class="flexible-push-reporting--push--image">
              <img src="{{reporting.visuel.sizes?.['w506h337']}}" width="506" height="337" alt="{{reporting.visuel.alt}}"/>
          </div>
          <div class="flexible-push-reporting--push--content">
            <div class="flexible-push-reporting--push--thematic">
              <div class="thematic-image">
                <img src="assets/images/icons/talk.svg" />
              </div>
              <div class="thematic-text">
                <p [customInnerHTML]="reporting.thematique"></p>
              </div>
            </div>
            <p class="flexible-push-reporting--push--title" [customInnerHTML]="reporting.title"></p>
            <p class="flexible-push-reporting--push--text" [customInnerHTML]="reporting.content"></p>
          </div>
          <a class="flexible-reporting-link" href="{{reporting.lien.url}}" rel="{{reporting.lien.type == 'ext'? 'external' : ''}}" target="{{reporting.lien.type == 'ext'? '_blank' : ''}}">Voir plus</a>
        </div>
      }
    </div>
  </div>
</div>
</div>
