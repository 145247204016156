import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { RouterLink, RouterLinkActive } from "@angular/router";
import { SliderOutilsComponent } from "../../components/slider-outils/slider-outils.component";

@Component({
  selector: 'app-collaborateurs',
  standalone: true,
  imports: [
    CommonModule,
    SliderOutilsComponent,
    ReactiveFormsModule,
    MatRadioModule,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './collaborateurs.component.html',
  styleUrl: './collaborateurs.component.scss'
})
export class CollaborateursComponent {

  isFiltersActive: boolean = false;
  isOptionsActive: boolean = false;

  openFilters(): void {
    this.isFiltersActive = !this.isFiltersActive;
  }

  @ViewChild('optionsOpener', { static: false }) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu', { static: false }) optionsMenu!: ElementRef;

  constructor() {}

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener && this.optionsMenu) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      const clickedInsideMenu = this.optionsMenu.nativeElement.contains(targetElement);

      if (!clickedInsideOpener && !clickedInsideMenu) {
        this.isOptionsActive = false;
      }
    }
  }
}
