import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MesAppsService {

  constructor() { }

  private mesAppsSource = new BehaviorSubject<any>(null);
  currentMesApps = this.mesAppsSource.asObservable();

  changeMesApps(data: any) {
    this.mesAppsSource.next(data);
  }
}
