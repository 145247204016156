import {Component, Input} from '@angular/core';
import {EssentielEntry} from "../../../models/essentiels";
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-entries',
  standalone: true,
  imports: [
    CustomInnerHtmlDirective
  ],
  templateUrl: './entries.component.html',
  styleUrl: './entries.component.scss'
})
export class EntriesComponent {
  @Input() public entries!: EssentielEntry[];
  @Input() public iconType: 'diamond' | 'triangle' | 'circle' = 'diamond';
}
