import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { Subject, takeUntil } from "rxjs";
import { filter } from "rxjs/operators";
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { AuthService } from "./services/auth.service";
import { environment } from "../environments/environment";
import { DepartmentService } from "./services/department.service";
import { NgIf } from "@angular/common";
import { ToastService, AngularToastifyModule } from 'angular-toastify'; 
import { ToastService as ToastS } from './services/toast.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    SidenavComponent,
    RouterOutlet,
    NgIf,
    AngularToastifyModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'VNF & Moi';
  isLoggedIn = false;
  isPreviewRoute = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private departmentService: DepartmentService,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private toastS: ToastS
  ) {
  }

  ngOnInit() {
    // Ecoute le statut isLoggedIn de loginLogoutService
    this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    // Subscribing to handleRedirectObservable before any other functions
    // both initializes the application and ensures redirects are handled
    this.msalService.handleRedirectObservable().subscribe();

    this.toastS.currentToast.subscribe((toast) => {
      toast && toast.type == "error" && this.toastService.error(toast.msg);
      toast && toast.type == "warn" && this.toastService.warn(toast.msg);
      toast && toast.type == "success" && this.toastService.success(toast.msg);
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        const isLoggedIn = this.setLoggedIn();
        isLoggedIn && this.departmentService.loadDepartment();
      });

    // Ecoute les actions de login et logout
    // De cette manière on peut placer les boutons login/logout n'importe où dans l'application
    // En utilisant le service loginLogoutService
    this.authService.loginAction$.subscribe(() => {
      this.loginRedirect();
    });
    this.authService.logoutAction$.subscribe(() => {
      this.logoutRedirect();
    });


    // Nettoyer le localStorage à l'arrivée sur l'application
    this.cleanLocalStorage();

    // Nettoyer le localStorage à chaque changement de route
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._destroying$)
      )
      .subscribe((event) => {
        this.isPreviewRoute = event.urlAfterRedirects.startsWith('/preview');
        this.cleanLocalStorage();
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoggedIn() {
    let loggedIn = this.msalService.instance.getAllAccounts().length > 0;
    this.authService.setLoggedIn(
      loggedIn
    );
    return loggedIn;
  }


  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  logoutRedirect() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      this.msalService.logout({
        account: activeAccount
      });
    }
  }

  isAuthenticated(): boolean {
    const accounts = this.msalService.instance.getAllAccounts();
    return accounts.length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  cleanLocalStorage(): void {
    const currentTime = new Date().getTime();
    const expirationTime = environment.cacheExpiration * 1000;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        const value = localStorage.getItem(key);

        if (value) {
          try {
            // Tenter de parser la valeur en JSON
            const parsedValue = JSON.parse(value);

            // Vérifier si la clé "cacheDate" existe au premier niveau
            if (parsedValue?.cacheDate) {
              const cacheDate = new Date(parsedValue.cacheDate).getTime();
              // Vérifier si l'élément et supérieur au délai d'expiration
              if (currentTime - cacheDate > expirationTime) {
                environment.enableLogging && console.log(`Deleting ${key} from localStorage (expired)`); // Log de la suppression
                localStorage.removeItem(key); // Supprimer l'élément
              }
            }
          } catch (e) {
          }
        }
      }
    }
  }


}
