import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FlexibleVideoComponent } from '../../flexible/flexible-video/flexible-video.component';
import { FlexibleVideo } from '../../../models/flexibles';
import { Visuel } from '../../../models/visuel';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-dialog-text-img-video',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, FlexibleVideoComponent, CustomInnerHtmlDirective],
  templateUrl: './dialog-text-img-video.component.html',
  styleUrl: './dialog-text-img-video.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTextImgVideoComponent {
  title!: string;
  content!: string;
  url!: string;
  flexibleVideo!: FlexibleVideo;
  visuel!: Visuel;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    this.url = this.data.url;
    this.visuel = this.data.visuel;
    this.flexibleVideo = this.data.flexibleVideo;
  }
}
