import {Component, Input} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BlocRedirectionComponent} from '../../components/bloc-redirection/bloc-redirection.component';
import {BoiteAOutilsComponent} from '../../components/boite-a-outils/boite-a-outils.component';
import {EssentielListComponent} from "../../components/essentiel/essentiel-list/essentiel-list.component";
import {HeaderComponent} from '../../components/header/header.component';
import {LoadingOverlayComponent} from '../../components/loading-overlay/loading-overlay.component';
import {ZoomSurComponent} from '../../components/zoom-sur/zoom-sur.component';
import {Essentiel,} from '../../models/essentiels';
import {Tool} from '../../models/tool';
import {Zoom} from '../../models/zoom';
import {ApiService} from '../../services/api.service';
import {formatEssentiel, formatTools, formatZoom, useCache} from '../../utils/utils.service';
import {SearchbarComponent} from "../../components/search/searchbar/searchbar.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-qui-somme-nous',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    HeaderComponent,
    ZoomSurComponent,
    BoiteAOutilsComponent,
    BlocRedirectionComponent,
    EssentielListComponent,
    SearchbarComponent,
    NgIf
  ],
  templateUrl: './qui-somme-nous.component.html',
  styleUrl: './qui-somme-nous.component.scss'
})
export class QuiSommeNousComponent {
  @Input() preview: boolean = false;
  public isLoading: boolean = false;
  identite: any;
  zoomsLien: Zoom[] = [];
  zoomsVideo: Zoom[] = [];
  essentiels: Essentiel[] = [];
  tools: Tool[] = [];

  constructor(private apiService: ApiService) {
  }

  async ngOnInit(): Promise<void> {

    useCache('qui-somme-nous', this, async () => {
      await this.getIdentite();
    }, () => {
      return {
        identite: this.identite,
        zoomsLien: this.zoomsLien,
        zoomsVideo: this.zoomsVideo,
        essentiels: this.essentiels,
        tools: this.tools
      }
    });
  }

  async getIdentite() {
    const {body, headers} = this.preview ? await this.apiService.getPreview({
      id: 200,
      postType: 'page'
    }) : await this.apiService.getIdentite();
    environment.enableLogging && console.log(body, headers);
    // transform value
    this.zoomsLien = formatZoom(body?.acf?.bloc_zooms_lien_video?.['zooms-lien'], 'url');
    this.zoomsVideo = formatZoom(body?.acf?.bloc_zooms_lien_video?.['zooms-video'], 'video');
    this.essentiels = formatEssentiel(body?.acf?.contenus_modules_essentiel, 'diamond');
    this.tools = formatTools(body?.acf?.module_boite_a_outils);

    this.identite = body;
    environment.enableLogging && console.log(this.identite);
  }
}
