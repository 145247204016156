<div class="col-fullwidth mb-15">
    <div class="section-title">
        <img *ngIf="essentielMiniCards.picto" src="{{essentielMiniCards.picto.url}}"
            alt="{{essentielMiniCards.picto.alt}}" width="40" height="40" />
        <h2>{{essentielMiniCards.title}}</h2>
    </div>
</div>
<div class="layercard__wrapper">
    <p *ngIf="essentielMiniCards.description" [customInnerHTML]="essentielMiniCards.description"></p>
    <div class="list__card">
        @for (card of essentielMiniCards.cards; track card.id) {
            <app-essentiel-mini-card class="tools_list--item-wrapper" [miniCard]="card"></app-essentiel-mini-card>
        }
    </div>
    @if(essentielMiniCards.cardComplementaire) {
        <div class="card__item">
            <div class="card__item__image">
                <div class="card__item__image">
                    <picture>
                      <source *ngIf="essentielMiniCards.cardComplementaire?.visuel.sizes?.['w390h280']" 
                              srcset="{{essentielMiniCards.cardComplementaire.visuel.sizes['w390h280']}}" 
                              width="390" height="280"
                              media="(max-width: 450px)" />
                      <source *ngIf="essentielMiniCards.cardComplementaire?.visuel.sizes?.['w600h130']" 
                              srcset="{{essentielMiniCards.cardComplementaire.visuel.sizes['w600h130']}}" 
                              width="600" height="130"
                              media="(max-width: 1600px) and (min-width: 1343px)" />
                      <source *ngIf="essentielMiniCards.cardComplementaire?.visuel.sizes?.['w182h130']" 
                              srcset="{{essentielMiniCards.cardComplementaire.visuel.sizes['w182h130']}}" 
                              width="182" height="130"
                              media="(min-width: 768px) and (max-width: 1342px)" />
                      <source *ngIf="essentielMiniCards.cardComplementaire?.visuel.sizes?.['w600h130']" 
                              srcset="{{essentielMiniCards.cardComplementaire.visuel.sizes['w600h130']}}" 
                              width="600" height="130"
                              media="(max-width: 767px)" />
                      <img *ngIf="essentielMiniCards.cardComplementaire?.visuel"
                           src="{{essentielMiniCards.cardComplementaire.visuel.url}}"
                           width="182" height="130"
                           alt="{{essentielMiniCards.cardComplementaire.visuel.alt}}" />
                    </picture>
                  </div>
            </div>
            <div class="card__item__content">
                <h3 class="card__item__title">{{essentielMiniCards.cardComplementaire.title}}</h3>
                <p class="card__item__text" *ngIf="essentielMiniCards.cardComplementaire.description" [customInnerHTML]="essentielMiniCards.cardComplementaire.description"></p>
                <a class="list__card__item__link"
                   href="{{essentielMiniCards.cardComplementaire.lien.url}}"
                   [rel]="essentielMiniCards.cardComplementaire.lien.type === 'ext'? 'external': ''"
                   [target]="essentielMiniCards.cardComplementaire.lien.type === 'ext'? '_blank': ''">Accéder</a>
            </div>
        </div>
    }
</div>