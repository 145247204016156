<div class="news-headline__item {{actualite.important? 'important' : ''}}"
    *ngFor="let actualite of actualites; let i = index">
    <a class="news-headline__link" *ngIf="actualite.lien.url" href="{{ '/' + actualite.lien.url}}">En savoir plus</a>
    <div class="news-headline__img">
        <div class="news-headline__date">
            <time>
                <span class="news-headline__day">{{actualite.date.format_day}}</span>
                <span class="news-headline__month">{{actualite.date.format_month}}</span>
                <span class="news-headline__year">{{actualite.date.format_year}}</span>
            </time>
        </div>
        <img src="{{actualite.visuel.url}}" alt="{{actualite.visuel.alt}}">
    </div>
    <div class="news-headline__content">

        <button class="options-opener news-headline__options--opener" (click)="toggleHighlightOptions(i)">Activer les
            options</button>

        <div class="options news-headline__options" [class.active]="isHightlightItemOptionsActive[i]">
            <button class="options--mobile_closer" (click)="toggleHighlightOptions(i)">Fermer les options</button>
            <ul class="options_list">
                <li class="options_list--item"><a (click)="openDialogShareByEmail(actualite.lien.url)">Envoyer par mail</a></li>
                <li class="options_list--item"><a (click)="copyUrlToClipboard(actualite.lien.url)">Copier le lien</a></li>
                <li class="options_list--item" *ngIf="actualite.lien.url"><a href="{{ '/' + actualite.lien.url}}">En savoir plus</a></li>
            </ul>
        </div>

        <h4 class="news-headline__title" [innerHTML]="actualite.title"></h4>
        @if(actualite.important) {
            <span class="news-headline__important">Important</span>
        }

        <div class="tag_list">
            <span class="tag bg-darkblue" *ngIf="actualite.dt"  [innerHTML]="'DT ' + actualite.dt"></span>
            <span class="tag bg-darkblue" *ngIf="actualite.perimetre === 'Siège'" [innerHTML]="'Siège'"></span>
            <span class="tag bg-blue" *ngIf="actualite.theme"  [innerHTML]="actualite.theme"></span>
        </div>
        <p class="news-headline__text" [innerHTML]="actualite.chapo"></p>
    </div>
    <a class="news-headline__link" *ngIf="actualite.lien.url" href="{{ '/' + actualite.lien.url}}">En savoir plus</a>
</div>
