import { NgFor, NgIf } from '@angular/common';
import { Component, HostListener, inject, Input } from '@angular/core';
import { Actualite } from '../../models/actualite';
import { copyUrlToClipboard } from '../../utils/utils.service';
import { DialogSendEmailComponent } from '../dialog/dialog-send-email/dialog-send-email.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-actualites-a-la-une',
  standalone: true,
  imports: [NgFor, MatDialogModule, NgIf],
  templateUrl: './actualites-a-la-une.component.html',
  styleUrl: './actualites-a-la-une.component.scss'
})
export class ActualitesALaUneComponent {
  @Input() actualites: Actualite[] = [];
  isHightlightItemOptionsActive: boolean[] = [];
  readonly dialog = inject(MatDialog);
  currentActiveIndex: number | null = null;

  toggleHighlightOptions(index: number): void {
    if (this.currentActiveIndex === index) {
      this.isHightlightItemOptionsActive[index] = false;
      this.currentActiveIndex = null;
    } else {
      this.isHightlightItemOptionsActive.fill(false);
      this.isHightlightItemOptionsActive[index] = true;
      this.currentActiveIndex = index;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    if (
      this.currentActiveIndex !== null &&
      !targetElement.closest(`.news-headline__item:nth-child(${this.currentActiveIndex + 1}) .news-headline__options--opener`) &&
      !targetElement.closest(`.news-headline__item:nth-child(${this.currentActiveIndex + 1}) .options_list--item`)
    ) {
      this.isHightlightItemOptionsActive[this.currentActiveIndex] = false;
      this.currentActiveIndex = null;
    }
  }

  openDialogShareByEmail(url: string): void {
    const dialogRef = this.dialog.open(DialogSendEmailComponent, {
      width: '100%',
      maxWidth: '740px',
      data: { slug: url },
    });
  }

  copyUrlToClipboard(url: string) {
    copyUrlToClipboard(window.location.origin + '/' + url);
  }
}

