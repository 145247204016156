<div class="mat-mdc-dialog-header">
    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button></div>
  
  <mat-dialog-content class="mat-typography">
    <p class="modale-email-title">À qui souhaitez-vous transmettre cet e-mail ?</p>
  
      <mat-form-field appearance="outline">
        <mat-label>Adresse e-mail</mat-label>
        <input matInput type="email" placeholder="exemple@vnf.fr" [ngClass]="{'is-invalid': isInvalid}" required #emailInput>
      </mat-form-field>
      <div class="invalid-feedback" *ngIf="isInvalid">
        Veuillez saisir un email valide.
      </div>
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <a (click)="sendEmail(emailInput.value)" class="btn btn--secondary">Envoyer</a>
  </mat-dialog-actions>
  