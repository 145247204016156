@if (header_type == 0) {
<div class="col-fullwidth mb-60">

    <div class="header-page">
        <div class="header-page--links {{type}}">
            <a class="link-sup" href="{{sup_link}}">{{sup}}</a>
            <a class="link-inf" href="{{inf_link}}">{{inf}}</a>
        </div>

        <div class="header-page--content">
            <h1 class="header-page--title">{{title}}</h1>
            <h1 class="header-page--text">
                @if (type == 'diamond') {
                {{chapoIdentite}}
                } @else if (type == 'triangle') {
                {{chapoCap}}
                } @else if(type == 'circle') {
                {{chapoQuotidien}}
                }
            </h1>
        </div>
    </div>

</div>
}
@else if(header_type == 1) {

}