<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-push-reporting--with-title flexible-content">
        <h2 class="flexible-push-reporting--title" *ngIf="flexible.title" [customInnerHTML]="flexible.title"></h2>
        @for (dossier of flexible.dossiers; track dossier.id) {
            <div class="flexible-push-reporting--push">
                <div class="flexible-push-reporting--push--image">
                    <picture>
                        <source srcset="{{dossier.visuel.url}}" width="506" height="337" />
                        <img src="{{dossier.visuel.url}}" width="506" height="337" alt="{{dossier.visuel.alt}}" />
                    </picture>
                </div>
                <div class="flexible-push-reporting--push--content">
                    <div class="flexible-push-reporting--push--thematic">
                        <div class="thematic-image">
                            <img src="assets/images/icons/talk.svg" />
                        </div>
                        <div class="thematic-text">
                            <p>{{dossier.thematique}}</p>
                        </div>
                    </div>
                    <p class="flexible-push-reporting--push--title" [customInnerHTML]="dossier.title"></p>
                    <p class="flexible-push-reporting--push--text" [customInnerHTML]="dossier.content"></p>
                </div>
                <a href="{{dossier.lien.url}}" class="flexible-push-reporting--link" rel="{{dossier.lien.type == 'ext'? 'external' : ''}}" target="{{dossier.lien.type == 'ext'? '_blank' : ''}}">
                    {{dossier.title || "Lien vers le contenu"}}
                </a>
            </div>
        }
        <!--
        <div class="flexible-push-reporting--push">
            <div class="flexible-push-reporting--push--image">
                <picture>
                    <source srcset="https://picsum.photos/506/337" width="506" height="337"/>
                    <img src="https://picsum.photos/506/337" width="506" height="337" alt="Highlight article image"/>
                </picture>
            </div>
            <div class="flexible-push-reporting--push--content">
                <div class="flexible-push-reporting--push--thematic">
                <div class="thematic-image">
                    <img src="assets/images/icons/talk.svg" />
                </div>
                <div class="thematic-text">
                    <p>Thématique 1</p>
                </div>
                </div>
                <p class="flexible-push-reporting--push--title">André 34 ans, lorem ipsum dolore sit ipsum dolore sit amet !</p>
                <p class="flexible-push-reporting--push--text">Les conférences se tiendront en parallèle aux rendez-vous d'affaires et seront l'occasion pour l'ensemble des professionnels marché, donneurs d'ordres et prestataires, d'accéder (…)</p>
            </div>
        </div>
        -->
    </div>
</div>
