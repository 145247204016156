import { Component, Input, forwardRef } from '@angular/core';
import { MatOption } from "@angular/material/core";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { MatTabsModule } from '@angular/material/tabs';
import { FlexibleOnglets } from '../../../models/flexibles';
import { FlexiblePushPageComponent } from "../../flexible/flexible-push-page/flexible-push-page.component";
import { FlexibleVideoComponent } from "../../flexible/flexible-video/flexible-video.component";
import { FlexibleFlashInfoComponent } from '../flexible-flash-info/flexible-flash-info.component';
import { FlexibleHighlightCardComponent } from '../flexible-highlight-card/flexible-highlight-card.component';
import { FlexibleImgComponent } from '../flexible-img/flexible-img.component';
import { FlexiblesComponent } from '../flexibles/flexibles.component';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule

@Component({
  selector: 'app-flexible-onglets',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatTabsModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    FlexibleImgComponent,
    FlexibleHighlightCardComponent,
    FlexibleFlashInfoComponent,
    FlexibleVideoComponent,
    FlexiblePushPageComponent,
    forwardRef(() => FlexiblesComponent),
    CommonModule
  ],
  templateUrl: './flexible-onglets.component.html',
  styleUrl: './flexible-onglets.component.scss'
})
export class FlexibleOngletsComponent {
  @Input() flexible!: FlexibleOnglets;
}
