<div class="searchbar-layout">
    <div class="col-fullwidth">
        <app-searchbar [query]="searchQuery" (searchTriggered)="onSearchTrigerred($event)">
        </app-searchbar>
    </div>
</div>

<div class="main-layout no-padding-top">
    <div #searchContainer class="col-left mb-60">
        <div class="search-headline">
            <div class="d-flex">
                <button class="previous-page" (click)="back()">Page précédente</button>
                <h2 class="title-icons-underline">{{ totalResults === 0 ? 'Aucun' : totalResults }}
                    résultat{{ totalResults > 1 ? 's' : '' }}</h2>
            </div>
            <button (click)="openMobileFiltersBar()" class="search-filter-opener"><span
                    class="vnf-icons-icon-m-filtres-a-outline"></span>Filtres
            </button>
        </div>
        <div class="mb-15">
            <app-filters-bar *ngIf="queryLaunched && filtres" [filtres]="filtres"
                [isMobileFilterBarOpen]="isMobileFilterBarOpen"
                (isMobileFilterBarOpenChange)="handleMobileFilterBarChange($event)"
                (searchTriggerred)="newFiltersSearch()"></app-filters-bar>
        </div>
        <app-search-results [documents]="results"></app-search-results>
        <app-search-pagination *ngIf="totalResults > 0" [currentPage]="currentPage" [totalItems]="totalResults"
            [maxPagesToShow]="5" (paginationChange)="onPaginationChange($event)"></app-search-pagination>

        <div class="contact-cta" *ngIf="totalResults > 0">
            <span class="icon vnf-icons-icon-missing-file"></span>
            <p>Vous ne trouverez pas ce que vous cherchez ?</p>
            <button (click)="documentRequest()" class="btn btn--primary">Contactez les équipes</button>
        </div>

        <div class="no-results-cta" *ngIf="totalResults == 0">
            <span class="icon vnf-icons-icon-missing-file"></span>
            <p>Votre recherche n’a donné aucun résultat.</p>
            <button (click)="clear()" class="btn btn--secondary">Déselectionner les filtres</button>
            <button (click)="documentRequest()" class="btn btn--primary">Contactez les équipes</button>
        </div>
    </div>

    <div class="col-right mb-60" *ngIf="suggestions.length">
        <div class="suggestions">
            <p>Suggestions</p>
            <div class="suggestions__list">
                <div class="suggestions__item" *ngFor="let suggestion of suggestions; let i = index"
                    (click)="onClickSuggestion(suggestion.key)">
                    <span class="icon vnf-icons-icon-loupe-outline"></span>
                    <div class="suggestions__image" *ngIf="suggestion.visuel.url">
                        <img width="365" height="129" src="{{suggestion.visuel.url}}" alt="placeholder">
                    </div>
                    <p [innerHTML]="suggestion.title"></p>
                </div>
            </div>
        </div>
    </div>
</div>