<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
<div class="flexible-push-reporting flexible-content">
  <div class="flexible-push-reporting--background"></div>
  <h2 class="flexible-push-reporting--title" [customInnerHTML]="flexible.title"></h2>
  <div class="flexible-push-reporting--container">
    <div class="flexible-push-reporting--pushs">
      @for(reporting of flexible.reportings; track reporting.id) {
        <div class="flexible-push-reporting--push">
          <div class="flexible-push-reporting--push--image">
              <img src="{{reporting.visuel.sizes?.['w506h337']}}" width="506" height="337" alt="{{reporting.visuel.alt}}"/>
          </div>
          <div class="flexible-push-reporting--push--content">
            <div class="flexible-push-reporting--push--thematic">
              <div class="thematic-image">
                <img src="assets/images/icons/talk.svg" />
              </div>
              <div class="thematic-text">
                <p [customInnerHTML]="reporting.thematique"></p>
              </div>
            </div>
            <p class="flexible-push-reporting--push--title" [customInnerHTML]="reporting.title"></p>
            <p class="flexible-push-reporting--push--text" [customInnerHTML]="reporting.content"></p>
          </div>
        </div>
      }

      <!--
      <div class="flexible-push-reporting--push">
        <div class="flexible-push-reporting--push--image">
          <picture>
            <source srcset="https://picsum.photos/506/337" width="506" height="337"/>
            <img src="https://picsum.photos/506/337" width="506" height="337" alt="Highlight article image"/>
          </picture>
        </div>
        <div class="flexible-push-reporting--push--content">
          <div class="flexible-push-reporting--push--thematic">
            <div class="thematic-image">
              <img src="assets/images/icons/talk.svg" />
            </div>
            <div class="thematic-text">
              <p>Thématique 1</p>
            </div>
          </div>
          <p class="flexible-push-reporting--push--title">André 34 ans, lorem ipsum dolore sit ipsum dolore sit amet !</p>
          <p class="flexible-push-reporting--push--text">Les conférences se tiendront en parallèle aux rendez-vous d'affaires et seront l'occasion pour l'ensemble des professionnels marché, donneurs d'ordres et prestataires, d'accéder (…)</p>
        </div>
      </div>

      <div class="flexible-push-reporting--push">
        <div class="flexible-push-reporting--push--image">
          <picture>
            <source srcset="https://picsum.photos/506/337" width="506" height="337"/>
      <img src="https://picsum.photos/506/337" width="506" height="337" alt="Highlight article image"/>
          </picture>
        </div>
        <div class="flexible-push-reporting--push--content">
          <div class="flexible-push-reporting--push--thematic">
            <div class="thematic-image">
              <img src="assets/images/icons/talk.svg" />
            </div>
            <div class="thematic-text">
              <p>Thématique qui passe sur 2 lignes si besoin</p>
            </div>
          </div>
          <p class="flexible-push-reporting--push--title">Titre du reporatge métier, lorem ipsum dolore sit ipsum dolore sit amet…</p>
          <p class="flexible-push-reporting--push--text">Les Voies Navigables de France (VNF) ont lancé via leur Mission Mécénat et la Fondation du Patrimoine une souscription pour la (…)</p>
        </div>
      </div>

      <div class="flexible-push-reporting--push">
        <div class="flexible-push-reporting--push--image">
          <picture>
            <source srcset="https://picsum.photos/506/337" width="506" height="337"/>
            <img src="https://picsum.photos/506/337" width="506" height="337" alt="Highlight article image"/>
          </picture>
        </div>
        <div class="flexible-push-reporting--push--content">
          <div class="flexible-push-reporting--push--thematic">
            <div class="thematic-image">
              <img src="assets/images/icons/talk.svg" />
            </div>
            <div class="thematic-text">
              <p>Thématique 2</p>
            </div>
          </div>
          <p class="flexible-push-reporting--push--title">André 34 ans, lorem ipsum dolore sit ipsum dolore sit amet !</p>
          <p class="flexible-push-reporting--push--text">Les conférences se tiendront en parallèle aux rendez-vous d'affaires et seront l'occasion pour l'ensemble des professionnels marché, donneurs d'ordres et prestataires, d'accéder (…)</p>
        </div>
      </div>
      -->

    </div>
  </div>
</div>
</div>