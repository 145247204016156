import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Title } from '@angular/platform-browser';
import { formatPageFille, sanitizeTitle, sendToast, useCache } from '../../utils/utils.service';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { PageFilleComponent } from '../../components/page-fille/page-fille.component';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-sujet',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    PageFilleComponent,
    NgIf
  ],
  templateUrl: './sujet.component.html',
  styleUrl: './sujet.component.scss'
})
export class SujetComponent {
  @Input() preview: boolean = false
  @Input() postId: number | null = null;
  public isLoading: boolean = false;
  fullPath: string = '';
  slug: string = '';
  page: any = null;

  constructor(private router: Router, private apiService: ApiService, private titleService: Title) {
  }

  async ngOnInit(): Promise<void> {
    this.fullPath = this.router.url;
    this.slug = this.router.url?.split('/').pop() ?? '';

    useCache(
      this.router.url,
      this,
      async () => {
        await this.getSujet();
      },
      () => {
        return { page: this.page }
      }
    );
  }

  async getSujet(): Promise<void> {
    try {
      const { body } = this.preview && this.postId ?
        await this.apiService.getPreview({ id: this.postId, postType: 'sujets' })
        : await this.apiService.getSujet({ slug: this.slug })
      environment.enableLogging && console.log(`Returned API body for path: ${this.fullPath}`, body);

      // Get the content, depending on the mode
      const page = this.preview ? body : body[0];

      this.page = formatPageFille(page, this.fullPath);
      // Set the title of the page if page is in cache
      this.page?.title && this.titleService.setTitle(sanitizeTitle(this.page.title));
    } catch (e) {
      console.error('Error in getSujet : ', e);
      sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
    }
  }
}
