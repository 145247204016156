<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <mat-accordion class="flexible-panel flexible-content pt-0 pb-0">
        @for(accordion of flexible.accordions; track accordion.id) {
            <mat-expansion-panel (opened)="panelOpenState.set(false)" (closed)="panelOpenState.set(true)">
                <mat-expansion-panel-header>
                    <mat-panel-title [customInnerHTML]="accordion.title"></mat-panel-title>
                </mat-expansion-panel-header>
                @for (flexible of accordion.flexibles; track flexible.id) {
                    @if (isFlexibleText(flexible)) {
                        <app-flexible-text [flexible]="flexible" class="layout"></app-flexible-text>
                    } @else if (isFlexibleImg(flexible)) {
                        <app-flexible-img [flexible]="flexible" class="layout"></app-flexible-img>
                    } @else if (isFlexibleListLinks(flexible)) {
                        <app-flexible-list-links [flexible]="flexible" class="layout"></app-flexible-list-links>
                    }
                }
            </mat-expansion-panel>
        }
    </mat-accordion>
</div>

<!--
<mat-accordion class="flexible-panel flexible-content pt-0 pb-0">
    <mat-expansion-panel (opened)="panelOpenState.set(false)" (closed)="panelOpenState.set(true)">
    <mat-expansion-panel-header>
        <mat-panel-title>Suivi des marchés</mat-panel-title>
    </mat-expansion-panel-header>
    <p>Contenu de l'accordéon suivi des marchés.</p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState.set(false)" (closed)="panelOpenState.set(true)">
        <mat-expansion-panel-header>
            <mat-panel-title>Suivi des marchés</mat-panel-title>
        </mat-expansion-panel-header>
        <p>Contenu de l'accordéon procédures internes.</p>
        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sollicitudin eleifend fringilla. Phasellus dapibus mollis purus, quis porta dolor egestas id. Duis elementum orci eget lorem bibendum, sed malesuada tellus efficitur. Maecenas tempus ullamcorper pretium. Praesent orci magna, imperdiet non dui vitae, imperdiet auctor sapien. Fusce in velit justo. Nunc tincidunt nisl sit amet sapien dignissim, ac rhoncus urna molestie. Vestibulum viverra, quam non dictum convallis, neque dui molestie eros, eu molestie purus metus ut lacus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed at sem enim. Morbi felis dolor, ullamcorper a sagittis quis, volutpat eget eros. Aliquam a varius libero. Duis enim dui, feugiat quis nibh ac, tincidunt imperdiet ante. Cras rutrum nisl at tortor bibendum tincidunt. In nec euismod velit.</p>
        <p>Praesent aliquet, ipsum et efficitur cursus, libero purus aliquam erat, sed auctor enim leo a quam. Nulla imperdiet lectus sit amet vestibulum tincidunt. Donec sed tellus sit amet massa lacinia euismod sed quis risus. Phasellus eget enim sodales ipsum molestie luctus in sed odio. Vivamus dictum magna magna, sed rutrum turpis sodales et. Proin id feugiat lorem, sit amet cursus orci. Integer in dictum turpis, non congue leo. Nullam a eleifend dui. Mauris at nunc a velit pellentesque pharetra. Quisque a lacus vitae ex luctus tincidunt nec quis nisi.</p>
        <p>Integer nunc tellus, laoreet sit amet egestas et, congue vel libero. Donec iaculis faucibus magna a convallis. Donec elit nulla, sagittis quis nisi eu, aliquet aliquet ipsum. Duis vitae sagittis ante. Aliquam et aliquet enim, vel lobortis ipsum. Nulla euismod mauris eu nisl luctus, at ornare nulla finibus. Proin bibendum turpis condimentum pharetra cursus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer accumsan quam libero, ultrices gravida elit finibus nec. </p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
    <mat-expansion-panel-header>
        <mat-panel-title>Marchés mutualisés</mat-panel-title>
    </mat-expansion-panel-header>
    <p>I'm visible because I am open</p>
    </mat-expansion-panel>
</mat-accordion>
-->
