import {NgFor, NgIf} from '@angular/common';
import {Component, HostListener, inject, Input} from '@angular/core';
import {Actualite} from '../../models/actualite';
import {DialogSendEmailComponent} from '../dialog/dialog-send-email/dialog-send-email.component';
import {copyUrlToClipboard} from '../../utils/utils.service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-list-actualites',
  standalone: true,
  imports: [NgFor, NgIf],
  templateUrl: './list-actualites.component.html',
  styleUrl: './list-actualites.component.scss'
})
export class ListActualitesComponent {
  @Input() actualitesALaUne: Actualite[] = [];
  @Input() actualites: Actualite[] = [];
  isFeedItemOptionsActive: boolean[] = [];
  readonly dialog = inject(MatDialog);
  currentActiveIndex: number | null = null;

  toggleFeedOptions(index: number): void {
    environment.enableLogging && console.log(this.currentActiveIndex);
    if (this.currentActiveIndex === index) {
      this.isFeedItemOptionsActive[index] = false;
      this.currentActiveIndex = null;
    } else {
      this.isFeedItemOptionsActive.fill(false);
      this.isFeedItemOptionsActive[index] = true;
      this.currentActiveIndex = index;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    if (
      this.currentActiveIndex !== null &&
      !targetElement.closest(`.news-feed__item:nth-child(${this.currentActiveIndex + 1}) .news-feed__options--opener`) &&
      !targetElement.closest(`.news-feed__item:nth-child(${this.currentActiveIndex + 1}) .options_list--item`)
    ) {
      this.isFeedItemOptionsActive[this.currentActiveIndex] = false;
      this.currentActiveIndex = null;
    }
  }

  hideActualite(actualite: Actualite): boolean {
    return this.actualitesALaUne.some(a => a.id === actualite.id);
  }

  openDialogShareByEmail(url: string): void {
    this.dialog.open(DialogSendEmailComponent, {
      width: '100%',
      maxWidth: '740px',
      data: {slug: url},
    });
  }

  copyUrlToClipboard(url: string) {
    copyUrlToClipboard(window.location.origin + '/' + url);
    if (this.currentActiveIndex !== null) {
      this.isFeedItemOptionsActive[this.currentActiveIndex] = false;
      this.currentActiveIndex = null;
    }
  }
}
