import {ChangeDetectionStrategy, Input, Component} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import {MatInputModule} from '@angular/material/input';
import { FlexibleFormContact } from '../../../models/flexibles';

@Component({
  selector: 'app-flexible-form-contact',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './flexible-form-contact.component.html',
  styleUrl: './flexible-form-contact.component.scss'
})
export class FlexibleFormContactComponent {
  @Input() public flexible!: FlexibleFormContact;
}
