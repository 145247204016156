
<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">

    <div class="flexible-push-page__wrapper flexible-content full-width" [ngClass]="{'d-none': isSliderVisible}">
        @for (element of flexible.elements; track element.id) {
            <div class="flexible-push-card {{element.type == 'document'? 'flexible-push-doc' : 'flexible-push-page'}}">
                <div class="flexible-push-card__top">
                    <div class="flexible-push-card__img">
                        <img src="{{element.type == 'document' ? 'assets/images/flexible-content/flexible-card-doc/doc-big.svg' : element.visuel?.sizes?.['w210h130']}}" width="{{element.type == 'document'? '30' : '210'}}" height="{{element.type == 'document'? '42' : '130'}}" alt="{{element.visuel?.alt}}" />
                    </div>
                    <div class="flexible-push-card__tag__container">
                        <span class="flexible-push-card__tag__thematic" *ngIf="element.tagSecondary" [customInnerHTML]="element.tagSecondary"></span>
                        <span class="flexible-push-card__tag" *ngIf="element.tagMain" [customInnerHTML]="element.tagMain"></span>
                    </div>
                </div>
                <div class="flexible-push-card__bottom">
                    <p [customInnerHTML]="element.title"></p>
                </div>
                <a class="flexible-push-card__link" href="{{element.lien.url}}" rel="{{element.lien.type == 'ext'? 'external' : ''}}" target="{{element.lien.type == 'ext'? '_blank' : ''}}">Accéder</a>
            </div>
        }
    </div>

    <div class="flexible-push-page__wrapper flexible-push-page-slider flexible-content full-width" [ngClass]="{'d-none': !isSliderVisible}">
        <swiper-container #swiperContainer class="swiper-container" init="false">
            @for (element of flexible.elements; track element.id) {
                <swiper-slide>
                    <div class="flexible-push-card {{element.type == 'document'? 'flexible-push-doc' : 'flexible-push-page'}}">
                        <div class="flexible-push-card__top">
                            <div class="flexible-push-card__img">
                                <img src="{{element.type == 'document'? 'assets/images/flexible-content/flexible-card-doc/doc-big.svg' : element.visuel?.sizes?.['w210h130']}}" width="{{element.type == 'document'? '30' : '210'}}" height="{{element.type == 'document'? '42' : '130'}}" alt="{{element.visuel?.alt}}" />
                            </div>
                            <div class="flexible-push-card__tag__container">
                                <span class="flexible-push-card__tag__thematic" *ngIf="element.tagSecondary" [customInnerHTML]="element.tagSecondary"></span>
                                <span class="flexible-push-card__tag" *ngIf="element.tagMain" [customInnerHTML]="element.tagMain"></span>
                            </div>
                        </div>
                        <div class="flexible-push-card__bottom">
                            <p [customInnerHTML]="element.title"></p>
                        </div>
                        <a class="flexible-push-card__link" href="{{element.lien.url}}" rel="{{element.lien.type == 'ext'? 'external' : ''}}" target="{{element.lien.type == 'ext'? '_blank' : ''}}">Accéder</a>
                    </div>
                </swiper-slide>
            }
        </swiper-container>
        <div class="swiper-navigation">
            <div #prevButton class="swiper-button-prev"></div>
            <div #nextButton class="swiper-button-next"></div>
        </div>
    </div>
</div>