import {NgClass, NgFor} from "@angular/common";
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  HostListener,
  inject,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {RouterModule} from '@angular/router';
import {DialogMesLiensComponent} from "./dialog-mes-liens/dialog-mes-liens.component";
import {Bookmark} from "../../models/bookmark";
import {MesLiensService} from "../../services/mes-liens.service";
import {environment} from "../../../environments/environment";

// @ts-ignore
@Component({
  selector: 'app-personal-list',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    RouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './personal-list.component.html',
  styleUrls: ['./personal-list.component.scss']
})
export class PersonalListComponent {
  @ViewChildren('swiperContainer', {read: ElementRef}) swiperContainers!: QueryList<ElementRef>;
  readonly dialog = inject(MatDialog);
  isActive = [false, false];
  @Input() apps: { title: string, url: string }[] = [];
  @Input() bookmarks: Bookmark[] = [];

  constructor(
    private mesLiensService: MesLiensService
  ) {
  }

  ngOnInit(): void {
    // mise à jour des liens de l'utilisateur en fonction de ce qu'il a modifié
    this.mesLiensService.currentMesLiens.subscribe((mesLiens) => {
      this.bookmarks = mesLiens;
    });
  }

  openDialogLinks() {
    const dialogRef = this.dialog.open(DialogMesLiensComponent, {
      width: '100%',
      maxWidth: '770px',
      data: {bookmarks: this.bookmarks},
    });

    dialogRef.afterClosed().subscribe(result => {
      environment.enableLogging && console.log(`Dialog result: ${result}`);
    });
  }

  toggleDropdown(event: Event, index: number): void {
    event.preventDefault();

    this.isActive = this.isActive.map((_, i) => i === index ? !this.isActive[i] : false);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    const clickedInside = (event.target as HTMLElement).closest('.personal-list__item');

    if (!clickedInside) {
      this.isActive = [false, false];
    }
  }

  ngAfterViewInit() {
    this.swiperContainers.forEach((container) => {
      this.initializeSwiper(container.nativeElement);
    });
  }

  initializeSwiper(swiperEl: any): void {
    const swiperParams = {
      slidesPerView: 7,
      direction: 'vertical',
      navigation: true,
      loop: false,
      cssMode: false,
      speed: 500,
      spaceBetween: 0,
      simulateTouch: true,
    };

    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }
}
