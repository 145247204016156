import {NgForOf} from '@angular/common';
import {Component} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {saveDataCache, useCache} from '../../utils/utils.service';
import {Service} from '../../models/service';
import {LoadingOverlayComponent} from '../../components/loading-overlay/loading-overlay.component';
import {environment} from '../../../environments/environment';
import {SidenavService} from '../../services/sidenav.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mes-services',
  standalone: true,
  imports: [
    NgForOf,
    LoadingOverlayComponent
  ],
  templateUrl: './mes-services.component.html',
  styleUrl: './mes-services.component.scss'
})
export class MesServicesComponent {
  public isLoading: boolean = false;
  services: Service[] = [];

  constructor(private apiService: ApiService, private sidenavService: SidenavService, private router: Router) {
  }

  ngOnInit(): void {
    // load apps data
    useCache('services', this, async () => {
      // load Breves, Events and Home data
      await Promise.all([
        this.getServices(),
      ]);
    }, () => {
      return {
        services: this.services,
      }
    });
  }

  async getServices(): Promise<void> {
    const {body, headers} = await this.apiService.getServices();
    environment.enableLogging && console.log('services', body, headers);
    // transform value
    if (body) {
      this.services = this.formatServices(body.data);
    }

    this.isLoading = false;
  }

  formatServices(data: any[]): Service[] {
    return data.map((service) => {
      return {
        id: service.id,
        title: service.title?.rendered,
        lien: {
          type: service.acf?.type_lien == 'externe' ? 'ext' : 'int',
          url: service.acf?.type_lien == 'externe' ? service.acf?.lien_externe?.url : `/${service.acf?.lien_interne?.url}`
        },
        flagId: service.flag_id,
        fontPicto: service.acf?.['font-icon-pictogramme'],
        isFavorite: service.is_favorite,
      };
    });
  }

  toggleFavorite(event: Event, service: Service): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const parent = target.parentElement as HTMLElement;
    const flag = parent.classList.toggle('favorite');

    const data = JSON.parse(localStorage.getItem('sidenav') ?? "[]");
    const services = data.userServices || [];

    environment.enableLogging && console.log('services', services);

    // Update favorite status
    if (flag) {
      // Add to favorite
      this.apiService.flag('services', service.flagId).then((e) => {
        environment.enableLogging && console.log('flag', e);
      });

      // ajouter le service de la sidenav
      const indexService = services.findIndex((s: Service) => s.flagId == service.flagId);
      indexService != -1 && services.splice(indexService, 1);
      services.push(service);
    } else {
      // Remove to favorite
      this.apiService.unflag('services', service.flagId).then((e) => {
        environment.enableLogging && console.log('unflag', e);
      });

      // retirer le service de la sidenav
      const indexService = services.findIndex((s: Service) => s.flagId == service.flagId);
      indexService != -1 && services.splice(indexService, 1);
    }

    // trier les services par ordre alphabétique
    services.sort((a: Service, b: Service) => a.flagId - b.flagId);

    // mettre à jour les services de la sidenav 
    this.sidenavService.changeServices(services);
    // mettre à jour le localstorage
    saveDataCache('sidenav', {userServices: services});
  }

  redirect(service: Service): void {
    if (service.lien.type == 'int') {
      window.open(service.lien.url, '_self');
    } else {
      window.open(service.lien.url, '_blank');
    }
  }
}
