<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="col-fullwidth mb-60">
        <div class="tools">
            <div class="tools_list">
                @for (tool of flexible.tools; track tool.id) {
                    <app-card-outil class="tools_list--item-wrapper" [tool]="tool"></app-card-outil>
                }
            </div>
        </div>
    </div>
</div>