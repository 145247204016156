import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {RouterLink} from "@angular/router";
import {
  MatCalendarCellClassFunction,
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangeInput
} from "@angular/material/datepicker";
import {environment} from "../../../../environments/environment";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatNativeDateModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {SearchFilter} from "../../../models/search-filters";

type FilterKeys = 'themes' | 'perimetres' | 'natures';

@Component({
  selector: 'app-filters-bar',
  standalone: true,
  imports: [
    NgClass,
    MatFormField,
    MatDatepickerToggle,
    MatDatepicker,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerInput,
    RouterLink,
    NgForOf,
    NgIf],
  templateUrl: './filters-bar.component.html',
  styleUrl: './filters-bar.component.scss'
})
export class FiltersBarComponent {
  @Input() filtres: {
    perimetres: SearchFilter[] | null,
    themes: SearchFilter[] | null,
    natures: SearchFilter[] | null,
    startDate: Date | null,
    endDate: Date | null
  } = {perimetres: null, themes: [], natures: [], startDate: null, endDate: null};
  @Output() filtresChange = new EventEmitter<any>();
  @Output() searchTriggerred = new EventEmitter<any>();

  openDropdownIndex: number | null = null;
  isActive: boolean = false;

  async onDateChange(dateRangeInput: MatDateRangeInput<Date>) {
    // Mettre à jour les dates de début et de fin
    this.filtres.startDate = dateRangeInput.value?.start ? new Date(dateRangeInput.value?.start) : null;
    this.filtres.endDate = dateRangeInput.value?.end ? new Date(dateRangeInput.value?.end) : null;

    environment.enableLogging && console.log(this.filtres.startDate, this.filtres.endDate);
    this.filtresChange.emit(this.filtres);
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate.getDate();

      return date === 1 || date === 20 ? 'example-custom-date-class' : '';
    }

    return '';
  };

  toggleActive(): void {
    this.isActive = !this.isActive; // Inverse l'état
  }

  asFilterKey(value: string): FilterKeys {
    if (value === 'themes' || value === 'perimetres' || value === 'natures') {
      return value;
    }
    throw new Error(`Invalid filter key: ${value}`);
  }

  onCheckboxChange(event: any, index: number, type: string, subIndex?: number) {
    if (type === 'themes' && this.filtres.themes) {
      this.filtres.themes[index].checked = event.target.checked;
    } else if (type === 'perimetres' && this.filtres.perimetres) {
      this.filtres.perimetres[index].checked = event.target.checked;
    } else if (subIndex && this.filtres.perimetres) {
      this.filtres.perimetres[index].sub_filters[subIndex].checked = event.target.checked;
    } else if (type === 'natures' && this.filtres.natures) {
      this.filtres.natures[index].checked = event.target.checked;
    }
    this.filtresChange.emit(this.filtres);
  }

  selectAll(type: FilterKeys) {
    this.filtres[type]?.forEach(checkbox => {
      checkbox.checked = true;
    });
    this.filtresChange.emit(this.filtres);
  }

  unselectAll(type: FilterKeys) {
    this.filtres[type]?.forEach(checkbox => {
      checkbox.checked = false;
    });
    this.filtresChange.emit(this.filtres);
  }

  isAnyChecked(type: FilterKeys): boolean {
    return this.filtres[type]?.some(checkbox => checkbox.checked) ?? false;
  }

  countChecked(type: FilterKeys): number {
    return this.filtres[type]?.filter(checkbox => checkbox.checked).length ?? 0;
  }

  search(): void {
    this.closeDropdown();
    this.searchTriggerred.emit();
  }

  toggleDropdown(index: number) {
    this.openDropdownIndex = this.openDropdownIndex === index ? null : index; // Ouvre ou ferme le dropdown
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const popinElements = document.querySelectorAll('.filters-dropdown__popin');
    const openerElements = document.querySelectorAll('.filters-dropdown__opener');

    const isInsidePopin = Array.from(popinElements).some(popin => popin.contains(target));
    const isOpenerClicked = Array.from(openerElements).some(opener => opener.contains(target));

    if (this.openDropdownIndex !== null && !isInsidePopin && !isOpenerClicked) {
      this.closeDropdown();
    }
  }

  closeDropdown() {
    this.openDropdownIndex = null;
  }
}
