import { Component, Input } from '@angular/core';
import { EssentielMiniCards } from '../../../models/essentiels';
import { NgIf, NgClass, NgForOf } from '@angular/common';
import { EssentielMiniCardComponent } from './essentiel-mini-card/essentiel-mini-card.component';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-essentiel-mini-cards',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgForOf,
    EssentielMiniCardComponent,
    CustomInnerHtmlDirective
  ],
  templateUrl: './essentiel-mini-cards.component.html',
  styleUrl: './essentiel-mini-cards.component.scss'
})
export class EssentielMiniCardsComponent {
  @Input() public essentielMiniCards!: EssentielMiniCards;
}
