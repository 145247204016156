import {Component, Inject} from '@angular/core';
import {LoadingOverlayComponent} from "../../loading-overlay/loading-overlay.component";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {TimelineEvent} from "../../../models/timeline";
import {FrenchDatePipe} from "../../../pipes/french-date.pipe";
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-dialog-timeline-event-info',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatFormField,
    MatInput,
    MatLabel,
    NgIf,
    FrenchDatePipe,
    CustomInnerHtmlDirective
  ],
  templateUrl: './dialog-timeline-event-info.component.html',
  styleUrl: './dialog-timeline-event-info.component.scss'
})
export class DialogTimelineEventInfoComponent {
  timelineEvent!: TimelineEvent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.timelineEvent = data.timelineEvent;
    environment.enableLogging && console.log('Timeline Event', this.timelineEvent);
  }

}
