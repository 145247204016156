

<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
  <form class="flexible-form-contact flexible-content">
    <h3 class="flexible-form-contact__title">Une question ?</h3>

      <mat-form-field appearance="outline">
        <mat-label>Objet de votre message</mat-label>
        <input matInput placeholder="Exemple">
      </mat-form-field>
      <mat-form-field>
        <textarea matInput rows="16" placeholder="Votre message ..."></textarea>
      </mat-form-field>
  </form>
  </div>

