import {Component, ElementRef, HostListener, inject, Input, ViewChild} from '@angular/core';
import {copyUrlToClipboard} from "../../../utils/utils.service";
import {DatePipe, JsonPipe, NgClass, NgIf} from "@angular/common";
import {DialogSendEmailComponent} from "../../dialog/dialog-send-email/dialog-send-email.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogDocumentInfoComponent} from "../../dialog/dialog-document-info/dialog-document-info.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-search-document',
  standalone: true,
  imports: [
    DatePipe,
    NgIf,
    NgClass,
    JsonPipe
  ],
  templateUrl: './search-document.component.html',
  styleUrl: './search-document.component.scss'
})
export class SearchDocumentComponent {
  @Input('document') document!: {
    score: number,
    name: string,
    webUrl: string,
    nature_du_document: string,
    perimetre: string,
    titre: string,
    date_d_effet: string,
    description: string,
    [key: string]: any;
  };
  @Input('isLoading') isLoading: boolean = false;
  @ViewChild('optionsOpener', {static: false}) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu') optionsMenu!: ElementRef;
  copyUrlToClipboard = copyUrlToClipboard;
  readonly dialog = inject(MatDialog);
  isOptionsActive = false;
  static anyOptionsActive: boolean = false;

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
    SearchDocumentComponent.anyOptionsActive = this.isOptionsActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      if (!clickedInsideOpener) {
        this.isOptionsActive = false;
        SearchDocumentComponent.anyOptionsActive = false;
      }
    }
  }
  
  openDocumentUrl(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (!this.optionsOpener.nativeElement.contains(targetElement) && !this.optionsMenu.nativeElement.contains(targetElement) && !SearchDocumentComponent.anyOptionsActive) {
      window.open(this.document.webUrl, '_blank');
    }
  }

  openDialogMedia() {
    const document = this.document;
    this.dialog.open(DialogDocumentInfoComponent, {
      width: '100%',
      maxWidth: environment.elasticSearchConfig.documentDetail ? '920px' : '350px',
      data: {document},
    });
  }

  openDialogShareByEmail(): void {
    const document = this.document;
    const docType = document.nature_du_document;
    const docPerimetre = document.perimetre;
    this.dialog.open(DialogSendEmailComponent, {
      width: '100%',
      maxWidth: '740px',
      data: {
        docTitle: document.titre? document.titre : document.name,
        docUrl: document.webUrl,
        docType: docType,
        docPerimetre: docPerimetre,
        docDate: document.date_d_effet
      },
    });
  }

  isPointerActive(): boolean {
    return !SearchDocumentComponent.anyOptionsActive;
  }

  closeOptions(): void {
    this.isOptionsActive = false;
    SearchDocumentComponent.anyOptionsActive = false;
  }

  protected readonly environment = environment;
}
