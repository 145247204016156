<div class="timeline">
    <div class="element-wrapper">
        <div class="timeline__info">
            <h5 class="timeline__title" [innerHTML]="timelineTitle"></h5>
            <div class="timeline__description" [innerHTML]="timelineDescription"></div>
        </div>
        <div #timelineWrapper class="timeline__wrapper">
            <button *ngIf="leftArrowVisible" class="timeline__nav timeline__nav--prev" (click)="prev()">&lsaquo;
            </button>
            <button *ngIf="rightArrowVisible" class="timeline__nav timeline__nav--next" (click)="next()">&rsaquo;
            </button>
            <div class="timeline__months" [style.transform]="transformStyle">
                <div class="timeline__month" *ngFor="let m of months; let i = index">
                    <div class="timeline__month-label"><span>{{m.month}}</span><span *ngIf="m.index === 0">{{m.year}}</span></div>
                    <div class="timeline__date" *ngFor="let columnEvents of getColumnsInMonth(m.index, m.year)">
                        <div class="timeline__events">
                            <div *ngFor="let timelineEvent of columnEvents"
                                 [ngClass]="{
                                 'timeline__event--single': columnEvents.length === 1 && timelineEvent.type === 'major',
                                 'timeline__event--multiple': columnEvents.length > 1 || timelineEvent.type === 'minor',
                                 }"
                                 class="timeline__event" (click)="openDialogTimelineEventInfo(timelineEvent)">
                                <div class="timeline__event-content">
                                    <div class="timeline__event-name"
                                         [ngClass]="columnEvents.length > 1 || timelineEvent.type === 'minor' ?
                                         'text-limited--two-lines' : 'text-limited--three-lines'"
                                         [innerHTML]="timelineEvent.name">
                                    </div>
                                    <div *ngIf="timelineEvent.status !== 'tous'"
                                         class="timeline__event-status timeline__event-status--{{timelineEvent.status}} ">
                                        {{ timelineEvent.status }}
                                    </div>
                                    <div class="timeline__event-description text-limited--three-lines" [innerHTML]="timelineEvent.description"></div>
                                    <div class="timeline__event-date">{{ timelineEvent.date | frenchDate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>