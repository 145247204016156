import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { ActualitesComponent } from '../../components/actualites/actualites.component';
import { EventsComponent } from '../../components/agenda/events/events.component';
import { BrevesComponent } from '../../components/breves/breves.component';
import { EnImagesComponent } from "../../components/en-images/en-images.component";
import { EssentielHpComponent } from "../../components/essentiel/essentiel-hp/essentiel-hp.component";
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { ParticipationComponent } from '../../components/participation/participation.component';
import { PersonalListComponent } from "../../components/personal-list/personal-list.component";
import { Actualite } from '../../models/actualite';
import { AppelsParticipation } from '../../models/appels-participation';
import { Breve } from '../../models/breve';
import { EnImage } from '../../models/en-images';
import { EssentielEntry } from '../../models/essentiels';
import { Event } from '../../models/event';
import { AuQuotidienTag } from '../../models/tag';
import { Zoom } from '../../models/zoom';
import { ApiService } from '../../services/api.service';
import { DepartmentService } from "../../services/department.service";
import {
  formatActualitesALaUne,
  formatAppelsParticipationPush,
  formatBreves,
  formatEnImages,
  formatEssentielEntries,
  formatEvents,
  formatServices,
  formatZoom,
  htmlDecode,
  saveDataCache,
  sendToast,
  useCache
} from '../../utils/utils.service';
import { SearchbarComponent } from "../../components/search/searchbar/searchbar.component";
import { MesLiensService } from '../../services/mes-liens.service';
import { Bookmark } from '../../models/bookmark';
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    LoadingOverlayComponent,
    BrevesComponent,
    ActualitesComponent,
    EventsComponent,
    PersonalListComponent,
    EnImagesComponent,
    ParticipationComponent,
    EssentielHpComponent,
    NgIf,
    RouterModule,
    SearchbarComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  [key: string]: any;
  actualitesALaUne: Actualite[] = [];
  public breves: Breve[] = [];
  public events: Event[] = [];
  enImages: EnImage[] = [];
  appelsParticipations: AppelsParticipation[] = [];
  public isLoading: boolean = false;
  homeMessage: string = '';
  quelCapEntries: EssentielEntry[] = [];
  quiSommesNousEntries: EssentielEntry[] = [];
  auQuotidienEntries: EssentielEntry[] = [];
  quiSommesNousZooms: Zoom[] = [];
  quelCapZooms: Zoom[] = [];
  auQuotidienZooms: Zoom[] = [];
  auQuotidienTags: AuQuotidienTag[] = [];
  chapoCap: string = '';
  chapoQuotidien: string = '';
  chapoIdentite: string = '';
  userApps: { title: string, url: string, flagId: number }[] = [];
  bookmarks: Bookmark[] = [];

  constructor(private apiService: ApiService, private departmentService: DepartmentService, private mesLiensService: MesLiensService) {
    moment.locale('fr');
  }

  ngOnInit(): void {
    // Ensure department is loaded before loading data
    this.departmentService.loadDepartment().finally(() => {
      useCache('home', this, async () => {
        // load Breves, Events and Home data
        await Promise.all([
          // this.getUserFavorites(),
          this.getHomepage(),
          // this.getBrevesAndFormat(),
          // this.getEventsAndFormat(),
          // this.getHomeData()
        ]);
      }, () => {
        return {
          actualitesALaUne: this.actualitesALaUne,
          enImages: this.enImages,
          breves: this.breves,
          events: this.events,
          appelsParticipations: this.appelsParticipations,
          quiSommesNousEntries: this.quiSommesNousEntries,
          quelCapEntries: this.quelCapEntries,
          auQuotidienEntries: this.auQuotidienEntries,
          quiSommesNousZooms: this.quiSommesNousZooms,
          quelCapZooms: this.quelCapZooms,
          auQuotidienZooms: this.auQuotidienZooms,
          auQuotidienTags: this.auQuotidienTags,
          chapoCap: this.chapoCap,
          chapoQuotidien: this.chapoQuotidien,
          chapoIdentite: this.chapoIdentite,
          userApps: this.userApps
        }
      });
    });

    // mise à jour des liens de l'utilisateur en fonction de ce qu'il a modifié
    this.mesLiensService.currentMesLiens.subscribe((mesLiens) => {
      this.bookmarks = mesLiens;
    });
  }


  async getHomepage() {
    try {
      const { body, headers } = await this.apiService.getHomepage();
      // transform value
      if (body) {
        if(body.home_message) 
          this.homeMessage = body.home_message;
        if (body.actualites_a_la_une)
          this.actualitesALaUne = formatActualitesALaUne(body.actualites_a_la_une);
        if (body.medias)
          this.enImages = formatEnImages(body.medias);
        if (body.breves)
          this.breves = formatBreves(body.breves);
        if (body.evenements)
          this.events = formatEvents(body.evenements);
        if (body.appels_participation)
          this.appelsParticipations = formatAppelsParticipationPush(body.appels_participation);
        if (body.entrees) {
          this.quiSommesNousEntries = formatEssentielEntries(body.entrees['qui-sommes-nous']);
          this.quelCapEntries = formatEssentielEntries(body.entrees['quel-cap']);
          this.auQuotidienEntries = formatEssentielEntries(body.entrees['au-quotidien']);
        }
        if (body.zooms) {
          this.quiSommesNousZooms = formatZoom(body.zooms['qui-sommes-nous'], 'url');
          this.quelCapZooms = formatZoom(body.zooms['quel-cap'], 'url');
          this.auQuotidienZooms = formatZoom(body.zooms['au-quotidien'], 'url');
        }
        if (body.tags_au_quotidien) {
          this.auQuotidienTags = body.tags_au_quotidien?.map((tag: any, id: number) => {
            const typeLien = tag.card_complementaire?.type_lien == "externe" ? 'ext' : 'int';
            return {
              id: id,
              title: tag.titre,
              entrees: tag.entrees,
              ...(tag.card_complementaire && {
                cardComplementaire: {
                  title: tag.card_complementaire.title,
                  description: tag.card_complementaire.description,
                  visuel: tag.card_complementaire.visuel,
                  lien: {
                    url: typeLien == "ext" ? tag.card_complementaire?.lien_externe : `/${tag.card_complementaire?.lien_interne}`,
                    type: typeLien
                  }
                }
              })
            }
          });
        }
        if (body.chapos_essentiels) {
          this.chapoCap = htmlDecode(body.chapos_essentiels['notre_cap']?.replace(/<\/?[^>]+(>|$)/g, ""));
          this.chapoQuotidien = htmlDecode(body.chapos_essentiels['au-quotidien']?.replace(/<\/?[^>]+(>|$)/g, ""));
          this.chapoIdentite = htmlDecode(body.chapos_essentiels['qui-sommes-nous']?.replace(/<\/?[^>]+(>|$)/g, ""));
        }

      }
      environment.enableLogging && console.log('homepage', body, headers);
    } catch (error) {
      console.error('Error getHomepage ', error);
      sendToast({type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.'});
    }
  }

  async getHomeData() {
    const { body, headers } = await this.apiService.getHome();
    environment.enableLogging && console.log('home', body, headers);
  }

  async getBrevesAndFormat() {
    const { body, headers } = await this.apiService.getBreves();
    const total = headers.get('x-wp-total');
    const totalPages = headers.get('x-wp-totalpages');
    environment.enableLogging && console.log(body, headers, total, totalPages);
    // transform value
    if (body) {
      this.breves = formatBreves(body);
    }
    environment.enableLogging && console.log(this.breves);
  }


  async getEventsAndFormat() {
    const now = moment().toDate();
    const { body, headers } = await this.apiService.getEvents(now);
    const total = headers.get('x-wp-total');
    const totalPages = headers.get('x-wp-totalpages');
    environment.enableLogging && console.log(body, headers, total, totalPages);
    if (body) {
      // transform value
      this.events = formatEvents(body);
    }
    environment.enableLogging && console.log(this.events);
  }
}
