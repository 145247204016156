import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MesLiensService {

  constructor() { }

  private mesLiensSource = new BehaviorSubject<any>(null);
  currentMesLiens = this.mesLiensSource.asObservable();

  changeMesLiens(data: any) {
    this.mesLiensSource.next(data);
  }
}
