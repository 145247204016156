import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import moment from 'moment';
import { environment } from '../../environments/environment';
import { Actualite } from '../models/actualite';
import { AppelsParticipation } from '../models/appels-participation';
import { Breve } from '../models/breve';
import { EnImage } from '../models/en-images';
import {
  Essentiel,
  EssentielCard,
  EssentielCards,
  EssentielEntry,
  EssentielList2Visuels,
  EssentielLists1Visuel,
  EssentielMiniCards,
  EssentielPictoList
} from '../models/essentiels';
import { Event } from '../models/event';
import {
  FlashInfo,
  Flexible,
  FlexibleAccordions,
  FlexibleArche,
  FlexibleButton,
  FlexibleDataVisualisation,
  FlexibleDossiers,
  FlexibleHighlightCard,
  FlexibleImg,
  FlexibleListLinks,
  FlexibleOnglets,
  FlexibleProfilCard,
  FlexiblePushMedias,
  FlexiblePushMediasTitle,
  FlexiblePushPage,
  FlexiblePushPortraits,
  FlexiblePushReportings,
  FlexiblePushServices,
  FlexiblePushTools,
  FlexibleReporting,
  FlexibleSeparateur,
  FlexibleSimilarArticles,
  FlexibleSliderImg,
  FlexibleSpacer,
  FlexibleText,
  FlexibleTextImg,
  FlexibleVideo
} from '../models/flexibles';
import { Link } from '../models/link';
import { PageFille } from '../models/page-fille';
import { Service } from '../models/service';
import { Timeline, TimelineEvent } from "../models/timeline";
import { Tool } from '../models/tool';
import { Trend } from "../models/trend";
import { Zoom } from '../models/zoom';
import { ToastService } from '../services/toast.service';
import { Toast } from '../models/toast';
import { ApiService } from '../services/api.service';
import { MesAppsService } from '../services/mes-apps.service';
import { MesLiensService } from '../services/mes-liens.service';
import { SidenavService } from '../services/sidenav.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }
}

export function isInternalUrl(baseUrl: string, urlToCheck: string) {
  const baseUrlObj = new URL(baseUrl);
  const urlToCheckObj = new URL(urlToCheck);
  return urlToCheckObj.origin === baseUrlObj.origin;
}

export function sendToast(toast: Toast) {
  const toastService = inject(ToastService);
  toastService.changeToast(toast);
}

export async function getUserFavorites(apiService: ApiService, mesLiensService: MesLiensService, mesAppsService: MesAppsService, sidenavService: SidenavService) {
  const { body, headers } = await apiService.getUserFavorites();
  environment.enableLogging && console.log('sidenav', body, headers);

  if (body) {
    const userApps = body.apps?.map((app: any) => ({ title: app.title, url: app.url, flagId: app.flag_id }));
    const bookmarks = body.bookmarks?.map((b: any) => ({ uuid: b.id, title: b.title, url: b.href, isEditing: false })).sort((a: any, b: any) => a.title.localeCompare(b.title));
    const userServices = formatServices(body.services);
    // mettre à jour mes liens
    mesLiensService.changeMesLiens(bookmarks);
    // mettre à jour mes apps
    mesAppsService.changeMesApps(userApps);
    // mettre à jour les services
    sidenavService.changeServices(userServices);
  }
}

export function formatLien(object: any): Link {
  try {
    const type = object?.type_lien == 'externe' ? 'ext' : 'int';
    const obj = (object?.type_lien == 'externe' ? object?.lien_externe : object?.lien_interne) || object; // object est utilisé pour les services dans la navbar qui n'ont pas d'objet lien
    // ne pas retourner de lien si l'objet est vide
    if (!obj?.url) return {} as Link;
    return {
      type: type,
      url: (obj?.url as string).startsWith('http') ? obj.url : `/${obj.url}`,
      label: obj?.title,
      object: obj
    };
  } catch (e) {
    console.error('Error in formatLien : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return {} as Link;
}

export function sanitizeTitle(text: string): string {
  const textarea = document.createElement('div');
  textarea.innerHTML = text;
  return textarea.innerHTML;
}

export function loadMainPageChapo(instance: any, apiService: any) {
  useCache('mainPageChapo', instance, async () => {
    const data = await Promise.all([apiService.getChapo('cap'), apiService.getChapo('quotidien'), apiService.getChapo('identite')]);
    if (data.length !== 3) return;
    instance.chapoCap = htmlDecode(data[0]).replace(/<\/?[^>]+(>|$)/g, "");
    instance.chapoQuotidien = htmlDecode(data[1]).replace(/<\/?[^>]+(>|$)/g, "");
    instance.chapoIdentite = htmlDecode(data[2]).replace(/<\/?[^>]+(>|$)/g, "");
  }, () => {
    return {
      chapoCap: instance.chapoCap,
      chapoQuotidien: instance.chapoQuotidien,
      chapoIdentite: instance.chapoIdentite,
    }
  }, true);
}

export function copyUrlToClipboard(url?: string) {
  const currentUrl = url ? url : window.location.href;

  navigator.clipboard.writeText(currentUrl).then(() => {
    environment.enableLogging && console.log('Lien copié dans le presse-papiers !');
    // Optionnel : afficher une notification à l'utilisateur
  }).catch(err => {
    console.error('Erreur lors de la copie du lien : ', err);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  });
}

export function filterParams(obj: { [key: string]: any }) {
  return Object.entries(obj)
    .filter(([_, value]) => value != null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export function objToHttpParams(obj: { [key: string]: any }): HttpParams {
  let params = new HttpParams();
  for (const [key, value] of Object.entries(obj)) {
    if (value)
      params = params.set(key, value);
  }
  return params;
}


export function saveDataCache(name: string, data: any) {
  const hasDataToSave = (obj: any): boolean => {
    return Object.values(obj).some(value => value !== undefined);
  };
  // Save data in cache if there is data to save
  if (hasDataToSave(data)) {
    environment.enableLogging && console.log(`Saving new data in cache for key "${name}":`, data);
    localStorage.setItem(name, JSON.stringify({ ...data, cacheDate: moment().toISOString() }));
  }
}

export function loadDataCache(name: string, instance: any) {
  const data = JSON.parse(localStorage.getItem(name) || "[]");
  for (const [key, value] of Object.entries(data)) {
    instance[key] = value;
  }
  return data;
}

export async function useCache(
  name: string,
  instance: any,
  func: any,
  dataFunc: any,
  useCache: boolean = false,
  seconds: number = 300
) {
  moment.locale('fr');

  // Désactive le cache par défaut, pour l'activer il faut mettre useCache à true [Fix temporaire]
  if (useCache) {
    const data = loadDataCache(name, instance);

    // Afficher le loader si les données en cache remonte a plus x seconds
    if (moment().diff(instance.cacheDate || 0, 'seconds') > seconds && !Object.entries(data)?.length) {
      instance.isLoading = true;
    }
  } else {
    // active le loader en permanence
    instance.isLoading = true;
  }

  try {
    // call api functions
    await func();
  } catch (e) {
  }



  useCache && saveDataCache(name, dataFunc());

  instance.isLoading = false;
}

export function htmlDecode(input: string): string {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent || '';
}


export function formatZoom(data: any[], type: 'url' | 'video'): Zoom[] {
  const zooms: Zoom[] = [];
  try {
    data?.forEach((e: any) => {
      const lien = formatLien(e.acf);
      const zoom: Zoom = {
        id: e.ID,
        type: type,
        visuel: e.acf.visuel,
        ...(e.acf.pictogramme && { pictogramme: e.acf.pictogramme }),
        title: e.title,
        text: e.acf?.texte,
        ...(e.acf?.type_lien && { typeLien: lien.type }),
        ...(e.acf?.type_lien && { lien: lien }),
        ...(e.acf?.lien_video && { videoUrl: e.acf?.lien_video }),
      };
      zooms.push(zoom);
    });
  } catch (e) {
    console.error('Error in formatZoom : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return zooms;
}

export function formatEssentielEntries(data: any[]): EssentielEntry[] {
  try {
    return data.map((entree: any, index: number) => {
      const lien = formatLien(entree);
      return {
        id: index,
        lien: lien,
        description: lien?.object?.description,
      } as EssentielEntry;
    });
  } catch (e) {
    console.error('Error in formatEssentielEntries : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
    return [];
  }
}

export function formatTerritorialEntries(data: { label: string, code: string | null, liens: any[] }[]): {
  label: string,
  code: string | null,
  entries: EssentielEntry[]
}[] {
  const result: { label: string, code: string | null, entries: EssentielEntry[] }[] = [];
  try {
    data.forEach((d: any, i: number) => {
      if (d.label && d.liens?.length) {
        result.push({
          label: d.label,
          code: d.code || null,
          entries: formatEssentielEntries(d.liens)
        });
      }
    });
  } catch (e) {
    console.error('Error in formatTerritorialEntries : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return result;
}

export function formatEssentiel(data: any[], icon_type: 'diamond' | 'triangle' | 'circle'): Essentiel[] {
  const essentiels: Essentiel[] = [];
  try {
    data?.forEach((e: any, index: number) => {
      let essentiel: Essentiel;

      switch (e?.acf_fc_layout) {
        case 'contenu_module_essentiel_national_territorial_liste_2_visuels':
          essentiel = {
            id: index,
            type: 'list-2-visuels',
            icon_type,
            title: e.titre,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            infography_natio: e.visuel_entrees_nationales,
            infography_natio_pos: e.position_visuel_entrees_nationales,
            national_entries: formatEssentielEntries(e.entrees_nationales),
            ...(e?.sous_titre_entrees_territoriales && { territorial_subtitle: e?.sous_titre_entrees_territoriales }),
            infography_territo: e.visuel_entrees_territoriales,
            infographic_territo_pos: e.position_visuel_entrees_territoriales,
            territorial_entries: formatTerritorialEntries(e.entrees_territoriales),
          } as EssentielList2Visuels;
          break;
        case 'contenu_module_essentiel_national_territorial_liste_1_visuel':
          essentiel = {
            id: index,
            type: 'lists-1-visuel',
            icon_type,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            title: e.titre,
            infography: e.visuel,
            infography_pos: e.position_visuel,
            national_entries: formatEssentielEntries(e.entrees_nationales),
            ...(e?.sous_titre_territorial && { territorial_subtitle: e?.sous_titre_territorial }),
            territorial_entries: formatTerritorialEntries(e.entrees_territoriales),
          } as EssentielLists1Visuel;
          break;
        case 'contenu_module_essentiel_national_cards':
          const lien = formatLien(e.flash_info);
          essentiel = {
            id: index,
            type: 'cards',
            icon_type,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            title: e.titre,
            ...(e.description && { description: e.description }),
            ...(e.flash_info && {
              flashInfo: {
                info: lien.object?.description,
                lien: lien
              }
            }),
            cards: e.entrees_nationales.map((c: any, i: number) => {
              const lien = formatLien(c);
              return {
                id: i,
                title: lien.label,
                ...(lien.object?.description && { description: lien.object?.description }),
                ...(c.picto && { picto: { url: c.picto.url, alt: c.picto.alt } }),
                visuel: lien.object?.visuel,
                lien: lien,
                couleurs_cards: e.couleurs_cards || 'clair',
              } as EssentielCard;
            }),
          } as EssentielCards;
          break;
        case 'contenu_module_essentiel_national_mini_cards':
          essentiel = {
            id: index,
            type: 'mini-cards',
            icon_type,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            title: e.titre,
            ...(e.description && { description: e.description }),
            cards: e.entrees_nationales.map((c: any, i: number) => {
              const lien = formatLien(c);
              return {
                id: i,
                title: lien.label,
                ...(lien.object?.description && { description: lien.object?.description }),
                ...(c.picto && { picto: { url: c.picto.url, alt: c.picto.alt } }),
                visuel: lien.object?.visuel,
                lien: lien,
                couleurs_cards: e.couleurs_cards || 'clair',
              } as EssentielCard;
            }),
            ...(e.card_complementaire && {
              cardComplementaire: {
                title: e.card_complementaire.title,
                description: e.card_complementaire.description,
                ...(e.card_complementaire.picto && {
                  picto: {
                    url: e.card_complementaire.picto.url,
                    alt: e.card_complementaire.picto.alt
                  }
                }),
                visuel: e.card_complementaire.visuel,
                couleurs_cards: e.couleurs_cards || 'clair',
                lien: formatLien(e.card_complementaire)
              }
            }),
          } as EssentielMiniCards;
          break;
        default:
          essentiel = {
            id: index,
            type: 'picto-list',
            icon_type,
            title: e.titre,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            national_entries: formatEssentielEntries(e.entrees_nationales),
            pictoPos: e.position_picto_titre,
            picto_infography: e.picto
          } as EssentielPictoList;
          break;
      }
      essentiels.push(essentiel);
    });
  } catch (e) {
    console.error('Error in formatEssentiel : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return essentiels;
}

export function formatTools(data: any[], key = 'key'): Tool[] {
  const tools: Tool[] = [];
  try {
    for (const t of data) {
      const tool: Tool = {
        id: key + t.ID,
        title: t.title,
        text: t.acf?.description?.replace(/<\/?[^>]+(>|$)/g, ""),
        perimetre: t.acf?.perimetre?.name,
        dt: t.acf?.dt?.name,
        url: t.acf?.type_lien == 'document' ? t.acf?.document?.url : `/${t.acf?.page_fille}`,
        type: t.acf?.type_lien == 'document' ? 'document' : 'page-fille',
        rubrique: {
          title: t.acf?.rubrique?.name,
          icon_type: t.acf?.rubrique?.slug == 'qui-sommes-nous' ? 'diamond' : t.acf?.rubrique?.slug == 'quel-cap' ? 'triangle' : 'circle'
        }
      };
      tools.push(tool);
    }
  } catch (e) {
    console.error('Error in formatTools : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return tools;
}

export function formatTimelineEvent(data: any, type: 'minor' | 'major' = 'major'): TimelineEvent {
  return {
    name: data.titre,
    type: type,
    date: new Date(data.date.split('/').reverse().join('-')),
    status: data.statut?.toLowerCase(),
    description: data.description.replace(/<[^>]+>/g, ''),
    htmlDescription: data.description,
    lien: formatLien(data),
  } as TimelineEvent;
}

export function formatTimeline(data: any[]): Timeline {
  const title = data[0].titre;
  const description = data[0].description;

  // Transformation des données
  const events: TimelineEvent[] = [];

  Object.values(data[0].annees).forEach((anneeObj: any) => {
    Object.values(anneeObj).forEach((moisObj: any) => {
      if (moisObj.temps_forts_majeurs) {
        moisObj.temps_forts_majeurs.forEach((tempsFort: any) => {
          events.push(formatTimelineEvent(tempsFort, 'major'));
        });
      }
      if (moisObj.temps_forts_mineurs_3) {
        moisObj.temps_forts_mineurs_3.forEach((tempsFort: any) => {
          events.push(formatTimelineEvent(tempsFort, 'minor'));
        });
      }
      if (moisObj.temps_forts_mineurs_6) {
        moisObj.temps_forts_mineurs_6.forEach((tempsFort: any) => {
          events.push(formatTimelineEvent(tempsFort, 'minor'));
        });
      }
    });
  });
  return {
    title,
    description,
    timelineEvents: events
  } as Timeline;
}

export function formatPushTools(data: any[]): Tool[] {
  const tools: Tool[] = [];
  try {
    for (const [key, value] of Object.entries(data)) {
      tools.push(...formatTools(value, key));
    }
  } catch (e) {
    console.error('Error in formatPushTools : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return tools;
}

export function formatFlexible(data: any, index: number): Flexible {
  let flexible: Flexible = {
    id: index,
    type: data.acf_fc_layout,
    bColor: data.background_color,
  };

  try {
    const lien = formatLien(data);
    const lienButton = formatLien(data.bouton);
    switch (data.acf_fc_layout) {
      case "contenu_flexible_flash_info":
        flexible = {
          id: index,
          type: 'flash-info',
          bColor: data.background_color,
          info: data.info,
          lien: lien
        } as FlashInfo;
        break;
      case "contenu_flexible_zone_texte":
        flexible = {
          id: index,
          type: 'text',
          bColor: data.background_color,
          content: data.zone_texte,
        } as FlexibleText;
        break;
      case "contenu_flexible_image":
        flexible = {
          id: index,
          type: 'img',
          bColor: data.background_color,
          visuel: data.visuel,
        } as FlexibleImg;
        break;
      case "contenu_flexible_texte_image_g":
        flexible = {
          id: index,
          type: 'text-img',
          bColor: data.background_color,
          title: data.titre?.replace(/<\/?p>/g, ""),
          content: data.texte,
          visuel: { ...data.visuel, date: moment(data.visuel.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') },
          color: data.couleur,
          img_pos: data['image-position'],
          lien: lien
        } as FlexibleTextImg;
        break;
      case "contenu_flexible_onglets":
        flexible = {
          id: index,
          type: 'onglets',
          bColor: data.background_color,
          onglets: data.onglets?.map((onglet: any, index: number) => {
            return {
              id: index,
              title: onglet?.titre,
              flexibles: onglet?.contenu && formatFlexibles(onglet?.contenu),
            }
          }),
        } as FlexibleOnglets;
        break;
      case "contenu_flexible_exergue":
        flexible = {
          id: index,
          type: 'highlight-card',
          bColor: data.background_color,
          content: data.texte,
          full_width: data.full_width,
          lien: lienButton,
        } as FlexibleHighlightCard;
        break;
      case "contenu_flexible_video":
        flexible = {
          id: index,
          type: 'video',
          bColor: data.background_color,
          url: data.lien_video,
          ...(data.visuel && { visuel: data.visuel })
        } as FlexibleVideo;
        break;
      case "contenu_flexible_arche":
        flexible = {
          id: index,
          type: 'arche',
          bColor: data.background_color,
          aColor: data.arch_color,
          title: data.titre,
          full_width: data.disposition == 'full' ? true : false,
        } as FlexibleArche;
        break;
      case "contenu_flexible_bouton":
        const lienButtonSecondary = formatLien(data.bouton_secondary);
        flexible = {
          id: index,
          type: 'button',
          bColor: data.background_color,
          lien: lienButtonSecondary,
          bType: data.type,
        } as FlexibleButton;
        break;
      case "contenu_flexible_datavisualisation_widget":
        flexible = {
          id: index,
          type: 'data-visualisation',
          bColor: data.background_color,
          title: data.titre,
          button: lienButton,
          elements: data.elements?.map((e: any, i: number) => {
            const lien = formatLien(e);
            return {
              id: i,
              style: e.format_description,
              title: e.titre,
              lien: lien,
              line1: e['line-1'],
              line2: e['line-2'],
              line3: e['line-3'],
              visuel: e.visuel,
              visuel_texte: e.visuel_texte,
            };
          }),
        } as FlexibleDataVisualisation;
        break;
      case "contenu_flexible_slider_images":
        flexible = {
          id: index,
          type: 'slider-img',
          bColor: data.background_color,
          visuels: data.visuels.map((v: any, i: number) => ({
            ...v,
            id: i,
            date: moment(v.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')
          }))
        } as FlexibleSliderImg;
        break;
      case "contenu_flexible_separator":
        flexible = {
          id: index,
          type: 'separateur',
          bColor: data.background_color,
        } as FlexibleSeparateur;
        break;
      case "contenu_flexible_spacer":
        flexible = {
          id: index,
          type: 'spacer',
          bColor: data.background_color,
          size: data.spacer == 'spacer-m' ? 'm' : 'xl',
        } as FlexibleSpacer;
        break;
      case "contenu_flexible_push_services":
        flexible = {
          id: index,
          type: 'push-services',
          bColor: data.background_color,
          services: data.push_services?.map((s: any, i: number) => {
            const lienAcf = formatLien(s?.acf);
            return {
              id: i,
              title: s.title,
              description: s.content,
              lien: lienAcf,
              fontPicto: s.acf?.['font-icon-pictogramme']
            };
          })
        } as FlexiblePushServices;
        break;
      case "contenu_flexible_push_medias":
        flexible = {
          id: index,
          type: 'push-medias',
          bColor: data.background_color,
          medias: data.push_medias?.map((v: any, i: number) => ({
            id: i,
            cColor: v.couleur_card,
            typeMedia: v.type_media == "image" ? 'img' : 'video',
            ...(v.lien_video && { lienVideo: v.lien_video }),
            visuel: v.visuel
          }))
        } as FlexiblePushMedias;
        break;
      case "contenu_flexible_push_medias_titre":
        flexible = {
          id: index,
          type: 'push-medias-title',
          bColor: data.background_color,
          medias: data.push_medias_titre?.map((v: any, i: number) => ({
            id: i,
            title: v.titre,
            cColor: v.couleur_card,
            typeMedia: v.type_media == "image" ? 'img' : 'video',
            ...(v.lien_video && { lienVideo: v.lien_video }),
            visuel: v.visuel
          }))
        } as FlexiblePushMediasTitle;
        break;
      case "contenu_flexible_reportage_metier_portrait":
        flexible = {
          id: index,
          type: 'reporting',
          bColor: data.background_color,
          title: data.titre,
          content: data.texte,
          visuel: { ...data.visuel, date: moment(data.visuel?.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') }
        } as FlexibleReporting;
        break;
      case "contenu_flexible_push_reportages":
        flexible = {
          id: index,
          type: 'push-reportings',
          bColor: data.background_color,
          title: data.titre,
          reportings: data.push_reportages?.map((r: any, i: number) => {
            const lien = formatLien(r);
            return {
              id: i,
              title: r.titre,
              content: r.texte,
              thematique: r.thematique,
              visuel: { ...r.visuel, date: moment(r.visuel?.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') },
              lien: lien
            };
          })
        } as FlexiblePushReportings;
        break;
      case "contenu_flexible_profils_cards":
        flexible = {
          id: index,
          type: 'profil-card',
          bColor: data.background_color,
          title: data.titre,
          lien: lienButton,
          profils: data.cartes?.map((p: any, i: number) => ({
            id: i,
            name: p.nom,
            fonction: p.fonction,
            phone: p.telephone,
            email: p.email,
            visuel: p.visuel,
          }))
        } as FlexibleProfilCard;
        break;
      case "contenu_flexible_push_cards":
        flexible = {
          id: index,
          type: 'push-page',
          bColor: data.background_color,
          elements: data.push_cards?.map((e: any, i: number) => {
            const type = e.type == 'page' ? 'page' : 'document';
            const lien = formatLien(e[type]);
            const typeDocument = e[type]?.type_document;
            let content;
            if (lien.type === 'ext') {
              content = lien.object;
            } else {
              content = (typeDocument === 'mediatheque') ? e[type].media_interne : lien.object;
            }

            const documentName = (type === 'document' && e.document?.nom_document) ? e.document?.nom_document : null;
            return {
              id: i,
              type: type,
              title: documentName ?? content.title,
              ...(content.visuel && { visuel: content.visuel }),
              lien: { url: content.url, type: lien.type },
              tagMain: type == 'document' ? type : e[type]?.tag_principal,
              ...(type == 'page' && e[type]?.tag_secondaire && { tagSecondary: e[type]?.tag_secondaire }),
            };
          })
        } as FlexiblePushPage;
        break;
      case "contenu_flexible_push_outils":
        flexible = {
          id: index,
          type: 'push-tools',
          bColor: data.background_color,
          tools: formatTools(data.outils),
        } as FlexiblePushTools;
        break;
      case "contenu_flexible_push_portraits":
        flexible = {
          id: index,
          type: 'push-portraits',
          bColor: data.background_color,
          portraits: data.push_portraits?.map((e: any, i: number) => ({
            id: i,
            fonction: e.fonction,
            nom: e.nom,
            content: e.texte,
            visuel: e.visuel
          }))
        } as FlexiblePushPortraits;
        break;
      case "contenu_flexible_liste_liens":
        flexible = {
          id: index,
          type: 'list-links',
          bColor: data.background_color,
          links: data.liste_liens?.map((l: any, i: number) => {
            const lien = formatLien(l);
            return {
              id: i,
              label: lien.label,
              category: lien?.object?.categorie,
              url: lien.url,
              type: lien.type
            };
          })
        } as FlexibleListLinks;
        break;
      case "contenu_flexible_accordeons":
        flexible = {
          id: index,
          type: 'accordions',
          bColor: data.background_color,
          accordions: data.accordeons?.map((a: any, i: number) => ({
            id: i,
            title: a.titre,
            flexibles: a.contenus_flexibles?.map((c: any, id: number) => formatFlexible(c, id))
          }))
        } as FlexibleAccordions;
        break;
      case "contenu_flexible_articles_similaires":
        flexible = {
          id: index,
          type: 'similar-articles',
          bColor: data.background_color,
          articles: data.articles_similaires && data.articles_similaires?.map((a: any, i: number) => {
            const moment_date = a.date?.includes('T') ? moment(a.date) : moment(a.date, 'DD/MM/YYYY');
            return {
              id: i,
              title: a.title,
              date: {
                format_day: moment_date.format('DD'),
                format_month: moment_date.format('MMM'),
                format_year: moment_date.format('YYYY'),
                full: a.date,
              },
              description: a.description,
              visuel: a.visuel,
              perimetre: a.perimetre?.name,
              dt: a.dt?.name,
              theme: a['theme-actualite']?.name,
              lien: { url: a.url },
            };
          }),
          medias: data.medias_similaires && data.medias_similaires?.map((m: any, i: number) => {
            const lienButton = formatLien(m.bouton);
            return {
              id: i,
              title: m.title,
              visuel: m.visuel,
              lien: { url: m.url },
              perimetre: m.perimetre?.name,
              theme: m['theme-actualite']?.name,
              dt: m.dt?.name,
              ...(m.video && { video: m.video }),
              ...(m.infographie && { infographie: m.infographie }),
              ...(lienButton && { button: lienButton }),
            };
          })
        } as FlexibleSimilarArticles;
        break;
      case "contenu_flexible_module_dossier":
        flexible = {
          id: index,
          type: 'dossiers',
          bColor: data.background_color,
          title: data.titre,
          dossiers: data.push_dossiers && data.push_dossiers?.map((d: any, i: number) => {
            const lien = formatLien(d);
            return {
              id: i,
              title: d.titre,
              content: d.texte,
              thematique: d.thematique,
              visuel: d.visuel,
              lien: lien
            };
          })
        } as FlexibleDossiers;
        break;
      default:
        flexible = {
          id: index,
          type: data.acf_fc_layout,
          bColor: data.background_color,
        } as FlexibleText;
    }
  } catch (e) {
    console.error('Error in formatFlexible : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return flexible;
}

export function formatFlexibles(data: any[]): Flexible[] {
  const flexibles: Flexible[] = [];
  try {
    if (Array.isArray(data)) {
      data.forEach((f: any, index: number) => {
        flexibles.push(formatFlexible(f, index));
      });
    }
  } catch (e) {
    console.error('Error in formatFlexibles:', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return flexibles;
}

export function formatActualitesALaUne(data: any[]): Actualite[] {
  const actualites: Actualite[] = [];
  try {
    for (const [k, v] of Object.entries(data)) {
      if (v instanceof Array && v.length) {
        v.forEach((a: any, i: number) => {
          const exist = actualites.find(actu => actu.id === a.ID);
          const moment_date = moment(a.date, 'DD/MM/YYYY');
          if (!exist)
            actualites.push({
              id: a.ID,
              title: a.title,
              chapo: a.chapo,
              date: {
                format_day: moment_date.format('DD'),
                format_month: moment_date.format('MMM'),
                format_year: moment_date.format('YYYY'),
                full: moment_date.format('YYYY-MM-DD'),
                date: moment_date.toDate()
              },
              dt: a.dt,
              perimetre: a.perimetre,
              theme: a['theme-actu'],
              lien: { url: a.url },
              important: k == 'actualites_majeures' ? true : false,
              visuel: a.visuel
            });
        });
      }
    }
  } catch (e) {
    console.error('Error in formatActualitesALaUne : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return actualites;
}

export function formatActualites(data: any[]): Actualite[] {
  const actualites: Actualite[] = [];
  try {
    data.forEach((a: any, i: number) => {
      const exist = actualites.find(actu => actu.id === a.id);
      const moment_date = moment(a.acf?.date);
      if (!exist)
        actualites.push({
          id: a.id,
          title: a.title.rendered,
          chapo: a.acf?.chapo,
          date: {
            format_day: moment_date.format('DD'),
            format_month: moment_date.format('MMM'),
            format_year: moment_date.format('YYYY'),
            full: moment_date.format('YYYY-MM-DD'),
            date: moment_date.toDate()
          },
          dt: a.acf?.dt?.name,
          perimetre: a.acf?.perimetre?.name,
          theme: a.acf?.theme_actualite?.name,
          lien: { url: a.slug },
          important: a.acf?.important,
          visuel: a.acf?.visuel
        });
    });
  } catch (e) {
    console.error('Error in formatActualites : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return actualites;
}

export function formatEnImages(data: any[]): EnImage[] {
  const enImages: EnImage[] = [];
  try {
    data.forEach((a: any, i: number) => {
      const exist = enImages.find(img => img.id === a.id);
      const typeMedia = a.acf?.type_media == 'infographie' ? 'infographie' : 'video';
      const btn = formatLien(a.acf?.bouton);
      if (!exist)
        enImages.push({
          id: a.id,
          title: a.acf?.titre,
          dt: a.acf?.dt?.name,
          perimetre: a.acf?.perimetre?.name,
          theme: a.acf?.theme_actualite?.name,
          lien_video: a.acf?.lien_video,
          visuel: a.acf?.visuel,
          infographie: a.acf?.infographie,
          lien_infographie: a.acf?.lien_infographie,
          typeMedia: typeMedia,
          button: btn,
        });
    });
  } catch (e) {
    console.error('Error in formatEnImages : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return enImages;
}

export function formatAppelsParticipationPush(data: any[]): AppelsParticipation[] {
  const appelsParticipations: AppelsParticipation[] = [];
  try {
    data.forEach((a: any, i: number) => {
      const exist = appelsParticipations.find(e => e.id === a.ID);
      const moment_date = moment(a.acf?.date);
      if (!exist)
        appelsParticipations.push({
          id: a.ID,
          title: a.title,
          chapo: a.description,
          dt: a.dt?.name,
          perimetre: a.perimetre?.name,
          date: {
            format_day: moment_date.format('DD'),
            format_month: moment_date.format('MMM'),
            format_year: moment_date.format('YYYY'),
            full: moment_date.format('YYYY-MM-DD'),
            date: moment_date.toDate()
          },
          lien: { url: `/${a.url}` },
          visuel: a.visuel
        });
    });
  } catch (e) {
    console.error('Error in formatAppelsParticipation : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return appelsParticipations;
  ;
}

export function formatBreves(data: any[]): Breve[] {
  const breves: Breve[] = [];
  try {
    data.forEach((e: any) => {
      const breve: Breve = {
        id: e.ID,
        date: moment(e.post_modified).format('DD.MM.YYYY'),
        dt: e.acf?.dt?.name,
        perimetre: e.acf?.perimetre?.name,
        text: e.acf?.texte?.replace(/<\/?[^>]+(>|$)/g, ""),
        richText: e.acf?.texte,
        ...(e.acf?.lien &&
        {
          lien: { url: e.acf?.lien?.url, label: e.acf?.lien?.title }
        }),
        isModalOpen: false,
      };
      breves.push(breve);
    });
  } catch (e) {
    console.error('Error in formatBreves : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return breves;
}

export function formatEvents(data: any[]): Event[] {
  const events: Event[] = [];
  try {
    data.forEach((e: any) => {
      const moment_date = e.acf?.date?.includes('T') ? moment(e.acf?.date) : moment(e.acf?.date, 'DD/MM/YYYY');
      // Heures optionnelles
      const heure_debut = e.acf?.heure_debut || '00:00'; // Par défaut à minuit si non fourni
      const heure_fin = e.acf?.heure_fin || '23:59';
      const eventStartDate = e.acf?.heure_debut
        ? moment(`${e.acf?.date} ${heure_debut}`, 'DD/MM/YYYY HH:mm').toDate()
        : moment_date.startOf('day').toDate();
      const eventEndDate = e.acf?.heure_fin
        ? moment(`${e.acf?.date} ${heure_fin}`, 'DD/MM/YYYY HH:mm').toDate()
        : moment_date.endOf('day').toDate();

      const event: Event = {
        id: e.id,
        date: moment_date.format('DD/MM/YYYY'),
        dt: e.acf?.dt?.name,
        perimetre: e.acf?.perimetre?.name,
        text: e.acf?.texte?.replace(/<\/?[^>]+(>|$)/g, ""),
        ...(e.acf?.lien &&
        {
          lien: { url: e.acf?.lien?.url, label: e.acf?.lien?.title }
        }),
        ...(e.acf?.lieu !== "" && { location: e.acf?.lieu }),
        format_day: moment_date.format('DD'),
        format_month: moment_date.format('MMM'),
        format_year: moment_date.format('YYYY'),
        eventStartDate,
        eventEndDate
      };
      events.push(event);
    });
  } catch (e) {
    console.error('Error in formatEvents : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return events;
}

export function formatServices(data: any[]): Service[] {
  const services: Service[] = [];
  try {
    data.forEach((s: any) => {
      const lien = formatLien(s);
      const service: Service = {
        id: s.id,
        flagId: s.flag_id,
        title: s.title,
        lien: lien,
        fontPicto: s['font-icon-pictogramme'],
      };
      services.push(service);
    });
  } catch (e) {
    console.error('Error in formatServices : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }

  // trier les services par ordre alphabétique
  services.sort((a: Service, b: Service) => a.flagId - b.flagId);

  return services;
}

export function formatTrends(body: any[]): Trend[] {
  return body
    .filter(item => item.titre_tendance)
    .map(item => ({
      titre: item.titre_tendance,
      keywords: item.mots_cles_tendance,
    } as Trend));
}

export function formatPageFille(data: any, path: string): PageFille | null {
  try {
    const pageFille: PageFille = {
      id: data.id,
      path: path,
      slug: data.slug,
      chapo: data.acf?.chapo,
      title: data.title?.rendered,
      dt: data.acf?.dt?.name,
      perimetre: data.acf?.perimetre?.name,
      flexibles: formatFlexibles(data.acf?.contenus_flexibles),
      parentPages: data.parent_post?.map((parent: any) => ({
        id: parent?.id,
        title: parent?.titre,
        url: parent?.url,
      }))
    };
    return pageFille;
  } catch (e) {
    console.error('Error in formatPageFille : ', e);
    sendToast({ type: 'error', msg: 'Une erreur a été rencontrée, veuillez contacter votre administrateur.' });
  }
  return null;
}
