<div class="layout">
    <div class="col-fullwidth mb-15">
        <div class="section-title">
            <img *ngIf="essentielList2Visuels.picto"
                 src="{{essentielList2Visuels.picto.url}}"
                 alt="{{essentielList2Visuels.picto.alt}}" width="40" height="40"/>
            <h2>{{ essentielList2Visuels.title }}</h2>
        </div>
    </div>

    <div class="col-fullwidth mb-60">
        <div class="module_essentiel essentiel_heading"
             [ngClass]="{'module_essentiel--reverse': essentielList2Visuels.infography_natio_pos === 'droite'}">
            <div class="essentiel_heading--infographie">
                <picture *ngIf="essentielList2Visuels.infography_natio">
                    <!-- Pour les écrans de moins de 576px -->
                    <source srcset="{{essentielList2Visuels.infography_natio.sizes?.['w518h297']}}"
                            media="(max-width: 576px)" width="518" height="297"/>
                    <!-- Pour les écrans entre 768px et 1342px -->
                    <source srcset="{{essentielList2Visuels.infography_natio.sizes?.['w296h200']}}"
                            media="(min-width: 577px) and (max-width: 1342px)" width="296" height="200"/>
                    <!-- Pour les écrans supérieurs à 1342px -->
                    <source srcset="{{essentielList2Visuels.infography_natio.sizes?.['w523h300']}}"
                            media="(min-width: 1343px)" width="523" height="300"/>
                    <!-- Image par défaut si aucune des sources précédentes ne s'applique -->
                    <img src="{{essentielList2Visuels.infography_natio.sizes?.['w523h300']}}"
                         alt="{{essentielList2Visuels.infography_natio.alt}}" width="523" height="300"/>
                </picture>
            </div>
            <app-entries [entries]="essentielList2Visuels.national_entries" [iconType]="essentielList2Visuels.icon_type"
                         class="essentiel_entries_wrapper"></app-entries>
        </div>
        <div class="module_select-list essentiel_content module_select-list"
             [ngClass]="{'module_select-list--reverse': essentielList2Visuels.infographic_territo_pos === 'gauche'}">
            <div class="module_essentiel_content_secondary">
                <div class="flexible-datavisualisation style-2">
                    <div class="flexible-datavisualisation-img">
                        <picture *ngIf="essentielList2Visuels.infography_territo">
                            <!-- Pour les écrans de moins de 576px -->
                            <source srcset="{{essentielList2Visuels.infography_territo.sizes?.['w538h265']}}"
                                    media="(max-width: 576px)" width="538" height="265"/>
                            <!-- Pour les écrans entre 768px et 1342px -->
                            <source srcset="{{essentielList2Visuels.infography_territo.sizes?.['w296h145']}}"
                                    media="(min-width: 577px) and (max-width: 1342px)" width="296" height="145"/>
                            <!-- Pour les écrans supérieurs à 1342px -->
                            <source srcset="{{essentielList2Visuels.infography_territo.sizes?.['w523h300']}}"
                                    media="(min-width: 1343px)" width="523" height="300"/>
                            <!-- Image par défaut si aucune des sources précédentes ne s'applique -->
                            <img src="{{essentielList2Visuels.infography_territo.sizes?.['w523h300']}}"
                                 width="523" height="300"
                                 alt="{{essentielList2Visuels.infography_territo.alt}}"/>
                        </picture>
                    </div>
                </div>
            </div>
            <div class="module_essentiel_content_main">
                <div class="select-list_heading--list select-list_heading--list--hightlight {{essentielList2Visuels.icon_type}}">
                    <div class="select-list_heading--list-interaction">
                        <h3 class="select-list_heading--list-interaction-title">{{ essentielList2Visuels.territorial_subtitle }}</h3>
                        <app-select-implantation class="select-implantation"
                                                 [keys]="keys"
                                                 [selectedKey]="selectedKey"
                                                 (selectedKeyChange)="onSelectedKeyChange($event)"
                        ></app-select-implantation>
                    </div>
                    <app-entries [entries]="selectedEntries" [iconType]="essentielList2Visuels.icon_type"
                                 class="essentiel_entries_wrapper"></app-entries>
                </div>
            </div>

        </div>
    </div>
</div>