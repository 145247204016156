<div class="main-layout">

    <div class="col-fullwidth">
        <app-searchbar [query]="searchQuery"
                       (searchTriggered)="onSearchTrigerred($event)">
        </app-searchbar>
    </div>


    <div class="col-left mb-60">
        <div class="news-headline-hp">
            <h2 class="title-icons-underline">
                <span class="icon vnf-icons-icon-news"></span>
                {{ totalResults === 0 ? 'Aucun' : totalResults }} résultat{{ totalResults > 1 ? 's' : '' }} </h2>
        </div>
        <div class="mb-15">
            <app-filters-bar
                    *ngIf="totalResults > 0"
                    [filtres]="filtres"
                    (searchTriggerred)="search()"
            ></app-filters-bar>
        </div>
        <app-search-results [documents]="results"></app-search-results>
        <app-search-pagination
                *ngIf="totalResults > 0"
                [totalItems]="totalResults"
                [perPage]="5"
                [maxPagesToShow]="5"
                (paginationChange)="onPaginationChange($event)"
        ></app-search-pagination>
        <div class="contact-cta">
            <span class="icon vnf-icons-icon-missing-file"></span>
            <p>Vous ne trouverez pas ce que vous cherchez ?</p>
            <a href="#" class="btn btn--primary">Contactez les équipes</a>
        </div>

        <div class="no-results-cta">
            <span class="icon vnf-icons-icon-missing-file"></span>
            <p>Votre recherche n’a donné aucun résultat.</p>
            <a href="#" class="btn btn--secondary">Déselectionner les filtres</a>
            <a href="#" class="btn btn--primary">Contactez les équipes</a>
        </div>
    </div>

    <div class="col-right mb-60">
        <div class="suggestions">
            <p>Suggestions</p>
            <div class="suggestions__list">
                <div class="suggestions__item">
                    <span class="icon vnf-icons-icon-loupe-outline"></span>
                    <div class="suggestions__image">
                        <img width="365" height="129" src="https://picsum.photos/365/129" alt="placeholder">
                    </div>
                    <p>Ipsum dolor sit amet, consec tetur adipiscing elit adipis cing elit congue nihil imperdiet</p>
                </div>
                <div class="suggestions__item">
                    <span class="icon vnf-icons-icon-loupe-outline"></span>
                    <div class="suggestions__image">
                        <img width="365" height="129" src="https://picsum.photos/365/129" alt="placeholder">
                    </div>
                    <p>Ipsum dolor sit amet, consec tetur adipiscing elit adipis cing elit congue nihil imperdiet</p>
                </div>
                <div class="suggestions__item">
                    <span class="icon vnf-icons-icon-loupe-outline"></span>
                    <div class="suggestions__image">
                        <img width="365" height="129" src="https://picsum.photos/365/129" alt="placeholder">
                    </div>
                    <p>Ipsum dolor sit amet, consec tetur adipiscing elit adipis cing elit congue nihil imperdiet</p>
                </div>
            </div>
        </div>
    </div>
</div>
