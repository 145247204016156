import { Component, Input } from '@angular/core';
import { EssentielCard } from '../../../../models/essentiels';
import { NgIf, NgClass, NgForOf } from '@angular/common';
import {CustomInnerHtmlDirective} from "../../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-essentiel-card',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgForOf,
    CustomInnerHtmlDirective,
  ],
  templateUrl: './essentiel-card.component.html',
  styleUrl: './essentiel-card.component.scss'
})
export class EssentielCardComponent {
  @Input() public card!: EssentielCard;
}
