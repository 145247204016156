@for (flexible of flexibles; track flexible.id) {
    @if (isFlexibleText(flexible)) {
        <app-flexible-text [flexible]="flexible" class="layout"></app-flexible-text>
    } @else if (isFlexibleImg(flexible)) {
        <app-flexible-img [flexible]="flexible" class="layout"></app-flexible-img>
    } @else if (isFlexibleTextImg(flexible)) {
        <app-flexible-text-img [flexible]="flexible" class="layout"></app-flexible-text-img>
    } @else if (isFlexibleFlashInfo(flexible)) {
        <app-flexible-flash-info [flashInfo]="flexible" class="layout"></app-flexible-flash-info>
    } @else if (isFlexibleOnglets(flexible)) {
        <!-- <app-flexible-onglets *ngIf="flexible" [flexible]="flexible" class="layout"></app-flexible-onglets> -->
    } @else if (isFlexibleVideo(flexible)) {
        <app-flexible-video [flexible]="flexible" class="layout"></app-flexible-video>
    } @else if (isFlexibleHighlightCard(flexible)) {
        <app-flexible-highlight-card [flexible]="flexible" class="layout"></app-flexible-highlight-card>
    } @else if (isFlexibleArche(flexible)) {
        <app-flexible-arche [flexible]="flexible" class="layout"></app-flexible-arche>
    } @else if (isFlexibleButton(flexible)) {
        <app-flexible-button class="layout" [flexible]="flexible"></app-flexible-button>
    } @else if (isFlexibleDataVisualisation(flexible)) {
        <app-flexible-datavisualisation class="layout" [flexible]="flexible"></app-flexible-datavisualisation>
    } @else if (isFlexibleSliderImg(flexible)) {
        <app-flexible-slider-img class="layout" [flexible]="flexible"></app-flexible-slider-img>
    } @else if (isFlexibleSeparateur(flexible)) {
        <app-flexible-separateur class="layout" [flexible]="flexible"></app-flexible-separateur>
    } @else if (isFlexibleSpacer(flexible)) {
        <app-flexible-spacer class="layout" [flexible]="flexible"></app-flexible-spacer>
    } @else if (isFlexiblePushServices(flexible)) {
        <app-flexible-push-service class="layout" [flexible]="flexible"></app-flexible-push-service>
    } @else if (isFlexiblePushMedias(flexible)) {
        <app-flexible-push-medias class="layout" [flexible]="flexible"></app-flexible-push-medias>
    } @else if (isFlexiblePushMediasTitle(flexible)) {
        <app-flexible-push-medias-title class="layout" [flexible]="flexible"></app-flexible-push-medias-title>
    } @else if (isFlexibleReporting(flexible)) {
        <app-flexible-reporting class="layout" [flexible]="flexible"></app-flexible-reporting>
    } @else if (isFlexiblePushReportings(flexible)) {
        <app-flexible-push-reporting class="layout" [flexible]="flexible"></app-flexible-push-reporting>
    } @else if (isFlexibleProfilCard(flexible)) {
        <app-flexible-profil-card class="layout" [flexible]="flexible"></app-flexible-profil-card>
    } @else if (isFlexiblePushPage(flexible)) {
        <app-flexible-push-page class="layout" [flexible]="flexible"></app-flexible-push-page>
    } @else if (isFlexiblePushTools(flexible)) {
        <app-flexible-push-outils class="layout" [flexible]="flexible"></app-flexible-push-outils>
    } @else if (isFlexiblePushPortraits(flexible)) {
        <app-flexible-push-portrait class="layout" [flexible]="flexible"></app-flexible-push-portrait>
    } @else if (isFlexibleAccordions(flexible)) {
        <app-flexible-accordions class="layout" [flexible]="flexible"></app-flexible-accordions>
    } @else if (isFlexibleSimilarArticles(flexible)) {
        <app-flexible-similar-articles class="layout" [flexible]="flexible"></app-flexible-similar-articles>
    } @else if (isFlexibleDossiers(flexible)) {
        <app-flexible-dossier class="layout" [flexible]="flexible"></app-flexible-dossier>
    }
}
