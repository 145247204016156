import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpEventType } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { filterParams, objToHttpParams } from '../utils/utils.service';
import { map, filter } from 'rxjs/operators';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  sendRequest(
    route: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD' | 'OPTIONS ' | 'TRACE ' | 'CONNECT',
    params: HttpParams | null = null,
    body: any = null): Observable<any> {

    const url = (route.toLowerCase().startsWith('http://') || route.toLowerCase().startsWith('https://')) ? route : this.apiUrl + route;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer token',
    });

    const req = new HttpRequest(method, url, body, {
      headers: headers,
      reportProgress: true, // Utile pour suivre la progression (upload/download)
      ...(params && { params }),
      responseType: 'json'
    });

    return this.http.request(req).pipe(
      filter(event => event.type === HttpEventType.Response),
      map((response: any) => ({ body: response.body, headers: response.headers }))
    );
  }

  async getBreves(page: number | null = null, limit: number | null = null): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
      per_page: limit,
      page: page,
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/breves`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getEvents(
    fromDate: Date | null = null,
    toDate: Date | null = null,
    dt_name: string | null = null,
    page: number | null = null,
    limit: number | null = null
  ): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
      ...(fromDate && { 'from-date': moment(fromDate).format('YYYY-MM-DD') }),
      ...(toDate && { 'to-date': moment(toDate).format('YYYY-MM-DD') }),
      ...(dt_name && { dt_name }),
      ...(limit && { per_page: limit }),
      ...(page && { page: page }),
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/evenements`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getTaxonomies(withTermes = false, page: number | null = null, limit: number | null = null): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
      per_page: limit,
      page: page,
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/vnf-et-moi/v1/taxonomies${withTermes ? '' : '/list'}`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getQuotidien(): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/pages/204`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getCap(): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/pages/202`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getIdentite(): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/pages/200`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getChapo(type: 'cap' | 'quotidien' | 'identite'): Promise<string> {
    let id = 200;

    const paramsObj = {
      _fields: 'acf.chapo',
    };

    switch (type) {
      case 'identite':
        id = 200;
        break;
      case 'cap':
        id = 202;
        break;
      case 'quotidien':
        id = 204;
        break;
    }
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/pages/${id}`, 'GET', params, null);
    const { body } = await firstValueFrom(observable);
    return body?.acf?.chapo as string;
  }

  async getPage(p: { slug: string }): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
      ...p
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/pages`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getPushOutils(page: number | null = null, limit: number | null = null): Promise<any> {
    const paramsObj = {
      per_page: limit,
      page: page,
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/page/boite-a-outils/push-outils`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getHome(): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/mises-en-avant`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async sharePageByEmail(slug: string, email: string): Promise<any> {
    const paramsObj = {
      'page-slug': slug,
      email: email
    };
    const observable = this.sendRequest(`/wp/v2/share-page`, 'POST', null, paramsObj);
    return firstValueFrom(observable);
  }

  async getActuALaUne(dt?: string, fromDate?: Date, toDate?: Date): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
      filter: 'actualites',
      ...(dt && { dt }),
      'from-date': fromDate ? moment(fromDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      ...(toDate && { 'to-date': moment(toDate).format('YYYY-MM-DD') }),
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/mises-en-avant`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getEnImages(dt?: string, theme?: string): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
      ...(theme && { 'theme-actualite': theme }),
      ...(dt && { dt }),
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/medias`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getAppelsParticipation(perimetre?: string): Promise<any> {
    const paramsObj = {
      acf_fields: 'acf',
      acf_format: 'standard',
      ...(perimetre && { perimetre }),
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/appels-participation`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getPageActualites(p?: {
    dt?: string,
    perimetre?: string,
    'theme-actu'?: string,
    'from-date'?: Date,
    'to-date'?: Date,
  }): Promise<any> {
    const paramsObj = {
      ...p,
      ...(p?.['from-date'] && { 'from-date': moment(p?.['from-date'])?.format('YYYY-MM-DD') }),
      ...(p?.['to-date'] && { 'to-date': moment(p?.['to-date'])?.format('YYYY-MM-DD') }),
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/page-actualites`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getHomepage(p?: {
    dt?: string,
    perimetre?: string,
    'theme-actu'?: string,
    'from-date'?: Date,
    'to-date'?: Date,
  }): Promise<any> {
    const paramsObj = {
      ...p,
      ...(p?.['from-date'] && { 'from-date': moment(p?.['from-date'])?.format('YYYY-MM-DD') }),
      ...(p?.['to-date'] && { 'to-date': moment(p?.['to-date'])?.format('YYYY-MM-DD') }),
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/homepage`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getActualites(
    p?: {
      slug?: string,
      dt?: string,
      perimetre?: string,
      'theme-actualite'?: string,
      'from-date'?: Date,
      'to-date'?: Date,
      page?: number | null,
      limit?: number | null
    }): Promise<any> {
    const paramsObj = {
      ...(p?.slug && { slug: p?.slug }),
      ...(p?.dt && { dt: p?.dt }),
      ...(p?.perimetre && { perimetre: p?.perimetre }),
      ...(p?.['theme-actualite'] && { 'theme-actualite': p?.['theme-actualite'] }),
      ...(p?.['from-date'] && { 'from-date': moment(p?.['from-date'])?.format('YYYY-MM-DD') }),
      ...(p?.['to-date'] && { 'to-date': moment(p?.['to-date'])?.format('YYYY-MM-DD') }),
      ...(p?.limit && { per_page: p?.limit }),
      ...(p?.page && { page: p?.page }),
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/actualites`, 'GET', params, null);
    return firstValueFrom(observable);
  }

  async getApps(
    p?: {
      categorie?: string,
      page?: number | null,
      limit?: number | null
    }): Promise<any> {
    const paramsObj = {
      ...(p?.categorie && { categorie: p?.categorie }),
      ...(p?.limit && { per_page: p?.limit }),
      ...(p?.page && { page: p?.page }),
    };
    const params = objToHttpParams(paramsObj);
    const observable = this.sendRequest(`/wp/v2/app-transverses`, 'GET', params, null);
    return firstValueFrom(observable);
  }
}
