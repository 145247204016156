import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { environment } from '../../environments/environment';
import {
  Essentiel,
  EssentielCard,
  EssentielCards,
  EssentielEntry,
  EssentielList2Visuels,
  EssentielLists1Visuel,
  EssentielMiniCards,
  EssentielPictoList
} from '../models/essentiels';
import { FlashInfo, Flexible, FlexibleAccordions, FlexibleArche, FlexibleButton, FlexibleDataVisualisation, FlexibleDossiers, FlexibleHighlightCard, FlexibleImg, FlexibleListLinks, FlexibleOnglets, FlexibleProfilCard, FlexiblePushMedias, FlexiblePushMediasTitle, FlexiblePushPage, FlexiblePushPortraits, FlexiblePushReportings, FlexiblePushServices, FlexiblePushTools, FlexibleReporting, FlexibleSeparateur, FlexibleSimilarArticles, FlexibleSliderImg, FlexibleSpacer, FlexibleText, FlexibleTextImg, FlexibleVideo } from '../models/flexibles';
import { Link } from '../models/link';
import { Tool } from '../models/tool';
import { Zoom } from '../models/zoom';
import { Actualite } from '../models/actualite';
import { EnImage } from '../models/en-images';
import { AppelsParticipation } from '../models/appels-participation';
import { Breve } from '../models/breve';
import { Event } from '../models/event';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }
}

export function loadMainPageChapo(instance: any) {
  useCache('mainPageChapo', instance, async () => {
    const data = await Promise.all([instance.apiService.getChapo('cap'), instance.apiService.getChapo('quotidien'), instance.apiService.getChapo('identite')]);
    if (data.length !== 3) return;
    instance.chapoCap = htmlDecode(data[0]).replace(/<\/?[^>]+(>|$)/g, "");
    instance.chapoQuotidien = htmlDecode(data[1]).replace(/<\/?[^>]+(>|$)/g, "");
    instance.chapoIdentite = htmlDecode(data[2]).replace(/<\/?[^>]+(>|$)/g, "");
  }, () => {
    return {
      chapoCap: instance.chapoCap,
      chapoQuotidien: instance.chapoQuotidien,
      chapoIdentite: instance.chapoIdentite,
    }
  });
}

export function copyUrlToClipboard(url?: string) {
  const currentUrl = url ? url : window.location.href;

  navigator.clipboard.writeText(currentUrl).then(() => {
    environment.enableLogging && console.log('Lien copié dans le presse-papiers !');
    // Optionnel : afficher une notification à l'utilisateur
  }).catch(err => {
    console.error('Erreur lors de la copie du lien : ', err);
  });
}

export function filterParams(obj: { [key: string]: any }) {
  return Object.entries(obj)
    .filter(([_, value]) => value != null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export function objToHttpParams(obj: { [key: string]: any }): HttpParams {
  let params = new HttpParams();
  for (const [key, value] of Object.entries(obj)) {
    if (value)
      params = params.set(key, value);
  }
  return params;
}


export function saveDataCache(name: string, data: any) {
  const hasDataToSave = (obj: any): boolean => {
    return Object.values(obj).some(value => value !== undefined);
  };
  // Save data in cache if there is data to save
  if (hasDataToSave(data)) {
    environment.enableLogging && console.log(`Saving new data in cache for key "${name}":`, data);
    localStorage.setItem(name, JSON.stringify({ ...data, cacheDate: moment().toISOString() }));
  }
}

export function loadDataCache(name: string, instance: any) {
  const data = JSON.parse(localStorage.getItem(name) || "[]");
  for (const [key, value] of Object.entries(data)) {
    instance[key] = value;
  }
  return data;
}

export async function useCache(name: string, instance: any, func: any, dataFunc: any, seconds: number = 300) {
  moment.locale('fr');

  const data = loadDataCache(name, instance);

  // Afficher le loader si les données en cache remonte a plus x seconds
  if (moment().diff(instance.cacheDate || 0, 'seconds') > seconds && !Object.entries(data)?.length) {
    instance.isLoading = true;
  }

  try {
    // call api functions
    await func();
  } catch (e) { }

  // save data in localstorage
  saveDataCache(name, dataFunc());


  instance.isLoading = false;
}

export function htmlDecode(input: string): string {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent || '';
}


export function formatZoom(data: any[], type: 'url' | 'video'): Zoom[] {
  const zooms: Zoom[] = [];
  try {
    data?.forEach((e: any) => {
      const typeLien = e.acf?.type_lien == "externe" ? 'ext' : 'int';
      const zoom: Zoom = {
        id: e.ID,
        type: type,
        visuel: e.acf.visuel,
        ...(e.acf.pictogramme && { pictogramme: e.acf.pictogramme }),
        title: e.title,
        text: e.acf?.texte,
        ...(e.acf?.type_lien && { typeLien: typeLien }),
        ...(e.acf?.type_lien && { lien: { url: typeLien == "ext" ? e.acf?.lien_externe?.url : `/${e.acf?.lien_interne?.url}` } }),
        ...(e.acf?.lien_video && { videoUrl: e.acf?.lien_video }),
      };
      zooms.push(zoom);
    });
  } catch (e) {
    console.error('Error in formatZoom : ', e);
  }
  return zooms;
}

export function formatEssentielEntries(data: any[]): EssentielEntry[] {
  try {
    return data.map((entree: any, index: number) => {
      let type = entree.type_lien === 'externe' ? 'ext' : 'int';
      let linkObj = type === 'ext' ? entree.lien_externe : entree.lien_interne;
      let url = type === 'ext' ? linkObj?.url : `/${linkObj?.url}`;
      return {
        id: index,
        type: type,
        url: url,
        title: linkObj?.title,
        description: linkObj?.description,
      } as EssentielEntry;
    });
  } catch (e) {
    console.error('Error in formatEssentielEntries : ', e);
    return [];
  }
}
export function formatTerritorialEntries(data: { label: string, code: string | null, liens: any[] }[]): { label: string, code: string | null, entries: EssentielEntry[] }[] {
  const result: { label: string, code: string | null, entries: EssentielEntry[] }[] = [];
  try {
    data.forEach((d: any, i: number) => {
      if (d.label && d.liens?.length) {
        result.push({
          label: d.label,
          code: d.code || null,
          entries: formatEssentielEntries(d.liens)
        });
      }
    });
  } catch (e) {
    console.error('Error in formatTerritorialEntries : ', e);
  }
  return result;
}

export function formatEssentiel(data: any[], icon_type: 'diamond' | 'triangle' | 'circle'): Essentiel[] {
  const essentiels: Essentiel[] = [];
  try {
    data?.forEach((e: any, index: number) => {
      let essentiel: Essentiel;

      switch (e?.acf_fc_layout) {
        case 'contenu_module_essentiel_national_territorial_liste_2_visuels':
          essentiel = {
            id: index,
            type: 'list-2-visuels',
            icon_type,
            title: e.titre,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            infography_natio: e.visuel_entrees_nationales,
            infography_natio_pos: e.position_visuel_entrees_nationales,
            national_entries: formatEssentielEntries(e.entrees_nationales),
            ...(e?.sous_titre_entrees_territoriales && { territorial_subtitle: e?.sous_titre_entrees_territoriales }),
            infography_territo: e.visuel_entrees_territoriales,
            infographic_territo_pos: e.position_visuel_entrees_territoriales,
            territorial_entries: formatTerritorialEntries(e.entrees_territoriales),
          } as EssentielList2Visuels;
          break;
        case 'contenu_module_essentiel_national_territorial_liste_1_visuel':
          essentiel = {
            id: index,
            type: 'lists-1-visuel',
            icon_type,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            title: e.titre,
            infography: e.visuel,
            infography_pos: e.position_visuel,
            national_entries: formatEssentielEntries(e.entrees_nationales),
            ...(e?.sous_titre_territorial && { territorial_subtitle: e?.sous_titre_territorial }),
            territorial_entries: formatTerritorialEntries(e.entrees_territoriales),
          } as EssentielLists1Visuel;
          break;
        case 'contenu_module_essentiel_national_cards':
          essentiel = {
            id: index,
            type: 'cards',
            icon_type,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            title: e.titre,
            ...(e.description && { description: e.description }),
            ...(e.flash_info && {
              flashInfo: {
                info: e.flash_info.type_lien == 'externe' ? e.flash_info.lien_externe.description : e.flash_info.lien_interne.description,
                typeLien: e.flash_info.type_lien == 'externe' ? 'ext' : 'int',
                lien: e.flash_info.type_lien == 'externe' ? e.flash_info.lien_externe.url : `/${e.flash_info.lien_interne.url}`
              }
            }),
            cards: e.entrees_nationales.map((c: any, i: number) => {
              const cardData = c.type_lien == 'externe' ? c.lien_externe : c.lien_interne;
              return {
                id: i,
                title: cardData.title,
                ...(cardData.description && { description: cardData.description }),
                ...(c.picto && { picto: { url: c.picto.url, alt: c.picto.alt } }),
                visuel: cardData.visuel,
                lien: cardData.url,
                couleurs_cards: e.couleurs_cards || 'clair',
              } as EssentielCard;
            }),
          } as EssentielCards;
          break;
        case 'contenu_module_essentiel_national_mini_cards':
          essentiel = {
            id: index,
            type: 'mini-cards',
            icon_type,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            title: e.titre,
            ...(e.description && { description: e.description }),
            cards: e.entrees_nationales.map((c: any, i: number) => {
              const cardData = c.type_lien == 'externe' ? c.lien_externe : c.lien_interne;
              return {
                id: i,
                title: cardData.title,
                ...(cardData.description && { description: cardData.description }),
                ...(c.picto && { picto: { url: c.picto.url, alt: c.picto.alt } }),
                visuel: cardData.visuel,
                lien: cardData.url,
                couleurs_cards: e.couleurs_cards || 'clair',
              } as EssentielCard;
            }),
            ...(e.card_complementaire && {
              cardComplementaire: {
                title: e.card_complementaire.title,
                description: e.card_complementaire.description,
                ...(e.card_complementaire.picto && { picto: { url: e.card_complementaire.picto.url, alt: e.card_complementaire.picto.alt } }),
                visuel: e.card_complementaire.visuel,
                lien: e.card_complementaire.type_lien == 'externe' ? e.card_complementaire.lien_externe : `/${e.card_complementaire.lien_interne}`,
                couleurs_cards: e.couleurs_cards || 'clair',
              }
            }),
          } as EssentielMiniCards;
          break;
        default:
          essentiel = {
            id: index,
            type: 'picto-list',
            icon_type,
            title: e.titre,
            ...(e.picto && { picto: { url: e.picto.url, alt: e.picto.alt } }),
            national_entries: formatEssentielEntries(e.entrees_nationales),
            pictoPos: e.position_picto_titre,
            picto_infography: e.picto
          } as EssentielPictoList;
          break;
      }
      essentiels.push(essentiel);
    });
  } catch (e) {
    console.error('Error in formatEssentiel : ', e);
  }
  return essentiels;
}

export function formatTools(data: any[], key = 'key'): Tool[] {
  const tools: Tool[] = [];
  try {
    for (const t of data) {
      const tool: Tool = {
        id: key + t.ID,
        title: t.title,
        text: t.acf?.description?.replace(/<\/?[^>]+(>|$)/g, ""),
        perimetre: t.acf?.perimetre?.name,
        url: t.acf?.type_lien == 'document' ? t.acf?.document?.url : `/${t.acf?.page_fille}`,
        type: t.acf?.type_lien == 'document' ? 'document' : 'page-fille',
        rubrique: {
          title: t.acf?.rubrique?.name,
          icon_type: t.acf?.rubrique?.slug == 'qui-sommes-nous' ? 'diamond' : t.acf?.rubrique?.slug == 'quel-cap' ? 'triangle' : 'circle'
        }
      };
      tools.push(tool);
    }
  } catch (e) {
    console.error('Error in formatTools : ', e);
  }
  return tools;
}

export function formatPushTools(data: any[]): Tool[] {
  const tools: Tool[] = [];
  try {
    for (const [key, value] of Object.entries(data)) {
      tools.push(...formatTools(value, key));
    }
  } catch (e) {
    console.error('Error in formatPushTools : ', e);
  }
  return tools;
}

export function formatFlexible(data: any, index: number): Flexible {
  let flexible: Flexible = {
    id: index,
    type: data.acf_fc_layout,
    bColor: data.background_color,
  };

  try {
    switch (data.acf_fc_layout) {
      case "contenu_flexible_flash_info":
        flexible = {
          id: index,
          type: 'flash-info',
          bColor: data.background_color,
          info: data.info,
          typeLien: data.type_lien == 'externe' ? 'ext' : 'int',
          lien: data.type_lien == 'externe' ? data.lien_externe?.url : `/${data.lien_interne?.url}`,
        } as FlashInfo;
        break;
      case "contenu_flexible_zone_texte":
        flexible = {
          id: index,
          type: 'text',
          bColor: data.background_color,
          content: data.zone_texte,
        } as FlexibleText;
        break;
      case "contenu_flexible_image":
        flexible = {
          id: index,
          type: 'img',
          bColor: data.background_color,
          visuel: data.visuel,
        } as FlexibleImg;
        break;
      case "contenu_flexible_texte_image_g":
        flexible = {
          id: index,
          type: 'text-img',
          bColor: data.background_color,
          title: data.titre,
          content: data.texte,
          visuel: { ...data.visuel, date: moment(data.visuel.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') },
        } as FlexibleTextImg;
        break;
      case "contenu_flexible_onglets":
        flexible = {
          id: index,
          type: 'onglets',
          bColor: data.background_color,
        } as FlexibleOnglets;
        break;
      case "contenu_flexible_exergue":
        flexible = {
          id: index,
          type: 'highlight-card',
          bColor: data.background_color,
          content: data.texte,
          full_width: data.full_width,
          url: data.bouton.url,
        } as FlexibleHighlightCard;
        break;
      case "contenu_flexible_video":
        flexible = {
          id: index,
          type: 'video',
          bColor: data.background_color,
          url: data.lien_video,
          ...(data.visuel && { visuel: data.visuel })
        } as FlexibleVideo;
        break;
      case "contenu_flexible_arche":
        flexible = {
          id: index,
          type: 'arche',
          bColor: data.background_color,
          aColor: data.arch_color,
          title: data.titre,
          full_width: data.disposition == 'full' ? true : false,
        } as FlexibleArche;
        break;
      case "contenu_flexible_bouton":
        flexible = {
          id: index,
          type: 'button',
          bColor: data.background_color,
          title: data.bouton_secondary?.title,
          url: data.bouton_secondary?.url,
          bType: data.type,
        } as FlexibleButton;
        break;
      case "contenu_flexible_datavisualisation_widget":
        flexible = {
          id: index,
          type: 'data-visualisation',
          bColor: data.background_color,
          title: data.titre,
          bTitle: data.bouton?.title,
          bUrl: data.bouton?.url,
          elements: data.elements?.map((e: any, i: number) => {
            return {
              id: i,
              style: e.format_description,
              title: e.titre,
              url: e.lien?.url,
              line1: e['line-1'],
              line2: e['line-2'],
              line3: e['line-3'],
              visuel: e.visuel,
              visuel_texte: e.visuel_texte,
            };
          }),
        } as FlexibleDataVisualisation;
        break;
      case "contenu_flexible_slider_images":
        flexible = {
          id: index,
          type: 'slider-img',
          bColor: data.background_color,
          visuels: data.visuels.map((v: any, i: number) => ({ ...v, id: i, date: moment(v.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') }))
        } as FlexibleSliderImg;
        break;
      case "contenu_flexible_separator":
        flexible = {
          id: index,
          type: 'separateur',
          bColor: data.background_color,
        } as FlexibleSeparateur;
        break;
      case "contenu_flexible_spacer":
        flexible = {
          id: index,
          type: 'spacer',
          bColor: data.background_color,
          size: data.spacer == 'spacer-m' ? 'm' : 'xl',
        } as FlexibleSpacer;
        break;
      case "contenu_flexible_push_services":
        flexible = {
          id: index,
          type: 'push-services',
          bColor: data.background_color,
          services: data.push_services?.map((s: any, i: number) => ({
            id: i,
            title: s.title,
            description: s.content,
            lien: { type: s?.acf?.type == 'externe' ? 'ext' : 'int', url: s?.acf?.type == 'externe' ? s?.acf?.lien_externe : `/${s?.acf?.lien_interne}` } as Link,
            fontPicto: s.acf?.['font-icon-pictogramme']
          }))
        } as FlexiblePushServices;
        break;
      case "contenu_flexible_push_medias":
        flexible = {
          id: index,
          type: 'push-medias',
          bColor: data.background_color,
          visuels: data.push_medias?.map((v: any, i: number) => ({ ...v.visuel, id: i, title: null }))
        } as FlexiblePushMedias;
        break;
      case "contenu_flexible_push_medias_titre":
        flexible = {
          id: index,
          type: 'push-medias-title',
          bColor: data.background_color,
          medias: data.push_medias_titre?.map((v: any, i: number) => ({
            id: i,
            title: v.titre,
            cColor: v.couleur_card,
            typeMedia: v.type_media == "image" ? 'img' : 'video',
            visuel: v.visuel
          }))
        } as FlexiblePushMediasTitle;
        break;
      case "contenu_flexible_reportage_metier_portrait":
        flexible = {
          id: index,
          type: 'reporting',
          bColor: data.background_color,
          title: data.titre,
          content: data.texte,
          visuel: { ...data.visuel, date: moment(data.visuel?.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') }
        } as FlexibleReporting;
        break;
      case "contenu_flexible_push_reportages":
        flexible = {
          id: index,
          type: 'push-reportings',
          bColor: data.background_color,
          title: data.titre,
          reportings: data.push_reportages?.map((r: any, i: number) => ({
            id: i,
            title: r.titre,
            content: r.texte,
            thematique: r.thematique,
            visuel: { ...r.visuel, date: moment(r.visuel?.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') },
            lien: { url: r.lien?.url, label: r.lien?.title }
          }))
        } as FlexiblePushReportings;
        break;
      case "contenu_flexible_profils_cards":
        flexible = {
          id: index,
          type: 'profil-card',
          bColor: data.background_color,
          title: data.titre,
          lien: { url: data.bouton?.url, label: data.bouton?.title },
          profils: data.cartes?.map((p: any, i: number) => ({
            id: i,
            name: p.nom,
            fonction: p.fonction,
            phone: p.telephone,
            email: p.email,
            visuel: p.visuel,
          }))
        } as FlexibleProfilCard;
        break;
      case "contenu_flexible_push_cards":
        flexible = {
          id: index,
          type: 'push-page',
          bColor: data.background_color,
          elements: data.push_cards?.map((e: any, i: number) => {
            const type = e.type == 'page' ? 'page' : 'document';
            const typeLien = e[type]?.type_lien == 'interne' ? 'int' : 'ext';
            const content = typeLien == 'ext' ? e[type]?.lien_externe : e[type]?.lien_interne;
            const documentName = (type === 'document' && e.document?.nom_document) ? e.document?.nom_document : null;
            return {
              id: i,
              type: type,
              title: documentName ?? content.title,
              ...(content.visuel && { visuel: content.visuel }),
              lien: { url: content.url, type: typeLien },
              tagMain: type == 'document' ? type : e[type]?.tag_principal,
              ...(type == 'page' && e[type]?.tag_secondaire && { tagSecondary: e[type]?.tag_secondaire }),
            };
          })
        } as FlexiblePushPage;
        break;
      case "contenu_flexible_push_outils":
        flexible = {
          id: index,
          type: 'push-tools',
          bColor: data.background_color,
          tools: formatTools(data.outils),
        } as FlexiblePushTools;
        break;
      case "contenu_flexible_push_portraits":
        flexible = {
          id: index,
          type: 'push-portraits',
          bColor: data.background_color,
          portraits: data.push_portraits?.map((e: any, i: number) => ({
            id: i,
            fonction: e.fonction,
            nom: e.nom,
            content: e.texte,
            visuel: e.visuel
          }))
        } as FlexiblePushPortraits;
        break;
      case "contenu_flexible_liste_liens":
        flexible = {
          id: index,
          type: 'list-links',
          bColor: data.background_color,
          links: data.liste_liens?.map((l: any, i: number) => {
            const typeLien = l.type_lien == 'interne' ? 'int' : 'ext';
            const contentLien = typeLien == 'ext' ? l.lien_externe : l.lien_interne;
            return {
              id: i,
              label: contentLien.titre,
              category: contentLien.categorie,
              url: contentLien.url,
              type: typeLien
            };
          })
        } as FlexibleListLinks;
        break;
      case "contenu_flexible_accordeons":
        flexible = {
          id: index,
          type: 'accordions',
          bColor: data.background_color,
          accordions: data.accordeons?.map((a: any, i: number) => ({
            id: i,
            title: a.titre,
            flexibles: a.contenus_flexibles?.map((c: any, id: number) => formatFlexible(c, id))
          }))
        } as FlexibleAccordions;
        break;
      case "contenu_flexible_articles_similaires":
        flexible = {
          id: index,
          type: 'similar-articles',
          bColor: data.background_color,
          articles: data.articles_similaires && data.articles_similaires?.map((a: any, i: number) => {
            const moment_date = moment(a.date);
            return {
              id: i,
              title: a.title,
              date: {
                format_day: moment_date.format('DD'),
                format_month: moment_date.format('MMM'),
                format_year: moment_date.format('YYYY'),
                full: a.date,
              },
              description: a.description,
              visuel: a.visuel,
              perimetre: a.perimetre?.name,
              theme: a['theme-actualite']?.name,
              lien: { url: a.url },
            };
          }),
          medias: data.medias_similaires && data.medias_similaires?.map((m: any, i: number) => ({
            id: i,
            title: m.title,
            visuel: m.visuel,
            lien: { url: m.url },
            perimetre: m.perimetre?.name,
            theme: m['theme-actualite']?.name,
            ...(m.video && { video: m.video }),
            ...(m.infographie && { infographie: m.infographie }),
          }))
        } as FlexibleSimilarArticles;
        break;
      case "contenu_flexible_module_dossier":
        flexible = {
          id: index,
          type: 'dossiers',
          bColor: data.background_color,
          title: data.titre,
          dossiers: data.push_dossiers && data.push_dossiers?.map((d: any, i: number) => ({
            id: i,
            title: d.titre,
            content: d.texte,
            thematique: d.thematique,
            visuel: d.visuel,
            lien: { url: d.lien.url, label: d.lien.title }
          }))
        } as FlexibleDossiers;
        break;
      default:
        flexible = {
          id: index,
          type: data.acf_fc_layout,
          bColor: data.background_color,
        } as FlexibleText;
    }
  } catch (e) {
    console.error('Error in formatFlexible : ', e);
  }
  return flexible;
}

export function formatFlexibles(data: any[]): Flexible[] {
  const flexibles: Flexible[] = [];
  try {
    data.forEach((f: any, index: number) => {
      flexibles.push(formatFlexible(f, index));
    });
  } catch (e) {
    console.error('Error in formatFlexibles : ', e);
  }
  return flexibles;
}

export function formatActualitesALaUne(data: any[]): Actualite[] {
  const actualites: Actualite[] = [];
  try {
    for (const [k, v] of Object.entries(data)) {
      if (v instanceof Array && v.length) {
        v.forEach((a: any, i: number) => {
          const exist = actualites.find(actu => actu.id === a.ID);
          const moment_date = moment(a.date, 'DD/MM/YYYY');
          if (!exist)
            actualites.push({
              id: a.ID,
              title: a.title,
              chapo: a.chapo,
              date: {
                format_day: moment_date.format('DD'),
                format_month: moment_date.format('MMM'),
                format_year: moment_date.format('YYYY'),
                full: moment_date.format('YYYY-MM-DD'),
                date: moment_date.toDate()
              },
              dt: a.dt,
              perimetre: a.perimetre,
              theme: a['theme-actu'],
              lien: { url: a.url },
              important: k == 'actualites_majeures' ? true : false,
              visuel: a.visuel
            });
        });
      }
    }
  } catch (e) {
    console.error('Error in formatActualitesALaUne : ', e);
  }
  return actualites;
}

export function formatActualites(data: any[]): Actualite[] {
  const actualites: Actualite[] = [];
  try {
    data.forEach((a: any, i: number) => {
      const exist = actualites.find(actu => actu.id === a.id);
      const moment_date = moment(a.acf?.date);
      if (!exist)
        actualites.push({
          id: a.id,
          title: a.title.rendered,
          chapo: a.acf?.chapo,
          date: {
            format_day: moment_date.format('DD'),
            format_month: moment_date.format('MMM'),
            format_year: moment_date.format('YYYY'),
            full: moment_date.format('YYYY-MM-DD'),
            date: moment_date.toDate()
          },
          dt: a.acf?.dt?.name,
          perimetre: a.acf?.perimetre?.name,
          theme: a.acf?.theme_actualite?.name,
          lien: { url: a.slug },
          important: a.acf?.important,
          visuel: a.acf?.visuel
        });
    });
  } catch (e) {
    console.error('Error in formatActualites : ', e);
  }
  return actualites;
}

export function formatEnImages(data: any[]): EnImage[] {
  const enImages: EnImage[] = [];
  try {
    data.forEach((a: any, i: number) => {
      const exist = enImages.find(img => img.id === a.id);
      const typeMedia = a.acf?.type_media == 'infographie' ? 'image' : 'video';
      if (!exist)
        enImages.push({
          id: a.id,
          title: a.title.rendered,
          dt: a.acf?.dt,
          perimetre: a.acf?.perimetre?.name,
          theme: a.acf?.theme_actualite?.name,
          lien: { url: typeMedia == 'image' ? a.acf?.lien_infographie : a.acf?.lien_video },
          visuel: a.acf?.visuel,
          infographie: a.acf?.infographie,
          typeMedia: typeMedia,
        });
    });
  } catch (e) {
    console.error('Error in formatEnImages : ', e);
  }
  return enImages;
}

export function formatAppelsParticipationPush(data: any[]): AppelsParticipation[] {
  const appelsParticipations: AppelsParticipation[] = [];
  try {
    data.forEach((a: any, i: number) => {
      const exist = appelsParticipations.find(e => e.id === a.ID);
      const moment_date = moment(a.acf?.date);
      if (!exist)
        appelsParticipations.push({
          id: a.ID,
          title: a.title,
          chapo: a.description,
          dt: a.dt?.name,
          perimetre: a.perimetre?.name,
          date: {
            format_day: moment_date.format('DD'),
            format_month: moment_date.format('MMM'),
            format_year: moment_date.format('YYYY'),
            full: moment_date.format('YYYY-MM-DD'),
            date: moment_date.toDate()
          },
          lien: { url: `/${a.url}` },
          visuel: a.visuel
        });
    });
  } catch (e) {
    console.error('Error in formatAppelsParticipation : ', e);
  }
  return appelsParticipations;
  ;
}

export function formatBreves(data: any[]): Breve[] {
  const breves: Breve[] = [];
  try {
    data.forEach((e: any) => {
      let department = (e.acf?.perimetre?.slug === "siege") ? e.acf.perimetre.slug : e.acf?.dt?.name;
      const breve: Breve = {
        id: e.ID,
        perimeter: e.acf?.perimetre?.slug,
        date: moment(e.post_modified).format('DD.MM.YYYY'),
        dt: department,
        text: e.acf?.texte?.replace(/<\/?[^>]+(>|$)/g, ""),
        richText: e.acf?.texte,
        ...(e.acf?.lien &&
        {
          lien: { url: e.acf?.lien?.url, label: e.acf?.lien?.title }
        }),
        isModalOpen: false,
      };
      breves.push(breve);
    });
  } catch (e) {
    console.error('Error in formatBreves : ', e);
  }
  return breves;
}

export function formatEvents(data: any[]): Event[] {
  const events: Event[] = [];
  try {
    data.forEach((e: any) => {
      const moment_date = e.acf?.date?.includes('T') ? moment(e.acf?.date) : moment(e.acf?.date, 'DD/MM/YYYY');
      const department = (e.acf?.perimetre?.slug === 'siege') ? e.acf?.perimetre?.name : e.acf?.dt?.name;
      const event: Event = {
        id: e.id,
        date: moment_date.format('DD/MM/YYYY'),
        dt: department,
        text: e.acf?.texte?.replace(/<\/?[^>]+(>|$)/g, ""),
        ...(e.acf?.lien &&
        {
          lien: { url: e.acf?.lien?.url, label: e.acf?.lien?.title }
        }),
        format_day: moment_date.format('DD'),
        format_month: moment_date.format('MMM'),
        format_year: moment_date.format('YYYY'),
        eventStartDate: moment_date.startOf('day').toDate(),
        eventEndDate: moment_date.endOf('day').toDate(),
      };
      events.push(event);
    });
  } catch (e) {
    console.error('Error in formatEvents : ', e);
  }
  return events;
}
