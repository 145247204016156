import { Component } from '@angular/core';

@Component({
  selector: 'app-mes-services',
  standalone: true,
  imports: [],
  templateUrl: './mes-services.component.html',
  styleUrl: './mes-services.component.scss'
})
export class MesServicesComponent {

  toggleFavorite(appItem: HTMLElement, event: Event): void {
    event.preventDefault();
    appItem.classList.toggle('favorite');
  }
}
