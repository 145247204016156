import {Directive, ElementRef, Input} from '@angular/core';
import DOMPurify from "dompurify";

@Directive({
  selector: '[customInnerHTML]',
  standalone: true
})
export class CustomInnerHtmlDirective {
  constructor(private el: ElementRef) {
  }

  @Input() set customInnerHTML(content: string | null | undefined) {

    if (content === null || content === undefined) {
      this.el.nativeElement.innerHTML = '';
      return;
    }

    const sanitizedContent = DOMPurify.sanitize(content, {
      ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'div', 'span', 'p', 'h1', 'h2',
        'h3', 'h4', 'h5', 'h6', 'ul', 'li', 'ol', 'br', 'hr', 'img'],
      ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'style','target'],  // Keep inline styles
      FORBID_TAGS: ['script'] // Ensure <script> tags are removed
    });
    this.el.nativeElement.innerHTML = sanitizedContent;
  }


}
