import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, Location, NgFor, NgIf } from '@angular/common';
import { MatDialog } from "@angular/material/dialog";
import { SearchbarComponent } from "../../components/search/searchbar/searchbar.component";
import { SearchResultsComponent } from "../../components/search/search-results/search-results.component";
import { FiltersBarComponent } from "../../components/search/filters-bar/filters-bar.component";
import { SearchResult } from "../../models/search-result";
import { ElasticsearchService } from "../../services/elasticsearch.service";
import { SearchPaginationComponent } from "../../components/search/search-pagination/search-pagination.component";
import { SearchFilter, SearchSubFilter } from "../../models/search-filters";
import { LoadingOverlayComponent } from "../../components/loading-overlay/loading-overlay.component";
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
import { Visuel } from '../../models/visuel';
import { objToHttpParams } from '../../utils/utils.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-recherche',
  standalone: true,
  imports: [CommonModule, SearchbarComponent, SearchResultsComponent, FiltersBarComponent, SearchPaginationComponent, LoadingOverlayComponent, NgFor, NgIf],
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.scss']
})
export class RechercheComponent implements OnInit, OnDestroy {
  readonly dialog = inject(MatDialog);
  @ViewChild('searchContainer') searchContainer!: ElementRef;
  private loadingSubscription: Subscription | undefined;
  isLoading: boolean = false;
  queryLaunched = false;
  currentPage: number = 1;
  perPage: number = 10;
  searchQuery: string = '';
  results: any[] = [];
  totalResults: number = 0;
  isMobileFilterBarOpen: boolean = false;
  suggestions: { title: string, visuel: Visuel, key: string }[] = [];

  filtres: {
    perimetres: SearchFilter[] | null,
    themes: SearchFilter[] | null,
    natures: SearchFilter[] | null,
    startDate: Date | null,
    endDate: Date | null
  } = { themes: null, perimetres: [], natures: [], startDate: null, endDate: null };

  readonly natureFiltersNomenclature: { [key: string]: string[] } = {
    "Documents officiels et/ou signés": ["note", "décret", "circulaire", "instruction", "procès verbal", "politique", "procédure", "décision", "délégation", "bulletin officiel", "texte officiel"],
    "Documents internes sans obligation de signature": ["glossaire", "organigramme", "guide", "fiche pratique", "flyer", "modèle-charte", "compte-rendu", "information"],
    "Autre": []
  };

  constructor(private elasticSearchService: ElasticsearchService, private location: Location, private apiService: ApiService) {
  }

  ngOnInit(): void {

    // Récupère la requête de recherche depuis l'URL si elle existe
    this.checkQueryUrl();
    // Charger les filtres depuis le localstorage
    this.loadFilters();
    // Lancer une recherche si une requête de recherche est présente
    if (this.searchQuery) {
      this.currentPage = 1;
      this.search(true);
    }

    this.loadingSubscription = this.elasticSearchService.loading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });

    // Souscrire aux changements des résultats de recherche
    this.elasticSearchService.searchResults$.subscribe((searchResult: SearchResult) => {
      // Mets à jour les résultats de recherche
      this.results = searchResult.hits;
      this.totalResults = searchResult.total;

      // Mets à jour les filtres de nature seulement si aucun filtre n'est appliqué
      if (!searchResult.hasFilters && searchResult.facets?.natureDistribution) {
        // Initialisation des filtres principaux avec une valeur de 0 et un tableau vide pour les sous-filtres
        this.filtres.natures = Object.keys(this.natureFiltersNomenclature).map((categorie) => ({
          name: categorie,
          value: "0",
          code: null,
          checked: false,
          active: false,
          sub_filters: []
        }));

        searchResult.facets.natureDistribution
          .filter((bucket: any) => bucket.key !== '')  // On ignore les clés vides
          .forEach((bucket: any) => {
            const { key, doc_count } = bucket;

            // Vérifie si le filtre appartient à l'une des deux catégories
            const category = Object.keys(this.natureFiltersNomenclature).find(
              category => this.natureFiltersNomenclature[category].includes(key.toLowerCase())
            );

            if (category) {
              // Trouve la catégorie correspondante dans `this.filtres.natures` puis ajoute le sous-filtre
              const filtre = this.filtres.natures?.find((f) => f.name === category);
              if (filtre) {
                filtre.sub_filters.push({ name: key, value: doc_count, code: null, checked: false, active: false });
              }
            } else {
              // Si le filtre ne correspond à aucune catégorie, on l'ajoute dans "Autres"
              const otherFilter = this.filtres.natures?.find((f) => f.name === "Autres");
              if (otherFilter) {
                otherFilter.sub_filters.push({ name: key, value: doc_count, code: null, checked: false, active: false });
              }
            }
          });

        // Filtre final pour supprimer les catégories vides
        this.filtres.natures = this.filtres.natures.filter(
          (filtre) => filtre.sub_filters.length > 0
        );

        this.queryLaunched = true;
      }

      // Mets à jour les filtres de périmètre seulement si aucun filtre n'est appliqué
      if (!searchResult.hasFilters && searchResult.facets?.perimetreDistribution) {
        this.filtres.perimetres = searchResult.facets?.perimetreDistribution
          .filter((bucket: any) => bucket.key !== '')
          .map((bucket: any) => {
            return {
              name: bucket.key,
              value: bucket.doc_count,
              checked: false,
              sous_filtres: []
            }
          });
      }
    });

    // récupérer les suggestions
    this.apiService.getSuggestions().then((data) => {
      this.suggestions = data?.body?.map((suggestion: any) => ({
        title: suggestion.titre_suggestion,
        visuel: suggestion.visuel_suggestion,
        key: suggestion.mots_cles_suggestion
      })) || [];
      environment.enableLogging && console.log('getSuggestions', data);
    });
  }

  handleMobileFilterBarChange(isOpen: boolean): void {
    // Mettre à jour l'état de la barre de filtres mobiles
    this.isMobileFilterBarOpen = isOpen;
  }

  uncheckedAllFilters() {
    this.filtres.themes?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.natures?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.natures?.forEach((filter: SearchFilter) => filter.sub_filters?.forEach((subFilter: SearchSubFilter) => subFilter.checked = false));
    this.filtres.perimetres?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.perimetres?.forEach((filter: SearchFilter) => filter.sub_filters?.forEach((subFilter: SearchSubFilter) => subFilter.checked = false));
    this.filtres.startDate = null;
    this.filtres.endDate = null;
  }

  checkQueryUrl(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('q');
    if (searchQuery) {
      this.searchQuery = decodeURIComponent(searchQuery) ?? '';
    }
  }

  onPaginationChange(event: any): void {
    // Mettre à jour les infos de pagination
    if (this.perPage !== event.perPage) {
      this.perPage = event.perPage;
      this.currentPage = 1;
    } else {
      this.currentPage = event.page;
    }
    // Lancer une nouvelle recherche
    this.search();

    // Scroll to the top of the search container
    this.scrollTop();
  }

  onClickSuggestion(query: string): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.onSearchTrigerred(query);
  }

  onSearchTrigerred(query: string): void {
    this.uncheckedAllFilters();
    this.searchQuery = query;
    this.search(true);
  }

  newFiltersSearch(): void {
    this.currentPage = 1;
    this.search();
  }

  search(newQuery: boolean = false): void {
    if (newQuery) this.currentPage = 1; // If it's a new query, reset the page number
    this.elasticSearchService.queryDocuments(
      this.searchQuery,
      this.perPage,
      this.currentPage,
      this.filtres,
      newQuery // Si la recherche est une nouvelle requête on sauvegarde l'historique
    ).subscribe();

    // sauvegarder les filtres dans le localstorage
    this.saveFilters();
  }

  loadFilters(): void {
    // Charger les filtres depuis le localstorage
    const savedFilters = localStorage.getItem('searchFilters');
    if (savedFilters) {
      this.filtres = JSON.parse(savedFilters);
      this.queryLaunched = true;
    }
  }

  saveFilters(): void {
    // Sauvegarde les filtres dans le localstorage
    localStorage.setItem('searchFilters', JSON.stringify(this.filtres));
  }

  scrollTop(): void {
    // Scroll to the top of the search container
    if (this.searchContainer) {
      const offset = 20;
      const elementPosition = this.searchContainer.nativeElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
    }
  }

  clear() {
    this.uncheckedAllFilters();
    this.search();
  }

  documentRequest(): void {
    const natures: string[] = [];

    this.filtres.natures?.forEach((nature) => {
      nature?.sub_filters?.forEach((sub) => {
        if (sub.checked)
          natures.push(sub.name);
      });
    });

    const paramsObj = {
      ...(this.searchQuery && { q: this.searchQuery }),
      ...(this.filtres.themes && { themes: this.filtres.themes.map((theme) => theme.name).join(',') }),
      ...(this.filtres.natures && { natures: natures.join(',') }),
      ...(this.filtres.perimetres && { perimetres: this.filtres.perimetres.filter((p) => p.checked).map((perimetre) => perimetre.name).join(',') }),
      ...(this.filtres.startDate && { startDate: this.filtres.startDate.toISOString() }),
      ...(this.filtres.endDate && { endDate: this.filtres.endDate.toISOString() })
    };
    const params = objToHttpParams(paramsObj);
    window.open(`/demande-document?${params}`, '_self');
  }

  back(): void {
    this.location.back();
  }

  openMobileFiltersBar(): void {
    this.isMobileFilterBarOpen = true;
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

}
