import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { FlashInfo } from '../../../models/flexibles';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-flash-info',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-flash-info.component.html',
  styleUrl: './flexible-flash-info.component.scss'
})
export class FlexibleFlashInfoComponent {
  @Input() flashInfo!: FlashInfo;
}
