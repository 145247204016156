<div class="layercard__item {{card.couleurs_cards == 'fonce'? 'layercard__item blue': ''}}">
    <picture>
        <source *ngIf="card.visuel.sizes?.['w330h175']"
                srcset="{{card.visuel.sizes?.['w330h175']}}"
                width="330" height="175"
                media="(max-width: 420px)" />
        <source *ngIf="card.visuel.sizes?.['w680h360']"
                srcset="{{card.visuel.sizes?.['w680h360']}}"
                width="680" height="360"
                media="(min-width: 421px) and (max-width: 767px)" />
        <source *ngIf="card.visuel.sizes?.['w330h175']"
                srcset="{{card.visuel.sizes?.['w330h175']}}"
                width="330" height="175"
                media="(min-width: 768px) and (max-width: 1601px)" />
        <source *ngIf="card.visuel.sizes?.['w400h212']"
                srcset="{{card.visuel.sizes?.['w400h212']}}"
                width="400" height="212"
                media="(min-width: 1601px)" />
        <img *ngIf="card.visuel"
             src="{{card.visuel.sizes?.['w400h212']}}"
             width="400" height="212"
             alt="{{card.visuel?.alt}}" />
    </picture>
    <div class="layercard__item__inner">
        <div class="layercard__item__title__bloc">
            <img *ngIf="card.picto" src="{{card.picto.url}}" alt="{{card.picto.alt}}" width="40" height="40" />
            <h4 class="layercard__item__title">
                {{card.title}}
            </h4>
        </div>
        <p *ngIf="card.description" [customInnerHTML]="card.description"></p>
    </div>
    <a href="{{card.lien}}" class="layercard__item__link">Accéder</a>
</div>
