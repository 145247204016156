<div class="page-fille">
<div class="child-page__actions">
    <button class="previous-page" (click)="back()">Page précédente</button>

    <app-share [options]="shareOptions"></app-share>
</div>
<app-breadcrumb [links]="breadcrumbLinks"></app-breadcrumb>
<app-flexible-text class="layout layout-fille" [flexible]="headerFlexible"></app-flexible-text>
<div  *ngIf="chapo" class="flexible-text flexible-content">
    <div class="col-left">
        <div class="chapo" [innerHTML]="chapo"></div>
    </div>
</div>
<app-flexible-img class="layout layout-fille" *ngIf="flexibleImg && flexibleImg.visuel"
    [flexible]="flexibleImg"></app-flexible-img>
<div class="tag_list tag_list__child">
    <span *ngIf="content.dt" class="news-highlight-tag" [innerHTML]="'DT '+content.dt"></span>
    <span *ngIf="content.perimetre === 'Siège'" class="news-highlight-tag" [innerHTML]="content.perimetre"></span>
</div>

<app-flexibles [flexibles]="content.flexibles"></app-flexibles>
</div>
