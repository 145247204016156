<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <mat-tab-group class="flexible-content" animationDuration="500ms"
                   [disablePagination]="disablePagination"
                   [ngClass]="{'full-width-tabs': disablePagination}">
      @for (onglet of flexible.onglets; track onglet.id) {
        <mat-tab label="{{onglet.title}}">
          <app-flexibles [flexibles]="onglet.flexibles"></app-flexibles>
        </mat-tab>
      }
    </mat-tab-group>
  </div>