import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { FlexibleTextImg } from '../../../models/flexibles';
import {CustomInnerHtmlDirective} from "../../../directives/custom-inner-html.directive";

@Component({
  selector: 'app-flexible-text-img',
  standalone: true,
  imports: [CommonModule, CustomInnerHtmlDirective],
  templateUrl: './flexible-text-img.component.html',
  styleUrl: './flexible-text-img.component.scss'
})
export class FlexibleTextImgComponent {
  @Input() public flexible!: FlexibleTextImg;
}
