import { NgClass } from '@angular/common';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button'; // Import correct de MatButtonModule
import { ShareOption } from '../../models/share-option';

@Component({
  selector: 'app-share',
  standalone: true,
  imports: [NgClass, MatButtonModule],
  templateUrl: './share.component.html',
  styleUrl: './share.component.scss',
})
export class ShareComponent {
  isOptionsMenuActive = false;
  @Input() options!: ShareOption[];

  @ViewChild('optionsMenu') optionsMenu!: ElementRef;
  @ViewChild('shareButton') shareButton!: ElementRef;

  constructor() {}

  toggleShareOptionsMenu(): void {
    this.isOptionsMenuActive = !this.isOptionsMenuActive;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event): void {
    const clickedInsideMenu = this.optionsMenu.nativeElement.contains(event.target);
    const clickedInsideButton = this.shareButton.nativeElement.contains(event.target);

    if (!clickedInsideMenu && !clickedInsideButton) {
      this.isOptionsMenuActive = false;
    }
    if (clickedInsideMenu) {
      this.isOptionsMenuActive = false;
    }
  }
}
