import { Component } from '@angular/core';
import {LoadingOverlayComponent} from "../../loading-overlay/loading-overlay.component";
import {MatDialogActions, MatDialogClose, MatDialogContent} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-dialog-confirm-email',
  standalone: true,
    imports: [
        LoadingOverlayComponent,
        MatDialogActions,
        MatDialogClose,
        MatDialogContent,
        MatFormField,
        MatInput,
        MatLabel,
        NgIf
    ],
  templateUrl: './dialog-confirm-email.component.html',
  styleUrl: './dialog-confirm-email.component.scss'
})
export class DialogConfirmEmailComponent {

}
