import { Component, Input } from '@angular/core';
import {EssentielPictoList} from "../../../models/essentiels";
import {NgClass, NgIf} from "@angular/common";
import {EntriesComponent} from "../entries/entries.component";

@Component({
  selector: 'app-picto-list',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    EntriesComponent,
  ],
  templateUrl: './picto-list.component.html',
  styleUrl: './picto-list.component.scss'
})
export class PictoListComponent {
  @Input() public essentielPictoList!: EssentielPictoList;
}
