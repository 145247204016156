import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild, ElementRef, Renderer2, AfterViewInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import { Tool } from '../../models/tool';

registerSwiperElements();

@Component({
  selector: 'app-slider-outils',
  standalone: true,
  imports: [],
  templateUrl: './slider-outils.component.html',
  styleUrl: './slider-outils.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SliderOutilsComponent implements AfterViewInit, OnChanges {
  @Input() tools: Tool[] = [];
  @ViewChild('swiperContainer', { static: false }) swiperContainer!: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) { }

  // Detect changes when the input 'breves' is updated
  ngOnChanges(): void {
    this.cdr.detectChanges();
    this.initSliderEvent();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.initSliderEvent();
  }

  initSliderEvent(): void {
    const swiperEl = this.swiperContainer.nativeElement;

    if (swiperEl.swiper) {
      swiperEl.swiper.update();
    } else {
      this.initializeSwiper(swiperEl);
    }

    const paginationEl = swiperEl.querySelector('.swiper-pagination');
    if (paginationEl) {
      this.renderer.setStyle(paginationEl, 'position', 'initial'); // Exemple de style
    }
  }

  initializeSwiper(swiperEl: any): void {
    // Swiper parameters
    const swiperParams = {
      slidesPerView: 1,
      direction: 'horizontal',
      cssMode: false,
      loop: false,
      speed: 500,
      spaceBetween: 20,
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }
}
