<div class="personal-list">
  <div class="personal-list__name">
    <span>Mes...</span>
  </div>

  <!--
  <div class="personal-list__item">
    <a class="personal-list__link" href="#">Ouverture modale mes favoris</a>
    <span>Mes favoris</span>
    <span class="icon icon-bookmark"></span>
  </div>
  -->

  <div class="personal-list__item">
    <a class="personal-list__link" href="#" (click)="toggleDropdown($event, 0)">Ouverture dropdown mes apps</a>
    <span>Mes Apps</span>
    <span class="icon vnf-icons-icon-apps"></span>

    <div class="personal-dropdown" [ngClass]="{'active': isActive[0]}">
      <button class="personal-list__close--mobile" (click)="toggleDropdown($event, 0)">Fermer le dropdown mes apps</button>
      <a routerLink="/mes-apps" routerLinkActive="active" class="personal-dropdown-link"></a>
      <span class="personal-dropdown__title">
        <span class="icon vnf-icons-icon-apps"></span>
        <span class="title">Mes Apps</span>
      </span>
      <div class="personal-dropdown-list">
        <swiper-container class="swiper-container" #swiperContainer>
          <swiper-slide *ngFor="let app of apps; let i = index">
            <div class="personal-dropdown__item">
              <a href="{{app.url}}" [innerHTML]="app.title"></a>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div class="personal-dropdown-overlay" (click)="toggleDropdown($event, 0)">
    </div>
  </div>

  <div class="personal-list__item">
    <a class="personal-list__link" href="#" (click)="toggleDropdown($event, 1)">Ouverture dropdown mes liens</a>
    <span>Mes liens</span>
    <span class="icon vnf-icons-icon-link"></span>

    <div class="personal-dropdown" [ngClass]="{'active': isActive[1]}">
      <button class="personal-list__close--mobile" (click)="toggleDropdown($event, 1)">Fermer le dropdown mes liens</button>
      <button class="personal-dropdown-link" (click)="openDialogLinks()"></button>
      <span class="personal-dropdown__title">
        <span class="icon vnf-icons-icon-link"></span>
        <span class="title">Mes liens</span>
      </span>
      <div class="personal-dropdown-list">
        <swiper-container class="swiper-container" #swiperContainer>
          <swiper-slide *ngFor="let bookmark of bookmarks; let i = index">
            <div class="personal-dropdown__item">
              <a href="{{bookmark.url}}" [innerHTML]="bookmark.title" target="_blank"></a>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div class="personal-dropdown-overlay" (click)="toggleDropdown($event, 1)">
    </div>
  </div>

  <!--
  <div class="personal-list__item">
    <a class="personal-list__link" href="#">Lien</a>
    <span>Mes équipes</span>
    <span class="icon vnf-icons-icon-dialogs"></span>
  </div>
  -->

</div>
