import {AfterViewInit, Component, ElementRef, HostListener, Input, Renderer2, ViewChild} from '@angular/core';
import {Event as VnfEvent} from '../../../models/event';
import { NgIf, NgClass } from '@angular/common';
import {environment} from "../../../../environments/environment";
import {GraphApiService} from "../../../services/graph-api.service";

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [
    NgIf,
    NgClass
  ],
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss'
})
export class EventComponent implements AfterViewInit {
  @Input() vnfEvent!: VnfEvent;
  @Input() graphicType: 'horizontal' | 'vertical' = 'vertical';
  isOptionsActive = false;
  alertMessage: { type: string, text: string } | null = null;

  // Références du bouton d'ouverture et du menu
  @ViewChild('optionsOpener', {static: false}) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu') optionsMenu!: ElementRef;
  originalParent!: HTMLElement;

  constructor(private renderer: Renderer2, private elRef: ElementRef, private graphApiService: GraphApiService) {
  }

  ngAfterViewInit(): void {
    this.originalParent = this.optionsMenu.nativeElement.parentElement;
    this.checkWindowWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowWidth();
  }

  /**
   * Permet la fermeture des options pour tout click en dehors de son propre toggle
   */
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      if (!clickedInsideOpener) {
        this.isOptionsActive = false;
      }
    }
  }


  /**
   * Affiche un message d'alerte dans le composant Event
   * Le message peut être de type 'success' ou 'error'
   * @param type
   * @param text
   */
  displayAlertMessage(type: 'success' | 'error', text: string): void {
    this.alertMessage = {type, text};
    setTimeout(() => {
      // Suppression du message après 7 secondes
      this.alertMessage = null;
    }, 7000);
  }


  // Déplace le menu dans le body pour éviter les problèmes avec la position fixed
  moveElementToBody(): void {
    if (this.optionsMenu.nativeElement.parentElement !== document.body) {
      this.renderer.appendChild(document.body, this.optionsMenu.nativeElement);
    }
  }

  // Replace l'élément dans son parent d'origine pour garder sa position absolute
  moveElementBack(): void {
    // Replace l'élément dans son parent d'origine s'il a été déplacé
    if (this.optionsMenu.nativeElement.parentElement !== this.originalParent) {
      this.renderer.appendChild(this.originalParent, this.optionsMenu.nativeElement);
    }
  }

  // Vérifie la largeur de la fenêtre pour déplacer le menu dans le dom si besoin
  checkWindowWidth(): void {
    const width = window.innerWidth;
    if (width < 768) {
      this.moveElementToBody();
    } else {
      this.moveElementBack();
    }
  }

  // Toggle d'ouverture/fermeture des options
  toggleOptions(event: any): void {
    this.isOptionsActive = !event.isOptionsActive;
  }


  // Ajout de l'événement au calendrier du user connecté
  /**
   * Ajoute l'événement au calendrier au clic sur le bouton "Ajouter au calendrier"
   */
  async addToCalendar(): Promise<void> {
    let existingEvent;
    try {
      existingEvent = await this.graphApiService.findEventBySubjectAndStartDate(this.vnfEvent.text, this.vnfEvent.eventStartDate);
    } catch (error) {
      this.displayAlertMessage('error', 'Une erreur s\'est produite lors l\'accès au calendrier');
      environment.enableLogging && console.log('error', error);
      return;
    }

    if (existingEvent) {
      // Affichage d'un message d'erreur si l'événement est déjà dans le calendrier
      this.displayAlertMessage('error', 'Cet évènement est déjà présent dans votre calendrier');
      return;
    }

    // Ajoute l'événement au calendrier
    this.graphApiService.createCalendarEvent(this.vnfEvent)
      .then(() => {
        this.displayAlertMessage('success', 'Événement ajouté au calendrier');
      }).catch((error) => {
      let errorMessage = 'Une erreur s\'est produite';
      this.displayAlertMessage('error', errorMessage);
      environment.enableLogging && console.log('error', error);
    });
  }


  // Téléchargement de l'événement au format ICS (à conserver)
  downloadICS(event: {
    title: string,
    description: string,
    location: string | null,
    startDate: Date,
    endDate: Date
  }): void {
    const icsContent = this.generateICSContent(event);
    this.downloadFile(icsContent, 'event.ics');
  }

  private generateICSContent(event: {
    title: string,
    description: string,
    location: string | null,
    startDate: Date,
    endDate: Date
  }): string {
    return `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//VNF//NONSGML v1.0//EN
BEGIN:VEVENT
UID:${Date.now()}
DTSTAMP:${this.formatDate(new Date())}
DTSTART:${this.formatDate(event.startDate)}
DTEND:${this.formatDate(event.endDate)}
SUMMARY:${event.title}
DESCRIPTION:${event.description}
${event.location ? 'LOCATION:' + event.location : ''}
END:VEVENT
END:VCALENDAR`;
  }

  private formatDate(date: Date): string {
    return date.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
  }

  private downloadFile(content: string, filename: string): void {
    const blob = new Blob([content], {type: 'text/calendar;charset=utf-8;'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

}
